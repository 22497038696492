import classNames from 'classnames';
import { Chip, ChipProps } from 'primereact/chip';
import React from 'react';
import styled from 'styled-components';

const StyledAppChip = styled(Chip)`
  font-size: var(--small-font-size);
  line-height: var(--small-line-height);
  border-radius: var(--small-border-radius);

  &.chip-primary {
    color: var(--green-dark);
    background: var(--green-pale);
  }

  &.chip-secondary {
    color: var(--black-main);
    background: var(--gray-pale);
  }

  &.chip-error {
    color: var(--red-main);
    background: var(--red-pale);
  }
`;

type AppChipProps = {
  label: string | undefined;
  type: 'primary' | 'secondary' | 'error';
  className?: string;
};

const AppChip: React.FC<
  AppChipProps & ChipProps & React.HTMLAttributes<any>
> = ({ label, type, className, ...rest }) => {
  const styles = classNames(
    {
      [`chip-${type}`]: type,
    },
    className,
  );

  return <StyledAppChip label={label} className={styles} {...rest} />;
};

export default AppChip;
