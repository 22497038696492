import { GroupCondition } from '@/api/enums';
import { equal, group as groupFilter, nested } from '@/api/helpers';
import { Account } from '@/client/accounts';
import { Branch } from '@/client/branches';
import {
  BranchStatusEnum,
  CourseEnrollmentTypeEnum,
  CourseEntityScheduleEnum,
  CourseScheduleType,
  EnrollFormValues,
  UserStatusEnum,
} from '@/client/courses';
import { Group } from '@/client/groups';
import { User } from '@/client/users';
import { courseScheduleFactory } from '@/components/courses/modals/courseSchedule.factory';
import { FormikSwitch } from '@/components/form';
import {
  BranchesMultiselectInput,
  GroupsMultiselectInput,
  UsersMultiselectInput,
} from '@/components/form/selectors';
import { AppButton } from '@/ui/buttons';
import { AppCalendar } from '@/ui/calendar';
import { FlexContainer } from '@/ui/styled-ui';
import { Field, Form, Formik } from 'formik';
import moment from 'moment';
import { Dialog, DialogProps } from 'primereact/dialog';
import { Message } from 'primereact/message';
import { FormEvent } from 'primereact/ts-helpers';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { AnyObject, ObjectSchema } from 'yup';

const StyledDialog = styled(Dialog)`
  width: 500px;
`;

type EnrollModalProps = {
  onSubmit: (data: EnrollFormValues) => void;
  account: Account;
  type?: CourseEnrollmentTypeEnum;
  branch?: Branch;
  group?: Group;
  user?: User;
  enrollType: CourseEntityScheduleEnum | undefined;
  maxDate?: Date;
  courseId?: string;
} & DialogProps;

export const EnrollModal: React.FC<EnrollModalProps> = ({
  enrollType,
  onSubmit,
  onHide,
  account,
  branch,
  group,
  user,
  visible,
  maxDate,
  type,
  courseId,
}) => {
  const { t } = useTranslation();
  const [initialValues, setInitialValues] = useState<EnrollFormValues>({
    courses: [],
    branches: branch ? [branch] : [],
    groups: group ? [group] : [],
    users: user ? [user] : [],
    accounts: [account],
    date: null,
    autoEnroll: false,
    type: CourseScheduleType.STATIC,
  });
  const [warningMessage, setWarningMessage] = useState<string>();
  const [modalHeading, setModalHeading] = useState<string>();
  const [validationSchema, setValidationSchema] =
    useState<ObjectSchema<AnyObject>>();

  useEffect(() => {
    if (!type) return;
    const callbacks = courseScheduleFactory(
      t,
      type,
      account,
      branch,
      group,
      user,
    );

    if (enrollType && callbacks[enrollType]) {
      callbacks[enrollType](
        setWarningMessage,
        setModalHeading,
        setValidationSchema,
      );
    }

    setInitialValues({
      courses: [],
      branches: branch ? [branch] : [],
      groups: group ? [group] : [],
      users: user ? [user] : [],
      accounts: [account],
      date: null,
      autoEnroll: false,
      type: CourseScheduleType.STATIC,
    });
  }, [enrollType, account, branch, group, user, type]);
  const isEnroll = type === CourseEnrollmentTypeEnum.ENROLL;

  const filters = isEnroll
    ? [equal('account', account?.id), equal('active', true)]
    : [
        nested('account', [equal('id', account?.id)]),
        groupFilter(GroupCondition.OR, [
          equal('status', BranchStatusEnum.ENROLLED),
          equal('status', UserStatusEnum.COMPLETED),
          equal('status', UserStatusEnum.IN_PROGRESS),
        ]),
      ];

  return (
    <StyledDialog
      blockScroll
      visible={visible}
      header={<h1>{modalHeading}</h1>}
      onHide={onHide}
      draggable={false}
      data-testid={`${type}-${enrollType}-modal`}
    >
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        validateOnMount
        onSubmit={onSubmit}
      >
        {({ setFieldValue, values, errors }) => {
          return (
            <Form>
              {enrollType === CourseEntityScheduleEnum.BRANCH && !branch && (
                <div className="field w-full">
                  <label htmlFor="branches">
                    {`${t('branches')} (${values?.branches?.length ?? 0})`}
                  </label>
                  <BranchesMultiselectInput
                    courseId={courseId}
                    className="w-full"
                    onChange={(branches) => setFieldValue('branches', branches)}
                    additionalFilters={filters}
                    selectedOptions={values.branches}
                  />
                </div>
              )}

              {enrollType === CourseEntityScheduleEnum.GROUP && !group && (
                <div className="field w-full">
                  <label htmlFor="groups">
                    {`${t('groups')} (${values?.groups?.length ?? 0})`}
                  </label>
                  <GroupsMultiselectInput
                    courseId={courseId}
                    className="w-full"
                    onChange={(groups) => setFieldValue('groups', groups)}
                    additionalFilters={filters}
                    selectedOptions={values.groups}
                  />
                </div>
              )}

              {enrollType === CourseEntityScheduleEnum.USER && !user && (
                <div className="field w-full">
                  <label htmlFor="users">
                    {`${t('users')} (${values?.users?.length ?? 0})`}
                  </label>
                  <UsersMultiselectInput
                    courseId={courseId}
                    className="w-full"
                    onChange={(users) => setFieldValue('users', users)}
                    additionalFilters={filters}
                    selectedOptions={values.users}
                  />
                </div>
              )}

              {isEnroll && (
                <div className="field w-full mb-4">
                  <label>{t('generic.enrollDate')}</label>
                  <AppCalendar
                    id="enroll-date"
                    name="enrollDate"
                    className="w-full"
                    placeholder={t('courses.select.enrollDate')}
                    minDate={new Date()}
                    maxDate={maxDate ? new Date(maxDate as Date) : undefined}
                    value={values.date}
                    onChange={(event: FormEvent) => {
                      setFieldValue(
                        'date',
                        moment(event.value?.toString()).utc(true).toDate(),
                      );
                    }}
                    readOnlyInput
                  />
                </div>
              )}

              {!isEnroll && enrollType === CourseEntityScheduleEnum.ACCOUNT && (
                <div>
                  {t('courses.unenroll.accountName.message', {
                    name: account.name,
                  })}
                </div>
              )}

              {isEnroll && enrollType !== CourseEntityScheduleEnum.USER && (
                <div className="field-checkbox mt-3">
                  <Field
                    inputId="autoEnroll"
                    name="autoEnroll"
                    label={t('courses.autoEnroll')}
                    value={values.autoEnroll}
                    component={FormikSwitch}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      setFieldValue('autoEnroll', e.target.value);
                    }}
                  />
                </div>
              )}

              {isEnroll && <Message severity="warn" text={warningMessage} />}

              <FlexContainer justify="flex-end" className="mt-5">
                <AppButton
                  label={t('button.cancel')}
                  severity="secondary"
                  type="outlined"
                  onClick={onHide}
                  className="mr-3"
                  data-testid={`enroll-${enrollType}-cancel-form`}
                />
                <AppButton
                  label={isEnroll ? t('generic.enroll') : t('generic.unenroll')}
                  isSubmit
                  isDisabled={!!Object.keys(errors).length}
                  data-testid={`${type}-${enrollType}-submit-form`}
                />
              </FlexContainer>
            </Form>
          );
        }}
      </Formik>
    </StyledDialog>
  );
};
