import { handleAxiosError } from '@/api/helpers';
import { AppBreadCrumbTemplate } from '@/app/AppBreadCrumbTemplate';
import { client } from '@/client';
import { Account } from '@/client/accounts';
import { SendTestEmailFormValues } from '@/client/campaigns';
import { SecurityCultureFormValues } from '@/client/security-culture/types';
import {
  LoadingStatuses,
  RedirectPaths,
  RedirectPathsEnum,
} from '@/common/constants';
import { TranslationFunctionType } from '@/common/types';
import { SecurityCultureForm } from '@/components/security-culture';
import { useNotifications } from '@/hooks/notifications.hook';
import { useSendEmail } from '@/hooks/query/security-culture.hooks';
import { useAppSelector } from '@/hooks/store';
import { useToast } from '@/hooks/useToast';
import { selectCurrentAccount } from '@/store/features/account';
import { AppBreadCrumb } from '@/ui/breadcrumb';
import { FlexContainer } from '@/ui/styled-ui';
import { AxiosError } from 'axios';
import { t } from 'i18next';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { v4 } from 'uuid';

const StyledText = styled.div`
  line-height: var(--small-line-height);
  font-size: var(--small-font-size);
  margin-block: var(--default-padding);
`;

const pathItems = (account: Account, t: TranslationFunctionType) => [
  {
    label: account?.name,
    url: RedirectPaths[RedirectPathsEnum.EDIT_ACCOUNT](account?.id),
    template: AppBreadCrumbTemplate,
  },
  {
    label: t('security.culture'),
    url: RedirectPaths[RedirectPathsEnum.SECURITY_CULTURE](),
    template: AppBreadCrumbTemplate,
  },
];

export const SecurityCulturePage: React.FC = () => {
  const currentAccount = useAppSelector(selectCurrentAccount);
  const toast = useToast();
  const [emailId, setEmailId] = useState<string | undefined>(undefined);

  const {
    lastMessage,
    setNotificationParam: setAccountId,
    notificationParam: notificationAccountId,
    isConnected,
  } = useNotifications(client.accounts.accountsNotifyUrl);

  useEffect(() => {
    if (!notificationAccountId) return;
    handleNotificationTestEmail(lastMessage);
  }, [JSON.stringify(lastMessage)]);

  const handleNotificationTestEmail = (messages: any) => {
    const event = messages?.data?.event;
    const payload = messages?.data?.payload;

    if (
      !event ||
      !payload ||
      !['account.security-culture.emails'].includes(event) ||
      payload?.emailId !== emailId ||
      payload?.id !== currentAccount?.id
    )
      return;

    if (event === 'account.security-culture.emails') {
      if (payload?.success) {
        toast?.success(
          t('toast.success'),
          t('security.culture.emailsSent'),
          5000,
        );
      } else if (payload?.error) {
        toast?.error(
          t('toast.error'),
          t('security.culture.emailsNotSent', {
            error: payload.error?.message,
          }),
          5000,
        );
      }
    }

    setEmailId(undefined);
    setAccountId(undefined);
  };

  const handleFormSubmit = async (data: SecurityCultureFormValues) => {
    await SendEmails({
      branches: data.branches,
      groups: data.groups,
      users: data.users,
      url: data.url,
    });
  };

  const handleSendTestEmail = async (
    data: SecurityCultureFormValues,
    test: SendTestEmailFormValues,
  ) => {
    await SendEmails({
      branches: [],
      groups: [],
      users: [],
      url: data.url,
      test: {
        email: test.email,
        firstName: test.firstName,
        lastName: test.lastName,
      },
    });
  };

  const sendServeyEmails = useSendEmail();

  const SendEmails = async (data: SecurityCultureFormValues) => {
    const emailId = v4();
    setEmailId(emailId);
    setAccountId(currentAccount?.id);

    // Wait for a notify connection to establish
    await new Promise((resolve) => {
      const intervalId = setInterval(() => {
        if (isConnected.current) {
          clearInterval(intervalId);
          resolve(true);
        }
      }, 100);
    });

    try {
      if (isConnected.current) {
        await sendServeyEmails.send({
          accountId: currentAccount?.id as string,
          branches: data.branches
            ? data.branches.map((branch) => branch.id)
            : [],
          groups: data.groups ? data.groups.map((group) => group.id) : [],
          users: data.users ? data.users.map((user) => user.id) : [],
          url: data.url,
          test: data.test
            ? {
                email: data.test.email,
                firstName: data.test.firstName,
                lastName: data.test.lastName,
              }
            : undefined,
          emailId,
        });
      }
    } catch (e) {
      handleAxiosError(e as AxiosError, toast);
    }
  };

  return (
    <FlexContainer direction="column" align="flex-start">
      <div className="w-full">
        <AppBreadCrumb model={pathItems(currentAccount as Account, t)} />
        <h1>{t('security.culture')}</h1>
        <StyledText>{t('security.culture.descr')}</StyledText>

        {currentAccount && (
          <SecurityCultureForm
            onSubmit={handleFormSubmit}
            onSendTestEmail={handleSendTestEmail}
            account={currentAccount}
            loading={emailId ? LoadingStatuses.LOADING : LoadingStatuses.IDLE}
          />
        )}
      </div>
    </FlexContainer>
  );
};
