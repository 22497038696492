export enum AccountTestEmailResultEvents {
  SUCCESS = 'account.test.email.sent',
  ERROR = 'account.test.email.failed',
}

export enum DateFormats {
  DATEONLY_SHORT = 'DD MMM',
  DATEONLY = 'MMM DD, YYYY',
  TIMEDATE = 'MMM DD, YYYY HH:mm',
  DATEONLY_FULL_MONTH = 'MMMM Do YYYY',
}
