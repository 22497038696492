import { handleAxiosError } from '@/api/helpers';
import { FiltersType } from '@/api/types';
import { client } from '@/client';
import { ExportUsersReportFormValues } from '@/client/reports';
import { UsersExportFormValues } from '@/client/users';
import { LoadingStatuses } from '@/common/constants';
import { useNotifications } from '@/hooks/notifications.hook';
import { useAppSelector } from '@/hooks/store';
import { useToast } from '@/hooks/useToast';
import { selectCurrentAccount } from '@/store/features/account';
import { AppButton } from '@/ui/buttons';
import { FlexContainer } from '@/ui/styled-ui';
import { AxiosError } from 'axios';
import { Field, Form, Formik } from 'formik';
import { Dialog, DialogProps } from 'primereact/dialog';
import { Message } from 'primereact/message';
import { RadioButtonChangeEvent } from 'primereact/radiobutton';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { v4 } from 'uuid';
import { FormikRadio } from '../form';

const StyledDialog = styled(Dialog)`
  width: 500px;
`;

type UsersExportModalProps = {
  defaultFilters: FiltersType | undefined;
  filters: FiltersType | undefined;
} & DialogProps;

export const UsersExportModal: React.FC<UsersExportModalProps> = ({
  defaultFilters,
  filters,
  onHide,
  visible,
}) => {
  const { t } = useTranslation();
  const toast = useToast();
  const account = useAppSelector(selectCurrentAccount);
  const [initialValues, setInitialValues] =
    useState<ExportUsersReportFormValues>({
      isAllData: true,
    });
  const [isDownloading, setIsDownloading] = useState<boolean>(false);
  const [downloadUrl, setDownloadUrl] = useState();
  const [exportKey, setExportKey] = useState<string>();

  const exportAwarenessReport = (isAllData: boolean) => {
    if (!account?.id) return;
    const usersExportKey = v4();
    client.users.exportUsers({
      filters: isAllData
        ? defaultFilters
        : [...(defaultFilters ?? []), ...(filters ?? [])],
      exportKey: usersExportKey,
      accountId: account?.id,
    });

    setExportKey(usersExportKey);
  };

  const {
    lastMessage,
    setNotificationParam: setAccountId,
    notificationParam: accountId,
    isConnected,
  } = useNotifications(client.accounts.accountsNotifyUrl);

  const exportUsers = async ({ isAllData }: UsersExportFormValues) => {
    if (account) {
      setIsDownloading(true);
      setAccountId(account?.id);
      setDownloadUrl(undefined);

      // Wait for a notify connection to establish
      await new Promise((resolve) => {
        const intervalId = setInterval(() => {
          if (isConnected) {
            clearInterval(intervalId);
            resolve(true);
          }
        }, 100);
      });

      try {
        if (isConnected) {
          exportAwarenessReport(isAllData);
        }
      } catch (e) {
        setIsDownloading(false);
        handleAxiosError(e as Error | AxiosError, toast);
      }
    }
  };

  useEffect(() => {
    if (!accountId) return;
    handleSyncNotification(lastMessage);
  }, [JSON.stringify(lastMessage)]);

  const handleSyncNotification = async (messages: { data: any } | null) => {
    if (
      !messages?.data?.event ||
      !['users.export.finished', 'users.export.failed'].includes(
        messages?.data?.event,
      ) ||
      messages.data.payload?.exportKey !== exportKey
    )
      return;

    if (
      messages.data.event === 'users.export.finished' &&
      messages.data.payload?.exportKey === exportKey
    ) {
      setDownloadUrl(messages.data.payload.downloadUrl);
    }

    if (messages.data.event === 'users.export.failed') {
      toast?.error(t('toast.error'), messages.data.payload.error);
    }

    setIsDownloading(false);
    setAccountId(undefined);
  };

  return (
    <StyledDialog
      blockScroll
      visible={visible}
      header={<h1>{t('generic.export')}</h1>}
      onHide={() => {
        onHide();
        setDownloadUrl(undefined);
      }}
      draggable={false}
      data-testid="reports-export-modal"
      closable={!isDownloading}
    >
      <Formik initialValues={initialValues} onSubmit={exportUsers}>
        {({ setFieldValue, values }) => (
          <Form>
            <FlexContainer gap={24} justify="flex-start" className="mb-4">
              <div className="field-radio">
                <Field
                  id="exportType"
                  type="radio"
                  name="exportType"
                  value={true}
                  label={t('reports.export.allData')}
                  shapeType="circle"
                  onChange={(e: RadioButtonChangeEvent) => {
                    setDownloadUrl(undefined);
                    setFieldValue('isAllData', e.value);
                  }}
                  checked={!!values.isAllData}
                  component={FormikRadio}
                  disabled={isDownloading}
                />
              </div>
              <div className="field-radio">
                <Field
                  id="exportType"
                  type="radio"
                  name="exportType"
                  value={false}
                  label={t('reports.export.filteredData')}
                  shapeType="circle"
                  onChange={(e: RadioButtonChangeEvent) => {
                    setDownloadUrl(undefined);
                    setFieldValue('isAllData', e.value);
                  }}
                  checked={!values.isAllData}
                  component={FormikRadio}
                  disabled={isDownloading}
                />
              </div>
            </FlexContainer>

            {downloadUrl && (
              <FlexContainer>
                <Message
                  severity="success"
                  text={
                    <div>
                      {t('reports.export.ready.p1')}{' '}
                      <a href={downloadUrl}>{t('reports.export.ready.p2')}</a>
                    </div>
                  }
                />
              </FlexContainer>
            )}

            <FlexContainer justify="flex-end" className="mt-5">
              <AppButton
                label={t('button.cancel')}
                severity="secondary"
                type="outlined"
                onClick={() => {
                  onHide();
                  setDownloadUrl(undefined);
                }}
                className="mr-3"
                data-testid="reports-export-cancel-form"
                isDisabled={isDownloading}
              />
              <AppButton
                icon="pi pi-download"
                state={
                  isDownloading ? LoadingStatuses.LOADING : LoadingStatuses.IDLE
                }
                label={t('generic.export')}
                isSubmit
                data-testid="reports-export-submit-form"
                isDisabled={isDownloading}
              />
            </FlexContainer>
          </Form>
        )}
      </Formik>
    </StyledDialog>
  );
};
