import { Account } from '@/client/accounts';
import { Actions, Subjects } from '@/client/users';
import { RedirectPaths, RedirectPathsEnum } from '@/common/constants';
import { TranslationFunctionType } from '@/common/types';
import { useAppSelector } from '@/hooks/store';
import { usePermission } from '@/hooks/usePermission';
import { selectCurrentAccount } from '@/store/features/account';
import { isOutsideOfEfront } from '@/utils/helpers';
import classNames from 'classnames';
import { MenuItem } from 'primereact/menuitem';
import { TabMenu } from 'primereact/tabmenu';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';

type AccountTabsProps = {
  account: Account;
};

const getMenuItems = (
  account: Account,
  currentAccount: Account,
  can: (action: Actions, subject: Subjects) => boolean,
  t: TranslationFunctionType,
): MenuItem[] => {
  const items: MenuItem[] = [
    {
      label: t('generic.info'),
      template: getItemTemplate(
        !currentAccount?.isSystem
          ? RedirectPaths[RedirectPathsEnum.ACCOUNT](account?.id)
          : RedirectPaths[RedirectPathsEnum.EDIT_ACCOUNT](account?.id),
        location.pathname,
      ),
    },
  ];

  if (can(Actions.READ, Subjects.AD_SYNC)) {
    items.push({
      label: t('account.azure'),
      template: getItemTemplate(
        RedirectPaths[RedirectPathsEnum.ACCOUNT_AZURE_AD](account?.id),
        location.pathname,
      ),
    });
  }

  if (can(Actions.READ, Subjects.ACCOUNT_SSO) && isOutsideOfEfront()) {
    items.push({
      label: t('sso'),
      template: getItemTemplate(
        RedirectPaths[RedirectPathsEnum.ACCOUNT_SSO](account?.id),
        location.pathname,
      ),
    });
  }

  if (
    currentAccount &&
    currentAccount?.isSystem &&
    can(Actions.READ, Subjects.ACCOUNTS)
  ) {
    items.push({
      label: t('integrations'),
      template: getItemTemplate(
        RedirectPaths[RedirectPathsEnum.ACCOUNT_INTEGRATIONS](account?.id),
        location.pathname,
      ),
    });
  }

  /*
if (currentAccount?.isSystem && can(Actions.READ, Subjects.ACCOUNTS)) {
items.push({
label: t('saml.config', { name: '' }),
template: getItemTemplate(
RedirectPaths[RedirectPathsEnum.ACCOUNT_SAML_CONFIG](account?.id),
location.pathname,
),
});
}
*/

  if (
    currentAccount?.isSystem &&
    can(Actions.READ, Subjects.USERS_MIGRATION) &&
    can(Actions.READ, Subjects.ACCOUNT_MIGRATION)
  ) {
    items.push({
      label: t('migration'),
      template: getItemTemplate(
        RedirectPaths[RedirectPathsEnum.ACCOUNT_MIGRATION](account?.id),
        location.pathname,
      ),
    });
  }

  if (
    currentAccount?.isSystem &&
    can(Actions.CREATE, Subjects.COURSE_ACCOUNT_AVAILABILITY)
  ) {
    items.push(
      {
        label: t('courses'),
        template: getItemTemplate(
          RedirectPaths[RedirectPathsEnum.ACCOUNT_COURSES](account?.id),
          location.pathname,
        ),
      },
      {
        label: t('documents'),
        template: getItemTemplate(
          RedirectPaths[RedirectPathsEnum.ACCOUNT_SECURITY_DOCUMENTS](
            account?.id,
          ),
          location.pathname,
        ),
      },
    );
  }

  return items;
};

const getItemTemplate = (url: string, current: string) => (item: MenuItem) =>
  (
    <Link
      to={url}
      className={classNames({
        'p-menuitem-link': true,
        'p-menuitem-link-active': current.includes(url),
      })}
      role="menuitem"
    >
      <span className="p-menuitem-text">{item.label}</span>
    </Link>
  );

export const AccountTabs: React.FC<AccountTabsProps> = ({ account }) => {
  const { t } = useTranslation();
  const location = useLocation();
  const currentAccount = useAppSelector(selectCurrentAccount);
  const { can } = usePermission();

  const [activeIndex, setActiveIndex] = useState<number>(0);

  useEffect(() => {
    switch (location.pathname) {
      case RedirectPaths[RedirectPathsEnum.EDIT_ACCOUNT](account?.id):
        setActiveIndex(0);
        break;
      case RedirectPaths[RedirectPathsEnum.ACCOUNT](account?.id):
        setActiveIndex(0);
        break;
      case RedirectPaths[RedirectPathsEnum.ACCOUNT_AZURE_AD](account?.id):
        setActiveIndex(1);
        break;
      case RedirectPaths[RedirectPathsEnum.ACCOUNT_SSO](account?.id):
        setActiveIndex(2);
        break;
      case RedirectPaths[RedirectPathsEnum.ACCOUNT_INTEGRATIONS](account?.id):
        if (can(Actions.READ, Subjects.ACCOUNTS)) {
          setActiveIndex(3);
        }
        break;
      case RedirectPaths[RedirectPathsEnum.ACCOUNTS_INTEGRATIONS_GO_PHISH](
        account?.id,
      ):
        if (can(Actions.READ, Subjects.ACCOUNTS)) {
          setActiveIndex(3);
        }
        break;
      case RedirectPaths[RedirectPathsEnum.ACCOUNTS_INTEGRATIONS_HUBSPOT](
        account?.id,
      ):
        if (can(Actions.READ, Subjects.ACCOUNTS)) {
          setActiveIndex(3);
        }
        break;
      /*
case RedirectPaths[RedirectPathsEnum.ACCOUNT_SAML_CONFIG](account?.id):
if (can(Actions.READ, Subjects.ACCOUNTS)) {
setActiveIndex(4);
}
break;
*/
      case RedirectPaths[RedirectPathsEnum.ACCOUNT_MIGRATION](account?.id):
        if (
          can(Actions.READ, Subjects.USERS_MIGRATION) &&
          can(Actions.READ, Subjects.ACCOUNT_MIGRATION) &&
          currentAccount?.isSystem
        ) {
          setActiveIndex(4);
        }
        break;
      case RedirectPaths[RedirectPathsEnum.ACCOUNT_COURSES](account?.id):
        if (currentAccount?.isSystem) {
          setActiveIndex(5);
        }
        break;
      case RedirectPaths[RedirectPathsEnum.ACCOUNT_SECURITY_DOCUMENTS](
        account?.id,
      ):
        if (currentAccount?.isSystem) {
          setActiveIndex(6);
        }
        break;
      default:
        setActiveIndex(0);
    }
  }, [location.pathname, account]);

  return (
    <TabMenu
      model={getMenuItems(
        account as Account,
        currentAccount as Account,
        can,
        t,
      )}
      activeIndex={activeIndex}
      onTabChange={() => null}
    />
  );
};
