import { GroupCondition } from '@/api/enums';
import {
  equal,
  getQueryTransformation,
  group,
  like,
  nested,
} from '@/api/helpers';
import { FiltersType } from '@/api/types';
import { CampaignUserResultMessages } from '@/client/campaigns';
import { FilterNamesEnum, getFiltersFromColumns } from '@/client/helpers';
import { UsersSummaryReport } from '@/client/reports';
import { Actions, Subjects } from '@/client/users';
import { TableNamesEnum } from '@/common/constants';
import {
  DataTable,
  DataTableColumnsMultiselect,
  DataTableColumnType,
  DataTableFilters,
  DataTableToolbar,
  FilterTypeEnum,
} from '@/components/tables/crud';
import {
  useBranchesPartialRequest,
  useGroups,
  useUsersSummaryReport,
} from '@/hooks/query';
import { useQueryParams } from '@/hooks/query.hook';
import { useAppSelector } from '@/hooks/store';
import { useTable } from '@/hooks/table.hook';
import { usePermission } from '@/hooks/usePermission';
import { selectCurrentAccount } from '@/store/features/account';
import { DateFormats } from '@/system-settings/enums';
import { FormatDate } from '@/ui/date';
import { FlexContainer } from '@/ui/styled-ui';
import { getCampaignUserResultStatuses } from '@/utils/helpers';
import { getBranchName } from '@/utils/helpers/branches.helper';
import { t } from 'i18next';
import { debounce, uniqueId } from 'lodash';
import { InputText } from 'primereact/inputtext';
import { Tooltip } from 'primereact/tooltip';
import React, { FormEvent, useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';

const StyledDataTable = styled(DataTable)`
  &.p-datatable {
    th[role='columnheader']:last-child .p-column-header-content {
      justify-content: flex-start;
    }

    td[role='cell']:last-child {
      text-align: left;
    }
  }

  .p-datatable-table {
    width: 100%;
  }

  .p-datatable-wrapper .p-datatable-table .icon-column {
    text-align: center !important;

    .p-column-title {
      margin: auto;

      &:before {
        display: block;
        width: 24px;
        height: 24px;
      }
    }

    &.enrolled-courses-icon .p-column-title:before {
      content: url("data:image/svg+xml,%3Csvg width='25' height='25' viewBox='0 0 25 25' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.1484 21.7273C11.0254 20.8119 9.78497 20.0895 8.42729 19.56C7.06959 19.0305 5.6433 18.7145 4.14844 18.6119V9.26574C5.67792 9.35547 7.13112 9.72053 8.50804 10.3609C9.88497 11.0013 11.0984 11.7939 12.1484 12.7388C13.1984 11.7939 14.4119 11.0013 15.7888 10.3609C17.1657 9.72053 18.619 9.35547 20.1484 9.26574V18.6119C18.6497 18.7145 17.2225 19.0305 15.8667 19.56C14.5109 20.0895 13.2715 20.8119 12.1484 21.7273ZM12.1484 20.4734C13.1984 19.7029 14.3151 19.0816 15.4984 18.6094C16.6818 18.1372 17.8984 17.8252 19.1484 17.6734V10.3888C17.842 10.6055 16.5949 11.027 15.4071 11.6532C14.2193 12.2795 13.1331 13.085 12.1484 14.0696C11.1638 13.085 10.0776 12.2795 8.88979 11.6532C7.70197 11.027 6.45485 10.6055 5.14844 10.3888V17.6734C6.39844 17.8252 7.6151 18.1372 8.79844 18.6094C9.98177 19.0816 11.0984 19.7029 12.1484 20.4734ZM12.1484 9.18881C11.26 9.18881 10.4994 8.87246 9.86671 8.23976C9.23401 7.60708 8.91766 6.8465 8.91766 5.95804C8.91766 5.06957 9.23401 4.30899 9.86671 3.67631C10.4994 3.04361 11.26 2.72726 12.1484 2.72726C13.0369 2.72726 13.7975 3.04361 14.4302 3.67631C15.0629 4.30899 15.3792 5.06957 15.3792 5.95804C15.3792 6.8465 15.0629 7.60708 14.4302 8.23976C13.7975 8.87246 13.0369 9.18881 12.1484 9.18881ZM12.1491 8.18881C12.7628 8.18881 13.2879 7.97031 13.7244 7.53331C14.1609 7.09633 14.3792 6.57101 14.3792 5.95736C14.3792 5.34371 14.1607 4.81862 13.7237 4.38209C13.2867 3.94554 12.7614 3.72726 12.1478 3.72726C11.5341 3.72726 11.009 3.94576 10.5725 4.38276C10.1359 4.81974 9.91766 5.34506 9.91766 5.95871C9.91766 6.57236 10.1362 7.09746 10.5732 7.53401C11.0101 7.97054 11.5355 8.18881 12.1491 8.18881Z' fill='%231C1B1F'/%3E%3C/svg%3E");
    }

    &.not-completed-courses-icon .p-column-title:before {
      content: url("data:image/svg+xml,%3Csvg width='25' height='25' viewBox='0 0 25 25' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M21.4711 22.1955L17.9634 18.6878C17.1891 19.3109 16.3298 19.7962 15.3856 20.1436C14.4413 20.4911 13.4333 20.6648 12.3615 20.6648C11.1165 20.6648 9.94652 20.4285 8.85152 19.956C7.75652 19.4835 6.80402 18.8423 5.99402 18.0323C5.18402 17.2223 4.54277 16.2698 4.07027 15.1748C3.59777 14.0798 3.36152 12.9098 3.36152 11.6648C3.36152 10.593 3.53524 9.58496 3.88267 8.64072C4.23011 7.69651 4.71536 6.83721 5.33845 6.06285L2.24805 2.97247L2.96152 2.259L22.1846 21.4821L21.4711 22.1955ZM12.3615 19.6648C13.291 19.6648 14.167 19.5135 14.9894 19.2109C15.8118 18.9084 16.5615 18.4923 17.2384 17.9628L12.8827 13.6071L10.9231 15.5725L7.36537 12.0148L8.07307 11.3071L10.9231 14.1571L12.175 12.8994L6.06345 6.78785C5.53396 7.46478 5.11794 8.21446 4.81537 9.0369C4.51281 9.85933 4.36152 10.7353 4.36152 11.6648C4.36152 13.8814 5.14069 15.7689 6.69902 17.3273C8.25736 18.8856 10.1449 19.6648 12.3615 19.6648ZM19.9865 16.4725L19.2423 15.7282C19.6051 15.1321 19.8824 14.4944 20.074 13.8153C20.2657 13.1362 20.3615 12.4194 20.3615 11.6648C20.3615 9.4481 19.5824 7.5606 18.024 6.00227C16.4657 4.44394 14.5782 3.66477 12.3615 3.66477C11.6115 3.66477 10.8958 3.7606 10.2144 3.95227C9.533 4.14394 8.89422 4.42118 8.29807 4.784L7.55382 4.03977C8.25312 3.60259 9.00706 3.26412 9.81562 3.02437C10.6242 2.78464 11.4728 2.66477 12.3615 2.66477C13.6065 2.66477 14.7765 2.90102 15.8715 3.37352C16.9665 3.84602 17.919 4.48727 18.729 5.29727C19.539 6.10727 20.1803 7.05977 20.6528 8.15477C21.1253 9.24977 21.3615 10.4198 21.3615 11.6648C21.3615 12.5535 21.2417 13.4021 21.0019 14.2107C20.7622 15.0192 20.4237 15.7732 19.9865 16.4725ZM15.0077 11.4878L14.3 10.7801L16.6115 8.46862L17.3192 9.17632L15.0077 11.4878Z' fill='%231C1B1F'/%3E%3C/svg%3E");
    }

    &.phising-campaigns-icon .p-column-title:before {
      content: url("data:image/svg+xml,%3Csvg width='25' height='25' viewBox='0 0 25 25' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M11.8269 20.7273C10.4038 20.7273 9.20192 20.2177 8.22115 19.1984C7.24038 18.1792 6.75 16.9516 6.75 15.5157V10.9388L10.3077 14.5734L9.61923 15.2677L7.75 13.3215V15.5157C7.75 16.667 8.14488 17.6568 8.93463 18.485C9.72436 19.3132 10.6885 19.7273 11.8269 19.7273C12.9654 19.7273 13.9295 19.3132 14.7192 18.485C15.509 17.6568 15.9038 16.667 15.9038 15.5157V12.1484C15.3679 12.0305 14.9263 11.7622 14.5788 11.3436C14.2314 10.925 14.0577 10.4375 14.0577 9.88112C14.0577 9.32471 14.2282 8.834 14.5692 8.409C14.9103 7.984 15.3551 7.71252 15.9038 7.59457V3.72727H16.9038V7.59457C17.4526 7.71252 17.8974 7.984 18.2385 8.409C18.5795 8.834 18.75 9.32471 18.75 9.88112C18.75 10.4375 18.5795 10.926 18.2385 11.3465C17.8974 11.767 17.4526 12.0343 16.9038 12.1484V15.5157C16.9038 16.9516 16.4135 18.1792 15.4327 19.1984C14.4519 20.2177 13.25 20.7273 11.8269 20.7273ZM16.4038 11.2273C16.7769 11.2273 17.0946 11.0962 17.3567 10.834C17.6189 10.5718 17.75 10.2542 17.75 9.88112C17.75 9.50804 17.6189 9.19041 17.3567 8.92822C17.0946 8.66606 16.7769 8.53497 16.4038 8.53497C16.0308 8.53497 15.7131 8.66606 15.451 8.92822C15.1888 9.19041 15.0577 9.50804 15.0577 9.88112C15.0577 10.2542 15.1888 10.5718 15.451 10.834C15.7131 11.0962 16.0308 11.2273 16.4038 11.2273Z' fill='%231C1B1F'/%3E%3C/svg%3E");
    }

    &.emails-sent-icon .p-column-title:before {
      content: url("data:image/svg+xml,%3Csvg width='25' height='25' viewBox='0 0 25 25' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M18.856 20.1215L18.1675 19.433L20.2233 17.333H15.356V16.333H20.2233L18.1675 14.233L18.856 13.5446L22.1444 16.833L18.856 20.1215ZM4.97133 17.333C4.5271 17.333 4.14682 17.1749 3.83048 16.8585C3.51413 16.5422 3.35596 16.1619 3.35596 15.7177V5.94841C3.35596 5.50418 3.51413 5.12389 3.83048 4.80756C4.14682 4.49121 4.5271 4.33303 4.97133 4.33303H17.7406C18.1848 4.33303 18.5651 4.49121 18.8814 4.80756C19.1978 5.12389 19.356 5.50418 19.356 5.94841V11.3561C19.2726 11.3484 19.1893 11.3426 19.106 11.3388C19.0226 11.335 18.9393 11.333 18.856 11.333C18.7726 11.333 18.6893 11.334 18.606 11.3359C18.5226 11.3378 18.4393 11.3427 18.356 11.3503V6.07918L11.256 11.2177L4.35596 6.08496V15.7177C4.35596 15.8971 4.41365 16.0446 4.52903 16.16C4.64442 16.2753 4.79185 16.333 4.97133 16.333H13.3733C13.3656 16.4164 13.3608 16.4997 13.3588 16.583C13.3569 16.6664 13.356 16.7497 13.356 16.833C13.356 16.9164 13.3579 16.9997 13.3617 17.083C13.3656 17.1664 13.3713 17.2497 13.379 17.333H4.97133ZM4.99828 5.33303L11.256 9.99841L17.7021 5.33303H4.99828Z' fill='%231C1B1F'/%3E%3C/svg%3E");
    }

    &.emails-opened-icon .p-column-title:before {
      content: url("data:image/svg+xml,%3Csvg width='25' height='25' viewBox='0 0 25 25' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.75 3.32342L21.2615 8.37727C21.4077 8.48369 21.5257 8.6151 21.6154 8.7715C21.7051 8.92791 21.75 9.09779 21.75 9.28112V19.5157C21.75 19.976 21.5958 20.3603 21.2875 20.6686C20.9792 20.977 20.5949 21.1311 20.1346 21.1311H5.36538C4.90513 21.1311 4.52083 20.977 4.2125 20.6686C3.90417 20.3603 3.75 19.976 3.75 19.5157V9.28112C3.75 9.09779 3.79487 8.92791 3.8846 8.7715C3.97435 8.6151 4.0923 8.48369 4.23845 8.37727L12.75 3.32342ZM12.75 13.7811L20.55 9.13112L12.75 4.48112L4.95 9.13112L12.75 13.7811ZM12.75 14.9388L4.75 10.1773V19.5157C4.75 19.6952 4.80769 19.8427 4.92308 19.958C5.03846 20.0734 5.18589 20.1311 5.36538 20.1311H20.1346C20.3141 20.1311 20.4615 20.0734 20.5769 19.958C20.6923 19.8427 20.75 19.6952 20.75 19.5157V10.1773L12.75 14.9388Z' fill='%231C1B1F'/%3E%3C/svg%3E");
    }

    &.links-clicked-icon .p-column-title:before {
      content: url("data:image/svg+xml,%3Csvg width='25' height='25' viewBox='0 0 25 25' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M8.17285 14.9965L10.5517 11.6696H15.4363L8.17285 5.9619V14.9965ZM13.9094 20.5542L10.5729 13.3696L7.17285 18.1119V3.90035L18.3267 12.6696H12.4382L15.7306 19.7023L13.9094 20.5542Z' fill='%231C1B1F'/%3E%3C/svg%3E");
    }

    &.data-submitted-icon .p-column-title:before {
      content: url("data:image/svg+xml,%3Csvg width='25' height='25' viewBox='0 0 25 25' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.7501 21.0023C12.5475 21.0023 12.3478 20.9619 12.151 20.8811C11.9543 20.8004 11.7751 20.6811 11.6135 20.5234L4.45395 13.3638C4.29625 13.2023 4.17701 13.0231 4.09625 12.8263C4.01548 12.6295 3.9751 12.4298 3.9751 12.2273C3.9751 12.0247 4.01548 11.8241 4.09625 11.6253C4.17701 11.4266 4.29625 11.2484 4.45395 11.0907L11.6135 3.93112C11.7751 3.76957 11.9543 3.64937 12.151 3.57052C12.3478 3.49169 12.5475 3.45227 12.7501 3.45227C12.9527 3.45227 13.1533 3.49169 13.352 3.57052C13.5507 3.64937 13.7289 3.76957 13.8866 3.93112L21.0462 11.0907C21.2078 11.2484 21.328 11.4266 21.4068 11.6253C21.4857 11.8241 21.5251 12.0247 21.5251 12.2273C21.5251 12.4298 21.4857 12.6295 21.4068 12.8263C21.328 13.0231 21.2078 13.2023 21.0462 13.3638L13.8866 20.5234C13.7289 20.6811 13.5507 20.8004 13.352 20.8811C13.1533 20.9619 12.9527 21.0023 12.7501 21.0023ZM13.1924 19.81L20.3328 12.6696C20.4354 12.567 20.4866 12.4196 20.4866 12.2273C20.4866 12.035 20.4354 11.8875 20.3328 11.785L13.1924 4.64457C13.0898 4.542 12.9424 4.49072 12.7501 4.49072C12.5578 4.49072 12.4104 4.542 12.3078 4.64457L5.1674 11.785C5.06483 11.8875 5.01355 12.035 5.01355 12.2273C5.01355 12.4196 5.06483 12.567 5.1674 12.6696L12.3078 19.81C12.4104 19.9125 12.5578 19.9638 12.7501 19.9638C12.9424 19.9638 13.0898 19.9125 13.1924 19.81ZM12.2501 13.4003H13.2501V8.16957H12.2501V13.4003ZM12.7501 15.6311C12.918 15.6311 13.0626 15.5705 13.1837 15.4494C13.3049 15.3282 13.3655 15.1837 13.3655 15.0157C13.3655 14.8478 13.3049 14.7032 13.1837 14.5821C13.0626 14.4609 12.918 14.4003 12.7501 14.4003C12.5821 14.4003 12.4376 14.4609 12.3164 14.5821C12.1953 14.7032 12.1347 14.8478 12.1347 15.0157C12.1347 15.1837 12.1953 15.3282 12.3164 15.4494C12.4376 15.5705 12.5821 15.6311 12.7501 15.6311Z' fill='%231C1B1F'/%3E%3C/svg%3E");
    }
  }
`;
type SummaryReportDatatableProps = {
  setExportFilters: (filters?: FiltersType) => void;
  forceRefetch: boolean;
};

export const SummaryReportDatatable: React.FC<SummaryReportDatatableProps> = ({
  setExportFilters,
  forceRefetch,
}) => {
  const account = useAppSelector(selectCurrentAccount);
  const { can } = usePermission();

  const { skip, take, sort, apiFilters, onFilter, onSort, onPage, setSkip } =
    useTable();

  const [multiSearchValue, setMultiSearchValue] = useState('');
  const debouncedSetMultiSearchValue = useMemo(
    () =>
      debounce((event: FormEvent) => {
        setSkip(0);
        setMultiSearchValue((event.target as HTMLInputElement).value);
      }, 300),
    [],
  );

  const { branches, isLoading: isBranchesLoading } = useBranchesPartialRequest({
    filters: [equal('account', account?.id)],
    sort: ['name,asc'],
  });

  const { groups, isLoading: isGroupsLoading } = useGroups({
    filters: [equal('account', account?.id)],
    sort: ['name,asc'],
  });

  const { params } = useQueryParams(getQueryTransformation<{ name: string }>());
  const { state } = useLocation();

  const columns = [
    {
      field: 'user',
      header: t('user'),
      sortable: false,
      filterable: false,
      render: (row: UsersSummaryReport) => (
        <FlexContainer direction="column" align="flex-start">
          <span>{row?.user?.name}</span>
          <span className="secondary-text mt-1">{row?.user?.email}</span>
        </FlexContainer>
      ),
    },
    {
      field: 'branch',
      header: t('branch'),
      sortable: false,
      filterable: true,
      filters: {
        placeholder: t('filter.branch'),
        field: FilterNamesEnum.SUMMARY_REPORTS_BRANCH,
        type: FilterTypeEnum.MULTI_SELECT,
        options: branches?.map(({ id, name }) => ({
          label: name,
          value: id,
        })),
      },
      className: 'no-padding',
      render: (row: UsersSummaryReport) => {
        const id = uniqueId('branch');
        return (
          <>
            {row?.user?.branch ? (
              <>
                {row?.user?.branch && (
                  <Tooltip
                    position="mouse"
                    target={`.${id}`}
                    content={getBranchName(row.user.branch)
                      .reverse()
                      .join(' / ')}
                  />
                )}
                <div className={id} style={{ minWidth: 'max-content' }}>
                  {branches?.find((x) => x.id === row.user.branch?.id)?.name}
                </div>
              </>
            ) : (
              <div className="group-row">&#8212;</div>
            )}
          </>
        );
      },
    },
    ...(can(Actions.READ, Subjects.GROUPS)
      ? [
          {
            field: 'group',
            header: t('group'),
            sortable: false,
            filterable: true,
            filters: {
              placeholder: t('filter.group'),
              field: FilterNamesEnum.SUMMARY_REPORTS_GROUP,
              type: FilterTypeEnum.MULTI_SELECT,
              options: groups?.result?.map(({ id, name }) => ({
                label: name,
                value: id,
              })),
            },
            className: 'no-padding',
            render: (row: UsersSummaryReport) => {
              const id = uniqueId('group');
              return (
                <>
                  {row?.user?.groups.length ? (
                    row.user.groups.map((group) => (
                      <div key={group.id} style={{ minWidth: 'max-content' }}>
                        {group.name}
                      </div>
                    ))
                  ) : (
                    <div className="group-row">&#8212;</div>
                  )}
                </>
              );
            },
          },
        ]
      : []),
    {
      field: 'enrolledCourses',
      header: () => {
        return (
          <Tooltip
            position="top"
            target="th.enrolled-courses-icon"
            content={t('courses.enrolled')}
          />
        );
      },
      iconLabel: t('courses.enrolled'),
      sortable: false,
      filterable: true,
      filters: {
        label: t('filter.have.enrolledCourses'),
        placeholder: t('generic.select'),
        field: FilterNamesEnum.SUMMARY_HAVE_ENROLLED_COURSES,
        type: FilterTypeEnum.SELECT_YES_NO,
      },
      className: 'icon-column',
      headerClassName: 'icon-column enrolled-courses-icon',
      render: (row: UsersSummaryReport) => row?.enrolledCourses,
    },
    {
      field: 'notCompletedCourses',
      header: (
        <Tooltip
          position="top"
          target="th.not-completed-courses-icon"
          content={t('courses.not.completed')}
        />
      ),
      iconLabel: t('courses.not.completed'),
      sortable: false,
      filterable: true,
      filters: {
        label: t('filter.have.completedAllCourses'),
        placeholder: t('generic.select'),
        field: FilterNamesEnum.SUMMARY_COMPLETED_ALL_COURSES,
        type: FilterTypeEnum.SELECT_YES_NO,
      },
      className: 'icon-column',
      headerClassName: 'icon-column not-completed-courses-icon',
      render: (row: UsersSummaryReport) => row?.notCompletedCourses,
    },
    {
      field: 'phishingCampaigns',
      header: (
        <Tooltip
          position="top"
          target="th.phising-campaigns-icon"
          content={t('campaigns.running')}
        />
      ),
      iconLabel: t('campaigns.running'),
      sortable: false,
      filterable: false,
      className: 'icon-column',
      headerClassName: 'icon-column phising-campaigns-icon',
      render: (row: UsersSummaryReport) => row?.phishingCampaigns,
    },
    {
      field: 'emailsSent',
      header: (
        <Tooltip
          position="top"
          target="th.emails-sent-icon"
          content={t('reports.campaigns.sent')}
        />
      ),
      iconLabel: t('reports.campaigns.sent'),
      className: 'icon-column',
      headerClassName: 'icon-column emails-sent-icon',
      sortable: false,
      filterable: false,
      render: (row: UsersSummaryReport) => row?.emailsSent,
    },
    account?.meta?.showCampaignOpenEvents
      ? {
          field: 'emailsOpened',
          header: (
            <Tooltip
              position="top"
              target="th.emails-opened-icon"
              content={t('campaign.emailOpened')}
            />
          ),
          iconLabel: t('campaign.emailOpened'),
          className: 'icon-column',
          headerClassName: 'icon-column emails-opened-icon',
          sortable: false,
          filterable: false,
          render: (row: UsersSummaryReport) => row?.emailsOpened,
        }
      : null,
    {
      field: 'linksClicked',
      header: (
        <Tooltip
          position="top"
          target="th.links-clicked-icon"
          content={t('campaign.clicked')}
        />
      ),
      iconLabel: t('campaign.clicked'),
      className: 'icon-column',
      headerClassName: 'icon-column links-clicked-icon',
      sortable: false,
      filterable: false,
      render: (row: UsersSummaryReport) => row?.linksClicked,
    },
    {
      field: 'dataSubmitted',
      header: (
        <Tooltip
          position="top"
          target="th.data-submitted-icon"
          content={t('campaign.submitted')}
        />
      ),
      iconLabel: t('campaign.submitted'),
      className: 'icon-column',
      headerClassName: 'icon-column data-submitted-icon',
      sortable: false,
      filterable: false,
      render: (row: UsersSummaryReport) => row?.dataSubmitted,
    },
    {
      field: 'averageScore',
      header: t('generic.averageScore'),
      className: 'no-padding',
      sortable: false,
      filterable: false,
      render: (row: UsersSummaryReport) => row.averageScore ?? 0,
    },
    {
      field: 'lastAction',
      header: t('generic.lastAction'),
      className: 'no-padding',
      style: { minWidth: '150px' },
      sortable: false,
      filterable: true,
      filters: {
        type: FilterTypeEnum.MULTI_SELECT,
        options: getCampaignUserResultStatuses(
          t,
          account?.meta?.showCampaignOpenEvents,
        ),
      },
      render: (row: UsersSummaryReport) => (
        <>
          {row?.lastAction ? (
            <span>{t(CampaignUserResultMessages[row.lastAction])}</span>
          ) : (
            <div className="group-row">&#8212;</div>
          )}
        </>
      ),
    },
    {
      field: 'companyName',
      header: t('generic.companyName'),
      sortable: false,
      filterable: true,
      filters: {
        field: FilterNamesEnum.SUMMARY_REPORTS_COMPANY_NAME,
        type: FilterTypeEnum.TEXT,
        placeholder: t('filter.companyName'),
      },
      className: 'no-padding',
      render: (row: UsersSummaryReport) => (
        <FlexContainer direction="column" align="flex-start">
          {row?.user.meta?.companyName ? (
            <span>{row?.user.meta?.companyName}</span>
          ) : (
            <div className="group-row">&#8212;</div>
          )}
        </FlexContainer>
      ),
    },
    {
      field: 'country',
      header: t('generic.country'),
      sortable: false,
      filterable: true,
      filters: {
        field: FilterNamesEnum.SUMMARY_REPORTS_COUNTRY,
        type: FilterTypeEnum.TEXT,
        placeholder: t('filter.country'),
      },
      className: 'no-padding',
      render: (row: UsersSummaryReport) => (
        <FlexContainer direction="column" align="flex-start">
          {row?.user.meta?.country ? (
            <span>{row?.user.meta?.country}</span>
          ) : (
            <div className="group-row">&#8212;</div>
          )}
        </FlexContainer>
      ),
    },
    {
      field: 'officeLocation',
      header: t('generic.officeLocation'),
      sortable: false,
      filterable: true,
      filters: {
        field: FilterNamesEnum.SUMMARY_REPORTS_OFFICE_LOCATION,
        type: FilterTypeEnum.TEXT,
        placeholder: t('filter.officeLocation'),
      },
      className: 'no-padding',
      render: (row: UsersSummaryReport) => (
        <FlexContainer direction="column" align="flex-start">
          {row?.user.meta?.officeLocation ? (
            <span>{row?.user.meta?.officeLocation}</span>
          ) : (
            <div className="group-row">&#8212;</div>
          )}
        </FlexContainer>
      ),
    },
    {
      field: 'department',
      header: t('generic.department'),
      sortable: false,
      filterable: true,
      filters: {
        field: FilterNamesEnum.SUMMARY_REPORTS_DEPARTMENT,
        type: FilterTypeEnum.TEXT,
        placeholder: t('filter.department'),
      },
      className: 'no-padding',
      render: (row: UsersSummaryReport) => (
        <FlexContainer direction="column" align="flex-start">
          {row?.user.meta?.department ? (
            <span>{row?.user.meta?.department}</span>
          ) : (
            <div className="group-row">&#8212;</div>
          )}
        </FlexContainer>
      ),
    },
    {
      field: 'manager',
      header: t('generic.manager'),
      sortable: false,
      filterable: true,
      filters: {
        field: FilterNamesEnum.SUMMARY_REPORTS_MANAGER,
        type: FilterTypeEnum.TEXT,
        placeholder: t('filter.manager'),
      },
      className: 'no-padding',
      render: (row: UsersSummaryReport) => (
        <FlexContainer direction="column" align="flex-start">
          {row?.user.meta?.manager ? (
            <span>{row?.user.meta?.manager}</span>
          ) : (
            <div className="group-row">&#8212;</div>
          )}
        </FlexContainer>
      ),
    },
    {
      field: 'jobTitle',
      header: t('generic.jobTitle'),
      sortable: false,
      filterable: true,
      filters: {
        field: FilterNamesEnum.SUMMARY_REPORTS_JOB,
        type: FilterTypeEnum.TEXT,
        placeholder: t('filter.jobTitle'),
      },
      className: 'no-padding',
      render: (row: UsersSummaryReport) => (
        <FlexContainer direction="column" align="flex-start">
          {row?.user.meta?.jobTitle ? (
            <span>{row?.user.meta?.jobTitle}</span>
          ) : (
            <div className="group-row">&#8212;</div>
          )}
        </FlexContainer>
      ),
    },
    {
      field: 'mobilePhone',
      header: t('generic.mobilePhone'),
      sortable: false,
      filterable: true,
      filters: {
        field: FilterNamesEnum.SUMMARY_REPORTS_MOBILE_PHONE,
        type: FilterTypeEnum.TEXT,
        placeholder: t('filter.mobilePhone'),
      },
      className: 'no-padding',
      render: (row: UsersSummaryReport) => (
        <FlexContainer direction="column" align="flex-start">
          {row?.user.meta?.mobilePhone ? (
            <span>{row?.user.meta?.mobilePhone}</span>
          ) : (
            <div className="group-row">&#8212;</div>
          )}
        </FlexContainer>
      ),
    },
    {
      field: 'lastLogin',
      header: t('user.lastLogin'),
      sortable: false,
      filterable: false,
      render: (row: UsersSummaryReport) => (
        <FlexContainer direction="column" align="flex-start" width="150px">
          {row?.user?.meta?.lastLogin ? (
            <FormatDate
              date={row?.user?.meta?.lastLogin}
              format={DateFormats.TIMEDATE}
            />
          ) : (
            <span>&#8212;</span>
          )}
        </FlexContainer>
      ),
    },
  ].filter(Boolean) as DataTableColumnType[];

  // Set the preselected columns
  const [visibleColumns, setVisibleColumns] = useState<string[]>([]);
  const defaultVisibleColumns = columns
    .filter(
      (column) =>
        ![
          'companyName',
          'country',
          'officeLocation',
          'department',
          'manager',
          'jobTitle',
          'mobilePhone',
          'lastLogin',
        ].includes(column.field),
    )
    .map((column) => column.field);
  const alwaysVisibleColumns = ['user'];
  //

  const toolbar = (
    <DataTableToolbar justify="space-between">
      <FlexContainer
        justify="space-between"
        gap={8}
        align="flex-start"
        wrap="wrap"
      >
        <DataTableFilters
          filters={getFiltersFromColumns(columns)}
          onFilter={onFilter}
          className="flex-initial"
          forcedFilter={
            state?.isDashboardLink && !!Object.keys(params.filters).length
              ? params.filters
              : undefined
          }
          tableName={TableNamesEnum.SUMMARY_REPORTS}
        />
        <div className="p-input-icon-left flex-auto min-w-300">
          <InputText
            className="w-full"
            onInput={debouncedSetMultiSearchValue}
            placeholder={t('reports.summary.search')}
            autoComplete="off"
          />
          <i className="pi pi-search" />
        </div>
      </FlexContainer>
      <DataTableColumnsMultiselect
        columns={columns}
        tableName={TableNamesEnum.SUMMARY_REPORTS}
        visibleColumns={visibleColumns}
        setVisibleColumns={setVisibleColumns}
        defaultVisibleColumns={defaultVisibleColumns}
        alwaysVisibleColumns={alwaysVisibleColumns}
      />
    </DataTableToolbar>
  );

  const multiSearchFilters = multiSearchValue.length
    ? [
        group(GroupCondition.OR, [
          nested('user', [like('email', multiSearchValue)]),
          nested('user', [like('name', multiSearchValue)]),
        ]),
      ]
    : [];

  const onSuccess = () =>
    setExportFilters([...apiFilters, ...multiSearchFilters]);

  const { isLoading, usersSummaryReport, refetch } = useUsersSummaryReport(
    account?.id || '',
    {
      take,
      skip,
      filters: [...apiFilters, ...multiSearchFilters],
    },
    onSuccess,
  );

  useEffect(() => {
    if (forceRefetch) {
      refetch();
    }
  }, [forceRefetch]);

  return (
    <StyledDataTable
      data={usersSummaryReport?.result}
      count={usersSummaryReport?.count as number}
      isLoading={isLoading || isBranchesLoading || isGroupsLoading}
      toolbar={toolbar}
      columns={columns}
      visibleColumns={visibleColumns}
      onPage={onPage}
      rows={take}
      skip={skip}
      onSort={onSort}
      sort={sort}
    />
  );
};
