import { handleAxiosError, isIn } from '@/api/helpers';
import { AppBreadCrumbTemplate } from '@/app/AppBreadCrumbTemplate';
import { client } from '@/client';
import {
  Campaign,
  CampaignActionsEnum,
  CampaignFormValues,
} from '@/client/campaigns';
import {
  LoadingStatuses,
  RedirectPaths,
  RedirectPathsEnum,
} from '@/common/constants';
import { CampaignForm } from '@/components/campaigns/forms';
import { StateProvider } from '@/components/state-provider';
import { useNotifications } from '@/hooks/notifications.hook';
import {
  useCampaign,
  useCampaignAction,
  useUpdateCampaign,
} from '@/hooks/query';
import { useAppSelector } from '@/hooks/store';
import { ToastType, useToast } from '@/hooks/useToast';
import { selectCurrentAccount } from '@/store/features/account';
import { selectCurrentUserState } from '@/store/features/users';
import { AppBreadCrumb } from '@/ui/breadcrumb';
import { FlexContainer } from '@/ui/styled-ui';
import {
  getCampaignSubmitValueByStep,
  getCampaignTemplateSubmitValueByStep,
  partialRequests,
  prepareRequestData,
  queryStateConverter,
  setCampaignInitialValues,
} from '@/utils/helpers';
import { HubspotProperty, hubspotTrack, HubspotValue } from '@/utils/hubspot';
import { AxiosError } from 'axios';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

export const UpdateCampaignPage = () => {
  const { t } = useTranslation();
  const toast = useToast();
  const { id } = useParams();
  const { state } = useLocation();
  const isTemplate = !!state?.isTemplate;
  const { user } = useAppSelector(selectCurrentUserState);
  const { campaign, isLoading } = useCampaign({ campaignId: id });

  const [activeStep, setActiveStep] = useState(state?.activeStep ?? 0);
  const [initialValues, setInitialValues] = useState<
    CampaignFormValues | undefined
  >(undefined);

  useEffect(() => {
    if (!campaign) return;
    const fillValues = async () => {
      try {
        const result = await partialRequests(
          [isIn('campaigns', [campaign.id])],
          client.users.getUsers,
        );

        setInitialValues(setCampaignInitialValues(campaign, result));
      } catch (e) {
        handleAxiosError(e as Error | AxiosError, toast);
      }
    };

    fillValues();
  }, [campaign]);

  useEffect(() => {
    if (user?.account?.freeTrialEndsAt) {
      hubspotTrack({
        [HubspotProperty.PHISHING_CAMPAIGN_VIEW]: HubspotValue.YES,
      });
    }
  }, []);

  const handleOnStepChange = (step: number) => setActiveStep(step);

  return (
    <StateProvider
      state={campaign && initialValues}
      isLoading={isLoading || !initialValues}
      loadingMessage={t('campaign.loading.data')}
      errorMessage={t('campaign.loading.data.failed')}
    >
      <UpdateCampaignWithState
        campaign={campaign as Campaign}
        initialValues={initialValues as CampaignFormValues}
        onStepChange={handleOnStepChange}
        activeStep={activeStep}
        isTemplate={isTemplate}
      />
    </StateProvider>
  );
};

export const UpdateCampaignWithState: React.FC<{
  campaign: Campaign;
  initialValues: CampaignFormValues;
  activeStep: number;
  onStepChange: (step: number) => void;
  isTemplate: boolean;
}> = ({ campaign, initialValues, onStepChange, activeStep, isTemplate }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const toast = useToast();

  const account = useAppSelector(selectCurrentAccount);

  const {
    lastMessage,
    setNotificationParam: setCampaignId,
    notificationParam: notificationCampaignId,
    isConnected,
  } = useNotifications(client.campaigns.campaignsNotifyUrl);
  const updateCampaign = useUpdateCampaign();
  const campaignAction = useCampaignAction();

  const [launchState, setLaunchState] = useState<
    typeof LoadingStatuses.IDLE | typeof LoadingStatuses.LOADING
  >(LoadingStatuses.IDLE);

  useEffect(() => {
    if (!notificationCampaignId) return;
    handleNotifyCampaignLaunch(lastMessage);
  }, [JSON.stringify(lastMessage)]);

  const handleNotifyCampaignLaunch = (messages: any) => {
    if (
      !messages?.data?.event ||
      ![
        'campaign.create.sync.finished',
        'campaign.create.sync.failed',
      ].includes(messages?.data?.event)
    )
      return;

    const statuses: {
      [key: string]: [ToastType | undefined, string, string];
    } = {
      'campaign.create.sync.finished': [
        toast?.success,
        'toast.success',
        'campaign.success.launch',
      ],
      'campaign.create.sync.failed': [
        toast?.info,
        'toast.info',
        'campaign.fail.launch',
      ],
    };

    const [callback, title, description] = statuses[messages?.data?.event];

    callback && callback(t(title), t(description));

    setLaunchState(LoadingStatuses.IDLE);
    setCampaignId(undefined);

    navigate(RedirectPaths[RedirectPathsEnum.CAMPAIGNS]());
  };

  const pathItems = [
    {
      label: account?.name,
      url: RedirectPaths[RedirectPathsEnum.EDIT_ACCOUNT](account?.id as string),
      template: AppBreadCrumbTemplate,
    },
    {
      label: t('campaigns'),
      url: RedirectPaths[RedirectPathsEnum.CAMPAIGNS](),
      template: AppBreadCrumbTemplate,
    },
    {
      label: t('generic.edit.name', { name: campaign?.name }),
      url: RedirectPaths[RedirectPathsEnum.CAMPAIGNS_EDIT](
        campaign?.id as string,
      ),
      template: AppBreadCrumbTemplate,
    },
  ];

  const handleSave = async (data: CampaignFormValues) => {
    const payload = prepareRequestData(account?.id as string, data);
    const partialPayload = isTemplate
      ? getCampaignTemplateSubmitValueByStep(activeStep, payload)
      : getCampaignSubmitValueByStep(activeStep, payload);

    await updateCampaign.update({
      campaignId: campaign.id,
      updates: partialPayload,
    });
  };

  const handleLaunchCampaign = async (data: CampaignFormValues) => {
    const payload = prepareRequestData(account?.id as string, data);
    const partialPayload = isTemplate
      ? getCampaignTemplateSubmitValueByStep(activeStep, payload)
      : getCampaignSubmitValueByStep(activeStep, payload);

    await updateCampaign.update({
      campaignId: campaign.id,
      updates: partialPayload,
    });

    await campaignAction.updateStatus({
      campaignId: campaign.id,
      action: CampaignActionsEnum.LAUNCH,
    });
  };

  const handleSubmit = async (data: CampaignFormValues) => {
    setCampaignId(campaign?.id);

    // Wait for a notify connection to establish
    await new Promise((resolve) => {
      const intervalId = setInterval(() => {
        if (isConnected.current) {
          clearInterval(intervalId);
          resolve(true);
        }
      }, 100);
    });

    try {
      if (isConnected.current) {
        setLaunchState(LoadingStatuses.LOADING);
        await handleLaunchCampaign(data);
      }
    } catch (e) {
      handleAxiosError(e as Error | AxiosError, toast);
    }
  };

  return (
    <FlexContainer
      direction="column"
      align="flex-start"
      justify="flex-start"
      minHeight="100%"
    >
      <AppBreadCrumb
        model={pathItems}
        data-testid="update-campaign-bread-crumb"
        className="flex-initial"
      />
      <CampaignForm
        initialValues={initialValues}
        title={t('campaign.update')}
        onSubmit={handleSubmit}
        onSave={handleSave}
        launchState={launchState}
        saveState={queryStateConverter(updateCampaign)}
        campaignId={campaign.id}
        onStepChange={onStepChange}
        activeStep={activeStep}
        isTemplate={isTemplate}
      />
    </FlexContainer>
  );
};
