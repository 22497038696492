import { GroupCondition } from '@/api/enums';
import { equal, group, like, nested } from '@/api/helpers';
import {
  Campaign,
  CreateCampaignFromTemplateFormState,
} from '@/client/campaigns';
import { ThumbnailState } from '@/client/thumbnails/enums';
import { useCampaigns } from '@/hooks/query';
import { ImageCard } from '@/ui/image-card';
import { CardGridContainer, FlexContainer } from '@/ui/styled-ui';
import { displaySenderProfile } from '@/utils/helpers';
import { debounce } from 'lodash';
import { InputText } from 'primereact/inputtext';
import { ProgressSpinner } from 'primereact/progressspinner';
import React, { SyntheticEvent, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { ThumbnailsGenerationNotify } from '../thumbnails';

const StyledCardGridContainer = styled(CardGridContainer)`
  width: 100%;
  @media screen and (max-width: 1080px) {
    grid-template-columns: repeat(3, 1fr);
  }
  @media screen and (max-width: 800px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media screen and (max-width: 600px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;

type CampaignTemplatesListPropsType = {
  values: CreateCampaignFromTemplateFormState;
  setFieldValue: (field: string, value: any) => void;
};

export const CampaignTemplatesList: React.FC<
  CampaignTemplatesListPropsType
> = ({ values, setFieldValue }) => {
  const { t } = useTranslation();

  const [search, setSearch] = useState('');
  const debouncedSearchValue = useMemo(
    () =>
      debounce((event: SyntheticEvent<HTMLInputElement>) => {
        setSearch((event.target as HTMLInputElement).value);
      }, 300),
    [],
  );

  const { isLoading, campaigns, refetch } = useCampaigns({
    take: 200,
    skip: 0,
    isTemplate: 1,
    withTemplates: 1,
    filters: [
      ...(search
        ? [
            group(GroupCondition.OR, [
              like('name', search),
              nested('sendingProfile', [like('name', search)]),
            ]),
          ]
        : []),
      equal('isTemplate', true),
    ],
  });

  return (
    <>
      <div className="w-full p-input-icon-left">
        <InputText
          className="w-full"
          onInput={debouncedSearchValue}
          placeholder={t('templates.searchNameOrSender')}
          autoComplete="off"
        />
        <i className="pi pi-search" />
      </div>
      {!isLoading && !campaigns?.result.length && (
        <FlexContainer direction="column" className="mt-5">
          <h3>{t('campaign.template.email.empty')}</h3>
        </FlexContainer>
      )}
      {isLoading && !campaigns && (
        <FlexContainer direction="column" className="mt-5">
          <ProgressSpinner />
          <h3>{t('campaign.template.email.loading')}</h3>
        </FlexContainer>
      )}
      {!isLoading && !!campaigns?.result.length && (
        <>
          <ThumbnailsGenerationNotify
            refetch={refetch}
            templates={campaigns.result.map(
              (campaign: Campaign) => campaign.emailTemplate,
            )}
          />
          <StyledCardGridContainer>
            {campaigns?.result.map((campaign: Campaign) => (
              <ImageCard
                isLoading={
                  campaign.emailTemplate.thumbnailState ===
                  ThumbnailState.PROCESSING
                }
                key={campaign.id}
                id={campaign.id}
                campaignValue={campaign}
                title={campaign.name}
                interactionType="withSelect"
                thumbnail={campaign.emailTemplate?.thumbnail?.signedUrl}
                author={displaySenderProfile(t, campaign.sendingProfile)}
                templateType="email"
                selectName={campaign.name}
                isSelected={values.selectedCampaign?.id === campaign.id}
                onChange={(event) =>
                  setFieldValue('selectedCampaign', event.value)
                }
              />
            ))}
          </StyledCardGridContainer>
        </>
      )}
    </>
  );
};
