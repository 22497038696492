import { FiltersType, ListingRequest, ListingResponse } from '@/api/types';

export const ITEMS_PER_REQUEST = 200;
export const ITEMS_PER_LAZY_REQUEST = 10;

export const partialRequests = async (
  filters: FiltersType,
  request: (params: ListingRequest) => Promise<ListingResponse<any>>,
  otherParams?: ListingRequest,
) => {
  const initialResult = await request({
    ...(otherParams ? otherParams : {}),
    take: otherParams?.take || ITEMS_PER_REQUEST,
    filters,
  });

  const result = initialResult?.result || [];

  if (!initialResult?.result) return [];

  const usersToFetch = initialResult.count - ITEMS_PER_REQUEST;
  const fetchesToExecute =
    parseInt(`${usersToFetch / ITEMS_PER_REQUEST}`) +
    (usersToFetch % ITEMS_PER_REQUEST && usersToFetch > 0 ? 1 : 0);

  for (let i = 1; i <= fetchesToExecute; i++) {
    const response = await request({
      take: ITEMS_PER_REQUEST,
      skip: ITEMS_PER_REQUEST * i,
      filters,
      ...(otherParams ? otherParams : {}),
    });

    result.push(...(response.result ?? []));
  }

  return result;
};
