import { processListingParams } from '@/api/helpers';
import {
  Campaign,
  CampaignSummary,
  CloneCampaignRequest,
  ExportCampaignsReportRequest,
  ListCampaignsRequest,
  ListCampaignsResponse,
  ListCampaignUserResultsRequest,
  ListCampaignUserResultsResponse,
  MigrationCampaignRequest,
  SaveCampaignRequest,
  SendTestEmail,
  UpdateCampaignAction,
  UpdateCampaignRequest,
} from '@/client/campaigns';
import { getExceptionFromAxiosError } from '@/client/helpers';
import { MessageResponseModel } from '@/client/models';
import { AxiosError, AxiosInstance, AxiosResponse } from 'axios';
import { omit } from 'lodash';

export const campaignsService = (client: AxiosInstance) => {
  const CAMPAIGNS_BASE_URL = '/gophish/campaigns';

  const getCampaigns = async (
    params: ListCampaignsRequest,
  ): Promise<ListCampaignsResponse> => {
    try {
      const result = await client.get<
        ListCampaignsResponse,
        AxiosResponse<ListCampaignsResponse>
      >(CAMPAIGNS_BASE_URL, {
        withCredentials: true,
        params: processListingParams(params),
      });

      return result.data;
    } catch (e) {
      throw getExceptionFromAxiosError(e as AxiosError);
    }
  };

  const getCampaignsSummary = async (
    accountId: string,
  ): Promise<CampaignSummary> => {
    try {
      const result = await client.get<
        CampaignSummary,
        AxiosResponse<CampaignSummary>
      >(`${CAMPAIGNS_BASE_URL}/accounts/${accountId}/summary`, {
        withCredentials: true,
      });

      return result.data;
    } catch (e) {
      throw getExceptionFromAxiosError(e as AxiosError);
    }
  };

  const getCampaignUserResults = async (
    params: ListCampaignUserResultsRequest,
    campaignId?: string,
  ): Promise<ListCampaignUserResultsResponse> => {
    try {
      const result = await client.get<
        ListCampaignUserResultsResponse,
        AxiosResponse<ListCampaignUserResultsResponse>
      >(`${CAMPAIGNS_BASE_URL}/${campaignId}/results`, {
        withCredentials: true,
        params: processListingParams(params),
      });

      return result.data;
    } catch (e) {
      throw getExceptionFromAxiosError(e as AxiosError);
    }
  };

  const getCampaign = async (campaignId: string): Promise<Campaign> => {
    try {
      const result = await client.get<Campaign, AxiosResponse<Campaign>>(
        `${CAMPAIGNS_BASE_URL}/${campaignId}`,
        {
          withCredentials: true,
        },
      );

      return result.data;
    } catch (e) {
      throw getExceptionFromAxiosError(e as AxiosError);
    }
  };

  const saveCampaignAction = async ({
    campaignId,
    action,
  }: UpdateCampaignAction) => {
    try {
      const result = await client.post<
        MessageResponseModel,
        AxiosResponse<MessageResponseModel>
      >(`${CAMPAIGNS_BASE_URL}/${campaignId}/actions/${action}`);

      return result.data;
    } catch (e) {
      throw getExceptionFromAxiosError(e as AxiosError);
    }
  };

  const saveCampaign = async (
    request: SaveCampaignRequest,
  ): Promise<Campaign> => {
    try {
      const result = await client.post(CAMPAIGNS_BASE_URL, request, {
        withCredentials: true,
      });

      return result.data;
    } catch (e) {
      throw getExceptionFromAxiosError(e as AxiosError);
    }
  };

  const cloneCampaign = async (
    request: CloneCampaignRequest,
  ): Promise<Campaign> => {
    try {
      const result = await client.post(
        `${CAMPAIGNS_BASE_URL}/${request.campaignId}/clone`,
        { account: request.account },
        {
          withCredentials: true,
        },
      );

      return result.data;
    } catch (e) {
      throw getExceptionFromAxiosError(e as AxiosError);
    }
  };

  const sendTestEmail = async (
    request: SendTestEmail,
  ): Promise<{ jobId: string } & MessageResponseModel> => {
    try {
      const result = await client.post<
        { jobId: string } & MessageResponseModel,
        AxiosResponse<{ jobId: string } & MessageResponseModel>
      >(
        `${CAMPAIGNS_BASE_URL}/${request.campaignId}/test-email`,
        omit(request, 'campaignId'),
        {
          withCredentials: true,
        },
      );

      return result.data;
    } catch (e) {
      throw getExceptionFromAxiosError(e as AxiosError);
    }
  };

  const updateCampaign = async (
    request: UpdateCampaignRequest,
  ): Promise<Campaign> => {
    try {
      const result = await client.patch(
        `${CAMPAIGNS_BASE_URL}/${request.campaignId}`,
        request.updates,
        {
          withCredentials: true,
        },
      );

      return result.data;
    } catch (e) {
      throw getExceptionFromAxiosError(e as AxiosError);
    }
  };

  const deleteCampaign = async (
    campaignId: string,
  ): Promise<MessageResponseModel> => {
    try {
      const result = await client.delete<
        MessageResponseModel,
        AxiosResponse<MessageResponseModel>
      >(`${CAMPAIGNS_BASE_URL}/${campaignId}`, {
        withCredentials: true,
      });

      return new MessageResponseModel(result.data);
    } catch (e) {
      throw getExceptionFromAxiosError(e as AxiosError);
    }
  };

  const migrateCampaign = async (
    request: MigrationCampaignRequest,
  ): Promise<MessageResponseModel> => {
    try {
      const result = await client.post(
        `${CAMPAIGNS_BASE_URL}/migration`,
        request,
        {
          withCredentials: true,
        },
      );

      return result.data;
    } catch (e) {
      throw getExceptionFromAxiosError(e as AxiosError);
    }
  };

  const syncCampaignResults = async (
    campaignId: string,
  ): Promise<MessageResponseModel> => {
    try {
      const result = await client.post<
        MessageResponseModel,
        AxiosResponse<MessageResponseModel>
      >(`${CAMPAIGNS_BASE_URL}/${campaignId}/results/sync`, {
        withCredentials: true,
      });

      return result.data;
    } catch (e) {
      throw getExceptionFromAxiosError(e as AxiosError);
    }
  };

  const exportPhishingReport = async (
    campaignId: string,
    request: ExportCampaignsReportRequest,
  ): Promise<MessageResponseModel> => {
    try {
      const result = await client.post<
        MessageResponseModel,
        AxiosResponse<MessageResponseModel>
      >(
        `${CAMPAIGNS_BASE_URL}/${campaignId}/results/export`,
        { ...request },
        {
          withCredentials: true,
        },
      );

      return result.data;
    } catch (e) {
      throw getExceptionFromAxiosError(e as AxiosError);
    }
  };

  const campaignsNotifyUrl = (campaignId: string) =>
    `${client.defaults.baseURL}gophish/campaigns/${campaignId}/notify`;

  return {
    getCampaigns,
    getCampaign,
    getCampaignsSummary,
    getCampaignUserResults,
    sendTestEmail,
    saveCampaignAction,
    saveCampaign,
    cloneCampaign,
    updateCampaign,
    deleteCampaign,
    migrateCampaign,
    syncCampaignResults,
    exportPhishingReport,
    campaignsNotifyUrl,
  };
};
