import classNames from 'classnames';
import { get } from 'lodash';
import { MultiSelect, MultiSelectProps } from 'primereact/multiselect';
import React from 'react';
import styled from 'styled-components';

const StyledMultiselect = styled(MultiSelect)`
  .p-multiselect-clear-icon {
    color: var(--gray-darker);
    font-size: var(--small-font-size);

    &:hover {
      color: var(--red-main);
    }
  }
`;

export type FormikMultiSelectProps = {
  field: {
    name: string;
    value: object;
    onChange: () => void;
    onBlur: () => void;
  };
  form: { touched: Record<string, any>; errors: Record<string, any> };
  label?: string;
};

export const FormikMultiSelect: React.FC<
  FormikMultiSelectProps & MultiSelectProps
> = ({ field, form, label, required, className, ...rest }) => {
  const fieldError = get(form.errors, field.name);
  const fieldTouched = get(form.touched, field.name);

  return (
    <>
      <label
        htmlFor={field.name}
        className={classNames({
          'p-error': fieldTouched && !!fieldError,
        })}
      >
        {label}
        {required ? <span className="red"> *</span> : ''}
      </label>
      <StyledMultiselect
        {...field}
        className={className}
        value={field.value ?? undefined}
        virtualScrollerOptions={
          rest?.options?.length && rest?.options?.length > 10
            ? { itemSize: 50, autoSize: true }
            : undefined
        }
        {...rest}
      />
      {fieldTouched && !!fieldError ? (
        <small className="p-error">{fieldError}</small>
      ) : null}
    </>
  );
};
