import { handleAxiosError } from '@/api/helpers';
import { AppBreadCrumbTemplate } from '@/app/AppBreadCrumbTemplate';
import { client } from '@/client';
import { Account, AccountTypeEnum } from '@/client/accounts';
import { FilterNamesEnum, getFiltersFromColumns } from '@/client/helpers';
import { Actions, Subjects } from '@/client/users';
import {
  RedirectPaths,
  RedirectPathsEnum,
  TableNamesEnum,
} from '@/common/constants';
import { DialogContext } from '@/common/context';
import { AppContext } from '@/common/context/AppContext';
import { TranslationFunctionType } from '@/common/types';
import {
  DataTable,
  DataTableColumnsMultiselect,
  DataTableColumnType,
  DataTableFilters,
  DataTableToolbar,
} from '@/components/tables/crud';
import { DataTableActions } from '@/components/tables/crud/DataTableActions';
import { FilterTypeEnum } from '@/components/tables/crud/types';
import { useNotifications } from '@/hooks/notifications.hook';
import {
  useAccounts,
  useDeleteAccounts,
  useForceAccountSync,
} from '@/hooks/query';
import { useUpdateAccounts } from '@/hooks/query/accounts.hooks';
import { useAppSelector } from '@/hooks/store';
import { useTable } from '@/hooks/table.hook';
import { usePermission } from '@/hooks/usePermission';
import { useToast } from '@/hooks/useToast';
import { selectCurrentAccount } from '@/store/features/account';
import { AppBreadCrumb } from '@/ui/breadcrumb';
import { AppButton } from '@/ui/buttons';
import { AppChip } from '@/ui/chip';
import { FormatDate } from '@/ui/date';
import { FlexContainer } from '@/ui/styled-ui';
import { courseDeliveryLmsOptions, displayLmsOption } from '@/utils/helpers';
import { AxiosError } from 'axios';
import { DataTableRowClickEvent } from 'primereact/datatable';
import { MenuItem } from 'primereact/menuitem';
import { ProgressSpinner } from 'primereact/progressspinner';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

const getPathItems = (
  currentAccount: Account,
  t: TranslationFunctionType,
): MenuItem[] => [
  {
    label: currentAccount?.name,
    url: RedirectPaths[RedirectPathsEnum.EDIT_ACCOUNT](currentAccount?.id),
    template: AppBreadCrumbTemplate,
  },
  {
    label: t('accounts'),
    url: '/accounts',
    template: AppBreadCrumbTemplate,
  },
];

export const AccountsPage = () => {
  const { t } = useTranslation();
  const toast = useToast();
  const currentAccount = useAppSelector(selectCurrentAccount);
  const { triggerAccountsLengthChange } = useContext(AppContext);
  const { can } = usePermission();
  const { skip, take, sort, apiFilters, onPage, onSort, onFilter } = useTable();

  const {
    lastMessage: notifications,
    setNotificationParam,
    notificationParam: notificationAccountId,
    isConnected,
  } = useNotifications(client.accounts.accountsNotifyUrl);

  useEffect(() => {
    if (!notificationAccountId) return;
    handleNotificationAccountArchived(notifications);
  }, [JSON.stringify(notifications)]);

  const { setDialogData } = useContext(DialogContext);

  const { isLoading, accounts, refetch } = useAccounts({
    take,
    skip,
    filters: apiFilters,
    sort: sort && sort.length > 0 ? [sort.join(',')] : [],
  });

  const updateAccount = useUpdateAccounts();
  const deleteAccount = useDeleteAccounts();
  const forceAccountSync = useForceAccountSync();

  const navigate = useNavigate();

  const onRowClick = (event: DataTableRowClickEvent) => {
    navigate(RedirectPaths[RedirectPathsEnum.EDIT_ACCOUNT](event.data.id));
  };

  const columns: DataTableColumnType[] = [
    {
      field: 'id',
      header: t('generic.accountId'),
      sortable: false,
      filterable: true,
      filters: {
        type: FilterTypeEnum.TEXT,
      },
    },
    {
      field: 'name',
      header: t('generic.name'),
      sortable: true,
      filterable: true,
      filters: {
        type: FilterTypeEnum.TEXT,
        placeholder: t('generic.name.search'),
      },
    },
    {
      field: 'active',
      header: t('generic.active'),
      sortable: true,
      filterable: true,
      filters: {
        label: t('filter.status'),
        type: FilterTypeEnum.SELECT_ACTIVE_INACTIVE,
      },
      render: (row: Account) =>
        row.active ? t('generic.active') : t('generic.inactive'),
    },
    {
      field: 'created',
      header: t('generic.created'),
      sortable: true,
      filterable: false,
      render: (row: Account) =>
        row?.created ? <FormatDate date={row?.created} /> : t('generic.never'),
    },
    {
      field: 'updated',
      header: t('generic.updated'),
      sortable: true,
      filterable: false,
      render: (row: Account) =>
        row?.updated ? <FormatDate date={row?.updated} /> : t('generic.never'),
    },
    {
      field: 'activeUsersCount',
      header: t('reports.active.users'),
      sortable: true,
      filterable: false,
      render: (row: Account) => row.activeUsersCount ?? 0,
    },
    {
      field: 'activeCampaignsCount',
      header: t('campaign.active'),
      sortable: true,
      filterable: false,
      render: (row: Account) => row.activeCampaignsCount,
    },
    {
      field: 'isPhishingOnly',
      header: t('account.phishingOnly'),
      sortable: false,
      filterable: true,
      filters: {
        type: FilterTypeEnum.SELECT_YES_NO,
      },
      render: (row: Account) =>
        row.isPhishingOnly ? (
          <AppChip label={t('dialog.yes')} type="primary" />
        ) : (
          <AppChip label={t('dialog.no')} type="secondary" />
        ),
    },
    {
      field: 'isMigratedOutsideOfEFront',
      header: t('account.isMigratedOutsideOfEFront'),
      sortable: false,
      filterable: true,
      filters: {
        type: FilterTypeEnum.SELECT_YES_NO,
      },
      render: (row: Account) =>
        row?.meta?.isMigratedOutsideOfEFront ? (
          <AppChip label={t('dialog.yes')} type="primary" />
        ) : (
          <AppChip label={t('dialog.no')} type="secondary" />
        ),
    },
    {
      field: 'meta.migratedOutsideOfEFrontAt',
      header: t('account.migratedDate'),
      sortable: true,
      filterable: false,
      render: (row: Account) =>
        row?.meta?.migratedOutsideOfEFrontAt ? (
          <FormatDate date={row?.meta?.migratedOutsideOfEFrontAt} />
        ) : (
          <span>&#8212;</span>
        ),
    },
    {
      field: 'hasHubspot',
      header: t('hubspot'),
      sortable: false,
      filterable: true,
      filters: {
        type: FilterTypeEnum.SELECT_YES_NO,
        placeholder: t('generic.select'),
      },
      render: (row: Account) =>
        row?.hubspot?.id ? (
          <AppChip label={t('dialog.yes')} type="primary" />
        ) : (
          <AppChip label={t('dialog.no')} type="secondary" />
        ),
    },
    {
      field: 'courseDelivery',
      header: t('account.courseDelivery'),
      sortable: false,
      filterable: true,
      filters: {
        type: FilterTypeEnum.SELECT,
        placeholder: t('generic.select'),
        options: courseDeliveryLmsOptions(t, true),
      },
      render: (row: Account) =>
        row?.courseDelivery ? (
          displayLmsOption(t)[row.courseDelivery]
        ) : (
          <span>&#8212;</span>
        ),
    },
    {
      field: 'freeTrialEndsAt',
      header: t('account.freeTrial.endDate'),
      sortable: true,
      filterable: true,
      filters: {
        label: t('account.freeTrial'),
        type: FilterTypeEnum.SELECT_YES_NO,
        placeholder: t('account.freeTrial'),
      },
      render: (row: Account) =>
        row?.freeTrialEndsAt ? (
          <FormatDate date={row?.freeTrialEndsAt} />
        ) : (
          <span>&#8212;</span>
        ),
    },
    {
      field: 'autoCourseAvailability',
      header: t('account.course.available'),
      sortable: false,
      filterable: true,
      filters: {
        type: FilterTypeEnum.SELECT_YES_NO,
      },
      render: (row: Account) =>
        row?.meta?.autoCourseAvailability ? (
          <AppChip label={t('dialog.yes')} type="primary" />
        ) : (
          <AppChip label={t('dialog.no')} type="secondary" />
        ),
    },
    {
      field: 'autoCourseEnrollment',
      header: t('account.course.enrollment'),
      sortable: false,
      filterable: true,
      filters: {
        type: FilterTypeEnum.SELECT_AUTO_MANUAL,
      },
      render: (row: Account) =>
        row?.meta?.autoCourseEnrollment ? (
          <AppChip label={t('generic.auto')} type="primary" />
        ) : (
          <AppChip label={t('generic.manual')} type="secondary" />
        ),
    },
    {
      field: 'type',
      header: t('account.test'),
      sortable: false,
      filterable: true,
      filters: {
        field: FilterNamesEnum.IS_DEMO_ACCOUNT,
        type: FilterTypeEnum.SELECT_YES_NO,
        placeholder: t('generic.select'),
      },
      render: (row: Account) =>
        row?.type === AccountTypeEnum.DEMO ? (
          <AppChip label={t('dialog.yes')} type="primary" />
        ) : (
          <AppChip label={t('dialog.no')} type="secondary" />
        ),
    },
    {
      field: 'actions',
      header: t('generic.actions'),
      sortable: false,
      filterable: false,
      style: {
        width: '80px',
        textAlign: 'center',
      },
      render: (row: Account) => {
        if (deletingAccountId === row.id) {
          return (
            <FlexContainer>
              <ProgressSpinner style={{ width: '24px', height: '24px' }} />
            </FlexContainer>
          );
        }
        return (
          <DataTableActions
            disabled={menuItems(row).length < 1}
            menuItems={menuItems(row)}
          />
        );
      },
    },
  ];

  // Set the preselected columns
  const [visibleColumns, setVisibleColumns] = useState<string[]>([]);

  const defaultVisibleColumns = columns
    .filter((column) => column.field !== 'id')
    .map((column) => column.field);
  const alwaysVisibleColumns = ['name', 'actions'];
  //

  const menuItems = (account: Account) => {
    const actions = [
      {
        label: t('integrations'),
        icon: 'pi pi-user-edit',
        command: () =>
          navigate(
            RedirectPaths[RedirectPathsEnum.ACCOUNT_INTEGRATIONS](account.id),
          ),
      },
    ];

    if (can(Actions.UPDATE, Subjects.ACCOUNTS)) {
      actions.push(
        ...[
          {
            label: t('generic.edit'),
            icon: 'pi pi-pencil',
            command: () =>
              navigate(
                RedirectPaths[RedirectPathsEnum.EDIT_ACCOUNT](account.id),
              ),
          },
          {
            label: account.active
              ? t('generic.deactivate')
              : t('generic.activate'),
            icon: account.active ? 'pi pi-minus-circle' : 'pi pi-check',
            command: () =>
              setDialogData({
                type: 'confirmation',
                show: true,
                header: `${
                  account.active
                    ? t('generic.deactivate')
                    : t('generic.activate')
                } ${t('dialog.confirm')}`,
                message: account.active
                  ? t('account.deactivate.name', { name: account?.name })
                  : t('account.activate.name', { name: account?.name }),
                onAccept: async () =>
                  handleToggleItemsStatus(account.id, account.active),
              }),
          },
        ],
      );
    }

    if (can(Actions.DELETE, Subjects.ACCOUNTS) && !account.isSystem) {
      actions.push({
        label: t('generic.delete'),
        icon: 'pi pi-times',
        command: () => {
          if (currentAccount?.id !== account.id) {
            setDialogData({
              type: 'confirmation',
              show: true,
              header: t('dialog.delete'),
              message: t('dialog.delete.account.name', { name: account?.name }),
              closeImmediatelyAfterAccept: true,
              onAccept: async () => handleArchiveAccount(account.id),
            });
          } else {
            setDialogData({
              type: 'info',
              show: true,
              header: t('dialog.delete.info'),
              message: t('dialog.currentAccount'),
            });
          }
        },
      });
    }

    if (
      can(Actions.CREATE, Subjects.EFRONT_SYNC) &&
      (!account?.meta?.isMigratedOutsideOfEFront ?? true)
    ) {
      actions.push({
        label: t('efront.force'),
        icon: 'pi pi-refresh',
        command: () =>
          setDialogData({
            type: 'confirmation',
            show: true,
            header: t('efront.force.confirm'),
            message: t('efront.force.sure'),
            onAccept: async () => handleForceAccountSync(account.id),
          }),
      });
    }

    return actions;
  };

  const [deletingAccountId, setDeletingAccountId] = useState<string | null>(
    null,
  );
  const handleNotificationAccountArchived = async (messages: any) => {
    if (
      !messages?.data?.event ||
      !['account.delete.finished', 'account.delete.error'].includes(
        messages?.data?.event,
      )
    )
      return;

    if (messages?.data?.event === 'account.delete.finished') {
      toast?.success(t('toast.success'), t('account.deleted'), 5000);
      triggerAccountsLengthChange && triggerAccountsLengthChange();
      await refetch();
    } else if (messages?.data?.event === 'account.delete.error')
      toast?.info(t('toast.info'), t('account.notDeleted'), 5000);

    setNotificationParam(undefined);
    setDeletingAccountId(null);
  };

  const handleToggleItemsStatus = async (
    accountId: string,
    active: boolean,
  ) => {
    try {
      await updateAccount.update({
        accountId: accountId,
        updates: { active: !active },
      });

      await refetch();
      toast?.success(
        t('toast.success'),
        active ? t('account.deactivated') : t('account.activated'),
      );
    } catch (e) {
      handleAxiosError(e as Error | AxiosError, toast);
    }
  };

  const handleForceAccountSync = async (accountId: string) => {
    await forceAccountSync.sync(accountId);

    toast?.info(t('toast.info'), t('job.created'));
  };

  const handleArchiveAccount = async (accountId: string) => {
    setNotificationParam(accountId);

    // Wait for a notify connection to establish
    await new Promise((resolve) => {
      const intervalId = setInterval(() => {
        if (isConnected.current) {
          clearInterval(intervalId);
          resolve(true);
        }
      }, 100);
    });

    try {
      if (isConnected.current) {
        await deleteAccount.delete(accountId);
        setDeletingAccountId(accountId);
      }
    } catch (e) {
      handleAxiosError(e as Error | AxiosError, toast);
      setDeletingAccountId(null);
    }
  };

  const toolbar = (
    <DataTableToolbar>
      <FlexContainer
        justify="space-between"
        gap={8}
        align="flex-start"
        wrap="wrap"
      >
        <DataTableFilters
          filters={getFiltersFromColumns(columns)}
          onFilter={onFilter}
          defaultValues={{
            name: ' ',
          }}
          tableName={TableNamesEnum.ACCOUNTS_LIST}
        />
        {can(Actions.CREATE, Subjects.ACCOUNTS) && (
          <AppButton
            label={t('button.createNew')}
            severity="secondary"
            onClick={() =>
              navigate(RedirectPaths[RedirectPathsEnum.CREATE_ACCOUNT]())
            }
          />
        )}
      </FlexContainer>
      <DataTableColumnsMultiselect
        columns={columns}
        tableName={TableNamesEnum.ACCOUNTS_LIST}
        visibleColumns={visibleColumns}
        setVisibleColumns={setVisibleColumns}
        defaultVisibleColumns={defaultVisibleColumns}
        alwaysVisibleColumns={alwaysVisibleColumns}
      />
    </DataTableToolbar>
  );

  return (
    <>
      {currentAccount && (
        <AppBreadCrumb model={getPathItems(currentAccount, t)} />
      )}
      <h1>{t('accounts')}</h1>
      <DataTable
        data={accounts?.result}
        count={accounts?.count as number}
        isLoading={isLoading}
        toolbar={toolbar}
        columns={columns}
        visibleColumns={visibleColumns}
        onPage={onPage}
        rows={take}
        skip={skip}
        onSort={onSort}
        sort={sort}
        onRowClick={onRowClick}
      />
    </>
  );
};
