import { RedirectPaths, RedirectPathsEnum } from '@/common/constants';
import classNames from 'classnames';
import { MenuItem } from 'primereact/menuitem';
import { TabMenu } from 'primereact/tabmenu';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const StyledTabMenu = styled(TabMenu)`
  &.p-tabmenu {
    @media screen and (max-width: 812px) {
      position: static !important;
      width: 100%;
      padding-top: 0 !important;
    }
    position: fixed !important;
    top: 70px;
    margin-left: calc(var(--default-padding) * -1);
    padding-left: var(--default-padding);
    border-radius: var(--default-border-radius) var(--default-border-radius) 0 0;
    width: calc(100% - 2 * var(--big-padding));
    max-width: calc(var(--max-dashboard-width));
    background-color: var(--white-main);
    z-index: 10;
    padding-top: var(--default-padding);
    padding-bottom: var(--default-padding) !important;
    margin-bottom: 0;
  }
  .p-menuitem-text {
    font-weight: 700;
    font-size: var(--body-font-size);
    line-height: var(--body-line-height);
    text-transform: capitalize;
  }

  .p-menuitem-link {
    padding: var(--xsmall-padding) 0 !important;
    margin-right: var(--xsmall-padding) !important;
    border-bottom: 3px solid transparent !important;
  }

  .p-highlight {
    .p-menuitem-link {
      border-bottom: 3px solid var(--red-main) !important;
    }
  }
`;

type LearnerDashboardTabsPropsType = {
  hasMaterials?: boolean;
};

export const LearnerDashboardTabs: React.FC<LearnerDashboardTabsPropsType> = ({
  hasMaterials,
}) => {
  const { t } = useTranslation();

  const getItemTemplate = (url: string, current: string) => (item: MenuItem) =>
    (
      <Link
        to={url}
        className={classNames({
          'p-menuitem-link': true,
          'p-menuitem-link-active': current.includes(url),
        })}
        role="menuitem"
      >
        <span className="p-menuitem-text">{item.label}</span>
      </Link>
    );

  const [activeIndex, setActiveIndex] = useState<number>(0);

  useEffect(() => {
    switch (location.pathname) {
      case RedirectPaths[RedirectPathsEnum.LEARNER_DASHBOARD_ACTIVE]():
      case RedirectPaths[RedirectPathsEnum.LEARNER_DASHBOARD_COMPLETED]():
        setActiveIndex(0);
        break;

      case RedirectPaths[RedirectPathsEnum.LEARNER_DASHBOARD_TEMPLATES]():
        setActiveIndex(1);
        break;
      // TODO: Uncomment when we add posters
      /*
      case RedirectPaths[RedirectPathsEnum.LEARNER_DASHBOARD_POSTERS_ALL]():
      case RedirectPaths[RedirectPathsEnum.LEARNER_DASHBOARD_POSTERS_COURSE]():
      case RedirectPaths[RedirectPathsEnum.LEARNER_DASHBOARD_POSTERS_CYBER]():
      case RedirectPaths[RedirectPathsEnum.LEARNER_DASHBOARD_POSTERS_GDPR]():
      case RedirectPaths[
        RedirectPathsEnum.LEARNER_DASHBOARD_POSTERS_INFOGRAPHICS
      ]():
      case RedirectPaths[
        RedirectPathsEnum.LEARNER_DASHBOARD_POSTERS_INFOSCREEN
      ]():
        setActiveIndex(2);
        break;
        */
      default:
        setActiveIndex(0);
    }
  }, [location.pathname]);

  const getTabItems = (): MenuItem[] => {
    const tabItems: MenuItem[] = [];

    tabItems.push({
      label: t('courses'),
      template: getItemTemplate(
        RedirectPaths[RedirectPathsEnum.LEARNER_DASHBOARD_ACTIVE](),
        location.pathname,
      ),
    });

    if (hasMaterials) {
      tabItems.push({
        label: t('materials'),
        template: getItemTemplate(
          RedirectPaths[RedirectPathsEnum.LEARNER_DASHBOARD_TEMPLATES](),
          location.pathname,
        ),
      });
      // TODO: Uncomment when we add posters
      /*
      tabItems.push({
        label: t('posters'),
        template: getItemTemplate(
          RedirectPaths[RedirectPathsEnum.LEARNER_DASHBOARD_POSTERS_ALL](),
          location.pathname,
        ),
      });
      */
    }

    return tabItems;
  };

  return (
    <StyledTabMenu
      model={getTabItems()}
      activeIndex={activeIndex}
      onTabChange={() => null}
    />
  );
};
