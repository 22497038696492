import React, { createContext, ReactNode, useState } from 'react';

type AppContextType = {
  isAccountsLengthChanged: boolean;
};

export const AppContext = createContext<{
  state: AppContextType;
  triggerAccountsLengthChange?: () => void;
  clearTrigger?: () => void;
}>({
  state: { isAccountsLengthChanged: false },
  triggerAccountsLengthChange: undefined,
  clearTrigger: undefined,
});

export const AppContextComponent: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [state, setState] = useState<AppContextType>({
    isAccountsLengthChanged: false,
  });

  const handleTriggerAccountsLengthChange = () => {
    setState((state) => ({ ...state, isAccountsLengthChanged: true }));
  };
  const handleClearTrigger = () => {
    setState((state) => ({ ...state, isAccountsLengthChanged: false }));
  };

  return (
    <AppContext.Provider
      value={{
        state,
        triggerAccountsLengthChange: handleTriggerAccountsLengthChange,
        clearTrigger: handleClearTrigger,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};
