import { AppBreadCrumbTemplate } from '@/app/AppBreadCrumbTemplate';
import { Account } from '@/client/accounts';
import { Branch } from '@/client/branches';
import { RedirectPaths, RedirectPathsEnum } from '@/common/constants';
import { useBranch } from '@/hooks/query';
import { useAppSelector } from '@/hooks/store';
import { LoadingPage } from '@/pages';
import { BranchesTabs } from '@/pages/branches/BranchesTabs';
import { selectCurrentAccount } from '@/store/features/account';
import { AppBreadCrumb } from '@/ui/breadcrumb';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

export const BranchesImport: React.FC = () => {
  const { t } = useTranslation();
  const { id } = useParams();

  const { branch, isLoading } = useBranch({ branchId: id as string });
  const account = useAppSelector(selectCurrentAccount);

  const pathItems = [
    {
      label: (account as Account)?.name,
      url: RedirectPaths[RedirectPathsEnum.EDIT_ACCOUNT](
        (account as Account)?.id,
      ),
      template: AppBreadCrumbTemplate,
    },
    {
      label: t('branches'),
      url: RedirectPaths[RedirectPathsEnum.BRANCHES](),
      template: AppBreadCrumbTemplate,
    },
    {
      label: t('generic.edit.name', { name: branch?.name }),
      url: RedirectPaths[RedirectPathsEnum.BRANCHES_EDIT](id as string),
      template: AppBreadCrumbTemplate,
    },
    {
      label: t('branch.import'),
      url: RedirectPaths[RedirectPathsEnum.BRANCHES_USERS_IMPORT](id as string),
      template: AppBreadCrumbTemplate,
    },
  ];

  if (isLoading) {
    return <LoadingPage message={t('branch.loading')} />;
  }

  return (
    <>
      <AppBreadCrumb model={pathItems} />
      <BranchesTabs branch={branch as Branch} />
    </>
  );
};
