import { handleAxiosError } from '@/api/helpers';
import { AppBreadCrumbTemplate } from '@/app/AppBreadCrumbTemplate';
import { Account } from '@/client/accounts';
import {
  SenderProfile,
  SenderProfileFormState,
} from '@/client/sender-profiles';
import { RedirectPaths, RedirectPathsEnum } from '@/common/constants';
import { TranslationFunctionType } from '@/common/types';
import { SenderProfileForm } from '@/components/sender-profiles/forms';
import { useSenderProfile, useUpdateSenderProfile } from '@/hooks/query';
import { useAppSelector } from '@/hooks/store';
import { useToast } from '@/hooks/useToast';
import { LoadingPage } from '@/pages';
import { selectCurrentAccount } from '@/store/features/account';
import { AppBreadCrumb } from '@/ui/breadcrumb';
import { FlexContainer } from '@/ui/styled-ui';
import { queryStateConverter } from '@/utils/helpers';
import { AxiosError } from 'axios';
import { FormikValues } from 'formik';
import { MenuItem } from 'primereact/menuitem';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

const getBreadcrumbs = (
  account: Account,
  senderProfile: SenderProfile,
  t: TranslationFunctionType,
): MenuItem[] => [
  {
    label: account?.name,
    url: RedirectPaths[RedirectPathsEnum.EDIT_ACCOUNT](account?.id),
    template: AppBreadCrumbTemplate,
  },
  {
    label: account?.isSystem ? t('sender.predefined') : t('sender.custom'),
    url: account?.isSystem
      ? RedirectPaths[RedirectPathsEnum.SENDER_PROFILES_PREDEFINED]()
      : RedirectPaths[RedirectPathsEnum.SENDER_PROFILES_CUSTOM](),
    template: AppBreadCrumbTemplate,
  },
  {
    label: t('sender.edit', { name: senderProfile?.name }),
    url: RedirectPaths[RedirectPathsEnum.SENDER_PROFILES_EDIT](
      senderProfile?.id,
    ),
    template: AppBreadCrumbTemplate,
  },
];

export const UpdateSenderProfilePage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { id } = useParams();
  const toast = useToast();
  const account = useAppSelector(selectCurrentAccount);
  const location = useLocation();
  const campaignId = location?.state?.campaignId;

  const { senderProfile, isLoading } = useSenderProfile({
    senderProfileId: id,
  });
  const updateSenderProfile = useUpdateSenderProfile();

  const [passwordVisibility, setPasswordVisibility] = useState(false);

  const [initialValues, setInitialValues] = useState<SenderProfileFormState>({
    name: '',
    senderName: '',
    fromAddress: '',
    interfaceType: 'SMTP',
    host: '',
    username: '',
    password: '',
    ignoreCertErrors: false,
    meta: undefined,
  });

  useEffect(() => {
    if (!senderProfile) return;

    setInitialValues({
      name: senderProfile.name,
      senderName: senderProfile.senderName,
      fromAddress: senderProfile.fromAddress,
      interfaceType: senderProfile.interfaceType,
      host: senderProfile.host,
      username: senderProfile.username,
      password: senderProfile.password,
      ignoreCertErrors: senderProfile.ignoreCertErrors,
      meta: senderProfile.meta,
    });
  }, [senderProfile]);

  const handleSubmit = async (data: FormikValues) => {
    try {
      if (!account) return;

      await updateSenderProfile.update({
        senderProfileId: id as string,
        updates: { ...data, account: account.isSystem ? null : account.id },
      });

      toast?.success(t('toast.success'), t('sender.updated'));

      if (!campaignId) {
        if (account.isSystem) {
          navigate(
            RedirectPaths[RedirectPathsEnum.SENDER_PROFILES_PREDEFINED](),
          );
        } else {
          navigate(RedirectPaths[RedirectPathsEnum.SENDER_PROFILES_CUSTOM]());
        }
      } else {
        navigate(RedirectPaths[RedirectPathsEnum.CAMPAIGNS_EDIT](campaignId), {
          state: {
            activeStep: 2,
            isTemplate: !!account?.isSystem,
          },
        });
      }
    } catch (e) {
      handleAxiosError(e as Error | AxiosError, toast);
    }
  };

  if (!senderProfile || isLoading) {
    return <LoadingPage message={t('sender.loading')} />;
  }

  return (
    <>
      <AppBreadCrumb
        model={getBreadcrumbs(account as Account, senderProfile, t)}
      />
      <FlexContainer justify="flex-start">
        <SenderProfileForm
          initialValues={initialValues}
          onSubmit={handleSubmit}
          state={queryStateConverter(updateSenderProfile)}
          isCreate={false}
          passwordVisibility={passwordVisibility}
          onPasswordToggle={() => setPasswordVisibility(!passwordVisibility)}
          campaignId={campaignId}
        />
      </FlexContainer>
    </>
  );
};
