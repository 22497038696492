import {
  equal,
  getQueryTransformation,
  handleAxiosError,
  like,
  nested,
  notEqual,
} from '@/api/helpers';
import {
  ChangeEnrollDateFormValues,
  Course,
  CourseEntitySchedule,
  CourseEntityScheduleEnum,
  CourseSchedule,
  CourseScheduleType,
  DeleteScheduleRequest,
  MaterialType,
} from '@/client/courses';
import { FilterNamesEnum, getFiltersFromColumns } from '@/client/helpers';
import { Actions, Subjects, SystemRoles } from '@/client/users';
import {
  RedirectPaths,
  RedirectPathsEnum,
  TableNamesEnum,
} from '@/common/constants';
import { DialogContext } from '@/common/context';
import { ChangeEnrollDateModal } from '@/components/courses/modals/ChangeEnrollDateModal';
import {
  DataTable,
  DataTableActions,
  DataTableColumnsMultiselect,
  DataTableColumnType,
  DataTableFilters,
  DataTableToolbar,
  FilterTypeEnum,
} from '@/components/tables/crud';
import {
  useAccountSchedules,
  useChangeScheduleDate,
  useChangeScheduleEnrollType,
  useDeleteSchedule,
} from '@/hooks/query';
import { useQueryParams } from '@/hooks/query.hook';
import { useAppSelector } from '@/hooks/store';
import { useTable } from '@/hooks/table.hook';
import { useMixpanel } from '@/hooks/useMixpanel';
import { pendoEvent, usePendo } from '@/hooks/usePendo';
import { usePermission } from '@/hooks/usePermission';
import { useToast } from '@/hooks/useToast';
import { selectCurrentAccount } from '@/store/features/account';
import { selectCurrentUserState } from '@/store/features/users';
import { DateFormats } from '@/system-settings/enums';
import { AppChip } from '@/ui/chip';
import DatatableThumbnail from '@/ui/datatable-thumbnail/DatatableThumbnail';
import { FormatDate } from '@/ui/date';
import { FlexContainer } from '@/ui/styled-ui';
import {
  calculateDayDifferenceFromToday,
  coursesEnrollToOptions,
} from '@/utils/helpers';
import { AxiosError } from 'axios';
import { debounce } from 'lodash';
import { default as moment } from 'moment';
import { DataTableRowClickEvent } from 'primereact/datatable';
import { InputText } from 'primereact/inputtext';
import { MenuItem } from 'primereact/menuitem';
import React, { FormEvent, useContext, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Md5 } from 'ts-md5';
import { AccountEnrollmentActionHeader } from './AccountEnrollmentActionHeader';
import { EnrollmentTabs } from './EnrollmentTabs';

export const AccountCourseSchedulePage: React.FC<{ isDocument?: boolean }> = ({
  isDocument,
}) => {
  const { t } = useTranslation();
  const { setDialogData } = useContext(DialogContext);
  const { track } = useMixpanel();
  const toast = useToast();
  const { user } = useAppSelector(selectCurrentUserState);
  const account = useAppSelector(selectCurrentAccount);
  const navigate = useNavigate();
  const { pendoTrackEnrollment } = usePendo();
  const { cannot } = usePermission();

  const { skip, take, sort, apiFilters, onFilter, onSort, onPage, setSkip } =
    useTable();

  type CourseEntityScheduleExtended = CourseEntitySchedule & {
    courseId?: string;
    courseName?: string;
  };

  const [selectedSchedule, setSelectedSchedule] = useState<
    CourseEntityScheduleExtended | undefined
  >(undefined);

  const [multiSearchValue, setMultiSearchValue] = useState('');
  const debouncedSetMultiSearchValue = useMemo(
    () =>
      debounce((event: FormEvent) => {
        setSkip(0);
        setMultiSearchValue((event.target as HTMLInputElement).value);
      }, 300),
    [],
  );

  const isBranchAdmin: boolean =
    user?.role.code === SystemRoles.ADMIN && !!user.branch?.id;

  const {
    isLoading: isScheduleLoading,
    schedules,
    refetch,
  } = useAccountSchedules(
    {
      take,
      skip,
      filters: [
        ...apiFilters,
        ...(multiSearchValue.length
          ? [nested('course', [like('name', multiSearchValue)])]
          : []),
        ...(account?.isSystem
          ? []
          : [nested('account', [equal('id', account?.id)])]),
        nested(
          'course',
          isDocument
            ? [notEqual('type', MaterialType.COURSE)]
            : [equal('type', MaterialType.COURSE)],
        ),
        ...(cannot(Actions.READ, Subjects.COURSE_GROUP_SCHEDULES)
          ? [notEqual('source', CourseEntityScheduleEnum.GROUP)]
          : []),
      ],
      sort:
        sort && sort.length > 0 && sort[0] !== 'created'
          ? [sort.join(',')]
          : ['date,asc'],
      enabled: true,
    },
    account?.id,
  );

  const handlePendoTrackEnrollment = (
    type: 'create' | 'update' | 'delete',
    schedule: CourseEntityScheduleExtended,
  ) => {
    if (!account) return;

    switch (schedule.source) {
      case CourseEntityScheduleEnum.ACCOUNT: {
        pendoTrackEnrollment({
          eventType: pendoEvent.enrollAccount,
          type,
          courses: [
            { id: schedule.courseId, name: schedule.courseName } as Course,
          ],
          accounts: [account],
          date: schedule?.date,
          oldEnrollDate: type === 'update' ? selectedSchedule?.date : null,
          autoEnroll: schedule.autoEnroll,
        });
        break;
      }
      case CourseEntityScheduleEnum.BRANCH: {
        pendoTrackEnrollment({
          eventType: pendoEvent.enrollBranch,
          type,
          accounts: [account],
          courses: [
            { id: schedule.courseId, name: schedule.courseName } as Course,
          ],
          branches: [schedule.entity],
          date: schedule.date,
          oldEnrollDate: type === 'update' ? selectedSchedule?.date : null,
          autoEnroll: schedule.autoEnroll,
        });
        break;
      }
      case CourseEntityScheduleEnum.USER: {
        pendoTrackEnrollment({
          eventType: pendoEvent.enrollUser,
          type,
          accounts: [account],
          courses: [
            { id: schedule.courseId, name: schedule.courseName } as Course,
          ],
          users: [schedule.entity],
          date: schedule.date,
          oldEnrollDate: type === 'update' ? selectedSchedule?.date : null,
          autoEnroll: schedule.autoEnroll,
        });
        break;
      }
      default:
        break;
    }
  };

  const changeScheduleDate = useChangeScheduleDate();
  const handleChangeScheduleDate = async (data: ChangeEnrollDateFormValues) => {
    if (selectedSchedule && data.date) {
      try {
        await changeScheduleDate.edit({
          scheduleId: selectedSchedule?.id,
          scheduleType: selectedSchedule?.source,
          date: data.date,
          type: CourseScheduleType.STATIC,
        });

        if (user?.account?.isTrackingEnabled && !isDocument) {
          // Track Mixpanel course enrollment with type update
          track('Update course enrollment', {
            Entity:
              selectedSchedule.source === CourseEntityScheduleEnum.ACCOUNT
                ? 'Account'
                : selectedSchedule.source === CourseEntityScheduleEnum.BRANCH
                ? 'Branch'
                : 'User',
            'Course id': selectedSchedule?.courseId,
            'Course name': selectedSchedule?.courseName,
            'Old enroll date (UTC)': moment
              .utc(selectedSchedule?.date)
              .format(DateFormats.DATEONLY_FULL_MONTH),
            'New enroll date (UTC)': moment
              .utc(data?.date)
              .format(DateFormats.DATEONLY_FULL_MONTH),
            'Enrolled to':
              selectedSchedule.source === CourseEntityScheduleEnum.USER
                ? Md5.hashStr(selectedSchedule?.entity?.id)
                : selectedSchedule?.entity?.name,
            'Account name': account?.name,
            'Total number of days': calculateDayDifferenceFromToday(data?.date),
            'Current date (UTC)': moment
              .utc()
              .format(DateFormats.DATEONLY_FULL_MONTH),
            'Enroll type': selectedSchedule?.autoEnroll ? 'auto' : 'manual',
          });

          handlePendoTrackEnrollment('update', selectedSchedule);
        }

        await refetch();
        setSelectedSchedule(undefined);
        toast?.success(
          t('toast.success'),
          t('courses.schedule.date.change.success'),
        );
      } catch (e) {
        handleAxiosError(e as Error | AxiosError, toast);
      }
    }
  };

  const deleteSchedule = useDeleteSchedule();
  const handleDeleteSchedule = async (payload: DeleteScheduleRequest) => {
    try {
      await deleteSchedule.delete(payload);

      if (user?.account?.isTrackingEnabled && !isDocument) {
        // Track Mixpanel course enrollment with type delete
        track('Delete course enrollment', {
          Entity:
            payload.source === CourseEntityScheduleEnum.ACCOUNT
              ? 'Account'
              : payload.source === CourseEntityScheduleEnum.BRANCH
              ? 'Branch'
              : 'User',
          'Course id': payload?.entity?.courseId,
          'Course name': payload?.entity?.courseName,
          'Enroll date (UTC)': moment
            .utc(payload?.entity?.date)
            .format(DateFormats.DATEONLY_FULL_MONTH),
          'Enrolled to':
            payload.source === CourseEntityScheduleEnum.USER
              ? Md5.hashStr(payload?.entity?.entity?.id)
              : payload?.entity?.entity?.name,
          'Account name': account?.name,
          'Current date (UTC)': moment
            .utc()
            .format(DateFormats.DATEONLY_FULL_MONTH),
          'Enroll type': payload?.entity?.schedule?.autoEnroll
            ? 'auto'
            : 'manual',
        });

        handlePendoTrackEnrollment('delete', payload.entity);
      }

      await refetch();
      toast?.success(
        t('toast.success'),
        t(`courses.${payload.source}.unenroll.success`),
      );
    } catch (e) {
      handleAxiosError(e as Error | AxiosError, toast);
    }
  };

  const changeEnrollMethod = useChangeScheduleEnrollType();
  const handleChangeEnrollMethod = async (
    courseSchedule: CourseEntitySchedule | undefined,
  ) => {
    if (courseSchedule?.id) {
      try {
        await changeEnrollMethod.edit({
          scheduleId: courseSchedule.id,
          scheduleType: courseSchedule.source,
          autoEnroll: !courseSchedule.autoEnroll,
          type: CourseScheduleType.STATIC,
        });
        await refetch();
        toast?.success(
          t('toast.success'),
          t('courses.enrollType.change.success'),
        );
      } catch (e) {
        handleAxiosError(e as Error | AxiosError, toast);
      }
    }
  };

  const menuItems = (
    courseSchedule: CourseEntitySchedule,
    courseId?: string,
    courseName?: string,
  ) => {
    const menu: MenuItem[] = [];

    if (courseSchedule?.source !== CourseEntityScheduleEnum.USER) {
      menu.push({
        label: courseSchedule?.autoEnroll
          ? t('courses.enrollType.change.manual')
          : t('courses.enrollType.change.auto'),
        icon: 'pi pi-sync',
        command: () =>
          setDialogData({
            type: 'confirmation',
            show: true,
            header: t('dialog.enrollType.change'),
            message: t('dialog.enrollType.warning'),
            onAccept: async () => {
              await handleChangeEnrollMethod(courseSchedule);
            },
          }),
      });
    }

    if (!courseSchedule?.executedAt) {
      menu.push(
        {
          label: t('courses.change.enrollDate'),
          icon: 'pi pi-calendar',
          command: () =>
            setSelectedSchedule({ ...courseSchedule, courseId, courseName }),
        },
        {
          label: t('generic.deleteSchedule'),
          icon: 'pi pi-calendar-times',
          command: () =>
            setDialogData({
              type: 'confirmation',
              show: true,
              header: t('dialog.deleteSchedule'),
              message: t(`dialog.deleteSchedule.${courseSchedule?.source}`, {
                name: courseSchedule.entity.name,
              }),
              onAccept: async () => {
                await handleDeleteSchedule({
                  id: courseSchedule?.id,
                  source: courseSchedule?.source,
                  entity: {
                    courseId,
                    courseName,
                    ...courseSchedule,
                  },
                });
              },
            }),
        },
      );
    }
    return menu;
  };

  const columns: DataTableColumnType[] = [
    {
      field: 'course.name',
      header: isDocument ? t('document') : t('course'),
      sortable: false,
      filterable: false,
      style: {
        minWidth: '200px',
        maxWidth: '300px',
      },
      render: (row: CourseSchedule) => (
        <FlexContainer gap={16}>
          {row?.courseThumbnailUrl && (
            <DatatableThumbnail url={row.courseThumbnailUrl} />
          )}
          <FlexContainer
            direction="column"
            gap={8}
            className="align-self-center"
          >
            {row?.course?.name}
          </FlexContainer>
        </FlexContainer>
      ),
    },
    {
      field: 'date',
      header: t('generic.enrollDate'),
      sortable: true,
      filterable: true,
      filters: {
        label: t('filter.byDate'),
        field: FilterNamesEnum.SCHEDULE_UPCOMING_DATE,
        type: FilterTypeEnum.SELECT_UPCOMING_PAST,
        placeholder: t('filter.date'),
      },
      className: 'no-padding',
      render: (row: CourseSchedule) =>
        row?.courseSchedule?.date ? (
          <FormatDate
            format={DateFormats.TIMEDATE}
            date={row.courseSchedule.date}
          />
        ) : (
          <div>&#8212;</div>
        ),
    },
    {
      field: 'entityName',
      header: t('generic.enrollingTo'),
      sortable: false,
      filterable: true,
      filters: { type: FilterTypeEnum.TEXT },
      className: 'no-padding',
      render: (row: CourseSchedule) =>
        row?.courseSchedule?.entity?.name ? (
          row.courseSchedule.entity.name
        ) : (
          <div>&#8212;</div>
        ),
    },
    {
      field: 'type',
      header: t('generic.entity'),
      sortable: false,
      filterable: true,
      filters: {
        field: FilterNamesEnum.ENTITY_TYPE,
        type: FilterTypeEnum.MULTI_SELECT,
        options: coursesEnrollToOptions(t, user),
      },
      className: 'no-padding',
      render: (row: CourseSchedule) =>
        row?.courseSchedule?.source ? (
          row.courseSchedule?.source === CourseEntityScheduleEnum.ACCOUNT ? (
            t('account')
          ) : row.courseSchedule?.source === CourseEntityScheduleEnum.BRANCH ? (
            t('branch')
          ) : row.courseSchedule?.source === CourseEntityScheduleEnum.GROUP ? (
            t('group')
          ) : (
            t('user')
          )
        ) : (
          <div>&#8212;</div>
        ),
    },
    {
      field: 'usersCount',
      header: t('users'),
      sortable: true,
      filterable: false,
      className: 'no-padding',
      render: (row: CourseSchedule) =>
        row?.courseSchedule?.usersCount ? row.courseSchedule.usersCount : 0,
    },
    {
      field: 'scheduledBy',
      header: t('generic.scheduledBy'),
      sortable: false,
      filterable: true,
      filters: { type: FilterTypeEnum.TEXT },
      className: 'no-padding',
      render: (row: CourseSchedule) =>
        row?.courseSchedule?.scheduledBy.id ? (
          row.courseSchedule.scheduledBy?.name
        ) : (
          <div>&#8212;</div>
        ),
    },
    {
      field: 'autoEnroll',
      header: t('schedule.newUsers.enroll'),
      sortable: true,
      filterable: true,
      filters: {
        label: t('filter.byEnrollType'),
        field: !isBranchAdmin
          ? FilterNamesEnum.COURSE_SCHEDULE_AUTO_ENROLL
          : FilterNamesEnum.COURSE_AUTO_ENROLL,
        type: FilterTypeEnum.SELECT_AUTO_MANUAL,
        placeholder: t('filter.enrollType'),
      },
      className: 'no-padding',
      render: (row: CourseSchedule) =>
        !isBranchAdmin ? (
          <>
            {row?.courseSchedule?.source ? (
              row.courseSchedule?.source === CourseEntityScheduleEnum.USER ? (
                <div>&#8212;</div>
              ) : row.courseSchedule?.autoEnroll ? (
                <AppChip label={t('generic.auto')} type="primary" />
              ) : (
                <AppChip label={t('generic.manual')} type="secondary" />
              )
            ) : (
              <div>&#8212;</div>
            )}
          </>
        ) : row?.accountAutoEnroll ? (
          <AppChip label={t('generic.auto')} type="primary" />
        ) : (
          <AppChip label={t('generic.manual')} type="secondary" />
        ),
    },
    {
      field: 'actions',
      header: t('generic.actions'),
      sortable: false,
      filterable: false,
      className: 'no-padding',
      style: {
        width: '80px',
        textAlign: 'center',
      },
      render: (row: CourseSchedule) => (
        <DataTableActions
          menuItems={menuItems(
            row.courseSchedule,
            row?.course?.id,
            row?.course?.name,
          )}
          disabled={menuItems(row.courseSchedule)?.length < 1}
        />
      ),
    },
  ];

  // Set the preselected columns
  const [visibleColumns, setVisibleColumns] = useState<string[]>([]);
  const defaultVisibleColumns = columns.map((column) => column.field);
  const alwaysVisibleColumns = ['name', 'actions'];
  //

  const { params } = useQueryParams(getQueryTransformation<{ name: string }>());

  const toolbar = (
    <DataTableToolbar justify="space-between">
      <FlexContainer
        justify="space-between"
        gap={8}
        align="flex-start"
        wrap="wrap"
      >
        <DataTableFilters
          filters={getFiltersFromColumns(columns)}
          onFilter={onFilter}
          defaultValues={
            !params.filters
              ? { [FilterNamesEnum.SCHEDULE_UPCOMING_DATE]: 'yes' }
              : params.filters
          }
          tableName={
            isDocument
              ? TableNamesEnum.DOCUMENTS_ACCOUNT_SCHEDULE
              : TableNamesEnum.COURSES_ACCOUNT_SCHEDULE
          }
          className="flex-initial"
        />
        <div className="p-input-icon-left flex-auto min-w-300">
          <InputText
            className="w-full"
            onInput={debouncedSetMultiSearchValue}
            placeholder={
              isDocument ? t('documents.search.name') : t('courses.search.name')
            }
            autoComplete="off"
          />
          <i className="pi pi-search" />
        </div>
      </FlexContainer>
      <DataTableColumnsMultiselect
        columns={columns}
        tableName={
          isDocument
            ? TableNamesEnum.DOCUMENTS_ACCOUNT_SCHEDULE
            : TableNamesEnum.COURSES_ACCOUNT_SCHEDULE
        }
        visibleColumns={visibleColumns}
        setVisibleColumns={setVisibleColumns}
        defaultVisibleColumns={defaultVisibleColumns}
        alwaysVisibleColumns={alwaysVisibleColumns}
      />
    </DataTableToolbar>
  );

  const onRowClick = (e: DataTableRowClickEvent) => {
    if (e?.data?.course?.id) {
      navigate(
        RedirectPaths[
          isDocument
            ? RedirectPathsEnum.POLICIES_AND_DOCUMENTS_EDIT
            : RedirectPathsEnum.COURSES_CONTENT
        ](e.data.course.id),
      );
    }
  };

  return (
    <>
      {account && (
        <>
          <AccountEnrollmentActionHeader
            account={account}
            onTriggerRefetch={refetch}
            isDocument={isDocument}
          />
          {account?.meta?.enableCoursePlanner && <EnrollmentTabs />}
          <DataTable
            dataKey="dataId"
            rowGroupMode="rowspan"
            groupRowsBy="course.name"
            data={schedules?.result.map((x, i) => ({
              ...x,
              dataId: `${x.courseSchedule?.entity.id}-${x.course?.id}-${i}`,
            }))}
            count={schedules?.count as number}
            isLoading={isScheduleLoading}
            toolbar={toolbar}
            columns={columns}
            visibleColumns={visibleColumns}
            onPage={onPage}
            rows={take}
            onRowClick={onRowClick}
            skip={skip}
            onSort={onSort}
            sort={sort}
            classNames="rowspan-datatable with-actions"
          />
          <ChangeEnrollDateModal
            date={selectedSchedule?.date}
            visible={!!selectedSchedule}
            onSubmit={handleChangeScheduleDate}
            onHide={() => setSelectedSchedule(undefined)}
          />
        </>
      )}
    </>
  );
};
