import { processListingParams } from '@/api/helpers';
import { getExceptionFromAxiosError } from '@/client/helpers';
import { MessageResponseModel } from '@/client/models';
import {
  ExportUsersRequest,
  ListUsersRequest,
  ListUsersResponse,
  SaveUserRequest,
  UpdateUserRequest,
  User,
} from '@/client/users';
import { AxiosError, AxiosInstance, AxiosResponse } from 'axios';
import { omit } from 'lodash';

export const usersService = (client: AxiosInstance) => {
  const USERS_BASE_URL = '/users';

  const getUsers = async (
    params: ListUsersRequest & { withBranches?: boolean },
  ): Promise<ListUsersResponse> => {
    try {
      const result = await client.get<
        ListUsersResponse,
        AxiosResponse<ListUsersResponse>
      >(USERS_BASE_URL, {
        withCredentials: true,
        params: {
          ...processListingParams(omit(params, 'withBranches')),
          withBranches: params.withBranches ? 1 : 0,
        },
      });

      return result.data;
    } catch (e) {
      throw getExceptionFromAxiosError(e as AxiosError);
    }
  };

  const getUser = async (userId: string): Promise<User> => {
    try {
      const result = await client.get<User, AxiosResponse<User>>(
        `${USERS_BASE_URL}/${userId}`,
        {
          withCredentials: true,
        },
      );

      return result.data;
    } catch (e) {
      throw getExceptionFromAxiosError(e as AxiosError);
    }
  };

  const saveUser = async (request: SaveUserRequest): Promise<User> => {
    try {
      const result = await client.post<User, AxiosResponse<User>>(
        USERS_BASE_URL,
        request,
        {
          withCredentials: true,
        },
      );

      return result.data;
    } catch (e) {
      throw getExceptionFromAxiosError(e as AxiosError);
    }
  };

  const updateUser = async (request: UpdateUserRequest): Promise<User> => {
    try {
      const result = await client.patch<User, AxiosResponse<User>>(
        `${USERS_BASE_URL}/${request.userId}`,
        request.updates,
        {
          withCredentials: true,
        },
      );

      return result.data;
    } catch (e) {
      throw getExceptionFromAxiosError(e as AxiosError);
    }
  };

  const deleteUser = async (userId: string): Promise<MessageResponseModel> => {
    try {
      const result = await client.delete<
        MessageResponseModel,
        AxiosResponse<MessageResponseModel>
      >(`${USERS_BASE_URL}/${userId}`, {
        withCredentials: true,
      });

      return new MessageResponseModel(result.data);
    } catch (e) {
      throw getExceptionFromAxiosError(e as AxiosError);
    }
  };

  const exportUsers = async (
    request: ExportUsersRequest,
  ): Promise<MessageResponseModel> => {
    try {
      const result = await client.post<
        MessageResponseModel,
        AxiosResponse<MessageResponseModel>
      >(
        `/accounts/${request.accountId}${USERS_BASE_URL}/export`,
        { ...request },
        {
          withCredentials: true,
        },
      );

      return result.data;
    } catch (e) {
      throw getExceptionFromAxiosError(e as AxiosError);
    }
  };

  const userNotifyUrl = (userId: string) =>
    `${client.defaults.baseURL}users/${userId}/notify`;

  const usersNotifyUrl = () => `${client.defaults.baseURL}users/notify`;

  return {
    getUsers,
    getUser,
    saveUser,
    updateUser,
    deleteUser,
    exportUsers,
    userNotifyUrl,
    usersNotifyUrl,
  };
};
