import { LanguagesEnum } from '@/api/enums';
import { processListingParams } from '@/api/helpers';
import { ListAccountsRequest, ListAccountsResponse } from '@/client/accounts';
import {
  AccountsSchedulesRequest,
  BranchesSchedulesRequest,
  ChangeScheduleDateRequest,
  ChangeScheduleEnrollTypeRequest,
  Course,
  CourseEntityScheduleEnum,
  DeleteEnrollmentPayload,
  ForceCompleteRequest,
  GroupsSchedulesRequest,
  ListAccountScheduleRequest,
  ListAccountScheduleResponse,
  ListCourseAccountRequest,
  ListCourseAccountResponse,
  ListCourseBranchesRequest,
  ListCourseBranchesResponse,
  ListCourseGroupsRequest,
  ListCourseGroupsResponse,
  ListCourseScheduleRequest,
  ListCourseScheduleResponse,
  ListCoursesRequest,
  ListCoursesResponse,
  ListCourseUsersRequest,
  ListCourseUsersResponse,
  MakeAvailableAccountsRequest,
  MakeAvailableCoursesToAccountRequest,
  SaveCourseRequest,
  UpdateCourseRequest,
  UsersSchedulesRequest,
} from '@/client/courses';
import { getExceptionFromAxiosError } from '@/client/helpers';
import { MessageResponseModel } from '@/client/models';
import { ScormCourseDispatchResponse } from '@/client/scorm';
import i18n from '@/i18n';
import { AxiosError, AxiosInstance, AxiosResponse } from 'axios';

export const coursesService = (client: AxiosInstance) => {
  const COURSES_BASE_URL = '/courses';

  const getCourses = async (
    params: ListCoursesRequest,
  ): Promise<ListCoursesResponse> => {
    try {
      const result = await client.get<
        ListCoursesResponse,
        AxiosResponse<ListCoursesResponse>
      >(COURSES_BASE_URL, {
        withCredentials: true,
        params: processListingParams(params),
        headers: {
          'x-accept-language': i18n.language,
        },
      });

      return result.data;
    } catch (e) {
      throw getExceptionFromAxiosError(e as AxiosError);
    }
  };

  const getCourse = async (courseId?: string): Promise<Course> => {
    try {
      const result = await client.get<Course, AxiosResponse<Course>>(
        `${COURSES_BASE_URL}/${courseId}`,
        {
          withCredentials: true,
        },
      );

      return result.data;
    } catch (e) {
      throw getExceptionFromAxiosError(e as AxiosError);
    }
  };

  const saveCourse = async (request: SaveCourseRequest): Promise<Course> => {
    try {
      const result = await client.post<Course, AxiosResponse<Course>>(
        COURSES_BASE_URL,
        request,
        {
          withCredentials: true,
        },
      );

      return result.data;
    } catch (e) {
      throw getExceptionFromAxiosError(e as AxiosError);
    }
  };

  const updateCourse = async (
    request: UpdateCourseRequest,
  ): Promise<Course> => {
    try {
      const result = await client.patch<Course, AxiosResponse<Course>>(
        `${COURSES_BASE_URL}/${request.courseId}`,
        request.updates,
        {
          withCredentials: true,
        },
      );

      return result.data;
    } catch (e) {
      throw getExceptionFromAxiosError(e as AxiosError);
    }
  };

  const deleteCourse = async (
    courseId: string,
  ): Promise<MessageResponseModel> => {
    try {
      const result = await client.delete<
        MessageResponseModel,
        AxiosResponse<MessageResponseModel>
      >(`${COURSES_BASE_URL}/${courseId}`, {
        withCredentials: true,
      });

      return new MessageResponseModel(result.data);
    } catch (e) {
      throw e as AxiosError;
    }
  };

  const getCourseUrl = async (
    scormCourseId: string,
    language: LanguagesEnum,
  ): Promise<ScormCourseDispatchResponse> => {
    try {
      const result = await client.get<
        ScormCourseDispatchResponse,
        AxiosResponse<ScormCourseDispatchResponse>
      >(`${COURSES_BASE_URL}/${scormCourseId}/dispatch-url/${language}`, {
        withCredentials: true,
      });

      return result.data;
    } catch (e) {
      throw getExceptionFromAxiosError(e as AxiosError);
    }
  };

  const saveAvailableAccounts = async (
    request: MakeAvailableAccountsRequest,
  ): Promise<MessageResponseModel> => {
    try {
      const result = await client.post<
        MessageResponseModel,
        AxiosResponse<MessageResponseModel>
      >(
        `${COURSES_BASE_URL}/${request.courseId}/availability/accounts`,
        { accounts: request.accounts },
        {
          withCredentials: true,
        },
      );

      return result.data;
    } catch (e) {
      throw getExceptionFromAxiosError(e as AxiosError);
    }
  };

  const getUnavailableCoursesToAccount = async (
    params: ListCoursesRequest,
    accountId: string,
  ): Promise<ListCoursesResponse> => {
    try {
      const result = await client.get<
        ListCoursesResponse,
        AxiosResponse<ListCoursesResponse>
      >(`accounts/${accountId}/unavailable-courses`, {
        withCredentials: true,
        params: processListingParams(params),
      });

      return result.data;
    } catch (e) {
      throw getExceptionFromAxiosError(e as AxiosError);
    }
  };

  const saveAvailableCoursesToAccount = async (
    request: MakeAvailableCoursesToAccountRequest,
  ): Promise<MessageResponseModel> => {
    try {
      const result = await client.post<
        MessageResponseModel,
        AxiosResponse<MessageResponseModel>
      >(
        `${COURSES_BASE_URL}/availability/accounts/${request.accountId}`,
        { courses: request.courses },
        {
          withCredentials: true,
        },
      );

      return result.data;
    } catch (e) {
      throw getExceptionFromAxiosError(e as AxiosError);
    }
  };

  const saveUnavailableAccounts = async (
    request: MakeAvailableAccountsRequest,
  ): Promise<MessageResponseModel> => {
    try {
      const result = await client.delete<
        MessageResponseModel,
        AxiosResponse<MessageResponseModel>
      >(`${COURSES_BASE_URL}/${request.courseId}/availability/accounts`, {
        data: { accounts: request.accounts },
        withCredentials: true,
      });

      return new MessageResponseModel(result.data);
    } catch (e) {
      throw e as AxiosError;
    }
  };

  const getUnavailableAccounts = async (
    params: ListAccountsRequest,
    courseId?: string,
  ): Promise<ListAccountsResponse> => {
    try {
      const result = await client.get<
        ListAccountsResponse,
        AxiosResponse<ListAccountsResponse>
      >(`${COURSES_BASE_URL}/${courseId}/unavailable-accounts`, {
        withCredentials: true,
        params: processListingParams(params),
      });

      return result.data;
    } catch (e) {
      throw getExceptionFromAxiosError(e as AxiosError);
    }
  };

  const getCourseSchedules = async (
    params: ListCourseScheduleRequest,
    courseId?: string,
  ): Promise<ListCourseScheduleResponse> => {
    try {
      const result = await client.get<
        ListCourseScheduleRequest,
        AxiosResponse<ListCourseScheduleResponse>
      >(`${COURSES_BASE_URL}/${courseId}/schedules`, {
        withCredentials: true,
        params: processListingParams(params),
      });

      return result.data;
    } catch (e) {
      throw getExceptionFromAxiosError(e as AxiosError);
    }
  };

  const saveAccountSchedule = async (
    request: AccountsSchedulesRequest,
  ): Promise<MessageResponseModel> => {
    try {
      const result = await client.post<
        AccountsSchedulesRequest,
        AxiosResponse<MessageResponseModel>
      >(
        `${COURSES_BASE_URL}/account-schedules`,
        {
          courses: request.courses,
          accounts: request.accounts,
          date: request.date,
          autoEnroll: request.autoEnroll,
          type: request.type,
        },
        {
          withCredentials: true,
        },
      );

      return result.data;
    } catch (e) {
      throw getExceptionFromAxiosError(e as AxiosError);
    }
  };

  const saveBranchesSchedules = async (
    request: BranchesSchedulesRequest,
  ): Promise<MessageResponseModel> => {
    try {
      const result = await client.post<
        BranchesSchedulesRequest,
        AxiosResponse<MessageResponseModel>
      >(
        `${COURSES_BASE_URL}/branch-schedules`,
        {
          courses: request.courses,
          branches: request.branches,
          date: request.date,
          autoEnroll: request.autoEnroll,
          type: request.type,
        },
        {
          withCredentials: true,
        },
      );

      return result.data;
    } catch (e) {
      throw getExceptionFromAxiosError(e as AxiosError);
    }
  };

  const saveGroupsSchedules = async (
    request: GroupsSchedulesRequest,
  ): Promise<MessageResponseModel> => {
    try {
      const result = await client.post<
        GroupsSchedulesRequest,
        AxiosResponse<MessageResponseModel>
      >(
        `${COURSES_BASE_URL}/group-schedules`,
        {
          courses: request.courses,
          groups: request.groups,
          date: request.date,
          autoEnroll: request.autoEnroll,
          type: request.type,
        },
        {
          withCredentials: true,
        },
      );

      return result.data;
    } catch (e) {
      throw getExceptionFromAxiosError(e as AxiosError);
    }
  };

  const saveUsersSchedules = async (
    request: UsersSchedulesRequest,
  ): Promise<MessageResponseModel> => {
    try {
      const result = await client.post<
        UsersSchedulesRequest,
        AxiosResponse<MessageResponseModel>
      >(
        `${COURSES_BASE_URL}/user-schedules`,
        {
          courses: request.courses,
          users: request.users,
          date: request.date,
          autoEnroll: request.autoEnroll,
          type: request.type,
        },
        {
          withCredentials: true,
        },
      );

      return result.data;
    } catch (e) {
      throw getExceptionFromAxiosError(e as AxiosError);
    }
  };

  const changeScheduleDate = async (
    request: ChangeScheduleDateRequest,
  ): Promise<MessageResponseModel> => {
    try {
      const result = await client.patch<
        MessageResponseModel,
        AxiosResponse<MessageResponseModel>
      >(
        `${COURSES_BASE_URL}/${request.scheduleType}-schedules/${request.scheduleId}`,
        {
          date: request.date,
          type: request.type,
        },
        {
          withCredentials: true,
        },
      );

      return new MessageResponseModel(result.data);
    } catch (e) {
      throw getExceptionFromAxiosError(e as AxiosError);
    }
  };

  const changeScheduleEnrollType = async (
    request: ChangeScheduleEnrollTypeRequest,
  ): Promise<MessageResponseModel> => {
    try {
      const result = await client.patch<
        MessageResponseModel,
        AxiosResponse<MessageResponseModel>
      >(
        `${COURSES_BASE_URL}/${request.scheduleType}-schedules/${request.scheduleId}`,
        { autoEnroll: request.autoEnroll, type: request.type },
        {
          withCredentials: true,
        },
      );

      return new MessageResponseModel(result.data);
    } catch (e) {
      throw getExceptionFromAxiosError(e as AxiosError);
    }
  };

  const deleteSchedule = async (
    scheduleId: string,
    scheduleType: CourseEntityScheduleEnum,
  ): Promise<MessageResponseModel> => {
    try {
      const result = await client.delete<
        MessageResponseModel,
        AxiosResponse<MessageResponseModel>
      >(`${COURSES_BASE_URL}/${scheduleType}-schedules/${scheduleId}`, {
        withCredentials: true,
      });

      return new MessageResponseModel(result.data);
    } catch (e) {
      throw getExceptionFromAxiosError(e as AxiosError);
    }
  };

  const deleteEnrollment = async (
    payload: DeleteEnrollmentPayload,
  ): Promise<MessageResponseModel> => {
    try {
      const result = await client.post<
        MessageResponseModel,
        AxiosResponse<MessageResponseModel>
      >(`${COURSES_BASE_URL}/${payload.courseId}/unenroll`, payload.request, {
        withCredentials: true,
      });

      return new MessageResponseModel(result.data);
    } catch (e) {
      throw getExceptionFromAxiosError(e as AxiosError);
    }
  };

  const getAccountSchedules = async (
    params: ListAccountScheduleRequest,
    accountId?: string,
  ): Promise<ListAccountScheduleResponse> => {
    try {
      const result = await client.get<
        ListAccountScheduleRequest,
        AxiosResponse<ListAccountScheduleResponse>
      >(`${COURSES_BASE_URL}/accounts/${accountId}/schedules`, {
        withCredentials: true,
        params: processListingParams(params),
        headers: {
          'x-accept-language': i18n.language,
        },
      });
      return result.data;
    } catch (e) {
      throw getExceptionFromAxiosError(e as AxiosError);
    }
  };

  const getCourseAccounts = async (
    params: ListCourseAccountRequest,
    courseId?: string,
  ): Promise<ListCourseAccountResponse> => {
    try {
      const result = await client.get<
        ListCourseAccountRequest,
        AxiosResponse<ListCourseAccountResponse>
      >(`${COURSES_BASE_URL}/${courseId}/accounts`, {
        withCredentials: true,
        params: processListingParams(params),
      });
      return result.data;
    } catch (e) {
      throw getExceptionFromAxiosError(e as AxiosError);
    }
  };

  const getCourseBranches = async (
    params: ListCourseBranchesRequest,
    courseId?: string,
  ): Promise<ListCourseBranchesResponse> => {
    try {
      const result = await client.get<
        ListCourseBranchesRequest,
        AxiosResponse<ListCourseBranchesResponse>
      >(`${COURSES_BASE_URL}/${courseId}/branches`, {
        withCredentials: true,
        params: processListingParams(params),
      });
      return result.data;
    } catch (e) {
      throw getExceptionFromAxiosError(e as AxiosError);
    }
  };

  const getCourseGroups = async (
    params: ListCourseGroupsRequest,
    courseId?: string,
  ): Promise<ListCourseGroupsResponse> => {
    try {
      const result = await client.get<
        ListCourseGroupsRequest,
        AxiosResponse<ListCourseGroupsResponse>
      >(`${COURSES_BASE_URL}/${courseId}/groups`, {
        withCredentials: true,
        params: processListingParams(params),
      });
      return result.data;
    } catch (e) {
      throw getExceptionFromAxiosError(e as AxiosError);
    }
  };

  const getCourseUsers = async (
    params: ListCourseUsersRequest,
    courseId?: string,
  ): Promise<ListCourseUsersResponse> => {
    try {
      const result = await client.get<
        ListCourseUsersRequest,
        AxiosResponse<ListCourseUsersResponse>
      >(`${COURSES_BASE_URL}/${courseId}/users`, {
        withCredentials: true,
        params: processListingParams(params),
      });
      return result.data;
    } catch (e) {
      throw getExceptionFromAxiosError(e as AxiosError);
    }
  };

  const coursesNotifyUrl = (courseId: string) =>
    `${client.defaults.baseURL}courses/notify/${courseId}`;

  const forceComplete = async (
    request: ForceCompleteRequest,
  ): Promise<MessageResponseModel> => {
    try {
      const result = await client.post<
        ForceCompleteRequest,
        AxiosResponse<MessageResponseModel>
      >(
        `${COURSES_BASE_URL}/${request.courseId}/users/${request.userId}/force-complete`,
        { score: request.score },
        { withCredentials: true },
      );

      return result.data;
    } catch (e) {
      throw getExceptionFromAxiosError(e as AxiosError);
    }
  };

  return {
    getCourses,
    getCourse,
    saveCourse,
    updateCourse,
    deleteCourse,
    getCourseUrl,
    saveAvailableAccounts,
    getUnavailableCoursesToAccount,
    saveAvailableCoursesToAccount,
    saveUnavailableAccounts,
    getUnavailableAccounts,
    getCourseSchedules,
    saveAccountSchedule,
    saveBranchesSchedules,
    saveGroupsSchedules,
    saveUsersSchedules,
    changeScheduleDate,
    changeScheduleEnrollType,
    deleteSchedule,
    deleteEnrollment,
    getAccountSchedules,
    getCourseAccounts,
    getCourseBranches,
    getCourseGroups,
    getCourseUsers,
    coursesNotifyUrl,
    forceComplete,
  };
};
