import { FilePurpose, UsersImportErrors } from '@/api/enums';
import { equal, handleAxiosError } from '@/api/helpers';
import { FiltersType } from '@/api/types';
import { isKeycloakEnabled } from '@/auth';
import { client } from '@/client';
import { FilterNamesEnum, getFiltersFromColumns } from '@/client/helpers';
import { Actions, Subjects, User } from '@/client/users';
import {
  RedirectPaths,
  RedirectPathsEnum,
  TableNamesEnum,
} from '@/common/constants';
import { roleTranslations, systemRoleOptions } from '@/common/constants/roles';
import { DialogContext } from '@/common/context';
import {
  DataTable,
  DataTableActions,
  DataTableColumnsMultiselect,
  DataTableColumnType,
  DataTableFilters,
  DataTableToolbar,
  FilterTypeEnum,
} from '@/components/tables/crud';
import { useNotifications } from '@/hooks/notifications.hook';
import {
  useAccountUsersImport,
  useBranchesPartialRequest,
  useBranchUsersImport,
  useDeleteUser,
  useGroupsPartialRequest,
  useUpdateUser,
  useUsers,
} from '@/hooks/query';
import { useAppSelector } from '@/hooks/store';
import { useTable } from '@/hooks/table.hook';
import { usePermission } from '@/hooks/usePermission';
import { MultipleToastType, ToastType, useToast } from '@/hooks/useToast';
import { selectCurrentAccount } from '@/store/features/account';
import { DateFormats } from '@/system-settings/enums';
import { AppButton } from '@/ui/buttons';
import { FormatDate } from '@/ui/date';
import { FlexContainer } from '@/ui/styled-ui';
import { randomString } from '@/utils/helpers';
import { getBranchName } from '@/utils/helpers/branches.helper';
import { AxiosError } from 'axios';
import { DataTableRowClickEvent } from 'primereact/datatable';
import { FileUpload, FileUploadUploadEvent } from 'primereact/fileupload';
import { Menu } from 'primereact/menu';
import { MenuItem } from 'primereact/menuitem';
import { ProgressSpinner } from 'primereact/progressspinner';
import React, {
  ReactNode,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useAuth } from 'react-oidc-context';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { UsersExportModal } from '../UsersExportModal';

const StyledFileUpload = styled(FileUpload)`
  > .p-button.p-component.p-fileupload-choose {
    padding: var(--medium-padding) var(--small-padding);
    width: 100%;
    border: 0;
    background-color: transparent;
    color: #000;
    text-align: left;
    border-radius: 0;

    &:hover {
      color: #000;
      border-color: var(--gray-pale) !important;
      background: var(--gray-pale) !important;
    }

    .p-button-label {
      font-weight: 400;
    }
  }
`;

type UserDatatableProps = {
  defaultFilters?: FiltersType;
  defaultTableFilters?: Record<string, any>;
  withActions?: boolean;
  withToolbar?: boolean;
  withBranches?: boolean;
  withGroups?: number;
  withAddNew?: boolean;
  setShowBulkModal?: (value: React.SetStateAction<boolean>) => void;
  additionalColumns?: DataTableColumnType[];
  toolbarAdditional?: ReactNode;
  shouldRefetch?: boolean;
  withImport?: boolean;
  branchId?: string;
};

export const UserDatatable: React.FC<UserDatatableProps> = ({
  defaultFilters = [],
  defaultTableFilters = {},
  withActions = false,
  withToolbar = false,
  withBranches = false,
  withGroups = 0,
  withAddNew = false,
  setShowBulkModal,
  additionalColumns,
  toolbarAdditional,
  shouldRefetch,
  withImport,
  branchId,
}) => {
  const { t } = useTranslation();
  const toast = useToast();
  const { can } = usePermission();
  const { user } = useAuth();
  const currentAccount = useAppSelector(selectCurrentAccount);

  const { skip, take, sort, apiFilters, onFilter, onSort, onPage } = useTable();

  const { setDialogData } = useContext(DialogContext);
  const [showExportModal, setShowExportModal] = useState(false);

  const { isLoading, users, refetch } = useUsers({
    take,
    skip,
    filters: [...apiFilters, ...defaultFilters],
    sort: sort && sort.length > 0 ? [sort.join(',')] : [],
    withBranches,
    withGroups,
  });

  const { branches } = useBranchesPartialRequest({
    filters: [equal('account', currentAccount?.id)],
    sort: ['name,asc'],
  });
  const { groups } = useGroupsPartialRequest({
    filters: [equal('account', currentAccount?.id)],
    sort: ['name,asc'],
  });

  const updateUser = useUpdateUser();
  const deleteUser = useDeleteUser();

  const navigate = useNavigate();

  const onRowClick = (event: DataTableRowClickEvent) =>
    navigate(RedirectPaths[RedirectPathsEnum.USERS_EDIT](event.data.id));

  let columns: DataTableColumnType[] = [
    {
      field: 'id',
      header: t('generic.id'),
      sortable: false,
      filterable: true,
      filters: {
        type: FilterTypeEnum.TEXT,
      },
    },
    {
      field: 'firstName',
      header: t('generic.name'),
      sortable: true,
      filterable: true,
      filters: {
        type: FilterTypeEnum.TEXT,
        placeholder: t('user.search.name'),
        field: FilterNamesEnum.NAME,
      },
      render: (row: User) => row.name ?? <span>&#8212;</span>,
    },
    {
      field: 'username',
      header: t('generic.username'),
      sortable: true,
      filterable: true,
      filters: {
        type: FilterTypeEnum.TEXT,
        placeholder: t('user.search.username'),
      },
      render: (row: User) => row.username,
    },
    {
      field: 'email',
      header: t('generic.email'),
      sortable: true,
      filterable: true,
      filters: {
        field: FilterNamesEnum.USER_BY_EMAIL,
        type: FilterTypeEnum.TEXT,
        placeholder: t('user.searchEmail'),
      },
      render: (row: User) => row.email,
    },
    {
      field: 'branch',
      header: t('branch'),
      sortable: false,
      filterable: true,
      filters: {
        label: t('branch'),
        type: FilterTypeEnum.SELECT,
        field: FilterNamesEnum.BRANCH,
        options: branches?.map(({ id, name }) => ({
          label: name,
          value: id,
        })),
      },
      render: (row: User) => (
        <FlexContainer direction="column" align="flex-start">
          {row?.branch ? (
            <span>{getBranchName(row.branch).reverse().join(' / ')}</span>
          ) : (
            <div className="group-row">&#8212;</div>
          )}
        </FlexContainer>
      ),
    },
    ...(can(Actions.READ, Subjects.GROUPS)
      ? [
          {
            field: 'groups',
            header: t('groups'),
            sortable: false,
            filterable: true,
            filters: {
              label: t('groups'),
              type: FilterTypeEnum.SELECT,
              field: FilterNamesEnum.GROUPS,
              options: groups?.map(({ id, name }) => ({
                label: name,
                value: id,
              })),
            },
            render: (row: User) => (
              <FlexContainer direction="column" align="flex-start">
                {row?.groups?.length ? (
                  <span>{row.groups?.map(({ name }) => name)?.join(', ')}</span>
                ) : (
                  <div className="group-row">&#8212;</div>
                )}
              </FlexContainer>
            ),
          },
        ]
      : []),
    {
      field: 'updated',
      header: t('generic.updated'),
      sortable: true,
      filterable: false,
      render: (row: User) =>
        row?.updated ? <FormatDate date={row?.updated} /> : t('generic.never'),
    },
    {
      field: 'active',
      header: t('generic.active'),
      sortable: true,
      filterable: true,
      filters: {
        label: t('filter.status'),
        type: FilterTypeEnum.SELECT_ACTIVE_INACTIVE,
      },
      render: (row: User) =>
        row.active ? t('generic.active') : t('generic.inactive'),
    },
    {
      field: 'role',
      header: t('generic.role'),
      sortable: false,
      filterable: true,
      filters: {
        type: FilterTypeEnum.SELECT,
        options: systemRoleOptions(t),
      },
      render: (row: User) => roleTranslations(row.role, t),
    },
    {
      field: 'companyName',
      header: t('generic.companyName'),
      sortable: false,
      filterable: true,
      filters: {
        field: FilterNamesEnum.COMPANY_NAME,
        type: FilterTypeEnum.TEXT,
        placeholder: t('filter.companyName'),
      },
      className: 'no-padding',
      render: (row: User) => (
        <FlexContainer direction="column" align="flex-start">
          {row?.meta?.companyName ? (
            <span>{row?.meta?.companyName}</span>
          ) : (
            <div className="group-row">&#8212;</div>
          )}
        </FlexContainer>
      ),
    },
    {
      field: 'country',
      header: t('generic.country'),
      sortable: false,
      filterable: true,
      filters: {
        field: FilterNamesEnum.COUNTRY,
        type: FilterTypeEnum.TEXT,
        placeholder: t('filter.country'),
      },
      className: 'no-padding',
      render: (row: User) => (
        <FlexContainer direction="column" align="flex-start">
          {row?.meta?.country ? (
            <span>{row?.meta?.country}</span>
          ) : (
            <div className="group-row">&#8212;</div>
          )}
        </FlexContainer>
      ),
    },
    {
      field: 'officeLocation',
      header: t('generic.officeLocation'),
      sortable: false,
      filterable: true,
      filters: {
        field: FilterNamesEnum.OFFICE_LOCATION,
        type: FilterTypeEnum.TEXT,
        placeholder: t('filter.officeLocation'),
      },
      className: 'no-padding',
      render: (row: User) => (
        <FlexContainer direction="column" align="flex-start">
          {row?.meta?.officeLocation ? (
            <span>{row?.meta?.officeLocation}</span>
          ) : (
            <div className="group-row">&#8212;</div>
          )}
        </FlexContainer>
      ),
    },
    {
      field: 'department',
      header: t('generic.department'),
      sortable: false,
      filterable: true,
      filters: {
        field: FilterNamesEnum.DEPARTMENT,
        type: FilterTypeEnum.TEXT,
        placeholder: t('filter.department'),
      },
      className: 'no-padding',
      render: (row: User) => (
        <FlexContainer direction="column" align="flex-start">
          {row?.meta?.department ? (
            <span>{row?.meta?.department}</span>
          ) : (
            <div className="group-row">&#8212;</div>
          )}
        </FlexContainer>
      ),
    },
    {
      field: 'manager',
      header: t('generic.manager'),
      sortable: false,
      filterable: true,
      filters: {
        field: FilterNamesEnum.MANAGER,
        type: FilterTypeEnum.TEXT,
        placeholder: t('filter.manager'),
      },
      className: 'no-padding',
      render: (row: User) => (
        <FlexContainer direction="column" align="flex-start">
          {row?.meta?.manager ? (
            <span>{row?.meta?.manager}</span>
          ) : (
            <div className="group-row">&#8212;</div>
          )}
        </FlexContainer>
      ),
    },
    {
      field: 'managerEmail',
      header: t('generic.managerEmail'),
      sortable: false,
      filterable: true,
      filters: {
        field: FilterNamesEnum.MANAGER_EMAIL,
        type: FilterTypeEnum.TEXT,
        placeholder: t('filter.managerEmail'),
      },
      className: 'no-padding',
      render: (row: User) => (
        <FlexContainer direction="column" align="flex-start">
          {row?.meta?.managerEmail ? (
            <span>{row?.meta?.managerEmail}</span>
          ) : (
            <div className="group-row">&#8212;</div>
          )}
        </FlexContainer>
      ),
    },
    {
      field: 'jobTitle',
      header: t('generic.jobTitle'),
      sortable: false,
      filterable: true,
      filters: {
        field: FilterNamesEnum.JOB,
        type: FilterTypeEnum.TEXT,
        placeholder: t('filter.jobTitle'),
      },
      className: 'no-padding',
      render: (row: User) => (
        <FlexContainer direction="column" align="flex-start">
          {row?.meta?.jobTitle ? (
            <span>{row?.meta?.jobTitle}</span>
          ) : (
            <div className="group-row">&#8212;</div>
          )}
        </FlexContainer>
      ),
    },
    {
      field: 'mobilePhone',
      header: t('generic.mobilePhone'),
      sortable: false,
      filterable: true,
      filters: {
        field: FilterNamesEnum.MOBILE_PHONE,
        type: FilterTypeEnum.TEXT,
        placeholder: t('filter.mobilePhone'),
      },
      className: 'no-padding',
      render: (row: User) => (
        <FlexContainer direction="column" align="flex-start">
          {row?.meta?.mobilePhone ? (
            <span>{row?.meta?.mobilePhone}</span>
          ) : (
            <div className="group-row">&#8212;</div>
          )}
        </FlexContainer>
      ),
    },
    {
      field: 'lastLogin',
      header: t('user.lastLogin'),
      sortable: false,
      filterable: false,
      render: (row: User) =>
        row?.meta?.lastLogin ? (
          <FormatDate
            date={row?.meta?.lastLogin}
            format={DateFormats.TIMEDATE}
          />
        ) : (
          <span>&#8212;</span>
        ),
    },
  ];

  if (withActions) {
    columns = [
      ...columns,
      {
        field: 'actions',
        header: t('generic.actions'),
        sortable: false,
        filterable: false,
        style: {
          width: '80px',
          textAlign: 'center',
        },
        render: (row: User) => {
          if (deletingUserId === row.id) {
            return (
              <FlexContainer>
                <ProgressSpinner style={{ width: '24px', height: '24px' }} />
              </FlexContainer>
            );
          }

          return (
            <DataTableActions
              disabled={menuItems(row).length < 1 || !!deletingUserId}
              menuItems={menuItems(row)}
            />
          );
        },
      },
    ];
  }

  if (additionalColumns && additionalColumns.length > 0) {
    columns = [...columns, ...additionalColumns];
  }

  // Set the preselected columns
  const [visibleColumns, setVisibleColumns] = useState<string[]>([]);

  const defaultVisibleColumns = columns
    .filter(
      (column) =>
        ![
          'id',
          'companyName',
          'country',
          'officeLocation',
          'department',
          'manager',
          'jobTitle',
          'mobilePhone',
          'lastLogin',
        ].includes(column.field),
    )
    .map((column) => column.field);
  const alwaysVisibleColumns = ['username', 'actions'];
  //

  useEffect(() => {
    if (!shouldRefetch) {
      return;
    }

    refetch();
  }, [shouldRefetch]);

  const menuItems = (user: User) => {
    const menu: MenuItem[] = [];

    if (can(Actions.UPDATE, Subjects.USERS)) {
      menu.push(
        ...[
          {
            items: [
              {
                label: t('generic.edit'),
                icon: 'pi pi-pencil',
                command: () => {
                  navigate(
                    RedirectPaths[RedirectPathsEnum.USERS_EDIT](user.id),
                  );
                },
              },
              {
                label: user.active
                  ? t('generic.deactivate')
                  : t('generic.activate'),
                icon: user.active ? 'pi pi-minus-circle' : 'pi pi-check',
                command: () => {
                  return setDialogData({
                    type: 'confirmation',
                    show: true,
                    header: `${
                      user.active
                        ? t('generic.deactivate')
                        : t('generic.activate')
                    } ${t('dialog.confirm')}`,
                    message: user.active
                      ? t('user.deactivate')
                      : t('user.activate'),
                    onAccept: async () =>
                      handleToggleItemsStatus(user.id, user.active),
                  });
                },
              },
            ],
          },
        ],
      );
    }

    if (can(Actions.DELETE, Subjects.USERS)) {
      menu.push(
        ...[
          {
            label: t('generic.delete'),
            icon: 'pi pi-times',
            command: () =>
              setDialogData({
                type: 'confirmation',
                show: true,
                header: t('dialog.delete'),
                message: t('dialog.delete.user'),
                onAccept: () => handleDeleteUser(user.id),
              }),
          },
        ],
      );
    }

    return menu;
  };

  const handleToggleItemsStatus = async (userId: string, active: boolean) => {
    try {
      await updateUser.update({
        userId: userId,
        updates: { active: !active },
      });
      await refetch();
      toast?.success(
        t('toast.success'),
        active ? t('user.deactivated') : t('user.activated'),
      );
    } catch (e) {
      handleAxiosError(e as Error | AxiosError, toast);
    }
  };

  const {
    lastMessage,
    setNotificationParam: setUserId,
    notificationParam: notificationUserId,
    isConnected,
  } = useNotifications(client.users.userNotifyUrl);

  useEffect(() => {
    if (!notificationUserId) return;
    handleSyncNotification(lastMessage);
  }, [JSON.stringify(lastMessage)]);

  const [deletingUserId, setDeletingUserId] = useState<string | null>(null);
  const handleDeleteUser = async (userId: string) => {
    setUserId(userId);

    // Wait for a notify connection to establish
    await new Promise((resolve) => {
      const intervalId = setInterval(() => {
        if (isConnected.current) {
          clearInterval(intervalId);
          resolve(true);
        }
      }, 100);
    });

    try {
      if (isConnected.current) {
        deleteUser.delete(userId);
        setDeletingUserId(userId);
      }
    } catch (e) {
      handleAxiosError(e as Error | AxiosError, toast);
      setDeletingUserId(null);
    }
  };

  const handleSyncNotification = async (messages: { data: any } | null) => {
    if (
      !messages?.data?.event ||
      !['user.delete.finished', 'user.delete.error'].includes(
        messages?.data?.event,
      )
    )
      return;

    if (messages.data.event === 'user.delete.error') {
      toast?.error(t('toast.error'), t('user.not.deleted'));
      setDeletingUserId(null);
      setUserId(undefined);
      await refetch();
    }

    if (messages.data.event === 'user.delete.finished') {
      toast?.success(t('toast.success'), t('user.deleted'));
      setDeletingUserId(null);
      setUserId(undefined);
      await refetch();
    }
  };

  const isKeycloakAuth = isKeycloakEnabled();
  const [isUploading, setIsUploading] = useState(false);

  const {
    lastMessage: lastMessageImportAccountLevel,
    setNotificationParam,
    notificationParam: notificationAccountId,
    isConnected: isConnectedImportAccountLevel,
  } = useNotifications(client.accounts.accountsNotifyUrl);

  const { upload: uploadUsersAccountLevel } = useAccountUsersImport();
  const { upload: uploadUsersBranchLevel } = useBranchUsersImport();

  const {
    lastMessage: lastMessageImportBranchLevel,
    setNotificationParam: setBranchId,
    notificationParam: notificationBranchId,
    isConnected: isConnectedImportBranchesLevel,
  } = useNotifications(client.branches.branchesNotifyUrl);

  useEffect(() => {
    if (!notificationAccountId) return;
    handleNotifyUsersUpload(lastMessageImportAccountLevel);
  }, [JSON.stringify(lastMessageImportAccountLevel)]);

  useEffect(() => {
    if (!notificationBranchId) return;
    handleNotifyBranchUsersUpload(lastMessageImportBranchLevel);
  }, [JSON.stringify(lastMessageImportBranchLevel)]);

  const parseImportWarnings = (messageErrors: any): MultipleToastType[] => {
    const errors = [] as MultipleToastType[];

    // Use setTimeout to delay error toast
    Object.values(messageErrors).forEach((row: any) => {
      const rowErrors: string[] = [];

      Object.values(row.errors).forEach((x: any) =>
        rowErrors.push(x.join(', ')),
      );

      errors.push({
        severity: 'info',
        summary: t('toast.info'),
        detail: t('users.import.failed.row', {
          row: row.rowNumber,
          errors: rowErrors.join(', '),
        }),
        life: 15000,
      });
    });

    return errors;
  };

  const parseImportNotification = (
    messages: any,
    type: 'account' | 'branch',
  ) => {
    const imported = messages?.data?.payload?.successful;
    const total = messages?.data?.payload?.total;
    const statuses: {
      [key: string]: [ToastType | undefined, string, string];
    } = {
      [`${type}.users.import.finished`]: [
        toast?.success,
        t('toast.success'),
        t('users.import.success', {
          total,
        }),
      ],
      [`${type}.users.import.failed`]: [
        toast?.error,
        t('toast.info'),
        t('users.import.fail'),
      ],
      [`${type}.users.import.warning`]: [
        imported > 0 ? toast?.success : toast?.error,
        imported > 0 ? t('toast.success') : t('toast.error'),
        imported > 0
          ? t('users.import.partially.imported', { imported, total })
          : t('users.import.none.imported', { total }),
      ],
    };

    const [callback, title, description] = statuses[messages.data.event];

    callback && callback(title, description);
  };

  const handleNotifyUsersUpload = (messages: any) => {
    if (
      !messages?.data?.event ||
      ![
        'account.users.import.finished',
        'account.users.import.failed',
        'account.users.import.warning',
      ].includes(messages?.data?.event)
    )
      return;

    parseImportNotification(messages, 'account');

    if (
      messages.data.event === 'account.users.import.warning' &&
      messages.data.payload?.errors
    ) {
      setTimeout(() => {
        toast?.multiple(parseImportWarnings(messages.data.payload.errors));
      }, 0);
    }

    setIsUploading(false);
    setNotificationParam(undefined);
    refetch();
  };

  const handleNotifyBranchUsersUpload = (messages: any) => {
    if (
      !messages?.data?.event ||
      ![
        'branch.users.import.finished',
        'branch.users.import.failed',
        'branch.users.import.warning',
      ].includes(messages?.data?.event)
    )
      return;

    parseImportNotification(messages, 'branch');

    if (
      messages.data.event === 'branch.users.import.warning' &&
      messages.data.payload?.errors
    ) {
      setTimeout(() => {
        toast?.multiple(parseImportWarnings(messages.data.payload.errors));
      }, 0);
    }

    setIsUploading(false);
    setBranchId(undefined);
    refetch();
  };

  const onUpload = async (e: FileUploadUploadEvent) => {
    setIsUploading(true);

    if (branchId) {
      setBranchId(branchId);
    } else {
      setNotificationParam(currentAccount?.id);
    }

    const response = JSON.parse(e.xhr.response);

    // Wait for a notify connection to establish
    await new Promise((resolve) => {
      const intervalId = setInterval(() => {
        if (isConnectedImportAccountLevel.current && !branchId) {
          clearInterval(intervalId);
          resolve(true);
        }
        if (isConnectedImportBranchesLevel.current && branchId) {
          clearInterval(intervalId);
          resolve(true);
        }
      }, 100);
    });

    try {
      if (isConnectedImportAccountLevel.current && !branchId) {
        await uploadUsersAccountLevel({
          accountId: currentAccount?.id as string,
          fileId: response.id,
          importKey: randomString(10),
        });
      }
      if (isConnectedImportBranchesLevel.current && branchId) {
        await uploadUsersBranchLevel({
          branchId,
          fileId: response.id,
          importKey: randomString(10),
        });
      }
    } catch (e) {
      setIsUploading(false);
      handleAxiosError(e as Error | AxiosError, toast);
    }
  };

  const itemActionMenu = useRef<any>(null);

  const toggleItemActionMenu = (event: any) => {
    if (!itemActionMenu.current) {
      return;
    }

    itemActionMenu.current.toggle(event);
  };

  const exportImportMenuItems: MenuItem[] = [
    {
      label: t('user.upload.csv'),
      icon: 'pi pi-user',
      template: () => (
        <StyledFileUpload
          mode="basic"
          name="file"
          url={client.files.getFileUploadUrl()}
          accept="text/csv,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          maxFileSize={5e6}
          onUpload={onUpload}
          auto
          chooseLabel={t('user.upload.csv')}
          disabled={isUploading}
          withCredentials
          onBeforeSend={(event) => {
            if (user && isKeycloakAuth) {
              event.xhr.setRequestHeader(
                'Authorization',
                `Bearer ${user?.access_token}`,
              );
            }
          }}
          onBeforeUpload={(event) => {
            event.formData.append('purpose', FilePurpose.IMPORT);
          }}
        />
      ),
    },
    {
      label: t('generic.export'),
      icon: 'pi pi-download',
      command: () => setShowExportModal(true),
    },
  ];

  const toolbar = (
    <DataTableToolbar>
      <FlexContainer
        justify="space-between"
        gap={8}
        align="flex-start"
        wrap="wrap"
      >
        <DataTableFilters
          filters={getFiltersFromColumns(columns)}
          onFilter={onFilter}
          defaultValues={defaultTableFilters}
          tableName={TableNamesEnum.USERS_LIST}
        />
        <FlexContainer width="auto" gap={8}>
          {withAddNew &&
            can(Actions.CREATE, Subjects.USERS) &&
            currentAccount?.active && (
              <AppButton
                label={t('button.createNew')}
                severity="secondary"
                onClick={() => {
                  navigate(RedirectPaths[RedirectPathsEnum.USERS_CREATE]());
                }}
              />
            )}
          {!!setShowBulkModal && (
            <AppButton
              onClick={() => setShowBulkModal(true)}
              severity="secondary"
              label={t('users.add')}
              className="w-fit-content "
            />
          )}
          {can(Actions.CREATE, Subjects.USERS) &&
            !currentAccount?.isSystem &&
            currentAccount?.active &&
            withImport && (
              <>
                <Menu
                  model={exportImportMenuItems}
                  popup
                  ref={itemActionMenu}
                  id="overlay_menu"
                  style={{ minWidth: '250px' }}
                />
                <AppButton
                  severity="info"
                  label={t('generic.import.export')}
                  aria-controls="overlay_menu"
                  onClick={toggleItemActionMenu}
                />
              </>
            )}
          {toolbarAdditional}
        </FlexContainer>
      </FlexContainer>
      <DataTableColumnsMultiselect
        columns={columns}
        tableName={TableNamesEnum.USERS_LIST}
        visibleColumns={visibleColumns}
        setVisibleColumns={setVisibleColumns}
        defaultVisibleColumns={defaultVisibleColumns}
        alwaysVisibleColumns={alwaysVisibleColumns}
      />
    </DataTableToolbar>
  );

  return (
    <>
      <DataTable
        data={users?.result}
        count={users?.count}
        isLoading={isLoading}
        toolbar={withToolbar ? toolbar : null}
        columns={columns}
        visibleColumns={visibleColumns}
        rows={take}
        skip={skip}
        onPage={onPage}
        onSort={onSort}
        sort={sort}
        onRowClick={
          can(Actions.UPDATE, Subjects.USERS) ? onRowClick : undefined
        }
      />
      <UsersExportModal
        defaultFilters={defaultFilters}
        filters={apiFilters}
        visible={showExportModal}
        onHide={() => setShowExportModal(false)}
      />
    </>
  );
};
