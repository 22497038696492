import { ScormStatusAttemptEnum } from '@/client/scorm';
import { LearnerCoursesList } from '@/pages/dashboards/learner/courses/LearnerCoursesList';
import { LearnerDashboardCoursesTabs } from '@/pages/dashboards/learner/courses/LearnerDashboardCoursesTabs';
import React from 'react';
import { LearnerLayout } from '../LearnerLayout';

type LearnerDashboardCoursesPageProps = {
  type: ScormStatusAttemptEnum.IN_PROGRESS | ScormStatusAttemptEnum.COMPLETED;
  atLeastOneCompleted?: boolean;
  isLoading?: boolean;
};

export const LearnerDashboardCoursesPage: React.FC<
  LearnerDashboardCoursesPageProps
> = ({ type, atLeastOneCompleted, isLoading }) => {
  return (
    <LearnerLayout isLoading={isLoading ?? false}>
      <LearnerDashboardCoursesTabs />
      <LearnerCoursesList
        type={type}
        atLeastOneCompleted={!!atLeastOneCompleted}
      />
    </LearnerLayout>
  );
};
