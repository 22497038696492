import { CourseEntityScheduleEnum } from '@/client/courses';
import { URL_REGEX } from '@/common/constants';
import { TranslationFunctionType } from '@/common/types';
import { array, object, string } from 'yup';

export const securityCultureSchema = (t: TranslationFunctionType) =>
  object().shape({
    url: string()
      .matches(URL_REGEX || '' || null, t('error.invalidUrl'))
      .required(t('error.requiredField')),

    target: string().oneOf([
      CourseEntityScheduleEnum.ACCOUNT,
      CourseEntityScheduleEnum.BRANCH,
      CourseEntityScheduleEnum.GROUP,
      CourseEntityScheduleEnum.USER,
    ]),

    branches: array().when(['target'], ([target], schema) =>
      target === CourseEntityScheduleEnum.BRANCH
        ? schema.min(1, t('error.requiredField'))
        : schema.notRequired(),
    ),

    groups: array().when(['target'], ([target], schema) =>
      target === CourseEntityScheduleEnum.GROUP
        ? schema.min(1, t('error.requiredField'))
        : schema.notRequired(),
    ),

    users: array().when(['target'], ([target], schema) =>
      target === CourseEntityScheduleEnum.USER
        ? schema.min(1, t('error.requiredField'))
        : schema.notRequired(),
    ),
  });
