import { client } from '@/client';
import { MessageResponseModel } from '@/client/models';
import {
  AddUserMutation,
  AddUserRequest,
  GenericForbiddenApiError,
  RemoveUserMutation,
  RemoveUserRequest,
} from '@/hooks/query';
import { useMutation } from 'react-query';

export const useAddUser = (
  type: 'branches' | 'groups',
): {
  add: (payload: AddUserRequest) => Promise<MessageResponseModel>;
} & AddUserMutation => {
  const { mutateAsync, ...rest } = useMutation<
    MessageResponseModel,
    GenericForbiddenApiError,
    AddUserRequest
  >('addUser', (payload: AddUserRequest) =>
    client[type].saveUsers(payload.typeId, {
      users: payload.userIds,
    }),
  );

  return { add: mutateAsync, ...(rest as any) };
};

export const useRemoveUser = (
  type: 'branches' | 'groups',
): {
  remove: ({
    typeId,
    userId,
  }: {
    typeId: string;
    userId: string;
  }) => Promise<MessageResponseModel>;
} & RemoveUserMutation => {
  const { mutateAsync, ...rest } = useMutation<
    MessageResponseModel,
    GenericForbiddenApiError,
    RemoveUserRequest
  >('deleteUser', ({ typeId, userId }: { typeId: string; userId: string }) =>
    client[type].deleteUser(typeId, userId),
  );

  return { remove: mutateAsync, ...(rest as any) };
};
