export enum Subjects {
  ALL = 'all',
  DEV_TOOLS = 'dev-tools',
  ACCOUNTS = 'accounts',
  RESELLERS = 'resellers',
  RESELLERS_DOMAIN_CONFIG = 'resellers-domain-config',
  USERS = 'users',
  ROLES = 'roles',
  GROUPS = 'groups',
  BRANCHES = 'branches',
  AD_SYNC = 'ad-sync',
  FILES = 'files',
  EFRONT_SYNC = 'efront-sync',
  EMAIL_TEMPLATES = 'email-templates',
  CAMPAIGNS = 'campaigns',
  CAMPAIGNS_MIGRATION = 'campaigns-migration',
  SENDING_PROFILES = 'sending-profiles',
  PAGES = 'pages',
  ACCOUNT_CAMPAIGNS_SUMMARY = 'account-campaigns-summary',
  CAMPAIGN_RESULTS = 'campaign-results',
  ADMIN_DASHBOARD = 'admin-dashboard',
  LEARNER_DASHBOARD = 'learner-dashboard',
  SYSTEM_SETTINGS = 'system-settings',
  ME = 'me',
  COURSES = 'courses',
  ATTEMPTS = 'attempts',
  COURSE_ACCOUNT_AVAILABILITY = 'course-account-availability',
  COURSE_ACCOUNT_SCHEDULES = 'course-account-schedules',
  COURSE_BRANCH_SCHEDULES = 'course-branch-schedules',
  COURSE_USER_SCHEDULES = 'course-user-schedules',
  ACCOUNT_REPORTS = 'account-reports',
  ACCOUNT_MIGRATION = 'account-migration',
  ACCOUNT_SSO = 'account-sso',
  USERS_MIGRATION = 'users-migration',
  HUBSPOT_ACCOUNT_LINK = 'hubspot-account-link',
  COURSE_GROUP_SCHEDULES = 'course-group-schedules',
  COURSE_FORCE_COMPLETE = 'course-force-complete',
  COURSE_PLANNER = 'course-planner',
  SECURITY_CULTURE = 'security-culture',
}
