import { equal, handleAxiosError, nested } from '@/api/helpers';
import { ForceCompleteFormValues, MaterialType } from '@/client/courses';
import { UserAwareness } from '@/client/reports';
import { ScormStatusAttemptEnum } from '@/client/scorm';
import { Actions, Subjects, User } from '@/client/users';
import {
  RedirectPaths,
  RedirectPathsEnum,
  TableNamesEnum,
} from '@/common/constants';
import { ForceCompleteModal } from '@/components/courses/modals';
import {
  DataTable,
  DataTableActions,
  DataTableColumnsMultiselect,
  DataTableColumnType,
  DataTableToolbar,
} from '@/components/tables/crud';
import { useForceComplete, useUsersAwarenessReport } from '@/hooks/query';
import { useAppSelector } from '@/hooks/store';
import { useTable } from '@/hooks/table.hook';
import { usePermission } from '@/hooks/usePermission';
import { useToast } from '@/hooks/useToast';
import { selectCurrentAccount } from '@/store/features/account';
import { FormatDate } from '@/ui/date';
import { FlexContainer } from '@/ui/styled-ui';
import {
  determineAttemptStatusColor,
  determineScoreColor,
} from '@/utils/helpers/ui.helper';
import { AxiosError } from 'axios';
import { t } from 'i18next';
import { DataTableRowClickEvent } from 'primereact/datatable';
import { MenuItem } from 'primereact/menuitem';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

type UserCoursesDatatableProps = {
  user: User;
};

export const UserCoursesDatatable: React.FC<UserCoursesDatatableProps> = ({
  user,
}) => {
  const account = useAppSelector(selectCurrentAccount);
  const navigate = useNavigate();
  const toast = useToast();
  const { can } = usePermission();

  const [showForceCompleteModal, setShowForceCompleteModal] = useState(false);
  const [selectedUser, setSelectedUser] = useState<UserAwareness>();

  const { skip, take, sort, apiFilters, onFilter, onSort, onPage } = useTable();

  const {
    isLoading,
    usersAwarenessReport: userCourses,
    refetch,
  } = useUsersAwarenessReport(account?.id || '', {
    take,
    skip,
    filters: [
      equal('id', user?.id),
      nested('course', [equal('type', MaterialType.COURSE)]),
      ...apiFilters,
    ],
  });

  const completeCourse = useForceComplete();
  const handleForceComplete = async (data: ForceCompleteFormValues) => {
    if (!selectedUser?.course?.id) return;
    try {
      await completeCourse.complete({
        courseId: selectedUser?.course.id,
        userId: selectedUser.id,
        score: data.score,
      });
      refetch();
      setShowForceCompleteModal(false);
      toast?.success(
        t('toast.success'),
        t('course.forceComplete.success', { course: selectedUser.course.name }),
      );
    } catch (e) {
      handleAxiosError(e as Error | AxiosError, toast);
    }
  };

  const menuItems = (user: UserAwareness) => {
    const menu: MenuItem[] = [];

    if (
      user.course.status !== ScormStatusAttemptEnum.COMPLETED &&
      can(Actions.UPDATE, Subjects.COURSE_FORCE_COMPLETE)
    ) {
      menu.push({
        label: t('course.forceComplete'),
        icon: 'pi pi-check',
        command: () => {
          setShowForceCompleteModal(true);
          setSelectedUser(user);
        },
      });
    }

    return menu;
  };

  const columns: DataTableColumnType[] = [
    {
      field: 'course',
      header: t('reports.courses.enrolled'),
      sortable: false,
      filterable: false,
      render: (row: UserAwareness) => row?.course?.name,
    },
    {
      field: 'date',
      header: t('generic.enrollDate'),
      sortable: false,
      filterable: false,
      render: (row: UserAwareness) =>
        row?.course.enrollmentDate ? (
          <FormatDate date={row?.course.enrollmentDate} />
        ) : (
          <span>&#8212;</span>
        ),
    },
    {
      field: 'completeDate',
      header: t('reports.courses.completionDate'),
      sortable: false,
      filterable: false,
      render: (row: UserAwareness) =>
        row?.course.completed ? (
          <FormatDate date={row?.course.completed} />
        ) : (
          <span>&#8212;</span>
        ),
    },
    {
      field: 'status',
      header: t('generic.status'),
      sortable: false,
      filterable: false,
      // filters: {
      //   field: FilterNamesEnum.AWARENESS_REPORTS_STATUS,
      //   type: FilterTypeEnum.MULTI_SELECT,
      //   options: getAwarenessReportStatusOptions(t),
      //   placeholder: t('generic.status.search'),
      // },
      render: (row: UserAwareness) =>
        determineAttemptStatusColor(row?.course.status, t),
    },
    {
      field: 'score',
      header: t('reports.score'),
      sortable: false,
      filterable: false,
      render: (row: UserAwareness) => (
        <FlexContainer justify="flex-start">
          {row?.course.score
            ? determineScoreColor(row?.course.status, row?.course.score)
            : determineScoreColor('enrolled', 0)}
        </FlexContainer>
      ),
    },
    {
      field: 'actions',
      header: t('generic.actions'),
      sortable: false,
      filterable: false,
      className: 'no-padding',
      style: {
        width: '80px',
        textAlign: 'center',
      },
      render: (row: UserAwareness) => {
        return (
          <FlexContainer direction="column" className="group-cell">
            <div className="group-row justify-content-center">
              <DataTableActions
                menuItems={menuItems(row)}
                disabled={menuItems(row).length < 1}
              />
            </div>
          </FlexContainer>
        );
      },
    },
  ];

  // Set the preselected columns
  const [visibleColumns, setVisibleColumns] = useState<string[]>([]);
  const defaultVisibleColumns = columns.map((column) => column.field);
  const alwaysVisibleColumns = ['course'];
  //

  const toolbar = (
    <DataTableToolbar>
      <FlexContainer
        justify="space-between"
        gap={8}
        align="flex-start"
        wrap="wrap"
      >
        {/* <DataTableFilters
          filters={getFiltersFromColumns(columns)}
          onFilter={onFilter}
          className="flex-initial"
        /> */}
        <></>
      </FlexContainer>
      <DataTableColumnsMultiselect
        columns={columns}
        tableName={TableNamesEnum.USER_COURSES_LIST}
        visibleColumns={visibleColumns}
        setVisibleColumns={setVisibleColumns}
        defaultVisibleColumns={defaultVisibleColumns}
        alwaysVisibleColumns={alwaysVisibleColumns}
      />
    </DataTableToolbar>
  );

  const onRowClick = (e: DataTableRowClickEvent) =>
    navigate(
      RedirectPaths[RedirectPathsEnum.COURSES_CONTENT](e.data?.course?.id),
    );

  return (
    <>
      <DataTable
        dataKey="dataId"
        data={userCourses?.result.map((x) => ({
          ...x,
          dataId: `${x.id}-${x.course?.id}`,
        }))}
        count={userCourses?.count as number}
        isLoading={isLoading}
        toolbar={toolbar}
        columns={columns}
        visibleColumns={visibleColumns}
        onPage={onPage}
        rows={take}
        skip={skip}
        onSort={onSort}
        sort={sort}
        onRowClick={onRowClick}
      />

      <ForceCompleteModal
        visible={showForceCompleteModal}
        onSubmit={handleForceComplete}
        onHide={() => setShowForceCompleteModal(false)}
        userName={user.name}
        courseName={selectedUser?.course.name}
      />
    </>
  );
};
