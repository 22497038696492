import { LanguagesEnum } from '@/api/enums';
import { handleAxiosError } from '@/api/helpers';
import { AppBreadCrumbTemplate } from '@/app/AppBreadCrumbTemplate';
import { client } from '@/client';
import {
  Account,
  AccountADFormValues,
  ADFormIndexValueMapping,
  ADMapping,
  displayAccountSyncStatus,
} from '@/client/accounts';
import { Actions, Subjects } from '@/client/users';
import {
  LoadingStateType,
  LoadingStatuses,
  RedirectPaths,
  RedirectPathsEnum,
} from '@/common/constants';
import { TranslationFunctionType } from '@/common/types';
import { AzureADForm } from '@/components/accounts/forms/AzureADForm';
import { useNotifications } from '@/hooks/notifications.hook';
import {
  useAccount,
  useADConfig,
  useForceADSync,
  useSaveADConfig,
} from '@/hooks/query';
import { useAppDispatch, useAppSelector } from '@/hooks/store';
import { usePermission } from '@/hooks/usePermission';
import { useToast } from '@/hooks/useToast';
import { AccountTabs } from '@/pages/accounts/AccountTabs';
import {
  selectCurrentAccount,
  setCurrentAccount,
} from '@/store/features/account';
import { DateFormats } from '@/system-settings/enums';
import { AppBreadCrumb } from '@/ui/breadcrumb';
import { FlexContainer } from '@/ui/styled-ui';
import { queryStateConverter } from '@/utils/helpers';
import { AxiosError } from 'axios';
import moment from 'moment';
import { MenuItem } from 'primereact/menuitem';
import { Message } from 'primereact/message';
import { ProgressSpinner } from 'primereact/progressspinner';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { v4 } from 'uuid';

const getPathItems = (
  account: Account | undefined,
  currentAccount: Account,
  can: (action: Actions, subject: Subjects) => boolean,
  t: TranslationFunctionType,
) => {
  const pathItems: MenuItem[] = [];

  pathItems.push({
    label: currentAccount?.name,
    url: !currentAccount.isSystem
      ? RedirectPaths[RedirectPathsEnum.ACCOUNT](currentAccount?.id)
      : RedirectPaths[RedirectPathsEnum.EDIT_ACCOUNT](currentAccount?.id),
    template: AppBreadCrumbTemplate,
  });

  if (
    currentAccount?.isSystem &&
    account &&
    can(Actions.READ, Subjects.ACCOUNTS)
  ) {
    pathItems.push(
      {
        label: t('accounts'),
        url: RedirectPaths[RedirectPathsEnum.ACCOUNTS](),
        template: AppBreadCrumbTemplate,
      },
      {
        label: account?.name,
        url: RedirectPaths[RedirectPathsEnum.EDIT_ACCOUNT](account?.id),
        className: 'active',
        template: AppBreadCrumbTemplate,
      },
    );
  }

  pathItems.push({
    label: t('account.azure'),
    url: RedirectPaths[RedirectPathsEnum.ACCOUNT_AZURE_AD](
      account ? account?.id : currentAccount?.id,
    ),
    template: AppBreadCrumbTemplate,
  });

  return pathItems;
};

export const AccountAzureADPage = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const toast = useToast();
  const { account, isLoading: isAccountLoading } = useAccount({
    accountId: id,
  });
  const { accountAd, refetch, isLoading } = useADConfig({
    accountId: id,
  });
  const currentAccount = useAppSelector(selectCurrentAccount);
  const { can } = usePermission();
  const dispatch = useAppDispatch();

  const forceADSync = useForceADSync();
  const [syncState, setSyncState] = useState<LoadingStateType>(
    LoadingStatuses.IDLE,
  );

  const {
    lastMessage,
    notificationParam: accountId,
    setNotificationParam: setAccountId,
  } = useNotifications(client.adSync.adSyncNotifyUrl);

  useEffect(() => {
    if (!accountId) return;
    handleSyncNotification(lastMessage);
  }, [JSON.stringify(lastMessage)]);

  useEffect(() => {
    if (!account || currentAccount?.id !== account.id) return;

    updateCurrentAccount(account);
  }, [account, currentAccount]);

  const saveADConfig = useSaveADConfig();

  const updateCurrentAccount = (account: Account) => {
    if (JSON.stringify(account) !== JSON.stringify(currentAccount)) {
      dispatch(setCurrentAccount(account));
    }
  };

  const [initialValues, setInitialValues] = useState<AccountADFormValues>({
    active: false,
    clientId: '',
    clientSecret: '',
    tenantId: '',
    mapping: {
      [v4()]: {
        groupId: '',
        account: account,
        branches: [],
        groups: [],
        language: LanguagesEnum.EN,
      },
    },
    isMigratedOutsideOfEFront: false,
    hasAd: false,
  });

  const transformMappingValues = (
    data: ADMapping[],
  ): ADFormIndexValueMapping => {
    const mappedObject: ADFormIndexValueMapping = {};

    data.map((value) => {
      mappedObject[v4()] = {
        ...value,
        ...(!value.branches.length && currentAccount
          ? { account: account }
          : {}),
      };
    });
    return mappedObject;
  };

  useEffect(() => {
    if (!accountAd || !account) return;

    setInitialValues({
      active: accountAd.active || false,
      clientId: accountAd.config?.clientId || '',
      clientSecret: accountAd.config?.clientSecret || '',
      tenantId: accountAd.config?.tenantId || '',
      mapping: transformMappingValues(accountAd.mapping),
      isMigratedOutsideOfEFront: !!account.meta?.isMigratedOutsideOfEFront,
      hasAd:
        !!accountAd.config?.clientId &&
        !!accountAd.config?.clientSecret &&
        !!accountAd.config?.tenantId,
    });
  }, [accountAd, account]);

  const handleForceADSync = async (accountId: string) => {
    if (!accountId) return;

    try {
      setSyncState(LoadingStatuses.LOADING);

      setAccountId(accountId);
      await forceADSync.sync(accountId);

      toast?.info(t('toast.info'), t('job.created'));
    } catch (e) {
      setSyncState(LoadingStatuses.IDLE);
      setAccountId(undefined);
      handleAxiosError(e as Error | AxiosError, toast);
    }
  };

  const handleSyncNotification = (
    messages: { data: any; lastEventId: string } | null,
  ) => {
    if (
      !messages?.data?.event ||
      messages.data.event === 'active-directory.account.sync.started' ||
      !['active-directory.account.synced', 'ping'].includes(
        messages?.data?.event,
      ) ||
      (messages?.data?.event === 'ping' && Number(messages?.lastEventId) <= 10)
    ) {
      return;
    }

    if (messages.data.event === 'ping') {
      toast?.info(t('sync.status.pending'), t('sync.inProgress'));
      return;
    }

    if (messages?.data?.error) {
      toast?.error(
        t('toast.error'),
        t('sync.data.error', { error: messages?.data?.error }),
      );
    }

    if (
      messages.data.event === 'active-directory.account.synced' &&
      !messages?.data?.error
    ) {
      toast?.success(t('toast.success'), t('account.azure.synced'));
    }

    setSyncState(LoadingStatuses.IDLE);
    setAccountId(undefined);
    refetch();
  };

  const handleUpdateAzureAD = async (data: AccountADFormValues) => {
    await saveADConfig.create({
      updates: {
        active: data.active,
        config: {
          clientId: data.clientId,
          clientSecret: data.clientSecret,
          tenantId: data.tenantId,
        },
        mapping: Object.values(data.mapping).map((value) => ({
          groupId: value.groupId,
          branches: value.account
            ? []
            : value.branches.map((branch) => ({ id: branch.id })),
          groups: value.groups.map((group) => ({ id: group.id })),
          language: value.language,
        })),
      },
      accountId: id as string,
    });
  };

  const handleSubmit = async (data: AccountADFormValues) => {
    try {
      if (!id || !data) return;

      if (data.active) {
        setSyncState(LoadingStatuses.LOADING);
        setAccountId(id);
      }

      await handleUpdateAzureAD(data);

      toast?.success(t('toast.success'), t('account.azure.updated'));
      refetch();
    } catch (e) {
      setSyncState(LoadingStatuses.IDLE);
      setAccountId(undefined);
      handleAxiosError(e as Error | AxiosError, toast);
    }
  };

  return (
    <>
      {isLoading || isAccountLoading || !account || !id ? (
        <FlexContainer direction="column" className="mt-5">
          <ProgressSpinner />
        </FlexContainer>
      ) : (
        <>
          {currentAccount && (
            <AppBreadCrumb
              model={getPathItems(account, currentAccount, can, t)}
            />
          )}
          {account && (
            <>
              <h1 className="mt-4">{account.name}</h1>
              <AccountTabs account={account} />
            </>
          )}

          {accountAd?.meta?.lastSync && accountAd?.meta?.status && (
            <Message
              severity="info"
              text={`${t('sync.lastSync')} ${moment(
                accountAd?.meta?.lastSync,
              ).format(DateFormats.TIMEDATE)}, ${displayAccountSyncStatus(
                accountAd?.meta?.status,
                t,
              )}`}
            />
          )}

          {accountAd?.meta?.lastSyncErrorLog && (
            <Message
              className="mt-2"
              severity="warn"
              text={accountAd?.meta?.lastSyncErrorLog}
            />
          )}

          <h3 className="mt-4 mb-4">{t('azure.config')}</h3>

          <AzureADForm
            initialValues={initialValues}
            onSubmit={handleSubmit}
            onSync={() => handleForceADSync(id)}
            account={account}
            state={queryStateConverter(saveADConfig)}
            syncState={syncState}
          />
        </>
      )}
    </>
  );
};
