import { FiltersType, ListingRequest } from '@/api/types';
import { client } from '@/client';
import {
  Account,
  AccountSendTestEmailRequest,
  ImportAccountUsersRequest,
  ImportSSOConfigRequest,
  ListAccountsResponse,
  ListDuplicatedUsersResponse,
  MigrateDuplicatedUsersRequest,
  SaveAccountRequest,
  UpdateAccountRequest,
  UpdateSSOConfigRequest,
} from '@/client/accounts';
import { MessageResponseModel } from '@/client/models';
import {
  ListUsersAwarenessReportResponse,
  ListUsersSummaryReportResponse,
} from '@/client/reports';
import {
  AccountSendTestEmailMutation,
  DeleteAccountMutation,
  ForceAccountSyncMutation,
  GenericForbiddenApiError,
  MigrateDuplicatedUsersMutation,
  SaveAccountMutation,
} from '@/hooks/query/types';
import { partialRequests } from '@/utils/helpers';
import {
  useMutation,
  useQuery,
  UseQueryOptions,
  UseQueryResult,
} from 'react-query';

const queryKey = 'accounts';
const singleQueryKey = 'account';
const migratedQueryKey = 'migrated-accounts';

export const useAccounts = (
  params: UseQueryOptions & {
    token?: string;
    take?: number;
    skip?: number;
    sort?: string[];
    filters?: FiltersType;
  } = {},
): UseQueryResult<ListAccountsResponse> & {
  accounts: ListAccountsResponse | undefined;
} => {
  const {
    take = 5,
    skip = 0,
    sort,
    filters,
    retry = false,
    retryDelay = 500,
    staleTime = 0,
    cacheTime = 0,
    ...restOptions
  } = params;
  const { data, ...rest } = useQuery(
    [queryKey, take, skip, JSON.stringify(sort), JSON.stringify(filters)],
    () => client.accounts.getAccounts(params),
    {
      retry,
      retryDelay,
      staleTime,
      cacheTime,
      ...(restOptions as any),
    },
  );

  return {
    accounts: data,
    ...(rest as UseQueryResult<ListAccountsResponse>),
  };
};

export const useAccountsPartialRequest = (
  params: UseQueryOptions & {
    sort?: string[];
    filters?: FiltersType;
  },
): UseQueryResult<Account[]> & {
  accounts: Account[] | undefined;
} => {
  const {
    sort,
    filters,
    retry = false,
    retryDelay = 500,
    staleTime = 0,
    cacheTime = 0,
    ...restOptions
  } = params;
  const accountsRequest = async (params: ListingRequest) =>
    await client.accounts.getAccounts({ ...params, sort });
  const { data, ...rest } = useQuery(
    [`${queryKey}-partial`],
    () => partialRequests(filters || [], accountsRequest),
    {
      retry,
      retryDelay,
      staleTime,
      cacheTime,
      ...(restOptions as any),
    },
  );

  return {
    accounts: data,
    ...(rest as UseQueryResult<Account[]>),
  };
};

export const useAccount = (
  params: UseQueryOptions & {
    accountId?: string;
  },
): UseQueryResult<Account> & {
  account: Account | undefined;
} => {
  const {
    accountId,
    retry = false,
    retryDelay = 500,
    staleTime = 0,
    cacheTime = 0,
    ...restOptions
  } = params;

  const { data, ...rest } = useQuery(
    [singleQueryKey, accountId],
    () => (accountId ? client.accounts.getAccount(accountId) : undefined),
    {
      retry,
      retryDelay,
      staleTime,
      cacheTime,
      ...(restOptions as any),
    },
  );

  return {
    account: data,
    ...(rest as UseQueryResult<Account>),
  };
};

export const useSaveAccount = (): {
  create: (payload: SaveAccountRequest) => Promise<Account>;
} & SaveAccountMutation => {
  const { mutateAsync, ...rest } = useMutation<
    Account,
    GenericForbiddenApiError,
    SaveAccountRequest
  >('addAccount', (payload: SaveAccountRequest) =>
    client.accounts.saveAccount(payload),
  );

  return { create: mutateAsync, ...(rest as any) };
};

export const useUpdateAccounts = (): {
  update: (payload: UpdateAccountRequest) => Promise<Account>;
} & SaveAccountMutation => {
  const { mutateAsync, ...rest } = useMutation<
    Account,
    GenericForbiddenApiError,
    UpdateAccountRequest
  >('updateAccount', (payload: UpdateAccountRequest) =>
    client.accounts.updateAccount(payload),
  );

  return { update: mutateAsync, ...(rest as any) };
};

export const useDeleteAccounts = (): {
  delete: (accountId: string) => Promise<MessageResponseModel>;
} & DeleteAccountMutation => {
  const { mutateAsync, ...rest } = useMutation<
    MessageResponseModel,
    GenericForbiddenApiError,
    string
  >('deleteAccount', (accountId: string) =>
    client.accounts.deleteAccount(accountId),
  );

  return { delete: mutateAsync, ...(rest as any) };
};

export const useForceAccountSync = (): {
  sync: (accountId: string) => Promise<MessageResponseModel>;
} & ForceAccountSyncMutation => {
  const { mutateAsync, ...rest } = useMutation<
    MessageResponseModel,
    GenericForbiddenApiError,
    string
  >('forceAccountSync', (accountId: string) =>
    client.accounts.forceAccountSync(accountId),
  );

  return { sync: mutateAsync, ...(rest as any) };
};

export const useDuplicatedUsers = (
  params: UseQueryOptions & {
    take?: number;
    skip?: number;
    sort?: string[];
    filters?: FiltersType;
  },
  accountId?: string,
): UseQueryResult<ListDuplicatedUsersResponse> & {
  duplicatedUsers: ListDuplicatedUsersResponse | undefined;
} => {
  const {
    take = 10,
    skip = 0,
    sort,
    filters,
    retry = false,
    retryDelay = 500,
    staleTime = 0,
    cacheTime = 0,
    ...restOptions
  } = params;

  const { data, ...rest } = useQuery(
    [
      migratedQueryKey,
      take,
      skip,
      JSON.stringify(sort),
      JSON.stringify(filters),
    ],
    () => client.accounts.getDuplicatedUsers(params, accountId),
    {
      retry,
      retryDelay,
      staleTime,
      cacheTime,
      ...(restOptions as any),
    },
  );

  return {
    duplicatedUsers: data,
    ...(rest as UseQueryResult<ListDuplicatedUsersResponse>),
  };
};

export const useMigrateDuplicatedUsers = (): {
  migrate: (
    payload: MigrateDuplicatedUsersRequest,
  ) => Promise<ListDuplicatedUsersResponse>;
} & MigrateDuplicatedUsersMutation => {
  const { mutateAsync, ...rest } = useMutation<
    ListDuplicatedUsersResponse,
    GenericForbiddenApiError,
    MigrateDuplicatedUsersRequest
  >('migrateDuplicatedUsers', (payload: MigrateDuplicatedUsersRequest) =>
    client.accountMigrationService.migrateDuplicatedUsers(payload),
  );

  return { migrate: mutateAsync, ...(rest as any) };
};

export const useSendTestEmailAccount = (): {
  send: (payload: AccountSendTestEmailRequest) => Promise<MessageResponseModel>;
} & AccountSendTestEmailMutation => {
  const { mutateAsync, ...rest } = useMutation<
    MessageResponseModel,
    GenericForbiddenApiError,
    AccountSendTestEmailRequest
  >('sendTestEmailAccount', (payload: AccountSendTestEmailRequest) =>
    client.accounts.sendTestEmail(payload),
  );

  return { send: mutateAsync, ...(rest as any) };
};

export const useUsersSummaryReport = (
  accountId: string,
  params: UseQueryOptions & {
    token?: string;
    take?: number;
    skip?: number;
    sort?: string[];
    filters?: FiltersType;
  } = {},
  onSuccess?: () => void,
): UseQueryResult<ListUsersSummaryReportResponse> & {
  usersSummaryReport: ListUsersSummaryReportResponse | undefined;
} => {
  const {
    take = 5,
    skip = 0,
    sort,
    filters,
    retry = false,
    retryDelay = 500,
    staleTime = 0,
    cacheTime = 0,
    ...restOptions
  } = params;
  const { data, ...rest } = useQuery(
    [queryKey, take, skip, JSON.stringify(sort), JSON.stringify(filters)],
    () => client.reports.getUsersSummaryReport(accountId, params),
    {
      retry,
      retryDelay,
      staleTime,
      cacheTime,
      onSuccess,
      ...(restOptions as any),
    },
  );

  return {
    usersSummaryReport: data,
    ...(rest as UseQueryResult<ListUsersSummaryReportResponse>),
  };
};

export const useUsersAwarenessReport = (
  accountId: string,
  params: UseQueryOptions & {
    token?: string;
    take?: number;
    skip?: number;
    sort?: string[];
    filters?: FiltersType;
  } = {},
  onSuccess?: () => void,
): UseQueryResult<ListUsersAwarenessReportResponse> & {
  usersAwarenessReport: ListUsersAwarenessReportResponse | undefined;
} => {
  const {
    take = 5,
    skip = 0,
    sort,
    filters,
    retry = false,
    retryDelay = 500,
    staleTime = 0,
    cacheTime = 0,
    ...restOptions
  } = params;
  const { data, ...rest } = useQuery(
    [queryKey, take, skip, JSON.stringify(sort), JSON.stringify(filters)],
    () => client.reports.getUsersAwarenessReport(accountId, params),
    {
      retry,
      retryDelay,
      staleTime,
      cacheTime,
      onSuccess,
      ...(restOptions as any),
    },
  );

  return {
    usersAwarenessReport: data,
    ...(rest as UseQueryResult<ListUsersAwarenessReportResponse>),
  };
};

export const useAccountUsersImport = (): {
  upload: (payload: ImportAccountUsersRequest) => Promise<Account>;
} & SaveAccountMutation => {
  const { mutateAsync, ...rest } = useMutation<
    MessageResponseModel,
    GenericForbiddenApiError,
    ImportAccountUsersRequest
  >('importUsers', (payload: ImportAccountUsersRequest) =>
    client.accounts.importUsers(
      payload.accountId,
      payload.fileId,
      payload.importKey,
    ),
  );

  return { upload: mutateAsync, ...(rest as any) };
};

export const useImportSSOConfig = (): {
  create: (payload: ImportSSOConfigRequest) => Promise<MessageResponseModel>;
} & SaveAccountMutation => {
  const { mutateAsync, ...rest } = useMutation<
    MessageResponseModel,
    GenericForbiddenApiError,
    ImportSSOConfigRequest
  >('importSSOConfig', (payload: ImportSSOConfigRequest) =>
    client.accounts.importSSOConfig(payload),
  );

  return { create: mutateAsync, ...(rest as any) };
};

export const useSSOConfig = (): {
  update: (payload: UpdateSSOConfigRequest) => Promise<MessageResponseModel>;
} & SaveAccountMutation => {
  const { mutateAsync, ...rest } = useMutation<
    MessageResponseModel,
    GenericForbiddenApiError,
    UpdateSSOConfigRequest
  >('ssoConfig', (payload: UpdateSSOConfigRequest) =>
    client.accounts.ssoConfig(payload),
  );

  return { update: mutateAsync, ...(rest as any) };
};
