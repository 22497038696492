import React from 'react';
import { NavigateOptions, useNavigate } from 'react-router-dom';
import styled from 'styled-components';

const StyledLink = styled.div`
  display: flex;
  align-items: center;
  text-decoration: none;
  color: var(--black-main);
  cursor: pointer;

  > i {
    border-radius: 50%;
    font-size: var(--xsmall-font-size);
    padding: 9px;
    border: 1px solid var(--purple-middle);
  }

  > span {
    line-height: var(--small-line-height);
    margin-left: var(--small-padding);
  }

  &:hover {
    > i {
      color: var(--white-main);
      background-color: var(--red-main);
      border-color: var(--red-main);
    }
  }

  > button {
    height: 32px;
    width: 32px;
  }
`;

type BackButtonProps = {
  to: string;
  text?: string;
  className?: string;
  options?: NavigateOptions;
};

export const BackButton: React.FC<BackButtonProps> = ({
  to,
  text,
  className,
  options,
}) => {
  const navigate = useNavigate();
  return (
    <StyledLink onClick={() => navigate(to, options)} className={className}>
      <i className="pi pi-arrow-left" />
      {text && <span>{text}</span>}
    </StyledLink>
  );
};
