import { GroupCondition } from '@/api/enums';
import { equal, group } from '@/api/helpers';
import { MaterialType } from '@/client/courses';
import { ScormStatusAttemptEnum } from '@/client/scorm';
import { useScormCourses } from '@/hooks/query';
import { LoadingPage } from '@/pages/LoadingPage';
import { FlexContainer } from '@/ui/styled-ui';
import React, { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { LearnerDashboardTabs } from './LearnerDashboardTabs';

const StyledPageContainer = styled(FlexContainer)`
  @media screen and (max-width: 812px) {
    padding-top: 0;
  }
  position: relative;
  padding-top: 76px;
  min-height: 100%;
`;

type LearnerLayoutProps = {
  children: ReactNode;
  isLoading: boolean;
};

export const LearnerLayout: React.FC<LearnerLayoutProps> = ({
  children,
  isLoading,
}) => {
  const { t } = useTranslation();
  const { isLoading: hasTemplatesLoading, scormCourses: templates } =
    useScormCourses({
      take: 1,
      skip: 0,
      type: ScormStatusAttemptEnum.IN_PROGRESS,
      filters: [
        group(GroupCondition.OR, [
          equal('type', MaterialType.DOCUMENT),
          equal('type', MaterialType.TEMPLATE),
        ]),
      ],
    });

  return (
    <>
      {hasTemplatesLoading || isLoading ? (
        <LoadingPage message={t('generic.loading')} />
      ) : (
        <>
          <LearnerDashboardTabs hasMaterials={!!templates?.count} />
          <StyledPageContainer
            direction="column"
            justify="flex-start"
            align="flex-start"
            className="w-full"
          >
            {children}
          </StyledPageContainer>
        </>
      )}
    </>
  );
};
