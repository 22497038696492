import { Actions, Subjects } from '@/client/users';
import {
  DashboardType,
  LAST_SELECTED_DASHBOARD,
  RedirectPaths,
  RedirectPathsEnum,
} from '@/common/constants';
import { REDIRECT_URL_KEY } from '@/common/constants/user';
import { usePermission } from '@/hooks/usePermission';
import {
  isOutsideOfEfront,
  isPhishingEnabled,
  isUserManagementEnabled,
} from '@/utils/helpers';
import React, { useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';

export const Redirect = () => {
  const navigate = useNavigate();
  const { can, cannot } = usePermission();

  const redirectUrl = sessionStorage.getItem(REDIRECT_URL_KEY);

  useEffect(() => {
    if (!redirectUrl) {
      return;
    }

    sessionStorage.removeItem(REDIRECT_URL_KEY);
  }, [redirectUrl]);

  useEffect(() => {
    // Set the initial dashboard style
    if (isOutsideOfEfront()) {
      if (
        (!localStorage.getItem(LAST_SELECTED_DASHBOARD) &&
          can(Actions.READ, Subjects.ADMIN_DASHBOARD)) ||
        (localStorage.getItem(LAST_SELECTED_DASHBOARD) ===
          DashboardType.ADMIN &&
          can(Actions.READ, Subjects.ADMIN_DASHBOARD))
      ) {
        navigate(
          redirectUrl && redirectUrl.replace('/', '') !== ''
            ? redirectUrl
            : RedirectPaths[RedirectPathsEnum.ADMIN_DASHBOARD](),
        );

        return;
      }

      const canAccess = redirectUrl?.match(/dashboard\/learner|courses/);

      navigate(
        redirectUrl && redirectUrl.replace('/', '') !== '' && canAccess
          ? redirectUrl
          : RedirectPaths[RedirectPathsEnum.LEARNER_DASHBOARD_ACTIVE](),
      );

      return;
    }

    if (
      can(Actions.READ, Subjects.USERS) &&
      cannot(Actions.SELF, Subjects.USERS) &&
      isUserManagementEnabled()
    ) {
      navigate(RedirectPaths[RedirectPathsEnum.USERS]());

      return;
    }

    if (
      can(Actions.READ, Subjects.CAMPAIGNS) &&
      cannot(Actions.SELF, Subjects.CAMPAIGNS) &&
      isPhishingEnabled()
    ) {
      navigate(RedirectPaths[RedirectPathsEnum.CAMPAIGNS]());

      return;
    }

    if (
      can(Actions.READ, Subjects.ACCOUNTS) &&
      cannot(Actions.SELF, Subjects.ACCOUNTS)
    ) {
      navigate(RedirectPaths[RedirectPathsEnum.ACCOUNTS]());

      return;
    }
  }, []);

  return <Outlet />;
};
