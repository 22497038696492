import { equal, handleAxiosError } from '@/api/helpers';
import { AppBreadCrumbTemplate } from '@/app/AppBreadCrumbTemplate';
import { Account } from '@/client/accounts';
import { RedirectPaths, RedirectPathsEnum } from '@/common/constants';
import { BranchForm } from '@/components/branches/forms';
import { useSaveBranch } from '@/hooks/query';
import { useAppSelector } from '@/hooks/store';
import { useToast } from '@/hooks/useToast';
import { selectCurrentAccount } from '@/store/features/account';
import { AppBreadCrumb } from '@/ui/breadcrumb';
import { FlexContainer } from '@/ui/styled-ui';
import { queryStateConverter } from '@/utils/helpers';
import { AxiosError } from 'axios';
import { FormikValues } from 'formik';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

export const CreateBranchPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const toast = useToast();

  const saveBranch = useSaveBranch();
  const account = useAppSelector(selectCurrentAccount);

  const filters = [equal('account', (account as Account)?.id)];

  const handleBranchAction = async (data: FormikValues) => {
    await saveBranch.create({
      name: data.name,
      active: data.active,
      eFrontSync: data.eFrontSync ?? false,
      account: (account as Account)?.id,
      parent: data?.parent ? data?.parent.id : null,
    });

    navigate(RedirectPaths[RedirectPathsEnum.BRANCHES]());
  };

  const handleSubmit = async (data: FormikValues) => {
    try {
      await handleBranchAction(data);

      toast?.success(t('toast.success'), t('branch.created'));
    } catch (e) {
      handleAxiosError(e as Error | AxiosError, toast);
    }
  };

  const pathItems = [
    {
      label: (account as Account)?.name,
      url: RedirectPaths[RedirectPathsEnum.EDIT_ACCOUNT](
        (account as Account)?.id,
      ),
      template: AppBreadCrumbTemplate,
    },
    {
      label: t('branches'),
      url: RedirectPaths[RedirectPathsEnum.BRANCHES](),
      template: AppBreadCrumbTemplate,
    },
    {
      label: t('button.create'),
      url: RedirectPaths[RedirectPathsEnum.BRANCHES_CREATE](),
      template: AppBreadCrumbTemplate,
    },
  ];

  return (
    <>
      <AppBreadCrumb model={pathItems} />
      <h1>{t('branch.newBranch')}</h1>
      <FlexContainer justify="flex-start">
        <BranchForm
          onSubmit={handleSubmit}
          additionalFilters={filters}
          state={queryStateConverter(saveBranch)}
        />
      </FlexContainer>
    </>
  );
};
