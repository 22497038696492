import { CoursePlanner, CourseScheduleTargets } from '@/client/planner/types';
import { Actions, Subjects } from '@/client/users';
import { CreateUpdatePlannerModal } from '@/components/planner/modals/CreateUpdatePlannerModal';
import { PlannerTargets } from '@/components/planner/PlannerTargets';
import { PlannerTimeline } from '@/components/planner/PlannerTimeline';
import { usePlanners } from '@/hooks/query/planner.hooks';
import { useAppSelector } from '@/hooks/store';
import { useTable } from '@/hooks/table.hook';
import { usePermission } from '@/hooks/usePermission';
import { selectCurrentAccount } from '@/store/features/account';
import { AppButton } from '@/ui/buttons';
import { FlexContainer } from '@/ui/styled-ui';
import { ExistingUsersIconPath, NewUsersIconPath } from '@/utils/helpers';
import { t } from 'i18next';
import { Paginator, PaginatorPageChangeEvent } from 'primereact/paginator';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Tooltip } from 'primereact/tooltip';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { AccountEnrollmentActionHeader } from './AccountEnrollmentActionHeader';
import { EnrollmentTabs } from './EnrollmentTabs';

const StyledDiv = styled.div`
  > div {
    padding: var(--default-padding);
    padding-bottom: var(--medium-padding);
    border-bottom: 1px solid var(--gray-dark);
    background: var(--beige-main);
    margin: 0 calc(var(--default-padding) * -1);
    gap: var(--small-padding);
    display: flex;
    flex-direction: column;

    &:first-child {
      border-top: 1px solid var(--gray-dark);
    }
  }
`;

const StyledContainer = styled(FlexContainer)`
  padding-bottom: var(--small-padding);

  label {
    color: var(--gray-darker);
    font-size: var(--small-font-size);
  }

  span {
    color: var(--black-main);
    font-size: var(--small-font-size);
    position: relative;

    &:before {
      position: absolute;
      color: var(--gray-darker);
      content: '•';
      left: -10px;
    }
  }
`;

const StyledAppButton = styled(AppButton)`
  &.p-button.p-component {
    height: 28px;
    padding: 2px var(--small-padding);
    background-color: var(--white-main);
    border-color: transparent;

    span:before {
      display: none;
    }

    &:hover {
      border-color: var(--red-pale) !important;
      background-color: var(--red-pale) !important;
    }
  }
`;

const StyledFloatingMenuContainer = styled(FlexContainer)`
  position: fixed;
  right: 38px;
  bottom: 16px;
  width: auto;
  padding: var(--xsmall-padding) var(--medium-padding);
  border-radius: 100px;
  background: var(--white-main);
  box-shadow: 0px 4px 32px 0px rgba(0, 0, 0, 0.25);
  z-index: 9;

  svg {
    cursor: pointer;

    &.inactive-item {
      opacity: 0.3;
    }
  }
`;

const StyledSeparator = styled.div`
  display: flex;
  width: 1px;
  height: 20px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  background: var(--gray-dark);
`;

export const AccountTrainingPlannerPage: React.FC = () => {
  const account = useAppSelector(selectCurrentAccount);
  const [isPlannerModalVisible, setIsPlannerModalVisible] = useState(false);
  const [isNewUsersVisible, setIsNewUsersVisible] = useState(true);
  const [isExistingUsersVisible, setIsExistingUsersVisible] = useState(true);
  const [planForEdit, setPlanForEdit] = useState<CoursePlanner>();
  const [visiblePlans, setVisiblePlans] = useState<CoursePlanner[]>();
  const { skip, take, setSkip } = useTable();

  const { planners, isLoading, refetch } = usePlanners(
    {
      skip,
      take,
    },
    account?.id,
  );
  const { can } = usePermission();

  useEffect(() => {
    setVisiblePlans(planners?.result || []);
  }, [planners]);

  const hasExcludes = (excludes: CourseScheduleTargets) => {
    return (
      (Array.isArray(excludes.branches) && excludes.branches.length > 0) ||
      (Array.isArray(excludes.groups) && excludes.groups.length > 0) ||
      (Array.isArray(excludes.users) && excludes.users.length > 0)
    );
  };

  return (
    <>
      {isLoading && (
        <FlexContainer direction="column" height="100%" className="mt-5">
          <ProgressSpinner />
          <h3>{t('course.content.loading')}</h3>
        </FlexContainer>
      )}

      {account && !isLoading && (
        <div className="flex flex-column h-full">
          <AccountEnrollmentActionHeader
            account={account}
            onTriggerRefetch={refetch}
            isTrainingPlanner
          />
          <EnrollmentTabs />

          {visiblePlans?.length ? (
            <StyledDiv>
              {visiblePlans?.map((item: CoursePlanner, index: number) => (
                <div key={index}>
                  <FlexContainer gap={8} justify="space-between">
                    <Tooltip
                      position="top"
                      target={`.${CSS.escape(item.id)}`}
                      content={t('planned.selection.parentBranches.tooltip')}
                    />
                    <StyledContainer wrap="wrap" gap={16} justify="flex-start">
                      <label>{t('courses.enrollTo')}</label>
                      <PlannerTargets
                        targets={item.targets}
                        isInclude
                        className={item.id}
                      />

                      {!!hasExcludes(item.excludes) && (
                        <>
                          <label>{t('courses.excluding')}</label>
                          <PlannerTargets targets={item.excludes} />
                        </>
                      )}

                      <StyledSeparator />

                      {can(Actions.UPDATE, Subjects.COURSE_PLANNER) && (
                        <StyledAppButton
                          label={t('generic.edit')}
                          onClick={() => {
                            setPlanForEdit(item);
                            setIsPlannerModalVisible(true);
                          }}
                        />
                      )}
                    </StyledContainer>
                  </FlexContainer>

                  {isExistingUsersVisible && (
                    <PlannerTimeline
                      items={item.staticCourseSchedules}
                      label={t('schedule.existingUsers')}
                      isPlaceholder={!item.staticCourseSchedules?.length}
                    />
                  )}

                  {isNewUsersVisible && (
                    <PlannerTimeline
                      items={item.dynamicCourseSchedules}
                      label={t('schedule.newUsers')}
                      isNewUsers
                      isPlaceholder={!item.dynamicCourseSchedules?.length}
                    />
                  )}
                </div>
              ))}
            </StyledDiv>
          ) : (
            <FlexContainer direction="column" flex="1" height="100%">
              <h3>{t('planner.noItems')}</h3>
            </FlexContainer>
          )}

          {!!planners?.count && planners?.count > 10 && (
            <Paginator
              className="justify-content-center pb-3 mr-auto"
              rows={take}
              first={skip}
              totalRecords={planners?.count}
              pageLinkSize={3}
              onPageChange={(event: PaginatorPageChangeEvent) => {
                setSkip(event.first);
              }}
              template="CurrentPageReport PrevPageLink PageLinks NextPageLink RowsPerPageDropdown"
              currentPageReportTemplate={`{first} ${t('filter.to')} {last} ${t(
                'filter.of',
              )} {totalRecords}`}
            />
          )}

          <CreateUpdatePlannerModal
            plan={planForEdit}
            refetch={refetch}
            visible={isPlannerModalVisible}
            onHide={() => {
              setPlanForEdit(undefined);
              setIsPlannerModalVisible(false);
            }}
          />

          <StyledFloatingMenuContainer gap={12}>
            <Tooltip position="top" target=".new-users-tooltip-icon" />
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              className={
                !isNewUsersVisible
                  ? 'inactive-item new-users-tooltip-icon'
                  : 'new-users-tooltip-icon'
              }
              data-pr-tooltip={t('schedule.newUsers')}
              onClick={() => setIsNewUsersVisible(!isNewUsersVisible)}
            >
              <NewUsersIconPath />
            </svg>

            <Tooltip position="top" target=".existing-users-tooltip-icon" />
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 18 18"
              fill="none"
              className={
                !isExistingUsersVisible
                  ? 'inactive-item existing-users-tooltip-icon'
                  : 'existing-users-tooltip-icon'
              }
              data-pr-tooltip={t('schedule.existingUsers')}
              onClick={() => setIsExistingUsersVisible(!isExistingUsersVisible)}
            >
              <ExistingUsersIconPath />
            </svg>

            {can(Actions.CREATE, Subjects.COURSE_PLANNER) && (
              <AppButton
                icon="pi pi-plus"
                size="md"
                onClick={() => {
                  setPlanForEdit(undefined);
                  setIsPlannerModalVisible(true);
                }}
              />
            )}
          </StyledFloatingMenuContainer>
        </div>
      )}
    </>
  );
};
