import { RedirectPaths, RedirectPathsEnum } from '@/common/constants';
import classNames from 'classnames';
import { MenuItem } from 'primereact/menuitem';
import { TabMenu } from 'primereact/tabmenu';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const StyledTabMenu = styled(TabMenu)`
  margin-bottom: var(--default-padding);
  background-color: var(--beige-main);

  .p-tabmenu-nav {
    background-color: var(--beige-main);
  }
`;

type PoliciesAndDocumentsTabsPropsType = {
  hasADocument: boolean;
};

export const PoliciesAndDocumentsTabs: React.FC<
  PoliciesAndDocumentsTabsPropsType
> = ({ hasADocument }) => {
  const { t } = useTranslation();

  const getItemTemplate = (url: string, current: string) => (item: MenuItem) =>
    (
      <Link
        to={url}
        className={classNames({
          'p-menuitem-link': true,
          'p-menuitem-link-active': current.includes(url),
        })}
        role="menuitem"
      >
        <span className="p-menuitem-text">{item.label}</span>
      </Link>
    );

  const tabItems: MenuItem[] = [
    {
      label: t('documents'),
      visible: hasADocument,
      template: getItemTemplate(
        RedirectPaths[RedirectPathsEnum.POLICIES_AND_DOCUMENTS_DOCUMENTS](),
        location.pathname,
      ),
    },
    {
      label: t('templates'),
      template: getItemTemplate(
        RedirectPaths[RedirectPathsEnum.POLICIES_AND_DOCUMENTS_TEMPLATES](),
        location.pathname,
      ),
    },
  ];

  const [activeIndex, setActiveIndex] = useState<number>(0);

  useEffect(() => {
    switch (location.pathname) {
      case RedirectPaths[RedirectPathsEnum.POLICIES_AND_DOCUMENTS_DOCUMENTS]():
        setActiveIndex(0);
        break;
      case RedirectPaths[RedirectPathsEnum.POLICIES_AND_DOCUMENTS_TEMPLATES]():
        setActiveIndex(1);
        break;
      default:
        setActiveIndex(0);
    }
  }, [location.pathname]);

  return (
    <StyledTabMenu
      model={tabItems}
      activeIndex={activeIndex}
      onTabChange={() => null}
    />
  );
};
