import { Conditions, GroupCondition } from '@/api/enums';
import { equal, group, like } from '@/api/helpers';
import { AppBreadCrumbTemplate } from '@/app/AppBreadCrumbTemplate';
import { MaterialType } from '@/client/courses';
import { FilterNamesEnum } from '@/client/helpers';
import { Actions, Subjects } from '@/client/users';
import {
  RedirectPaths,
  RedirectPathsEnum,
  TableNamesEnum,
} from '@/common/constants';
import { TranslationFunctionType } from '@/common/types';
import { GridTable } from '@/components/grid-table';
import { PoliciesAndDocumentsTabs } from '@/components/resources';
import {
  DataTableFilters,
  DataTableToolbar,
  FilterType,
  FilterTypeEnum,
} from '@/components/tables/crud';
import { useCourses } from '@/hooks/query';
import { useAppSelector } from '@/hooks/store';
import { useTable } from '@/hooks/table.hook';
import { usePermission } from '@/hooks/usePermission';
import { selectCurrentAccount } from '@/store/features/account';
import { AppBreadCrumb } from '@/ui/breadcrumb';
import { AppButton } from '@/ui/buttons';
import { DocumentCard } from '@/ui/document-card';
import { CardGridContainer, FlexContainer } from '@/ui/styled-ui';
import { accountHasDocuments, templatesColumnsWidth } from '@/utils/helpers';
import { debounce } from 'lodash';
import { InputText } from 'primereact/inputtext';
import React, { FormEvent, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

const StyledCardGridContainer = styled(CardGridContainer)`
  margin: 0;
  width: 100%;
  grid-template-columns: repeat(2, 1fr);

  @media screen and (max-width: ${templatesColumnsWidth.ONE}px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;

const getFilters = (t: TranslationFunctionType): FilterType[] => [
  {
    label: t('generic.custom.content'),
    field: FilterNamesEnum.COURSE_CUSTOM_CONTENT,
    type: FilterTypeEnum.SELECT_YES_NO,
    placeholder: t('generic.custom.content'),
  },
];

export const PoliciesAndDocumentsPage: React.FC<{ type: MaterialType }> = ({
  type,
}) => {
  const { t } = useTranslation();
  const { can } = usePermission();
  const navigate = useNavigate();
  const currentAccount = useAppSelector(selectCurrentAccount);
  const isTemplate = type === MaterialType.TEMPLATE;
  const hasDocuments = accountHasDocuments(currentAccount);

  const {
    skip,
    take,
    apiFilters,
    onFilter,
    onGridPageChange,
    onGridRowChange,
    setSkip,
  } = useTable();

  useEffect(() => {
    setSkip(0);
  }, [isTemplate]);

  useEffect(() => {
    if (!hasDocuments && !isTemplate && !currentAccount?.isSystem) {
      navigate(
        RedirectPaths[RedirectPathsEnum.POLICIES_AND_DOCUMENTS_TEMPLATES](),
      );
    }
  }, [isTemplate, hasDocuments]);

  const [multiSearchValue, setMultiSearchValue] = useState('');
  const debouncedSetMultiSearchValue = useMemo(
    () =>
      debounce((event: FormEvent) => {
        setSkip(0);
        setMultiSearchValue((event.target as HTMLInputElement).value);
      }, 500),
    [],
  );

  const { courses: templates, isLoading } = useCourses({
    take: take,
    skip: skip,
    search: multiSearchValue,
    filters: [
      ...apiFilters,
      ...(!currentAccount?.isSystem
        ? [
            {
              field: 'availableToAccounts',
              condition: Conditions.EQUAL,
              value: currentAccount?.id,
            },
          ]
        : []),

      equal('type', type),
    ],
  });

  const pathItems = [
    {
      label: currentAccount?.name,
      url: RedirectPaths[RedirectPathsEnum.EDIT_ACCOUNT](
        currentAccount?.id as string,
      ),
      template: AppBreadCrumbTemplate,
    },
    {
      label: t('policies.and.documents'),
      url: RedirectPaths[
        isTemplate
          ? RedirectPathsEnum.POLICIES_AND_DOCUMENTS_TEMPLATES
          : RedirectPathsEnum.POLICIES_AND_DOCUMENTS_DOCUMENTS
      ](),
      template: AppBreadCrumbTemplate,
    },
  ];

  const toolbar = (
    <DataTableToolbar justify="space-between">
      <DataTableFilters
        className="flex-initial"
        filters={getFilters(t)}
        onFilter={onFilter}
        tableName={TableNamesEnum.DOCUMENTS_LIST}
      />

      <div className="p-input-icon-left flex-auto min-w-300">
        <InputText
          className="w-full"
          onInput={debouncedSetMultiSearchValue}
          placeholder={t('generic.name.search')}
          autoComplete="off"
        />
        <i className="pi pi-search" />
      </div>
    </DataTableToolbar>
  );

  return (
    <>
      <AppBreadCrumb model={pathItems} />
      <FlexContainer justify="space-between" className="mb-4">
        <h1 className="mb-0">{t('policies.and.documents')}</h1>
        {can(Actions.CREATE, Subjects.COURSES) && currentAccount?.isSystem && (
          <AppButton
            label={t('button.createNew')}
            severity="secondary"
            onClick={() => {
              navigate(
                RedirectPaths[
                  RedirectPathsEnum.POLICIES_AND_DOCUMENTS_CREATE
                ](),
                { state: { type } },
              );
            }}
          />
        )}
      </FlexContainer>
      <GridTable
        isLoading={isLoading}
        count={templates?.count}
        take={take}
        skip={skip}
        onPageChange={onGridPageChange}
        onRowsChange={onGridRowChange}
        toolbarContent={toolbar}
        rowsPerPage={[6, 12]}
      >
        <FlexContainer direction="column" align="flex-start">
          <PoliciesAndDocumentsTabs
            hasADocument={hasDocuments || !!currentAccount?.isSystem}
          />
          {!!templates?.count && (
            <StyledCardGridContainer>
              {templates?.result.map((template) => (
                <DocumentCard
                  key={template.id}
                  template={template}
                  isSystem={currentAccount?.isSystem}
                  withActions
                />
              ))}
            </StyledCardGridContainer>
          )}
        </FlexContainer>
      </GridTable>
    </>
  );
};
