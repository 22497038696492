import { Course } from '@/client/courses';
import {
  CourseSchedulePeriodUnit,
  PlannerCRUDCourse,
} from '@/client/planner/types';
import PagePlaceholderImage from '@/images/placeholders/page-template.svg';
import { AppButton } from '@/ui/buttons';
import { FlexContainer } from '@/ui/styled-ui';
import { t } from 'i18next';
import { escapeRegExp } from 'lodash';
import moment from 'moment';
import { Dropdown } from 'primereact/dropdown';
import React, { useRef, useState } from 'react';
import styled from 'styled-components';

const StyledAppButton = styled(AppButton)`
  &.p-button.p-button-icon-only {
    margin: auto 16px;
    border: 0 !important;
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.15);
  }

  &.p-button.p-button-secondary:enabled:hover {
    border: 0 !important;
  }
`;

const StyledDropdown = styled(Dropdown)`
  &.p-dropdown {
    visibility: hidden;
    width: 0px;
    height: 0px;
    top: -75px;
    left: 25px;
    position: relative;
  }
`;

const StyledFlexContainer = styled(FlexContainer)`
  .course-thumbnail {
    width: 48px;
    height: 48px;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    background-color: var(--gray-pale);
  }
`;

const StyledSpan = styled.span`
  margin-top: var(--small-padding);
  color: var(--black-main);
`;

type PlannerCourseAddButtonProps = {
  courses?: Course[];
  timelineItems?: PlannerCRUDCourse[];
  currentTimelineItem?: PlannerCRUDCourse;
  onItemAdd: (item: PlannerCRUDCourse) => void;
  isCoursesLoading?: boolean;
};

export const PlannerCourseAddButton: React.FC<PlannerCourseAddButtonProps> = ({
  courses,
  timelineItems,
  currentTimelineItem,
  onItemAdd,
  isCoursesLoading,
}) => {
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const dropdownRef = useRef<Dropdown>(null);

  const toggleDropdown = () => {
    if (!dropdownVisible) {
      dropdownRef?.current?.show();
    } else {
      dropdownRef?.current?.hide();
    }
    setDropdownVisible(!dropdownVisible);
  };

  const optionItemTemplate = (course: Course) => {
    return (
      <StyledFlexContainer justify="flex-start" gap={8}>
        <div
          className="course-thumbnail"
          style={{
            backgroundImage: `url(${escapeRegExp(
              course?.courseThumbnail?.signedUrl ?? PagePlaceholderImage,
            )}`,
          }}
        />
        <div>{course.name}</div>
      </StyledFlexContainer>
    );
  };

  return (
    <>
      <StyledAppButton
        icon={isCoursesLoading ? 'pi pi-spinner pi-spin' : ' pi pi-plus'}
        severity="secondary"
        type="outlined"
        isDisabled={isCoursesLoading}
        onClick={() => toggleDropdown()}
      />
      {!timelineItems?.length && (
        <StyledSpan>{t('planner.addCourses')}</StyledSpan>
      )}
      <StyledDropdown
        ref={dropdownRef}
        filter
        filterBy="name"
        options={courses}
        panelStyle={{ width: '380px' }}
        itemTemplate={optionItemTemplate}
        onChange={(e) => {
          onItemAdd({
            ...e.value,
            enrollDate:
              currentTimelineItem?.enrollDate || moment.utc(new Date()),
            periodValue: currentTimelineItem?.periodValue || 0,
            periodUnit:
              currentTimelineItem?.periodUnit || CourseSchedulePeriodUnit.DAYS,
          });
        }}
        optionDisabled={(option) =>
          !!timelineItems?.some((item: any) => item?.id === option?.id)
        }
        onHide={() => setDropdownVisible(false)}
      />
    </>
  );
};
