import { AxiosError, AxiosInstance, AxiosResponse } from 'axios';
import { omit } from 'lodash';
import { getExceptionFromAxiosError } from '../helpers';
import { MessageResponseModel } from '../models';
import { SecurityCultureRequest } from './types';

export const securityCultureService = (client: AxiosInstance) => {
  const BASE_URL = '/account';
  const SECURITY_CULTURE_URL = 'security/culture';

  const sendServeyMails = async (
    request: SecurityCultureRequest,
  ): Promise<MessageResponseModel> => {
    try {
      const result = await client.post<
        MessageResponseModel,
        AxiosResponse<MessageResponseModel>
      >(
        `${BASE_URL}/${request.accountId}/${SECURITY_CULTURE_URL}`,
        omit(request, 'accountId'),
        {
          withCredentials: true,
        },
      );
      return result.data;
    } catch (e) {
      throw getExceptionFromAxiosError(e as AxiosError);
    }
  };

  return { sendServeyMails };
};
