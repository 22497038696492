import {
  EMAIL_REGEX,
  KEYCLOAK_USERNAME_REGEX,
  PASSWORD_REGEX,
} from '@/common/constants';
import { TranslationFunctionType } from '@/common/types';
import { boolean, object, string } from 'yup';

export const createUserSchema = (
  t: TranslationFunctionType,
  isBranchAdmin: boolean,
) =>
  object({
    firstName: string().required(t('error.requiredField')),
    lastName: string().required(t('error.requiredField')),
    username: string()
      .required(t('error.requiredField'))
      .matches(KEYCLOAK_USERNAME_REGEX, t('error.onlyLowercase')),
    email: string()
      .required(t('error.requiredField'))
      .matches(EMAIL_REGEX, t('error.invalidEmail')),
    password: string(),
    role: object().required(t('error.requiredField')),
    language: string().required(t('error.requiredField')),
    branch: isBranchAdmin
      ? object().required(t('error.requiredField'))
      : object(),
    active: boolean().default(true),
    eFrontSync: boolean().default(false),
    isManual: boolean().default(false),
  });

export const updateUserSchema = (
  t: TranslationFunctionType,
  isBranchAdmin: boolean,
) =>
  object({
    firstName: string().required(t('error.requiredField')),
    lastName: string().required(t('error.requiredField')),
    username: string()
      .required(t('error.requiredField'))
      .matches(KEYCLOAK_USERNAME_REGEX, t('error.onlyLowercase')),
    email: string()
      .required(t('error.requiredField'))
      .matches(EMAIL_REGEX, t('error.invalidEmail')),
    password: string()
      .matches(PASSWORD_REGEX, t('error.password.complexity'))
      .min(8, t('error.password.length', { length: 8 })),
    role: object().required(t('error.requiredField')),
    language: string().required(t('error.requiredField')),
    branch: isBranchAdmin
      ? object().required(t('error.requiredField'))
      : object(),
    active: boolean(),
    eFrontSync: boolean(),
  });

export const updateUserPropertiesSchema = (t: TranslationFunctionType) =>
  object({
    companyName: string(),
    department: string(),
    manager: string(),
    country: string(),
    jobTitle: string(),
    mobilePhone: string(),
    officeLocation: string(),
  });
