import { Tooltip } from 'primereact/tooltip';
import React from 'react';
import styled from 'styled-components';
import { FlexContainer } from '../styled-ui';

const StyledCheckboxContainer = styled(FlexContainer)`
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  flex-grow: 1;
  align-self: stretch;
  gap: var(--medium-padding);
  background-color: var(--beige-main);
  padding: var(--default-padding);
  border-radius: var(--small-border-radius);
  margin-top: var(--xsmall-padding);
  margin-bottom: var(--default-padding);

  &.toggle-container {
    padding: var(--medium-padding) var(--default-padding);
    margin-bottom: 0;
  }
`;

const StyledIcon = styled.i`
  font-size: var(--xsmall-font-size);
  color: var(--gray-darker);
  cursor: pointer;
`;

type CheckboxesContainerProps = {
  children: React.ReactNode;
  label?: string;
  tooltipId?: string;
  tooltip?: string;
  className?: string;
};

export const CheckboxesContainer: React.FC<CheckboxesContainerProps> = ({
  children,
  label,
  tooltipId,
  tooltip,
  className,
}) => {
  return (
    <div className="w-full">
      <label>{label}</label>
      {tooltip && tooltipId && (
        <>
          <Tooltip
            target={`#${tooltipId}`}
            position="top"
            content={tooltip}
            my="center bottom-10"
          />
          <StyledIcon className="pi pi-info-circle ml-1" id={tooltipId} />
        </>
      )}
      <StyledCheckboxContainer className={className}>
        {children}
      </StyledCheckboxContainer>
    </div>
  );
};
