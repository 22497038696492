import { DataTableColumn } from '@/components/tables/crud/DataTableColumn';
import { Column } from 'primereact/column';
import React from 'react';
import { DataTableColumnType } from './types';

type DynamicColumnsProps = {
  columns: DataTableColumnType[];
  visibleColumns?: string[];
  isLoading: boolean;
};

export const DynamicColumns = ({
  columns,
  visibleColumns,
  isLoading,
}: DynamicColumnsProps) =>
  columns
    .filter((column) => {
      if (!visibleColumns) return true;
      return visibleColumns?.includes(column.field);
    })
    .map((column) => (
      <Column
        key={`column-${column.field}`}
        field={column.field}
        className={column.className}
        headerClassName={column.headerClassName}
        header={column.header}
        sortable={column.sortable}
        exportable={false}
        body={(rowData: object) => (
          <DataTableColumn
            key={`dt-table-column-${column.field}`}
            item={rowData}
            isLoading={isLoading}
            field={column.field}
            render={column.render}
          />
        )}
        style={column.style}
      />
    ));
