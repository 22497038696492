import { EMAIL_REGEX } from '@/common/constants';
import { TranslationFunctionType } from '@/common/types';
import { object, string } from 'yup';

export const testEmailSchema = (t: TranslationFunctionType) =>
  object().shape(
    {
      emailType: string().oneOf(['custom', 'admin']),
      email: string()
        .required(t('error.requiredField'))
        .matches(EMAIL_REGEX, 'error.invalidEmail'),
      firstName: string(),
      lastName: string(),
      position: string(),
      admin: object().when(['emailType'], ([emailType], schema) =>
        emailType === 'admin'
          ? schema.required(t('error.testEmail.admin'))
          : schema,
      ),
    },
    [['admin', 'emailType']],
  );
