import { FilterNamesEnum } from '@/client/helpers';

export type FilterType = {
  type: FilterTypeEnum;
  field: FilterNamesEnum;
  label?: string;
  options?: object[];
  placeholder?: string | string[];
  defaultValue?: object | number | string;
};

export type ColumnFilters = {
  type: FilterTypeEnum;
  field?: FilterNamesEnum;
  label?: string;
  options?: object[];
  placeholder?: string | string[];
  defaultValue?: object | number | string;
};

export enum FilterTypeEnum {
  SELECT = 'select',
  SELECT_YES_NO = 'select-yes-no',
  SELECT_AUTO_MANUAL = 'select-auto-manual',
  SELECT_ACTIVE_INACTIVE = 'select-active-inactive',
  SELECT_UPCOMING_PAST = 'select-upcoming-past',
  SELECT_AVAILABLE_UNAVAILABLE = 'select-available-unavailable',
  TEXT = 'text',
  DATE = 'date',
  MULTI_SELECT = 'multi-select',
  BETWEEN = 'between',
  DATE_BETWEEN = 'date-between',
}
