import { GroupCondition } from '@/api/enums';
import { equal, group, handleAxiosError, isIn, nested } from '@/api/helpers';
import { Branch, BranchDescendant } from '@/client/branches';
import { CourseEntityEnrollEnum } from '@/client/courses';
import { Group } from '@/client/groups';
import {
  CoursePlanner,
  CourseSchedulePeriodUnit,
  PlannerCRUDCourse,
  PlannerUsersEnrollType,
} from '@/client/planner/types';
import { User } from '@/client/users';
import {
  useBranchesPartialRequest,
  useGroupsPartialRequest,
  useUsersPartialRequest,
} from '@/hooks/query';
import {
  useCreatePlanner,
  useUpdatePlanner,
} from '@/hooks/query/planner.hooks';
import { useAppSelector } from '@/hooks/store';
import { useToast } from '@/hooks/useToast';
import { selectCurrentAccount } from '@/store/features/account';
import { AppButton } from '@/ui/buttons';
import { FlexContainer } from '@/ui/styled-ui';
import { RemoveIconPath } from '@/utils/helpers';
import { getAllParentIds } from '@/utils/helpers/branches.helper';
import {
  formatIncludeExcludeEntities,
  getEntityIdsByType,
  processPlanIncludeExcludeEntities,
} from '@/utils/planner';
import { AxiosError } from 'axios';
import { t } from 'i18next';
import { uniqBy } from 'lodash';
import moment from 'moment';
import { Dialog, DialogProps } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import { MultiSelect, MultiSelectChangeEvent } from 'primereact/multiselect';
import { Skeleton } from 'primereact/skeleton';
import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { PlannerCRUDTimeline } from '../PlannerCRUDTimeline';

const StyledDialog = styled(Dialog)`
  width: 100%;
  max-width: 1400px;
  max-height: 650px;
  height: 100%;

  .p-dialog-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 0;
  }
`;

const StyledGrayFlexContainer = styled(FlexContainer)`
  background-color: var(--beige-main);
  padding: var(--big-padding);
  padding-bottom: 0;
  margin-top: var(--default-padding);
  height: 100%;
`;

const StyledSpan = styled.span`
  font-size: var(--xsmall-font-size);
  color: var(--black-main);
`;

const StyledMultiSelect = styled(MultiSelect)`
  .p-multiselect-label {
    flex-wrap: nowrap;
  }
  .p-multiselect-token-label {
    &::first-letter {
      color: var(--gray-darker);
      margin-right: 4px;
    }
  }
`;

const StyledTopDiv = styled.div`
  width: 100%;
  color: var(--black-main);
  padding: var(--medium-padding) var(--default-padding) 0 var(--default-padding);
`;

const StyledBottomDiv = styled.div`
  width: 100%;
  margin-top: var(--default-padding);
  padding: var(--default-padding);
  padding-top: 0;
`;

const StyledLink = styled.div`
  cursor: pointer;
  color: var(--red-main);
  font-size: var(--small-font-size);
  line-height: var(--small-line-height);

  &:hover {
    color: var(--red-dark);
  }
`;

type CreateUpdatePlannerModalProps = {
  plan?: CoursePlanner;
  refetch?: () => void;
} & DialogProps;

type GroupedEntitiesType = {
  label: string;
  items: Branch[] | Group[];
}[];

export const CreateUpdatePlannerModal: React.FC<
  CreateUpdatePlannerModalProps
> = ({ plan, visible, onHide, refetch }) => {
  const toast = useToast();
  const account = useAppSelector(selectCurrentAccount);
  const [usersType, setUsersType] = useState(PlannerUsersEnrollType.NEW_USERS);
  const groupedEntities = useRef<GroupedEntitiesType>([]);

  const [includedEntities, setIncludedEntities] = useState<(Branch | Group)[]>(
    [],
  );
  const [excludedEntities, setExcludedEntities] = useState<(Branch | Group)[]>(
    [],
  );

  const [includedEntitiesDescendants, setIncludedEntitiesDescendants] =
    useState<(Branch | Group)[]>([]);

  const [newUsersSelectedCourses, setNewUsersSelectedCourses] = useState<
    PlannerCRUDCourse[]
  >([]);
  const [existingUsersSelectedCourses, setExistingUsersSelectedCourses] =
    useState<PlannerCRUDCourse[]>([]);

  const { branches, isLoading: isBranchesLoading } = useBranchesPartialRequest({
    filters: [equal('account', account?.id), equal('active', true)],
    sort: ['name,asc'],
    withDescendants: true,
    take: 20,
  });
  const { groups, isLoading: isGroupsLoading } = useGroupsPartialRequest({
    filters: [equal('account', account?.id)],
    sort: ['name,asc'],
  });

  const [shouldRefetchUsers, setShouldRefetchUsers] = useState(true);
  const [isExcludeUsers, setIsExcludeUsers] = useState(false);
  const [isDropdownLoading, setIsDropdownLoading] = useState(false);
  const groupedUsers = useRef<GroupedEntitiesType>([]);
  const { isLoading: isUsersLoading, refetch: refetchUsers } =
    useUsersPartialRequest({
      filters: [
        equal('account', account?.id),
        equal('active', true),
        group(GroupCondition.OR, [
          nested('branch', [
            isIn(
              'id',
              getEntityIdsByType(
                [...includedEntities, ...includedEntitiesDescendants],
                CourseEntityEnrollEnum.BRANCHES,
              ),
            ),
          ]),
          nested('groups', [
            isIn(
              'id',
              getEntityIdsByType(
                includedEntities,
                CourseEntityEnrollEnum.GROUPS,
              ),
            ),
          ]),
        ]),
      ],
      sort: ['name,asc'],
      enabled: false,
    });

  useEffect(() => {
    if (!isBranchesLoading && !isGroupsLoading) {
      const entities = [];

      if (branches?.length) {
        entities.push({
          label: t('branches'),
          items: formatIncludeExcludeEntities(
            branches,
            'B',
            CourseEntityEnrollEnum.BRANCHES,
          ),
        });
      }

      if (groups?.length) {
        entities.push({
          label: t('groups'),
          items: formatIncludeExcludeEntities(
            groups,
            'G',
            CourseEntityEnrollEnum.GROUPS,
          ),
        });
      }

      groupedEntities.current = entities;
    }
  }, [isBranchesLoading, isGroupsLoading]);

  useEffect(() => {
    setIncludedEntities([]);
    setExcludedEntities([]);
    setExistingUsersSelectedCourses([]);
    setNewUsersSelectedCourses([]);
  }, [visible]);

  useEffect(() => {
    if (plan) {
      const { includedEntities, excludedEntities } =
        processPlanIncludeExcludeEntities(plan);

      setIncludedEntities(includedEntities);
      setExcludedEntities(excludedEntities);
      setIncludedEntitiesDescendants(
        extractAllEntitiesDescendants(includedEntities),
      );

      setNewUsersSelectedCourses(
        plan.dynamicCourseSchedules.map((item) => ({
          ...item,
          ...item.course,
        })),
      );
      setExistingUsersSelectedCourses(
        plan.staticCourseSchedules.map((item) => ({
          ...item,
          ...item.course,
        })),
      );
    }
  }, [plan]);

  const extractAllEntitiesDescendants = (items: Branch[]) => {
    return items.flatMap((item: Branch) => {
      const itemMapped = branches?.find(({ id }) => id === item.id);

      return formatIncludeExcludeEntities(
        branches?.filter((b) =>
          itemMapped?.descendants?.some(({ id }: Branch) => id === b.id),
        ) || [],
        'B',
        CourseEntityEnrollEnum.BRANCHES,
      );
    });
  };

  const handleIncludeExcludeChange = (
    event: MultiSelectChangeEvent,
    isInclude: boolean,
  ) => {
    const items = event.value;

    const descendantsFormatted = extractAllEntitiesDescendants(items);
    const allEntities = [...items, ...descendantsFormatted];
    const uniqueEntities = uniqBy(allEntities, 'id');

    if (isInclude) {
      setIncludedEntities(
        items.filter(
          ({ id }: Branch | Group) =>
            !descendantsFormatted.some((des) => des.id === id),
        ),
      );
      setIncludedEntitiesDescendants(descendantsFormatted);
    } else {
      setExcludedEntities(uniqueEntities);
    }
  };

  const checkIsIncludeOptionDisabled = (option: Branch | Group) => {
    return (
      excludedEntities.some((excluded) => excluded.id === option.id) ||
      getAllParentIds(branches?.find(({ id }) => id === option.id)).some(
        (parentId) => includedEntities.map(({ id }) => id).includes(parentId),
      )
    );
  };

  const checkIsExcludeOptionDisabled = (option: any) => {
    return (
      includedEntities.some((included) => included.id === option.id) ||
      getAllParentIds(branches?.find(({ id }) => id === option.id)).some(
        (parentId) => excludedEntities.map(({ id }) => id).includes(parentId),
      ) ||
      option?.descendants?.some((descendant: BranchDescendant) =>
        includedEntities.map(({ id }) => id).includes(descendant.id),
      )
    );
  };

  const setCourses = (items: any) => {
    usersType === PlannerUsersEnrollType.NEW_USERS
      ? setNewUsersSelectedCourses(items)
      : setExistingUsersSelectedCourses(items);
  };

  const getCourses = () => {
    return usersType === PlannerUsersEnrollType.NEW_USERS
      ? newUsersSelectedCourses
      : existingUsersSelectedCourses;
  };

  const updateCourseDate = (date: Date, itemId: string) => {
    const enrollDate = moment(date).isSame(moment(), 'day')
      ? moment.utc(new Date())
      : date;

    setCourses((currentCourses: PlannerCRUDCourse[]) => {
      return currentCourses.map((course: PlannerCRUDCourse) => {
        if (course.id === itemId) {
          return { ...course, enrollDate };
        }
        return course;
      });
    });
  };

  const updateCoursePeriod = (
    periodValue: number,
    periodUnit: CourseSchedulePeriodUnit,
    itemId: string,
  ) => {
    setCourses((currentCourses: PlannerCRUDCourse[]) => {
      return currentCourses.map((course: PlannerCRUDCourse) => {
        if (course.id === itemId) {
          return { ...course, periodValue, periodUnit };
        }

        return course;
      });
    });
  };

  const createPlanner = useCreatePlanner();
  const updatePlanner = useUpdatePlanner();
  const handleCreatePlanner = async () => {
    if (!account?.id) return;

    try {
      const action = plan?.id ? updatePlanner.update : createPlanner.create;
      await action({
        accountId: account.id,
        id: plan?.id,
        targets: {
          branches: getEntityIdsByType(
            includedEntities,
            CourseEntityEnrollEnum.BRANCHES,
          ),
          groups: getEntityIdsByType(
            includedEntities,
            CourseEntityEnrollEnum.GROUPS,
          ),
        },
        existingUserCourses: existingUsersSelectedCourses.map(
          (course: any) => ({
            id: course.id,
            enrollDate: course.enrollDate || moment().utc(true).toDate(),
          }),
        ),
        newUserCourses: newUsersSelectedCourses.map((course: any) => ({
          id: course.id,
          enrollOffset: {
            value: course.periodValue || 0,
            unit: course.periodUnit || CourseSchedulePeriodUnit.DAYS,
          },
        })),
        excludes: {
          branches: getEntityIdsByType(
            excludedEntities,
            CourseEntityEnrollEnum.BRANCHES,
          ),
          groups: getEntityIdsByType(
            excludedEntities,
            CourseEntityEnrollEnum.GROUPS,
          ),
          users: getEntityIdsByType(
            excludedEntities,
            CourseEntityEnrollEnum.USERS,
          ),
        },
      });

      if (refetch) {
        await refetch();
      }
      plan?.id
        ? toast?.success(t('toast.success'), t('planner.updated'))
        : toast?.success(t('toast.success'), t('planner.created'));
      onHide();
    } catch (e) {
      handleAxiosError(e as Error | AxiosError, toast);
    }
  };

  const onChangeExcludeDropdownMode = async () => {
    setIsDropdownLoading(true);

    if (shouldRefetchUsers && !isExcludeUsers) {
      const users = await refetchUsers();

      groupedUsers.current = [
        {
          label: t('users'),
          items: formatIncludeExcludeEntities(
            users.data || [],
            'U',
            CourseEntityEnrollEnum.USERS,
          ),
        },
      ];

      setShouldRefetchUsers(false);
    }

    setIsExcludeUsers(!isExcludeUsers);

    setTimeout(() => {
      setIsDropdownLoading(false);
    }, 250);
  };

  useEffect(() => {
    setShouldRefetchUsers(true);
  }, [includedEntities]);

  const footerTemplate = (
    <StyledLink onClick={onChangeExcludeDropdownMode}>
      {isExcludeUsers
        ? t('planner.excludeBranchesGroups')
        : t('planner.excludeUsers')}
    </StyledLink>
  );

  const selectedItemTemplate = (
    option: Branch | Group | User,
    isInclude: boolean,
  ) => {
    return option ? (
      <div
        className={
          (isInclude && checkIsIncludeOptionDisabled(option)) ||
          (!isInclude && checkIsExcludeOptionDisabled(option))
            ? 'p-multiselect-token p-disabled'
            : 'p-multiselect-token'
        }
        data-pc-section="token"
      >
        <span
          className="p-multiselect-token-label"
          data-pc-section="tokenlabel"
        >
          {option?.name}
        </span>
        <svg
          width="14"
          height="14"
          viewBox="0 0 14 14"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className="p-icon p-multiselect-token-icon"
          aria-hidden="true"
          onClick={() =>
            isInclude
              ? setIncludedEntities(
                  includedEntities.filter(({ id }) => id !== option.id),
                )
              : setExcludedEntities(
                  excludedEntities.filter(({ id }) => id !== option.id),
                )
          }
        >
          <RemoveIconPath />
        </svg>
      </div>
    ) : null;
  };

  const loadingTemplate = () =>
    Array.from({ length: 5 }).map((_, index) => (
      <div key={index} className="flex align-items-center p-2">
        <Skeleton key={index} width="100%" height="32px" />
      </div>
    ));

  return (
    <StyledDialog
      header={<h1>{t('account.trainingPlan')}</h1>}
      blockScroll
      visible={visible}
      onHide={onHide}
      draggable={false}
      dismissableMask
      closeOnEscape
      data-testid="create-update-planner-modal"
    >
      <StyledTopDiv>
        <StyledSpan>
          {t('planner.enrollTo')}
          <span className="red"> *</span>
        </StyledSpan>
        <FlexContainer justify="flex-start" gap={12}>
          <Dropdown
            id="type"
            placeholder={t('generic.select')}
            value={usersType}
            optionValue="id"
            optionLabel="label"
            onChange={(e) => setUsersType(e.value)}
            options={Object.values(PlannerUsersEnrollType).map((value) => ({
              id: value,
              label: t(`planner.${value}`),
            }))}
            style={{ minWidth: '200px' }}
          />
          {t('generic.at')}
          <StyledMultiSelect
            dropdownIcon={
              isBranchesLoading || isGroupsLoading
                ? 'pi pi-spinner pi-spin'
                : 'pi pi-chevron-down'
            }
            dataKey="id"
            value={includedEntities}
            options={groupedEntities.current}
            onChange={(event) => handleIncludeExcludeChange(event, true)}
            filter
            optionLabel="name"
            optionGroupLabel="label"
            optionGroupChildren="items"
            placeholder={account?.name}
            display="chip"
            className="flex-1"
            disabled={isBranchesLoading || isGroupsLoading}
            optionDisabled={checkIsIncludeOptionDisabled}
            panelClassName="hide-first-letter"
            style={{ width: '200px' }}
            panelStyle={{ width: '200px' }}
            selectedItemTemplate={(item) => selectedItemTemplate(item, true)}
          />
          {t('generic.excl')}
          <StyledMultiSelect
            dropdownIcon={
              isBranchesLoading || isGroupsLoading || isUsersLoading
                ? 'pi pi-spinner pi-spin'
                : 'pi pi-chevron-down'
            }
            dataKey="id"
            value={excludedEntities}
            options={
              isExcludeUsers ? groupedUsers.current : groupedEntities.current
            }
            onChange={(event) => handleIncludeExcludeChange(event, false)}
            filter
            optionLabel="name"
            optionGroupLabel="label"
            optionGroupChildren="items"
            placeholder={t('generic.none')}
            display="chip"
            className="flex-1"
            disabled={isBranchesLoading || isGroupsLoading || isUsersLoading}
            optionDisabled={checkIsExcludeOptionDisabled}
            panelClassName="hide-first-letter"
            style={{ width: '200px' }}
            panelStyle={{ width: '200px' }}
            virtualScrollerOptions={
              isDropdownLoading
                ? {
                    loadingTemplate,
                    showLoader: true,
                    loading: isDropdownLoading,
                  }
                : undefined
            }
            panelFooterTemplate={footerTemplate}
            selectedItemTemplate={(item) => selectedItemTemplate(item, false)}
            onHide={() => setIsExcludeUsers(false)}
          />
        </FlexContainer>
      </StyledTopDiv>
      <StyledGrayFlexContainer direction="column" gap={12} width="100%">
        <PlannerCRUDTimeline
          items={getCourses()}
          isNewUsers={usersType === PlannerUsersEnrollType.NEW_USERS}
          onItemAdd={(item: PlannerCRUDCourse) =>
            setCourses([...getCourses(), item])
          }
          onItemDateChange={updateCourseDate}
          onItemPeriodChange={updateCoursePeriod}
          onItemRemove={(itemId: string) =>
            setCourses(
              getCourses().filter(
                (course: PlannerCRUDCourse) => course.id !== itemId,
              ),
            )
          }
        />
      </StyledGrayFlexContainer>
      <StyledBottomDiv>
        <FlexContainer justify="flex-end">
          <AppButton
            label={t('button.close')}
            severity="secondary"
            type="outlined"
            onClick={onHide}
            className="mr-3"
            data-testid="create-update-planner-cancel"
          />
          <AppButton
            label={t('button.save')}
            data-testid="save-create-update-planner-cancel"
            onClick={handleCreatePlanner}
            isDisabled={
              !newUsersSelectedCourses?.length &&
              !existingUsersSelectedCourses?.length
            }
          />
        </FlexContainer>
      </StyledBottomDiv>
    </StyledDialog>
  );
};
