import { Campaign } from '@/client/campaigns';
import { FormikRadio } from '@/components/form';
import EmailPlaceholderImage from '@/images/placeholders/email-template.svg';
import PagePlaceholderImage from '@/images/placeholders/page-template.svg';
import { AppButton } from '@/ui/buttons';
import { FlexContainer } from '@/ui/styled-ui';
import classNames from 'classnames';
import { Field } from 'formik';
import { escapeRegExp } from 'lodash';
import { Card } from 'primereact/card';
import { Menu } from 'primereact/menu';
import { MenuItem } from 'primereact/menuitem';
import { ProgressSpinner } from 'primereact/progressspinner';
import { RadioButtonChangeEvent } from 'primereact/radiobutton';
import React, { SyntheticEvent, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

const StyledStatusContainer = styled.span<{ active?: boolean }>`
  position: absolute;
  padding: 0 var(--xsmall-padding);
  border-radius: 2px;
  color: ${({ active }) => (active ? 'var(--red-main)' : 'var(--gray-darker)')};
  background-color: var(--white-main);
  z-index: 1;
  font-size: var(--small-font-size);
  line-height: var(--small-line-height);
  top: var(--small-padding);
  left: var(--small-padding);
  font-weight: 600;
`;

const StyledImageCard = styled(Card)`
  &.p-card {
    border: 1px solid var(--gray-dark);
    border-radius: var(--medium-border-radius);
    box-shadow: none;
    overflow: hidden;

    &.selected,
    &:hover,
    &:focus-within {
      .p-card-header .image-card-overlay {
        opacity: 1;
        cursor: pointer;
      }
    }

    &.disabled {
      opacity: 0.6;
      pointer-events: none !important;
    }

    .p-card-body {
      padding: var(--small-padding);

      .p-card-title {
        margin-bottom: 0;
        font-size: var(--medium-font-size);
        line-height: var(--medium-line-height);
        color: var(--black-main);
      }

      .p-card-subtitle {
        margin-bottom: 0;
        font-size: var(--xsmall-font-size);
        line-height: var(--xsmall-line-height);
        color: var(--black-main);
      }
    }

    .p-card-header {
      height: 194px;
      position: relative;
      display: flex;
      align-items: stretch;

      .image-card-thumbnail {
        background-size: cover;
        background-repeat: no-repeat;
        background-color: var(--gray-pale);

        &.default-thumbnail {
          background-position: center center;
        }
      }

      .spinner {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }

      .image-card-overlay {
        position: absolute;
        height: 100%;
        padding: var(--small-padding);
        background: var(--red-opacity);
        opacity: 0;
        transition: all 0.4s;
      }

      .p-button.p-component {
        width: var(--default-padding);
        height: var(--default-padding);
        border-radius: 2px;
        background: var(--beige-main) !important;
        padding: 0;

        &.p-disabled:hover {
          color: var(--red-opacity) !important;
          border: transparent !important;
        }

        .p-button-icon {
          font-size: var(--xsmall-font-size);
        }
        &:hover {
          background: var(--beige-main);
        }
      }
    }
  }
`;

export type ImageCardProps = {
  id: string;
  title: string;
  author?: string;
  templateType: 'page' | 'email';
  interactionType?: 'withActions' | 'withSelect' | 'withSelectAndActions';
  disabledActions?: boolean;
  showStatus?: boolean;
  activeStatus?: boolean;
  status?: string;
  isTemplate?: boolean;
  thumbnail?: string;
  actions?: MenuItem[];
  selectName?: string;
  isSelected?: boolean;
  isDisabled?: boolean;
  isLoading?: boolean;
  additionalClass?: string;
  onChange?: (event: RadioButtonChangeEvent) => void;
  campaignValue?: Campaign;
};

export const ImageCard: React.FC<ImageCardProps> = ({
  id,
  title,
  author,
  templateType,
  interactionType,
  disabledActions,
  showStatus,
  activeStatus,
  status,
  isTemplate,
  thumbnail,
  actions,
  selectName,
  isSelected,
  isDisabled,
  isLoading,
  additionalClass,
  onChange,
  campaignValue,
  ...rest
}) => {
  const { t } = useTranslation();
  const className = classNames(
    {
      disabled: isDisabled,
      selected: isSelected,
    },
    additionalClass,
  );

  const itemActionMenu = useRef<any>(null);

  const toggleItemActionMenu = (event: SyntheticEvent<HTMLButtonElement>) => {
    if (!itemActionMenu.current) {
      return;
    }
    itemActionMenu.current.toggle(event);
  };

  const header = (
    <label
      className={
        thumbnail
          ? 'image-card-thumbnail flex-1'
          : 'image-card-thumbnail default-thumbnail flex-1'
      }
      style={{
        backgroundImage: `url(${escapeRegExp(
          thumbnail
            ? thumbnail
            : templateType === 'email'
            ? EmailPlaceholderImage
            : PagePlaceholderImage,
        )}`,
      }}
    >
      {status && (
        <StyledStatusContainer active={status !== 'campaign.status.draft'}>
          {status}
        </StyledStatusContainer>
      )}

      {showStatus && (
        <StyledStatusContainer active={activeStatus}>
          {activeStatus ? t('generic.active') : t('generic.inactive')}
        </StyledStatusContainer>
      )}

      {isTemplate && (
        <StyledStatusContainer active={isTemplate}>
          {t('generic.template')}
        </StyledStatusContainer>
      )}

      {isLoading && <ProgressSpinner className="spinner" />}
      <FlexContainer
        className="image-card-overlay"
        justify={
          interactionType === 'withActions'
            ? 'flex-end'
            : interactionType === 'withSelectAndActions'
            ? 'space-between'
            : 'flex-start'
        }
        align="flex-start"
      >
        {interactionType &&
          ['withSelect', 'withSelectAndActions'].includes(interactionType) && (
            <Field
              type="radio"
              name={selectName}
              value={campaignValue ? campaignValue : id}
              onChange={onChange}
              checked={isSelected}
              component={FormikRadio}
            />
          )}
        {interactionType &&
          ['withActions', 'withSelectAndActions'].includes(interactionType) && (
            <>
              <Menu
                model={actions}
                popup
                ref={itemActionMenu}
                id="image_card_menu"
              />
              <AppButton
                icon="pi pi-ellipsis-h"
                type="text"
                aria-controls="image_card_menu"
                onClick={toggleItemActionMenu}
                isDisabled={disabledActions}
              />
            </>
          )}
      </FlexContainer>
    </label>
  );

  return (
    <StyledImageCard
      id={id}
      title={title}
      subTitle={author}
      className={className}
      header={header}
      {...rest}
    />
  );
};
