import { LanguagesEnum } from '@/api/enums';
import { Course } from '@/client/courses';
import { ScormLanguageChanger } from '@/scorm/components/ScormLanguageChanger';
import { AppButton } from '@/ui/buttons';
import { FlexContainer } from '@/ui/styled-ui';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

const StyledGrayFlexContainer = styled(FlexContainer)`
  border: 1px solid var(--gray-dark);
  border-radius: var(--xsmall-border-radius);
  background-color: var(--beige-main);
  padding: var(--default-padding);
`;

type ScormLanguageSelectScreenProps = {
  course: Course;
  language?: LanguagesEnum;
  isLoading?: boolean;
  isDisabled?: boolean;
  setIsScormStarted: (isStarted: boolean) => void;
  setSelectedLanguage: (language: LanguagesEnum) => void;
};

export const ScormLanguageSelectScreen: React.FC<
  ScormLanguageSelectScreenProps
> = ({
  course,
  language,
  isLoading,
  isDisabled,
  setIsScormStarted,
  setSelectedLanguage,
}) => {
  const { t } = useTranslation();
  return (
    <StyledGrayFlexContainer direction="column" flex="1">
      <h2>{t('course.choose.language')}</h2>
      <FlexContainer className="mt-4" wrap="wrap" gap={12}>
        <ScormLanguageChanger
          language={language}
          course={course}
          onChange={(value) => setSelectedLanguage(value)}
        />
        <AppButton
          label={t('generic.continue')}
          className="ml-2"
          onClick={() => setIsScormStarted(true)}
          isDisabled={isDisabled || isLoading}
          icon={isLoading ? 'pi pi-spinner' : ''}
        />
      </FlexContainer>
    </StyledGrayFlexContainer>
  );
};
