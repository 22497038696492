import { TranslationFunctionType } from '@/common/types';
import { isEmpty } from 'lodash';
import { array, boolean, lazy, mixed, object, string } from 'yup';

export const azureAdSchema = (t: TranslationFunctionType) =>
  object({
    clientId: string()
      .uuid(t('error.validValue'))
      .required(t('error.requiredField')),
    clientSecret: string().required(t('error.requiredField')),
    tenantId: string()
      .uuid(t('error.validValue'))
      .required(t('error.requiredField')),
    active: boolean().default(false),
    mapping: lazy((value) => {
      if (!isEmpty(value)) {
        const validationObject = {
          groupId: string()
            .uuid(t('error.enterObjectId'))
            .required(t('error.enterObjectId')),
          account: object().nullable(),
          branches: array(),
          // .when('account', ([account], schema) =>
          //   account ? schema : schema.min(1, t('error.selectBranch'))),
          groups: array(),
          language: string().required(t('error.requiredLanguage')),
        };
        const newEntries = Object.keys(value).reduce(
          (acc, val) => ({
            ...acc,
            [val]: object(validationObject),
          }),
          {},
        );

        return object().shape(newEntries);
      }
      return mixed();
    }),
  });
