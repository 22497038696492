import { FiltersType } from '@/api/types';
import { User } from '@/client/users';
import { LoadingStateType } from '@/common/constants';
import { managersSchema } from '@/components/users/forms/validations';
import { AppButton } from '@/ui/buttons';
import { FlexContainer, FormContainer } from '@/ui/styled-ui';
import { Form, Formik, FormikHelpers, FormikValues } from 'formik';
import { Dialog, DialogProps } from 'primereact/dialog';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { BulkUserDatatable } from '../datatables';

const StyledDialog = styled(Dialog)`
  width: 800px;
  height: 698px;
`;

const StyledText = styled.div`
  font-size: var(--small-font-size);
  line-height: var(--small-line-height);
  font-weight: 700;
  color: var(--red-main);
  text-align: start;
  min-width: max-content;
`;

type BulkUsersModalFormProps = {
  initialValues: { users?: User[] };
  onSubmit: (
    data: FormikValues,
    helpers?: FormikHelpers<{ users?: User[] }>,
  ) => void;
  modalHeading: string;
  state?: LoadingStateType;
  additionalFilters?: FiltersType;
} & DialogProps;

export const BulkUsersModalForm: React.FC<BulkUsersModalFormProps> = ({
  visible,
  initialValues,
  onSubmit,
  onHide,
  modalHeading,
  state,
  additionalFilters,
}) => {
  const { t } = useTranslation();
  const [showSelected, setShowSelected] = useState(false);

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={managersSchema(t)}
      onSubmit={onSubmit}
    >
      {({ values, errors, setFieldValue, touched, dirty, submitForm }) => (
        <StyledDialog
          blockScroll
          visible={visible}
          header={
            <FlexContainer justify="flex-start" align="baseline" gap={12}>
              <h1>{modalHeading}</h1>
              <StyledText>({values.users?.length ?? 0} selected)</StyledText>
            </FlexContainer>
          }
          onHide={onHide}
          draggable={false}
          footer={
            <FlexContainer justify="space-between">
              <AppButton
                label={
                  showSelected
                    ? t('generic.showAll')
                    : t('generic.showSelected')
                }
                type="outlined"
                onClick={() => setShowSelected(!showSelected)}
              />
              <FlexContainer justify="flex-end">
                <AppButton
                  label={t('button.cancel')}
                  severity="secondary"
                  type="outlined"
                  onClick={onHide}
                  className="mr-3"
                />
                <AppButton
                  onClick={submitForm}
                  severity="success"
                  label={t('users.add')}
                  className="w-fit-content"
                  state={state}
                  isDisabled={!dirty || !!Object.keys(errors).length}
                />
              </FlexContainer>
            </FlexContainer>
          }
        >
          <FormContainer width={100}>
            <Form className="w-full mb-4 gap-3" autoComplete="off">
              <BulkUserDatatable
                values={values}
                setFieldValue={setFieldValue}
                defaultFilters={additionalFilters}
                defaultTableFilters={{ active: 'yes' }}
                showSelected={showSelected}
              />
              <div className="field w-full m-0">
                {touched.users && errors.users ? (
                  <small className="p-error">{errors.users}</small>
                ) : null}
              </div>
            </Form>
          </FormContainer>
        </StyledDialog>
      )}
    </Formik>
  );
};
