import { processListingParams } from '@/api/helpers';
import { PostUserRequest } from '@/client/accounts';
import {
  Group,
  ListGroupsRequest,
  ListGroupsResponse,
  SaveGroupRequest,
  UpdateGroupRequest,
} from '@/client/groups';
import { getExceptionFromAxiosError } from '@/client/helpers';
import { MessageResponseModel } from '@/client/models';
import { AxiosError, AxiosInstance, AxiosResponse } from 'axios';

export const groupsService = (client: AxiosInstance) => {
  const GROUPS_BASE_URL = '/groups';
  const USERS_GROUPS_BASE_URL = (groupId: string) => `/groups/${groupId}/users`;

  const getGroups = async (
    params: ListGroupsRequest,
  ): Promise<ListGroupsResponse> => {
    try {
      const result = await client.get<
        ListGroupsResponse,
        AxiosResponse<ListGroupsResponse>
      >(GROUPS_BASE_URL, {
        withCredentials: true,
        params: processListingParams(params),
      });

      return result.data;
    } catch (e) {
      throw getExceptionFromAxiosError(e as AxiosError);
    }
  };

  const getGroup = async (groupId?: string): Promise<Group> => {
    try {
      const result = await client.get<Group, AxiosResponse<Group>>(
        `${GROUPS_BASE_URL}/${groupId}`,
        {
          withCredentials: true,
        },
      );

      return result.data;
    } catch (e) {
      throw getExceptionFromAxiosError(e as AxiosError);
    }
  };

  const saveGroup = async (request: SaveGroupRequest): Promise<Group> => {
    try {
      const result = await client.post<Group, AxiosResponse<Group>>(
        GROUPS_BASE_URL,
        request,
        {
          withCredentials: true,
        },
      );

      return result.data;
    } catch (e) {
      throw getExceptionFromAxiosError(e as AxiosError);
    }
  };

  const saveUsers = async (
    groupId: string,
    request: PostUserRequest,
  ): Promise<MessageResponseModel> => {
    try {
      const result = await client.post<
        MessageResponseModel,
        AxiosResponse<MessageResponseModel>
      >(USERS_GROUPS_BASE_URL(groupId), request, {
        withCredentials: true,
      });

      return result.data;
    } catch (e) {
      throw getExceptionFromAxiosError(e as AxiosError);
    }
  };

  const updateGroup = async (request: UpdateGroupRequest): Promise<Group> => {
    try {
      const result = await client.patch<Group, AxiosResponse<Group>>(
        `${GROUPS_BASE_URL}/${request.groupId}`,
        request.updates,
        {
          withCredentials: true,
        },
      );

      return result.data;
    } catch (e) {
      throw getExceptionFromAxiosError(e as AxiosError);
    }
  };

  const deleteGroup = async (
    accountId: string,
  ): Promise<MessageResponseModel> => {
    try {
      const result = await client.delete<
        MessageResponseModel,
        AxiosResponse<MessageResponseModel>
      >(`${GROUPS_BASE_URL}/${accountId}`, {
        withCredentials: true,
      });

      return new MessageResponseModel(result.data);
    } catch (e) {
      throw e as AxiosError;
    }
  };

  const deleteUser = async (
    groupId: string,
    userId: string,
  ): Promise<MessageResponseModel> => {
    try {
      const result = await client.delete<
        MessageResponseModel,
        AxiosResponse<MessageResponseModel>
      >(`${USERS_GROUPS_BASE_URL(groupId)}/${userId}`, {
        withCredentials: true,
      });

      return new MessageResponseModel(result.data);
    } catch (e) {
      throw e as AxiosError;
    }
  };

  return {
    getGroups,
    getGroup,
    saveGroup,
    saveUsers,
    updateGroup,
    deleteGroup,
    deleteUser,
  };
};
