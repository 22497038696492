import { equal } from '@/api/helpers';
import { Account } from '@/client/accounts';
import { SendTestEmailFormValues } from '@/client/campaigns';
import { CourseEntityScheduleEnum } from '@/client/courses';
import { SecurityCultureFormValues } from '@/client/security-culture/types';
import { LoadingStatuses } from '@/common/constants';
import { FormikDropdown, FormikInput } from '@/components/form';
import {
  BranchesMultiselectInput,
  GroupsMultiselectInput,
  UsersMultiselectInput,
} from '@/components/form/selectors';
import { useAppSelector } from '@/hooks/store';
import { selectCurrentUser } from '@/store/features/users';
import { AppButton } from '@/ui/buttons';
import { FlexContainer, FormContainer } from '@/ui/styled-ui';
import { coursesEnrollToOptions } from '@/utils/helpers';
import { Field, Form, Formik } from 'formik';
import { Chip } from 'primereact/chip';
import { DropdownChangeEvent } from 'primereact/dropdown';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { SendTestEmailModal } from '../campaigns';
import { securityCultureSchema } from './validations';

const StyledChip = styled(Chip)`
  height: var(--default-input-height);
  border-radius: var(--default-border-radius);
  padding: 10px var(--default-padding);
  font-size: var(--small-font-size);
  line-height: var(--small-line-height);
  background-color: var(--beige-main);
  color: var(--black-main);
`;

type SecurityCultureFormProps = {
  onSubmit: (data: SecurityCultureFormValues) => void;
  onSendTestEmail: (
    data: SecurityCultureFormValues,
    test: SendTestEmailFormValues,
  ) => void;
  account: Account;
  loading: LoadingStatuses;
};

export const SecurityCultureForm: React.FC<SecurityCultureFormProps> = ({
  onSubmit,
  onSendTestEmail,
  account,
  loading,
}) => {
  const { t } = useTranslation();
  const currentUser = useAppSelector(selectCurrentUser);
  const [testEmail, setTestEmail] = useState(false);
  const [initialValues] = useState<SecurityCultureFormValues>({
    url: '',
    target: CourseEntityScheduleEnum.ACCOUNT,
    branches: [],
    groups: [],
    users: [],
  });

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={securityCultureSchema(t)}
      validateOnMount
      onSubmit={(data) => onSubmit(data)}
    >
      {({ setFieldValue, setFieldTouched, values, errors, touched }) => {
        return (
          <FormContainer>
            <Form className="w-full">
              <div className="field w-full mb-4">
                <Field
                  id="url"
                  name="url"
                  label={t('security.culture.surveyUrl')}
                  className="w-full"
                  component={FormikInput}
                  disabled={loading === LoadingStatuses.LOADING}
                  required
                />
              </div>

              <div className="field w-full mb-4">
                <Field
                  id="target"
                  name="target"
                  label={t('security.culture.target')}
                  className="w-full"
                  component={FormikDropdown}
                  onChange={(e: DropdownChangeEvent) => {
                    setFieldValue('target', e.value);
                    setFieldValue('users', []);
                    setFieldValue('branches', []);
                    setFieldValue('groups', []);
                  }}
                  options={coursesEnrollToOptions(t)}
                  disabled={loading === LoadingStatuses.LOADING}
                />
              </div>

              {values.target === CourseEntityScheduleEnum.ACCOUNT && (
                <div className="field w-full">
                  <label htmlFor="account">
                    {t('account')} <span className="red"> *</span>
                  </label>
                  <StyledChip className="w-full" label={account?.name} />
                </div>
              )}

              {values.target === CourseEntityScheduleEnum.BRANCH && (
                <div className="field w-full">
                  <label
                    htmlFor="branches"
                    className={
                      errors.branches && touched.branches ? 'p-error' : ''
                    }
                  >
                    {`${t('branches')} (${values?.branches?.length ?? 0})`}
                    <span className="red"> *</span>
                  </label>
                  <BranchesMultiselectInput
                    className="w-full"
                    onChange={(branches) => setFieldValue('branches', branches)}
                    onBlur={() => setFieldTouched('branches', true)}
                    additionalFilters={[
                      equal('account', account?.id),
                      equal('active', true),
                    ]}
                    selectedOptions={values.branches}
                    isDisabled={loading === LoadingStatuses.LOADING}
                  />
                  {touched.branches && !!errors.branches && (
                    <small className="p-error">{errors.branches}</small>
                  )}
                </div>
              )}

              {values.target === CourseEntityScheduleEnum.GROUP && (
                <div className="field w-full">
                  <label
                    htmlFor="groups"
                    className={errors.groups && touched.groups ? 'p-error' : ''}
                  >
                    {`${t('groups')} (${values?.groups?.length ?? 0})`}
                    <span className="red"> *</span>
                  </label>
                  <GroupsMultiselectInput
                    className="w-full"
                    onChange={(groups) => setFieldValue('groups', groups)}
                    onBlur={() => setFieldTouched('groups', true)}
                    additionalFilters={[
                      equal('account', account?.id),
                      equal('active', true),
                    ]}
                    selectedOptions={values.groups}
                    isDisabled={loading === LoadingStatuses.LOADING}
                  />
                  {touched.groups && !!errors.groups && (
                    <small className="p-error">{errors.groups}</small>
                  )}
                </div>
              )}

              {values.target === CourseEntityScheduleEnum.USER && (
                <div className="field w-full">
                  <label
                    htmlFor="users"
                    className={errors.users && touched.users ? 'p-error' : ''}
                  >
                    {`${t('users')} (${values?.users?.length ?? 0})`}
                    <span className="red"> *</span>
                  </label>
                  <UsersMultiselectInput
                    className="w-full"
                    onChange={(users) => {
                      setFieldValue('users', users);
                    }}
                    onBlur={() => setFieldTouched('users', true)}
                    additionalFilters={[
                      equal('account', account?.id),
                      equal('active', true),
                    ]}
                    selectedOptions={values.users}
                    isDisabled={loading === LoadingStatuses.LOADING}
                  />
                  {touched.users && !!errors.users && (
                    <small className="p-error">{errors.users}</small>
                  )}
                </div>
              )}

              <FlexContainer
                justify="space-between"
                className="mt-5"
                wrap="wrap"
                gap={12}
              >
                <AppButton
                  label={t('camapign.testEmail.send')}
                  severity="secondary"
                  type="outlined"
                  onClick={() => setTestEmail(true)}
                  isDisabled={!!Object.keys(errors).length}
                  state={loading}
                />
                <AppButton
                  label={t('security.culture.sendSurveyTarget')}
                  isSubmit
                  isDisabled={!!Object.keys(errors).length}
                  state={loading}
                />
              </FlexContainer>

              <SendTestEmailModal
                email={currentUser?.email as string}
                visible={testEmail}
                onSubmit={(test: SendTestEmailFormValues) => {
                  onSendTestEmail(values, test);
                  setTestEmail(false);
                }}
                onHide={() => setTestEmail(false)}
                extended={false}
                withName
              />
            </Form>
          </FormContainer>
        );
      }}
    </Formik>
  );
};
