import { handleAxiosError } from '@/api/helpers';
import { CreateCampaignFromTemplateFormState } from '@/client/campaigns';
import {
  LoadingStatuses,
  RedirectPaths,
  RedirectPathsEnum,
} from '@/common/constants';
import { useCloneCampaign } from '@/hooks/query';
import { useAppSelector } from '@/hooks/store';
import { useToast } from '@/hooks/useToast';
import { selectCurrentAccount } from '@/store/features/account';
import { AppButton } from '@/ui/buttons';
import { FlexContainer } from '@/ui/styled-ui';
import { AxiosError } from 'axios';
import { Form, Formik, FormikValues } from 'formik';
import { Dialog, DialogProps } from 'primereact/dialog';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { CampaignTemplatesList } from './CampaignTemplatesList';
import { PreviewCampaignTemplate } from './PreviewCampaignTemplate';

const StyledDialog = styled(Dialog)`
  @media screen and (min-width: 1133px) {
    width: 1132px;
  }
  @media screen and (max-width: 1132px) {
    width: 800px;
  }
  @media screen and (max-width: 800px) {
    width: 600px;
  }
  max-width: 1132px;
  height: 848px;
`;

const StyledName = styled.span`
  color: var(--red-main);
`;

const initialValues: CreateCampaignFromTemplateFormState = {
  selectedCampaign: undefined,
};

export const CampaignTemplatesModal: React.FC<DialogProps> = ({
  onHide,
  visible,
}) => {
  const { t } = useTranslation();
  const [showList, setShowList] = useState(true);
  const currentAccount = useAppSelector(selectCurrentAccount);
  const navigate = useNavigate();
  const toast = useToast();
  const [isLoading, setIsLoading] = useState<LoadingStatuses>(
    LoadingStatuses.IDLE,
  );

  const cloneCampaign = useCloneCampaign();

  const handleCreateNewCampaignFromTemplate = async (data: FormikValues) => {
    try {
      if (!currentAccount || !data?.selectedCampaign) return;
      setIsLoading(LoadingStatuses.LOADING);

      const response = await cloneCampaign.clone({
        campaignId: data?.selectedCampaign?.id,
        account: currentAccount?.id,
      });

      setIsLoading(LoadingStatuses.IDLE);

      if (response && !response?.id) return;

      navigate(RedirectPaths[RedirectPathsEnum.CAMPAIGNS_EDIT](response?.id), {
        state: { isTemplate: false },
      });
      toast?.success(t('toast.success'), t('campaign.created'));
    } catch (e) {
      handleAxiosError(e as Error | AxiosError, toast);
      setIsLoading(LoadingStatuses.IDLE);
    }
  };

  return (
    <>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        onSubmit={handleCreateNewCampaignFromTemplate}
      >
        {({ values, setFieldValue, errors, submitForm }) => {
          const footer = (
            <FlexContainer wrap="wrap" width="100%" justify="flex-end">
              {showList ? (
                <AppButton
                  label={t('generic.preview')}
                  severity="secondary"
                  onClick={() => setShowList(false)}
                  isDisabled={
                    !values.selectedCampaign || !!Object.keys(errors).length
                  }
                />
              ) : (
                <AppButton
                  label={t('generic.preview.close')}
                  severity="secondary"
                  icon="pi pi-times"
                  iconPos="right"
                  onClick={() => setShowList(true)}
                />
              )}
              <FlexContainer
                justify="flex-end"
                className="mt-auto pb-2"
                wrap="wrap"
                flex="1"
              >
                <AppButton
                  label={t('button.cancel')}
                  severity="secondary"
                  type="outlined"
                  onClick={() => {
                    onHide();
                    setFieldValue('selectedCampaign', '');
                    setShowList(true);
                  }}
                  className="mr-3"
                />
                <AppButton
                  label={t('campaign.create')}
                  onClick={submitForm}
                  state={isLoading}
                  isDisabled={
                    !values.selectedCampaign || !!Object.keys(errors).length
                  }
                />
              </FlexContainer>
            </FlexContainer>
          );

          return (
            <Form id="campaign-template-form-container">
              <StyledDialog
                blockScroll
                visible={visible}
                header={
                  values.selectedCampaign ? (
                    <h1>
                      {t('campaigns.fromTemplate')}:{' '}
                      <StyledName>{values.selectedCampaign.name}</StyledName>
                    </h1>
                  ) : (
                    <h1>{t('campaigns.fromTemplate')}</h1>
                  )
                }
                footer={footer}
                onHide={onHide}
                draggable={false}
              >
                {showList ? (
                  <CampaignTemplatesList
                    values={values}
                    setFieldValue={setFieldValue}
                  />
                ) : (
                  <PreviewCampaignTemplate values={values} />
                )}
              </StyledDialog>
            </Form>
          );
        }}
      </Formik>
    </>
  );
};
