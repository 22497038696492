import { equal, handleAxiosError } from '@/api/helpers';
import { AppBreadCrumbTemplate } from '@/app/AppBreadCrumbTemplate';
import { Group } from '@/client/groups';
import { getFiltersFromColumns } from '@/client/helpers';
import { Actions, Subjects } from '@/client/users';
import {
  RedirectPaths,
  RedirectPathsEnum,
  TableNamesEnum,
} from '@/common/constants';
import { DialogContext } from '@/common/context';
import {
  DataTable,
  DataTableActions,
  DataTableColumnsMultiselect,
  DataTableColumnType,
  DataTableFilters,
  DataTableToolbar,
  FilterTypeEnum,
} from '@/components/tables/crud';
import { useDeleteGroup, useGroups, useUpdateGroup } from '@/hooks/query';
import { useAppSelector } from '@/hooks/store';
import { useTable } from '@/hooks/table.hook';
import { usePermission } from '@/hooks/usePermission';
import { useToast } from '@/hooks/useToast';
import { selectCurrentAccount } from '@/store/features/account';
import { AppBreadCrumb } from '@/ui/breadcrumb';
import { AppButton } from '@/ui/buttons';
import { AppChip } from '@/ui/chip';
import { FormatDate } from '@/ui/date';
import { FlexContainer } from '@/ui/styled-ui';
import { AxiosError } from 'axios';
import { DataTableRowClickEvent } from 'primereact/datatable';
import { MenuItem } from 'primereact/menuitem';
import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

export const GroupsPage = () => {
  const { t } = useTranslation();
  const toast = useToast();
  const { can } = usePermission();
  const { skip, take, sort, apiFilters, onFilter, onSort, onPage } = useTable();

  const account = useAppSelector(selectCurrentAccount);

  const navigate = useNavigate();

  const { setDialogData } = useContext(DialogContext);

  const { isLoading, groups, refetch } = useGroups({
    take,
    skip,
    filters: [...apiFilters, equal('account', account?.id as string)],
    sort: sort && sort.length > 0 ? [sort.join(',')] : [],
  });
  const updateGroup = useUpdateGroup();
  const deleteGroup = useDeleteGroup();

  const menuItems = (group: Group) => {
    const menu: MenuItem[] = [
      {
        label: t('users'),
        icon: 'pi pi-users',
        command: () =>
          navigate(RedirectPaths[RedirectPathsEnum.GROUPS_USERS](group.id)),
      },
    ];

    if (can(Actions.UPDATE, Subjects.GROUPS)) {
      menu.push(
        ...[
          {
            items: [
              {
                label: t('generic.edit'),
                icon: 'pi pi-pencil',
                command: () => {
                  navigate(
                    RedirectPaths[RedirectPathsEnum.GROUPS_EDIT](group.id),
                  );
                },
              },
              {
                label: t('generic.delete'),
                icon: 'pi pi-times',
                command: () =>
                  setDialogData({
                    type: 'confirmation',
                    show: true,
                    header: t('dialog.delete'),
                    message: t('dialog.delete.group'),
                    onAccept: async () => handleDeleteUserGroups(group.id),
                  }),
              },
            ],
          },
        ],
      );
    }

    return menu;
  };

  const columns: DataTableColumnType[] = [
    {
      field: 'name',
      header: t('generic.name'),
      sortable: true,
      filterable: true,
      filters: { type: FilterTypeEnum.TEXT },
    },
    {
      field: 'updated',
      header: t('generic.updated'),
      sortable: true,
      filterable: false,
      render: (row: Group) =>
        row?.updated ? <FormatDate date={row?.updated} /> : t('generic.never'),
    },
    {
      field: 'autoAssign',
      header: t('group.autoAssign'),
      sortable: false,
      filterable: true,
      filters: {
        type: FilterTypeEnum.SELECT_YES_NO,
        placeholder: t('generic.select'),
      },
      render: (row: Group) =>
        row.autoAssign ? (
          <AppChip label={t('dialog.yes')} type="primary" />
        ) : (
          <AppChip label={t('dialog.no')} type="secondary" />
        ),
    },
    {
      field: 'actions',
      header: t('generic.actions'),
      filterable: false,
      sortable: false,
      style: {
        width: '80px',
        textAlign: 'center' as const,
      },
      render: (row: Group) => (
        <DataTableActions
          disabled={menuItems(row).length < 1}
          menuItems={menuItems(row)}
        />
      ),
    },
  ];

  // Set the preselected columns
  const [visibleColumns, setVisibleColumns] = useState<string[]>([]);
  const defaultVisibleColumns = columns.map((column) => column.field);
  const alwaysVisibleColumns = ['name', 'actions'];
  //

  const handleToggleItemsStatus = async (groupId: string, active?: boolean) => {
    try {
      await updateGroup.update({
        groupId,
        updates: { active: !active },
      });
      await refetch();
      toast?.success(
        t('toast.success'),
        active ? t('group.deactivated') : t('group.activated'),
      );
    } catch (e) {
      handleAxiosError(e as Error | AxiosError, toast);
    }
  };

  const handleDeleteUserGroups = async (groupId: string) => {
    try {
      await deleteGroup.delete(groupId);
      await refetch();

      toast?.success(t('toast.success'), t('group.deleted'));
    } catch (e) {
      handleAxiosError(e as Error | AxiosError, toast);
    }
  };

  const onRowClick = (e: DataTableRowClickEvent) =>
    navigate(RedirectPaths[RedirectPathsEnum.GROUPS_EDIT](e.data.id));

  const toolbar = (
    <DataTableToolbar>
      <FlexContainer
        justify="space-between"
        gap={8}
        align="flex-start"
        wrap="wrap"
      >
        <DataTableFilters
          filters={getFiltersFromColumns(columns)}
          onFilter={onFilter}
        />
        {can(Actions.CREATE, Subjects.GROUPS) && (
          <AppButton
            label={t('button.createNew')}
            severity="secondary"
            onClick={() => {
              navigate(RedirectPaths[RedirectPathsEnum.GROUPS_CREATE]());
            }}
          />
        )}
      </FlexContainer>
      <DataTableColumnsMultiselect
        columns={columns}
        tableName={TableNamesEnum.GROUPS_LIST}
        visibleColumns={visibleColumns}
        setVisibleColumns={setVisibleColumns}
        defaultVisibleColumns={defaultVisibleColumns}
        alwaysVisibleColumns={alwaysVisibleColumns}
      />
    </DataTableToolbar>
  );

  const pathItems = [
    {
      label: account?.name,
      url: RedirectPaths[RedirectPathsEnum.EDIT_ACCOUNT](account?.id as string),
      template: AppBreadCrumbTemplate,
    },
    {
      label: t('groups'),
      url: RedirectPaths[RedirectPathsEnum.GROUPS](),
      template: AppBreadCrumbTemplate,
    },
  ];

  return (
    <>
      <AppBreadCrumb model={pathItems} />
      <h1>{t('groups')}</h1>
      <DataTable
        data={groups?.result}
        count={groups?.count as number}
        isLoading={isLoading}
        toolbar={toolbar}
        columns={columns}
        visibleColumns={visibleColumns}
        onPage={onPage}
        rows={take}
        skip={skip}
        onSort={onSort}
        sort={sort}
        onRowClick={onRowClick}
      />
    </>
  );
};
