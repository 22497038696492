import { RedirectPaths, RedirectPathsEnum } from '@/common/constants';
import classNames from 'classnames';
import { MenuItem } from 'primereact/menuitem';
import { TabMenu } from 'primereact/tabmenu';
import React, { HTMLAttributes, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';

const getItemTemplate = (url: string, current: string) => (item: MenuItem) =>
  (
    <Link
      to={url}
      className={classNames({
        'p-menuitem-link': true,
        'p-menuitem-link-active': current.includes(url),
      })}
      role="menuitem"
    >
      <span className="p-menuitem-text">{item.label}</span>
    </Link>
  );

export const SystemEmailsTabs: React.FC<HTMLAttributes<HTMLUListElement>> = ({
  className,
}) => {
  const { t } = useTranslation();
  const location = useLocation();

  const menuItems = () => {
    const items: MenuItem[] = [
      {
        label: t('generic.email.templates'),
        template: getItemTemplate(
          RedirectPaths[RedirectPathsEnum.SYSTEM_EMAILS_TEMPLATES](),
          location.pathname,
        ),
      },
      {
        label: t('generic.sending.configs'),
        template: getItemTemplate(
          RedirectPaths[RedirectPathsEnum.SYSTEM_EMAILS_SENDING_CONFIGS](),
          location.pathname,
        ),
      },
      {
        label: t('generic.email.configs'),
        template: getItemTemplate(
          RedirectPaths[RedirectPathsEnum.SYSTEM_EMAILS_TYPES_CONFIGS](),
          location.pathname,
        ),
      },
    ];

    return items;
  };

  const [activeIndex, setActiveIndex] = useState<number>(0);

  useEffect(() => {
    switch (location.pathname) {
      case RedirectPaths[RedirectPathsEnum.SYSTEM_EMAILS_SENDING_CONFIGS]():
        setActiveIndex(1);
        break;
      case RedirectPaths[RedirectPathsEnum.SYSTEM_EMAILS_TYPES_CONFIGS]():
        setActiveIndex(2);
        break;
      default:
        setActiveIndex(0);
    }
  }, [location.pathname]);

  return (
    <TabMenu
      className={className}
      model={menuItems()}
      activeIndex={activeIndex}
      onTabChange={() => null}
    />
  );
};
