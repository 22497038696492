import { FiltersType } from '@/api/types';
import { client } from '@/client';
import { MessageResponseModel } from '@/client/models';
import {
  ListSenderProfilesResponse,
  SaveSenderProfileRequest,
  SenderProfile,
  UpdateSenderProfileRequest,
} from '@/client/sender-profiles';
import {
  DeleteSenderProfileMutation,
  GenericForbiddenApiError,
  SaveSenderProfileMutation,
} from '@/hooks/query';
import {
  useMutation,
  useQuery,
  UseQueryOptions,
  UseQueryResult,
} from 'react-query';

const queryKey = 'sender-profiles';
const singleQueryKey = 'sender-profile';

export const useSenderProfiles = (
  params: UseQueryOptions & {
    take?: number;
    skip?: number;
    sort?: string[];
    filters?: FiltersType;
  },
): UseQueryResult<ListSenderProfilesResponse> & {
  senderProfiles: ListSenderProfilesResponse | undefined;
} => {
  const {
    take = 5,
    skip = 0,
    sort,
    filters,
    retry = false,
    retryDelay = 500,
    staleTime = 0,
    cacheTime = 0,
    ...restOptions
  } = params;

  const { data, ...rest } = useQuery(
    [queryKey, take, skip, JSON.stringify(sort), JSON.stringify(filters)],
    () => client.senderProfiles.getSenderProfiles(params),
    {
      retry,
      retryDelay,
      staleTime,
      cacheTime,
      ...(restOptions as any),
    },
  );

  return {
    senderProfiles: data,
    ...(rest as UseQueryResult<ListSenderProfilesResponse>),
  };
};

export const useSenderProfile = (
  params: UseQueryOptions & {
    senderProfileId?: string;
  },
): UseQueryResult<SenderProfile> & {
  senderProfile: SenderProfile | undefined;
} => {
  const {
    senderProfileId,
    retry = false,
    retryDelay = 500,
    staleTime = 0,
    cacheTime = 0,
    ...restOptions
  } = params;

  const { data, ...rest } = useQuery(
    [singleQueryKey, senderProfileId],
    () =>
      senderProfileId
        ? client.senderProfiles.getSenderProfile(senderProfileId)
        : undefined,
    {
      retry,
      retryDelay,
      staleTime,
      cacheTime,
      ...(restOptions as UseQueryResult<SenderProfile>),
    },
  );

  return {
    senderProfile: data,
    ...(rest as UseQueryResult<SenderProfile>),
  };
};

export const useSaveSenderProfile = (): {
  create: (payload: SaveSenderProfileRequest) => Promise<SenderProfile>;
} & SaveSenderProfileMutation => {
  const { mutateAsync, ...rest } = useMutation<
    SenderProfile,
    GenericForbiddenApiError,
    SaveSenderProfileRequest
  >('addSenderProfile', (payload: SaveSenderProfileRequest) =>
    client.senderProfiles.saveSenderProfile(payload),
  );

  return { create: mutateAsync, ...(rest as any) };
};

export const useUpdateSenderProfile = (): {
  update: (payload: UpdateSenderProfileRequest) => Promise<SenderProfile>;
} & SaveSenderProfileMutation => {
  const { mutateAsync, ...rest } = useMutation<
    SenderProfile,
    GenericForbiddenApiError,
    UpdateSenderProfileRequest
  >('updateSenderProfile', (payload: UpdateSenderProfileRequest) =>
    client.senderProfiles.updateSenderProfile(payload),
  );

  return { update: mutateAsync, ...(rest as any) };
};

export const useDeleteSenderProfile = (): {
  delete: (senderProfileId: string) => Promise<MessageResponseModel>;
} & DeleteSenderProfileMutation => {
  const { mutateAsync, ...rest } = useMutation<
    MessageResponseModel,
    GenericForbiddenApiError,
    string
  >('deleteSenderProfile', (senderProfileId: string) =>
    client.senderProfiles.deleteSenderProfile(senderProfileId),
  );

  return { delete: mutateAsync, ...(rest as any) };
};
