import { RedirectPaths, RedirectPathsEnum } from '@/common/constants';
import { useAppSelector } from '@/hooks/store';
import { selectCurrentAccount } from '@/store/features/account';
import classNames from 'classnames';
import { MenuItem } from 'primereact/menuitem';
import { TabMenu } from 'primereact/tabmenu';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';

const getItemTemplate = (url: string, current: string) => (item: MenuItem) =>
  (
    <Link
      to={url}
      className={classNames({
        'p-menuitem-link': true,
        'p-menuitem-link-active': current.includes(url),
      })}
      role="menuitem"
    >
      <span className="p-menuitem-text">{item.label}</span>
    </Link>
  );

export const EmailTemplatesTabs: React.FC = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const currentAccount = useAppSelector(selectCurrentAccount);

  const tabItems: MenuItem[] = [
    {
      label: t('generic.predefined'),
      template: getItemTemplate(
        RedirectPaths[RedirectPathsEnum.EMAIL_TEMPLATES_PREDEFINED](),
        location.pathname,
      ),
    },
  ];

  if (currentAccount && !currentAccount.isSystem) {
    tabItems.push({
      label: t('generic.custom'),
      template: getItemTemplate(
        RedirectPaths[RedirectPathsEnum.EMAIL_TEMPLATES_CUSTOM](),
        location.pathname,
      ),
    });
  }

  const [activeIndex, setActiveIndex] = useState<number>(0);

  useEffect(() => {
    const locationIndex = {
      [RedirectPaths[RedirectPathsEnum.EMAIL_TEMPLATES_PREDEFINED]()]: 0,
      [RedirectPaths[RedirectPathsEnum.EMAIL_TEMPLATES_CUSTOM]()]: 1,
    };
    setActiveIndex(locationIndex[location.pathname] ?? 0);
  }, [location.pathname]);

  return (
    <TabMenu
      model={tabItems}
      activeIndex={activeIndex}
      onTabChange={() => null}
    />
  );
};
