import {
  PlannerCourseSchedule,
  PlannerCRUDCourse,
} from '@/client/planner/types';
import { updateTimeline } from '@/utils/planner';
import { t } from 'i18next';
import { ScrollPanel } from 'primereact/scrollpanel';
import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { PlannerImageCard } from './PlannerImageCard';

const StyledScrollPanel = styled(ScrollPanel)`
  width: 100%;
  height: 190px;
`;

const StyledLabel = styled.label`
  display: block;
  color: var(--gray-darker);
  font-size: var(--xsmall-font-size);
  margin-bottom: var(--medium-padding);
  position: relative;

  span {
    position: relative;
    z-index: 9;
    background-color: var(--beige-main);
    padding-right: 6px;
  }

  &:after {
    content: '';
    display: inline-block;
    position: absolute;
    width: 1px;
    left: 0;
    top: var(--xsmall-padding);
    height: 1px;
    border-top: 2px dotted var(--gray-dark);
    width: 100%;
    vertical-align: middle;
  }
`;

const StyledDiv = styled.div`
  margin: 0 var(--xsmall-padding);

  &.date-header {
    position: relative;

    &:before {
      content: '';
      display: inline-block;
      position: absolute;
      width: 1px;
      left: -17px;
      height: 100%;
      background-color: var(--gray-dark);
      vertical-align: middle;
    }
  }
`;

const StyledItemDiv = styled.div`
  display: flex;

  &.red {
    .date-span {
      color: var(--red-main);
    }

    .date-header {
      &:before {
        background-color: var(--red-main);
      }
    }
  }
`;

const StyledDateDiv = styled.div`
  display: flex;
  width: var(--xsmall-line-height);

  > span {
    font-size: var(--xsmall-font-size);
    width: 100%;
    writing-mode: tb-rl;
    transform: rotate(-180deg);
    text-align: center;
    color: var(--gray-darker);
    margin: auto;
    background-color: var(--beige-main);
    z-index: 99;
    padding: 6px 0;
  }
`;

const StyledPlaceholderDiv = styled.div`
  width: 160px;
  height: 170px;
  border: 2px dashed var(--gray-dark);
  border-radius: var(--xsmall-border-radius);
`;

type PlannerTimelineProps = {
  items: PlannerCourseSchedule[];
  label: string;
  isNewUsers?: boolean;
  isPlaceholder?: boolean;
};

export const PlannerTimeline: React.FC<PlannerTimelineProps> = ({
  items,
  label,
  isNewUsers,
  isPlaceholder,
}) => {
  const scrollPanelRef = useRef<any>(null);

  const [timelineItems, setTimelineItems] = useState<PlannerCRUDCourse[]>([]);

  useEffect(() => {
    const firstNonPastDateIndex = updateTimeline(
      items.map((item: PlannerCourseSchedule) => ({
        ...item,
        ...item.course,
      })),
      setTimelineItems,
      isNewUsers,
    );

    if (!isPlaceholder) {
      setTimeout(() => {
        const scrollToElement = (index: number, offset = 0) => {
          if (!scrollPanelRef.current) return;

          const slider = scrollPanelRef.current.getElement();
          const content = slider.querySelector('.p-scrollpanel-content');
          const items = content.getElementsByClassName('item');

          if (index >= 0 && index < items.length) {
            const item = items[index] as HTMLElement;
            const sliderRect = slider.getBoundingClientRect();
            const itemRect = item.getBoundingClientRect();
            const itemLeft = itemRect.left - sliderRect.left;
            const scrollPosition = slider.scrollLeft + itemLeft - offset;

            content.scrollTo({
              left: scrollPosition,
              behavior: 'smooth',
            });
          }
        };

        scrollToElement(firstNonPastDateIndex || 0, 60);
      }, 250);
    }
  }, [items]);

  return (
    <div>
      <StyledLabel>
        <span>{label}</span>
      </StyledLabel>

      {!isPlaceholder && (
        <StyledScrollPanel ref={scrollPanelRef}>
          <div className="flex">
            {timelineItems.map((item, index) => (
              <StyledItemDiv
                key={index}
                className={item.firstNonPastDate && !isNewUsers ? 'red' : ''}
              >
                {item.isDateHeader && (
                  <StyledDateDiv>
                    <span className="date-span">{item.dateKey}</span>
                  </StyledDateDiv>
                )}
                <StyledDiv
                  className={
                    item.isDateHeader ? 'date-header relative' : 'relative'
                  }
                >
                  <PlannerImageCard item={item} isNewUsers={isNewUsers} />
                </StyledDiv>
              </StyledItemDiv>
            ))}
          </div>
        </StyledScrollPanel>
      )}

      {isPlaceholder && (
        <div className="flex overflow-hidden">
          <div className="flex">
            <StyledDateDiv>
              <span>{isNewUsers ? `0 ${t('days')}` : t('today')}</span>
            </StyledDateDiv>
            <StyledDiv className="date-header relative">
              <StyledPlaceholderDiv />
            </StyledDiv>
          </div>
        </div>
      )}
    </div>
  );
};
