import {
  EMAIL_REGEX,
  PASSWORD_REGEX,
  USERNAME_REGEX,
} from '@/common/constants';
import { TranslationFunctionType } from '@/common/types';
import { array, boolean, object, string } from 'yup';

export const systemEmailSendingConfigSchema = (t: TranslationFunctionType) =>
  object({
    senderName: string(),
    fromAddress: string()
      .required(t('error.requiredField'))
      .matches(EMAIL_REGEX, t('error.invalidEmail')),
    host: string().required(t('error.requiredField')),
    username: string().matches(USERNAME_REGEX, t('error.onlyLetters')),
    password: string()
      .matches(PASSWORD_REGEX, t('error.password.complexity'))
      .min(8, t('error.password.length', { length: 8 })),
    ignoreCertErrors: boolean().default(false),
  });

export const systemEmailsConfigSchema = (t: TranslationFunctionType) =>
  object({
    type: string().required(t('error.requiredField')),
    sendTime: array()
      .min(1, t('error.requiredField'))
      .required(t('error.requiredField')),
    active: boolean().default(false),
  });

export const systemEmailsTemplatesSchema = (t: TranslationFunctionType) =>
  object({
    type: string().required(t('error.requiredField')),
    language: string().required(t('error.requiredField')),
    active: boolean().default(false),
    subject: string()
      .max(190, t('error.subject.length', { length: 190 }))
      .required(t('error.requiredField')),
    text: string().required(t('error.requiredField')),
  });
