import { LanguagesEnum } from '@/api/enums';
import { handleAxiosError } from '@/api/helpers';
import { AppBreadCrumbTemplate } from '@/app/AppBreadCrumbTemplate';
import { client } from '@/client';
import {
  ScormCourseAttempt,
  ScormCourseDispatchResponse,
  ScormStatusAttemptEnum,
} from '@/client/scorm';
import { RedirectPaths, RedirectPathsEnum } from '@/common/constants';
import { TranslationFunctionType } from '@/common/types';
import { useCourse, useScormCourseAttempt } from '@/hooks/query';
import { useAppSelector } from '@/hooks/store';
import { useToast } from '@/hooks/useToast';
import { ScormLanguageChanger } from '@/scorm/components/ScormLanguageChanger';
import { ScormLanguageSelectScreen } from '@/scorm/components/ScormLanguageSelectScreen';
import { ScormProvider } from '@/scorm/context/ScormContext';
import { selectCurrentUser } from '@/store/features/users';
import { AppBreadCrumb } from '@/ui/breadcrumb';
import { AppButton } from '@/ui/buttons';
import { AppChip } from '@/ui/chip';
import { FlexContainer } from '@/ui/styled-ui';
import {
  getTranslatedScormStatusAttempt,
  getTranslationByLanguage,
  isUserLanguageAvailableInScorm,
  MaximizeIcon,
  MinimizeIcon,
} from '@/utils/helpers';
import { isIOS, isMobile } from '@/utils/helpers/ui.helper';
import { AxiosError } from 'axios';
import { MenuItem } from 'primereact/menuitem';
import { ProgressSpinner } from 'primereact/progressspinner';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import OrientationWarning from '../components/OrientationWarning';
import { ScormVersion } from '../types';

const StyledFlexContainer = styled(FlexContainer)`
  word-break: break-word;

  &.stretch {
    height: 100%;
  }

  @media screen and (max-width: 424px) {
    &.stretch {
      height: auto;
    }
  }
`;

const StyledButton = styled(AppButton)`
  width: 50px;
  height: 50px;
  padding: 0 !important;

  &.minimize-button,
  &.exit-button {
    position: absolute;
    left: var(--medium-padding);
    top: var(--medium-padding);
    z-index: 999;
  }

  &.minimize-button {
    background: var(--purple-middle);
    border-color: var(--purple-middle);
  }
`;

const StyledAppChip = styled(AppChip)`
  margin-left: var(--small-padding);

  &.mobile .p-chip-text {
    margin: var(--xxsmall-padding);
  }
`;

const StyledDiv = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: var(--small-padding);

  h1 {
    margin-bottom: 0;

    @media screen and (max-width: 767px) {
      font-size: var(--small-font-size);
      line-height: var(--small-line-height);
    }
  }
`;

const StyledIframeContainer = styled.div`
  position: relative;
  display: flex;
  margin: auto;
  width: 70%;

  &::before {
    content: '';
    width: 0;
    padding-bottom: 56.25%; /* 16:9 aspect ratio */
    display: block;
  }

  @media screen and (max-width: 767px) {
    & {
      width: 100%;
    }
  }
`;

const StyledIframe = styled.iframe`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  border: none;
`;

const getAppChipType = (status: string | undefined) => {
  switch (status) {
    case ScormStatusAttemptEnum.IN_PROGRESS:
      return 'secondary';
    case ScormStatusAttemptEnum.COMPLETED:
      return 'primary';
    case ScormStatusAttemptEnum.FAILED:
      return 'error';
    default:
      return 'secondary';
  }
};

export const ScormPlayerPage: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const toast = useToast();
  const { id: scormCourseId, attemptId } = useParams();
  const currentUser = useAppSelector(selectCurrentUser);
  const [status, setStatus] = useState<ScormStatusAttemptEnum>();
  const [isScormStarted, setIsScormStarted] = useState<boolean>(false);
  const [isUrlLoading, setIsUrlLoading] = useState<boolean>(false);
  const [scormDetails, setScormDetails] =
    useState<ScormCourseDispatchResponse>();

  const iframeRef = useRef<HTMLIFrameElement>(null);
  const [frameLoaded, setFrameLoaded] = useState(false);

  const lockLandscapeScreen = () =>
    (screen as any).orientation
      .lock('landscape-primary')
      .catch((e: Error) => toast?.error(t('error'), e.message));

  const unlockLandscapeScreen = () => (screen as any).orientation.unlock();

  useEffect(() => {
    if (!currentUser?.firstName || !frameLoaded || !isScormStarted) return;

    let interval: any = null;

    const setUserData = () => {
      if (
        typeof (iframeRef.current?.contentWindow as any)?.GetPlayer ===
        'undefined'
      ) {
        return;
      }

      const player = (iframeRef.current?.contentWindow as any)?.GetPlayer();

      if (player) {
        player.SetVar('Firstname', currentUser?.firstName);
        player.SetVar('ITperson', currentUser?.email);
        player.SetVar('useremail', currentUser?.email);
        player.SetVar('usermanager', currentUser?.meta?.manager ?? '');

        if (interval) clearInterval(interval);
      }
    };

    interval = setInterval(setUserData, 200);
  }, [frameLoaded, currentUser?.firstName, currentUser?.email, isScormStarted]);

  // Fetch course data
  const { course, isLoading: isCourseLoading } = useCourse({
    courseId: scormCourseId,
    staleTime: Infinity,
  });

  const { scormCourseAttempt, isLoading: isAttemptLoading } =
    useScormCourseAttempt({ scormCourseId, attemptId });

  const [selectedLanguage, setSelectedLanguage] = useState<
    LanguagesEnum | undefined
  >(undefined);

  const [attemptLanguage, setAttemptLanguage] = useState<
    LanguagesEnum | undefined
  >(undefined);

  useEffect(() => {
    if (
      (course?.selfHosted && course?.courseLanguages?.length === 1) ||
      !currentUser ||
      !course ||
      isAttemptLoading
    ) {
      return;
    }

    // Set initial preselected language if not available set to English
    setSelectedLanguage(
      scormCourseAttempt?.language
        ? scormCourseAttempt.language
        : currentUser?.language &&
          course &&
          isUserLanguageAvailableInScorm(
            course?.courseFiles,
            currentUser?.language as LanguagesEnum,
          )
        ? (currentUser?.language as LanguagesEnum)
        : LanguagesEnum.EN,
    );
  }, [currentUser, course, scormCourseAttempt]);

  const courseName = getTranslationByLanguage(
    course?.courseTranslations ?? [],
    currentUser?.language
      ? (currentUser?.language as LanguagesEnum)
      : LanguagesEnum.EN,
  )?.name;

  useEffect(() => {
    setStatus(scormCourseAttempt?.status);
  }, [scormCourseAttempt]);

  useEffect(() => {
    if (course?.selfHosted && course?.courseLanguages?.length === 1) {
      setIsScormStarted(true);
    }

    if (scormCourseAttempt?.language) {
      setIsScormStarted(true);
    }

    fetchScormUrl();
  }, [course, scormCourseAttempt]);

  useEffect(() => {
    fetchScormUrl();
  }, [selectedLanguage]);

  useEffect(() => {
    if (isScormStarted && frameLoaded && isMobile() && !isIOS()) {
      openFullscreen();
    }
  }, [isScormStarted, frameLoaded]);

  const getLanguage = () => {
    if (course?.selfHosted && course?.courseLanguages?.length === 1) {
      return course?.courseLanguages[0];
    }

    if (course && !course?.selfHosted) {
      return LanguagesEnum.EN;
    }

    return selectedLanguage;
  };

  const fetchScormUrl = async () => {
    const courseLanguage = getLanguage();

    if (course && courseLanguage) {
      try {
        setScormDetails(undefined);
        setIsUrlLoading(true);
        const data = await client.courses.getCourseUrl(
          course.id,
          courseLanguage,
        );
        setScormDetails(data);
        setAttemptLanguage(courseLanguage);
      } catch (e) {
        handleAxiosError(e as Error | AxiosError, toast);
      } finally {
        setIsUrlLoading(false);
      }
    }
  };

  const [isMaximized, setIsMaximized] = useState(false);
  const elem = document.getElementById('scorm-iframe') as any;
  const openFullscreen = () => {
    const callbacks = {
      // webkitEnterFullscreen: () => elem.webkitEnterFullscreen(), // for IOS but works only on iframe
      requestFullscreen: () => elem.requestFullscreen(),
      webkitRequestFullscreen: () => elem.webkitRequestFullscreen(),
      msRequestFullscreen: () => elem.msRequestFullscreen(),
    } as any;

    const key = Object.keys(callbacks).find((k) => !!elem[k]);
    key &&
      callbacks[key]()
        .then(() => {
          if (!isIOS()) {
            lockLandscapeScreen();
          }
          setIsMaximized(true);
        })
        .catch(() => {
          toast?.info(
            t('toast.info'),
            t('courses.manual.fullscreenMode.message'),
          );
        });
  };

  const closeFullscreen = () => {
    const doc = document as any;
    const callbacks = {
      exitFullscreen: () => doc.exitFullscreen(),
      webkitExitFullscreen: () => doc.webkitExitFullscreen(),
      msExitFullscreen: () => doc.msExitFullscreen(),
    } as any;

    const key = Object.keys(callbacks).find((k) => !!doc[k]);
    key &&
      callbacks[key]()
        .then(() => {
          if (!isIOS()) {
            unlockLandscapeScreen();
          }
          setIsMaximized(false);
        })
        .catch((e: Error) => toast?.error(t('error'), e.message));
  };

  const getBreadcrumbs = (t: TranslationFunctionType): MenuItem[] => [
    {
      label: t('courses'),
      url:
        location?.state?.from ||
        RedirectPaths[RedirectPathsEnum.LEARNER_DASHBOARD_ACTIVE](),
      template: AppBreadCrumbTemplate,
    },
    {
      label: courseName,
      url: RedirectPaths[RedirectPathsEnum.COURSE_INFO](course?.id ?? ''),
      template: AppBreadCrumbTemplate,
      data: { state: location?.state },
    },
  ];

  const navigateToCourseInfo = async () => {
    if (course) {
      navigate(RedirectPaths[RedirectPathsEnum.COURSE_INFO](course?.id), {
        state: location?.state,
      });
    }
  };

  return (
    <>
      {(!currentUser || isAttemptLoading || isCourseLoading) && (
        <FlexContainer direction="column" className="h-full">
          <FlexContainer direction="column" className="mt-5 flex-1">
            <ProgressSpinner />
            <h3>{t('course.loading')}</h3>
          </FlexContainer>
        </FlexContainer>
      )}
      {!isAttemptLoading && !isCourseLoading && currentUser && course && (
        <StyledFlexContainer
          direction="column"
          align="flex-start"
          className={!isScormStarted && course.selfHosted ? 'stretch' : ''}
          gap={24}
        >
          <AppBreadCrumb model={getBreadcrumbs(t)} className="no-space" />
          <FlexContainer justify="space-between" wrap="wrap">
            <StyledDiv>
              <AppButton
                type="outlined"
                icon="pi pi-arrow-left"
                size={isMobile() ? 'xs' : 'sm'}
                ariaLabel={t('course.player.back')}
                onClick={
                  isMobile()
                    ? () =>
                        navigate(
                          location?.state?.from ||
                            RedirectPaths[
                              RedirectPathsEnum.LEARNER_DASHBOARD_ACTIVE
                            ](),
                        )
                    : navigateToCourseInfo
                }
              />
              <h1>{courseName}</h1>
              <StyledAppChip
                className={isMobile() ? 'mobile' : ''}
                key={status}
                label={getTranslatedScormStatusAttempt(status)}
                type={getAppChipType(status)}
              />
            </StyledDiv>
            <StyledDiv>
              {isScormStarted &&
                course.selfHosted &&
                course?.courseLanguages?.length > 1 &&
                !isMobile() && (
                  <ScormLanguageChanger
                    language={selectedLanguage}
                    course={course}
                    shouldShowWarning={true}
                    onChange={(value) => setSelectedLanguage(value)}
                  />
                )}

              {isScormStarted && !isMobile() && (
                <AppButton
                  type="outlined"
                  icon="pi pi-times"
                  size={isMobile() ? 'xs' : 'sm'}
                  ariaLabel={t('courses.back')}
                  onClick={() =>
                    navigate(
                      location?.state?.from ||
                        RedirectPaths[
                          RedirectPathsEnum.LEARNER_DASHBOARD_ACTIVE
                        ](),
                    )
                  }
                />
              )}
            </StyledDiv>
          </FlexContainer>

          {(isScormStarted || !course.selfHosted) &&
            !isUrlLoading &&
            scormDetails && (
              <ScormProvider
                course={course}
                attempt={scormCourseAttempt as ScormCourseAttempt}
                attemptLanguage={attemptLanguage}
                version={scormDetails.version}
                user={currentUser}
                setStatus={setStatus}
                isPreview={false}
              >
                <StyledIframeContainer id="scorm-iframe">
                  {frameLoaded && <OrientationWarning />}

                  {isMaximized &&
                    isMobile() &&
                    !isIOS() &&
                    status !== ScormStatusAttemptEnum.COMPLETED && (
                      <StyledButton
                        className="minimize-button"
                        size="sm"
                        icon={<MinimizeIcon />}
                        onClick={closeFullscreen}
                      />
                    )}
                  {isMaximized &&
                    status === ScormStatusAttemptEnum.COMPLETED &&
                    isMobile() && (
                      <StyledButton
                        className="exit-button"
                        size="sm"
                        icon="pi pi-times"
                        onClick={() =>
                          navigate(
                            RedirectPaths[
                              RedirectPathsEnum.LEARNER_DASHBOARD_ACTIVE
                            ](),
                          )
                        }
                      />
                    )}
                  <StyledIframe
                    src={scormDetails.url}
                    ref={iframeRef}
                    onLoad={() => setFrameLoaded(true)}
                  />
                </StyledIframeContainer>
              </ScormProvider>
            )}

          {!isScormStarted &&
            course.selfHosted &&
            !scormCourseAttempt?.language && (
              <ScormLanguageSelectScreen
                course={course}
                language={selectedLanguage}
                isLoading={isUrlLoading}
                isDisabled={!scormDetails}
                setIsScormStarted={setIsScormStarted}
                setSelectedLanguage={setSelectedLanguage}
              />
            )}

          {isScormStarted &&
            course.selfHosted &&
            course?.courseLanguages?.length > 1 &&
            isMobile() && (
              <FlexContainer
                justify="space-between"
                className="w-full"
                gap={16}
              >
                <ScormLanguageChanger
                  className="player"
                  language={selectedLanguage}
                  course={course}
                  shouldShowWarning={true}
                  onChange={(value) => setSelectedLanguage(value)}
                />
                {!isIOS() && (
                  <StyledButton
                    icon={<MaximizeIcon />}
                    size="sm"
                    ariaLabel={t('courses.maximize')}
                    onClick={openFullscreen}
                  />
                )}
              </FlexContainer>
            )}
        </StyledFlexContainer>
      )}
    </>
  );
};
