import { CampaignFormValues } from '@/client/campaigns';
import { FormikInput, FormikSwitch } from '@/components/form';
import { AppButton } from '@/ui/buttons';
import { FlexContainer } from '@/ui/styled-ui';
import { Field } from 'formik';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

const StyledMessage = styled.span`
  color: var(--red-main);
`;

type InfoStepProps = {
  values: CampaignFormValues;
  valuesSetter: any;
};

export const InfoStep: React.FC<InfoStepProps> = ({ values, valuesSetter }) => {
  const { t } = useTranslation();
  const [canEditName, setCanEditName] = useState(false);

  return (
    <>
      <h2>{t('campaign.template.info')}</h2>
      <FlexContainer justify="flex-start" align="flex-end" className="mb-4">
        <div className="field w-6 m-0 relative">
          <Field
            id="name"
            name="name"
            label={t('generic.name')}
            value={values.name}
            className="w-full"
            onChange={(event: any) => valuesSetter('name', event.target.value)}
            component={FormikInput}
            disabled={!canEditName}
            data-testid="campaign-form-info-step-name"
          />
        </div>
        <AppButton
          icon="pi pi-pencil"
          type="text"
          onClick={() => setCanEditName(true)}
          isDisabled={canEditName}
          data-testid="campaign-form-info-step-enable-edit-name"
        />
      </FlexContainer>

      <div className="field-checkbox mb-4">
        <Field
          disabled={
            !values.emailTemplate ||
            !values.landingPage ||
            !values.senderProfile
          }
          inputId="isTemplate"
          name="isTemplate"
          label={
            !values.emailTemplate ||
            !values.landingPage ||
            !values.senderProfile ? (
              <>
                {t('campaign.template.isTemplate')}
                <StyledMessage>
                  {' '}
                  {`(${t('campaign.template.selectAll')})`}
                </StyledMessage>
              </>
            ) : (
              <>{t('campaign.template.isTemplate')}</>
            )
          }
          value={!!values.isTemplate}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            valuesSetter('isTemplate', e.target.value)
          }
          component={FormikSwitch}
        />
      </div>
    </>
  );
};
