import { Actions, Subjects, SystemRoles } from '@/client/users';
import { RedirectPaths, RedirectPathsEnum } from '@/common/constants';
import { useAppSelector } from '@/hooks/store';
import { usePermission } from '@/hooks/usePermission';
import { selectCurrentAccount } from '@/store/features/account';
import { selectCurrentUserState } from '@/store/features/users';
import classNames from 'classnames';
import { MenuItem } from 'primereact/menuitem';
import { TabMenu } from 'primereact/tabmenu';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';

type CoursesTabsProps = {
  courseId: string;
  isMaterial: boolean;
};

const getItemTemplate = (url: string, current: string) => (item: MenuItem) =>
  (
    <Link
      to={url}
      className={classNames({
        'p-menuitem-link': true,
        'p-menuitem-link-active': current.includes(url),
      })}
      role="menuitem"
    >
      <span className="p-menuitem-text">{item.label}</span>
    </Link>
  );

export const CoursesTabs: React.FC<CoursesTabsProps> = ({
  courseId,
  isMaterial,
}) => {
  const { t } = useTranslation();
  const { can } = usePermission();
  const location = useLocation();
  const account = useAppSelector(selectCurrentAccount);
  const { user } = useAppSelector(selectCurrentUserState);

  const items: MenuItem[] = [
    {
      label: t('generic.details'),
      template: getItemTemplate(
        isMaterial
          ? RedirectPaths[RedirectPathsEnum.POLICIES_AND_DOCUMENTS_EDIT](
              courseId,
            )
          : RedirectPaths[RedirectPathsEnum.COURSES_CONTENT](courseId),
        location.pathname,
      ),
      data: isMaterial
        ? RedirectPaths[RedirectPathsEnum.POLICIES_AND_DOCUMENTS_EDIT](courseId)
        : RedirectPaths[RedirectPathsEnum.COURSES_CONTENT](courseId),
    },
  ];

  if (!isMaterial) {
    items.push({
      label: t('course.scorm.preview'),
      template: getItemTemplate(
        RedirectPaths[RedirectPathsEnum.COURSES_SCORM_PREVIEW](courseId),
        location.pathname,
      ),
      data: RedirectPaths[RedirectPathsEnum.COURSES_SCORM_PREVIEW](courseId),
    });
  }

  items.push({
    label: t('generic.schedule'),
    template: getItemTemplate(
      isMaterial
        ? RedirectPaths[RedirectPathsEnum.POLICIES_AND_DOCUMENTS_SCHEDULE](
            courseId,
          )
        : RedirectPaths[RedirectPathsEnum.COURSES_SCHEDULE](courseId),
      location.pathname,
    ),
    data: isMaterial
      ? RedirectPaths[RedirectPathsEnum.POLICIES_AND_DOCUMENTS_SCHEDULE](
          courseId,
        )
      : RedirectPaths[RedirectPathsEnum.COURSES_SCHEDULE](courseId),
  });

  const isBranchAdmin =
    user?.role.code === SystemRoles.ADMIN && !!user?.branch?.id;

  if (
    can(Actions.UPDATE, Subjects.COURSE_ACCOUNT_SCHEDULES) &&
    !isBranchAdmin
  ) {
    items.push({
      label: t('accounts'),
      template: getItemTemplate(
        isMaterial
          ? RedirectPaths[RedirectPathsEnum.POLICIES_AND_DOCUMENTS_ACCOUNTS](
              courseId,
            )
          : RedirectPaths[RedirectPathsEnum.COURSES_ACCOUNTS](courseId),
        location.pathname,
      ),
      data: isMaterial
        ? RedirectPaths[RedirectPathsEnum.POLICIES_AND_DOCUMENTS_ACCOUNTS](
            courseId,
          )
        : RedirectPaths[RedirectPathsEnum.COURSES_ACCOUNTS](courseId),
    });
  }

  if (account?.isSystem && !isMaterial) {
    items.push({
      label: t('analytics'),
      template: getItemTemplate(
        RedirectPaths[RedirectPathsEnum.COURSES_ANALYTICS](courseId),
        location.pathname,
      ),
      data: RedirectPaths[RedirectPathsEnum.COURSES_ANALYTICS](courseId),
    });
  }

  if (
    !account?.isSystem &&
    can(Actions.UPDATE, Subjects.COURSE_BRANCH_SCHEDULES)
  ) {
    items.push({
      label: t('branches'),
      template: getItemTemplate(
        isMaterial
          ? RedirectPaths[RedirectPathsEnum.POLICIES_AND_DOCUMENTS_BRANCHES](
              courseId,
            )
          : RedirectPaths[RedirectPathsEnum.COURSES_BRANCHES](courseId),
        location.pathname,
      ),
      data: isMaterial
        ? RedirectPaths[RedirectPathsEnum.POLICIES_AND_DOCUMENTS_BRANCHES](
            courseId,
          )
        : RedirectPaths[RedirectPathsEnum.COURSES_BRANCHES](courseId),
    });
  }
  if (
    !account?.isSystem &&
    can(Actions.UPDATE, Subjects.COURSE_GROUP_SCHEDULES)
  ) {
    items.push({
      label: t('groups'),
      template: getItemTemplate(
        isMaterial
          ? RedirectPaths[RedirectPathsEnum.POLICIES_AND_DOCUMENTS_GROUPS](
              courseId,
            )
          : RedirectPaths[RedirectPathsEnum.COURSES_GROUPS](courseId),
        location.pathname,
      ),
      data: isMaterial
        ? RedirectPaths[RedirectPathsEnum.POLICIES_AND_DOCUMENTS_GROUPS](
            courseId,
          )
        : RedirectPaths[RedirectPathsEnum.COURSES_GROUPS](courseId),
    });
  }
  if (
    !account?.isSystem &&
    can(Actions.UPDATE, Subjects.COURSE_USER_SCHEDULES)
  ) {
    items.push({
      label: t('users'),
      template: getItemTemplate(
        isMaterial
          ? RedirectPaths[RedirectPathsEnum.POLICIES_AND_DOCUMENTS_USERS](
              courseId,
            )
          : RedirectPaths[RedirectPathsEnum.COURSES_USERS](courseId),
        location.pathname,
      ),
      data: isMaterial
        ? RedirectPaths[RedirectPathsEnum.POLICIES_AND_DOCUMENTS_USERS](
            courseId,
          )
        : RedirectPaths[RedirectPathsEnum.COURSES_USERS](courseId),
    });
  }

  const [activeIndex, setActiveIndex] = useState<number>(0);

  useEffect(() => {
    const paths = items.map((item) => item.data);
    const currentPath = location.pathname;
    const index = paths.indexOf(currentPath);

    if (index !== -1) {
      setActiveIndex(index);
    } else {
      setActiveIndex(0);
    }
  }, [location.pathname, courseId]);

  return (
    <TabMenu
      model={items}
      activeIndex={activeIndex}
      onTabChange={() => null}
      className="mt-5 flex-shrink-0"
    />
  );
};
