import { GroupCondition } from '@/api/enums';
import {
  equal,
  group as groupCondition,
  handleAxiosError,
  isIn,
  none,
} from '@/api/helpers';

import { AppBreadCrumbTemplate } from '@/app/AppBreadCrumbTemplate';
import { Account } from '@/client/accounts';
import { FilterNamesEnum } from '@/client/helpers';
import { Actions, Subjects, User } from '@/client/users';
import {
  LoadingStatuses,
  RedirectPaths,
  RedirectPathsEnum,
} from '@/common/constants';
import { DialogContext } from '@/common/context';
import {
  DataTableActions,
  DataTableColumnType,
} from '@/components/tables/crud';
import { UserDatatable } from '@/components/users/datatables';
import { BulkUsersModalForm } from '@/components/users/forms';
import { useAddUser, useGroup, useRemoveUser } from '@/hooks/query';
import { useAppSelector } from '@/hooks/store';
import { usePermission } from '@/hooks/usePermission';
import { useToast } from '@/hooks/useToast';
import { GroupsTabs } from '@/pages/groups/GroupsTabs';
import { selectCurrentAccount } from '@/store/features/account';
import { AppBreadCrumb } from '@/ui/breadcrumb';
import { queryStateConverter } from '@/utils/helpers';
import { AxiosError } from 'axios';
import { FormikHelpers, FormikValues } from 'formik';
import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { LoadingPage } from '../LoadingPage';

export const GroupUsers: React.FC = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const { can } = usePermission();
  const navigate = useNavigate();
  const location = useLocation();

  const [showBulkModal, setShowBulkModal] = useState(false);

  const { group, isLoading } = useGroup({ groupId: id });
  const account = useAppSelector(selectCurrentAccount);

  const filters = [
    equal('account', (account as Account)?.id),
    isIn('groups', [group?.id]),
  ];

  const [shouldRefetch, setShouldRefetch] = useState<boolean>(false);
  const onCreateOrDelete = () => {
    setShouldRefetch(true);

    setTimeout(() => {
      setShouldRefetch(false);
    }, 500);
  };

  const removeUser = useRemoveUser('groups');

  const pathItems = [
    {
      label: (account as Account)?.name,
      url: RedirectPaths[RedirectPathsEnum.EDIT_ACCOUNT](
        (account as Account)?.id,
      ),
      template: AppBreadCrumbTemplate,
    },
    {
      label: t('groups'),
      url: RedirectPaths[RedirectPathsEnum.GROUPS](),
      template: AppBreadCrumbTemplate,
    },
    {
      label: group?.name,
      url: RedirectPaths[RedirectPathsEnum.GROUPS_EDIT](id as string),
      template: AppBreadCrumbTemplate,
    },
    {
      label: t('users'),
      url: RedirectPaths[RedirectPathsEnum.GROUPS_USERS](id as string),
      template: AppBreadCrumbTemplate,
    },
  ];

  const { setDialogData } = useContext(DialogContext);

  const handleRemoveUser = async (userId: string) => {
    try {
      await removeUser.remove({ typeId: group?.id as string, userId });

      toast?.success(t('toast.success'), t('user.removed'));
      onCreateOrDelete();
    } catch (e) {
      handleAxiosError(e as Error | AxiosError, toast);
    }
  };

  const menuItems = (user: User) => [
    {
      label: t('generic.actions'),
      items: [
        {
          label: t('generic.edit'),
          icon: 'pi pi-pencil',
          command: () =>
            navigate(RedirectPaths[RedirectPathsEnum.USERS_EDIT](user.id), {
              state: {
                pathname: location.pathname,
                search: location.search,
              },
            }),
        },
        {
          label: t('generic.remove'),
          icon: 'pi pi-times',
          command: () =>
            setDialogData({
              type: 'confirmation',
              show: true,
              header: t('dialog.remove'),
              message: t('user.remove'),
              onAccept: async () => handleRemoveUser(user.id),
            }),
        },
      ],
    },
  ];

  const columns: DataTableColumnType[] = can(
    Actions.REMOVE_USER,
    Subjects.GROUPS,
  )
    ? [
        {
          field: 'actions',
          header: '',
          sortable: false,
          filterable: false,
          style: {
            width: '80px',
            textAlign: 'center',
          },
          render: (row: User) => (
            <DataTableActions
              disabled={menuItems(row).length < 1}
              menuItems={menuItems(row)}
            />
          ),
        },
      ]
    : [];

  const addUser = useAddUser('groups');
  const toast = useToast();

  const handleSubmit = async (
    data: FormikValues,
    helpers?: FormikHelpers<{ users?: User[] }>,
  ) => {
    try {
      await addUser.add({
        typeId: group?.id as string,
        userIds: data.users.map((user: User) => user.id),
      });

      toast?.success(
        t('toast.success'),
        data?.users && data.users?.length > 1
          ? t('users.added')
          : t('user.added'),
      );

      setShowBulkModal(false);
      helpers?.resetForm();
      onCreateOrDelete();
    } catch (e) {
      handleAxiosError(e as Error | AxiosError, toast);
    }
  };

  if (isLoading) {
    return <LoadingPage message={t('generic.loading')} />;
  }

  return (
    <>
      <AppBreadCrumb model={pathItems} />
      {group && (
        <>
          <h1>{group.name}</h1>
          <GroupsTabs group={group} />
        </>
      )}

      {can(Actions.ADD_USER, Subjects.GROUPS) && (
        <BulkUsersModalForm
          visible={showBulkModal}
          onHide={() => setShowBulkModal(false)}
          modalHeading={`${t('generic.addTo')} ${group?.name}`}
          initialValues={{ users: [] }}
          additionalFilters={[
            equal('active', true),
            equal('account', (account as Account)?.id),
            groupCondition(GroupCondition.OR, [
              none('groups', group?.id),
              equal('groups', null),
            ]),
          ]}
          onSubmit={handleSubmit}
          state={addUser ? queryStateConverter(addUser) : LoadingStatuses.IDLE}
        />
      )}

      <UserDatatable
        withToolbar
        withBranches
        withGroups={1}
        defaultFilters={filters}
        defaultTableFilters={{ [FilterNamesEnum.ACTIVE]: 'yes' }}
        additionalColumns={columns}
        shouldRefetch={shouldRefetch}
        setShowBulkModal={setShowBulkModal}
      />
    </>
  );
};
