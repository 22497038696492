import { Account, AccountCourseDelivery } from '@/client/accounts';
import { MaterialType } from '@/client/courses';
import { TranslationFunctionType } from '@/common/types';

export const accountHasMaterials = (account?: Account | null): boolean =>
  !!account?.availableCoursesByType &&
  !!Object.keys(account?.availableCoursesByType).find((x) =>
    [
      MaterialType.DOCUMENT as string,
      MaterialType.POSTER as string,
      MaterialType.TEMPLATE as string,
    ].includes(x),
  );

export const accountHasDocuments = (account: Account | null): boolean =>
  !!account?.availableCoursesByType &&
  !!Object.keys(account?.availableCoursesByType).find(
    (x) => (MaterialType.DOCUMENT as string) === x,
  );

export const courseDeliveryLmsOptions = (
  t: TranslationFunctionType,
  isFilter?: boolean,
) => [
  ...(isFilter
    ? [
        {
          label: t('cp.app'),
          value: AccountCourseDelivery.CP_APP,
        },
      ]
    : []),
  {
    label: t('account.courseDelivery.thirdParty'),
    value: AccountCourseDelivery.THIRD_PARTY,
  },
  {
    label: t('account.courseDelivery.videbarometer'),
    value: AccountCourseDelivery.VIDENBAROMETER,
  },
];

export const displayLmsOption = (t: TranslationFunctionType) => ({
  [AccountCourseDelivery.CP_APP]: t('cp.app'),
  [AccountCourseDelivery.THIRD_PARTY]: t('account.courseDelivery.thirdParty'),
  [AccountCourseDelivery.VIDENBAROMETER]: t(
    'account.courseDelivery.videbarometer',
  ),
});
