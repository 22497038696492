import { GroupCondition } from '@/api/enums';
import { equal, group } from '@/api/helpers';
import { MaterialType } from '@/client/courses';
import { ScormStatusAttemptEnum } from '@/client/scorm';
import { useScormCourses } from '@/hooks/query';
import { DocumentCard } from '@/ui/document-card';
import { CardGridContainer } from '@/ui/styled-ui';
import { templatesColumnsWidth } from '@/utils/helpers';
import React from 'react';
import styled from 'styled-components';
import { LearnerLayout } from '../LearnerLayout';

const StyledCardGridContainer = styled(CardGridContainer)`
  width: 100%;
  grid-template-columns: repeat(2, 1fr);
  margin-top: 0;

  @media screen and (max-width: ${templatesColumnsWidth.ONE}px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;

export const LearnerDashboardTemplatesPage: React.FC = () => {
  const { isLoading, scormCourses: templates } = useScormCourses({
    skip: 0,
    type: ScormStatusAttemptEnum.IN_PROGRESS,
    filters: [
      group(GroupCondition.OR, [
        equal('type', MaterialType.DOCUMENT),
        equal('type', MaterialType.TEMPLATE),
      ]),
    ],
    staleTime: Infinity,
  });

  return (
    <LearnerLayout isLoading={isLoading}>
      <StyledCardGridContainer>
        {templates?.result.map((template) => (
          <DocumentCard key={template.id} template={template} isLearnerView />
        ))}
      </StyledCardGridContainer>
    </LearnerLayout>
  );
};
