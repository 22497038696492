import { equal, handleAxiosError } from '@/api/helpers';
import { AppBreadCrumbTemplate } from '@/app/AppBreadCrumbTemplate';
import { client } from '@/client';
import { Account } from '@/client/accounts';
import {
  PageTemplate,
  PageTemplatesEnum,
  RedirectPageTemplateFormState,
} from '@/client/page-templates';
import { RedirectPaths, RedirectPathsEnum } from '@/common/constants';
import { DialogContext } from '@/common/context';
import { TranslationFunctionType } from '@/common/types';
import { BeePluginPageContainer } from '@/components/bee-plugin';
import { RedirectPageTemplateInformationForm } from '@/components/redirect-page-templates/forms';
import { StateProvider } from '@/components/state-provider';
import { usePageTemplate, useUpdatePageTemplate } from '@/hooks/query';
import { useAppSelector } from '@/hooks/store';
import { useToast } from '@/hooks/useToast';
import { selectCurrentAccount } from '@/store/features/account';
import { AppBreadCrumb } from '@/ui/breadcrumb';
import { AppButton } from '@/ui/buttons';
import { AbsoluteLoader, FlexContainer } from '@/ui/styled-ui';
import { AxiosError } from 'axios';
import { isEqual } from 'lodash';
import { MenuItem } from 'primereact/menuitem';
import { Steps } from 'primereact/steps';
import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import plainTemplate from './templates/plain-template.json';

const StyledMainContainer = styled(FlexContainer)`
  position: relative;
`;

const StyledFormWrapper = styled.div`
  padding: var(--default-padding);
`;

const getBreadcrumbs = (
  account: Account,
  redirectPageTemplate: PageTemplate,
  t: TranslationFunctionType,
): MenuItem[] => [
  {
    label: account?.name,
    url: RedirectPaths[RedirectPathsEnum.EDIT_ACCOUNT](account?.id),
    template: AppBreadCrumbTemplate,
  },
  {
    label: account?.isSystem
      ? t('templates.predefined.redirect')
      : t('templates.custom.redirect'),
    url: account?.isSystem
      ? RedirectPaths[RedirectPathsEnum.REDIRECT_PAGE_TEMPLATES_PREDEFINED]()
      : RedirectPaths[RedirectPathsEnum.REDIRECT_PAGE_TEMPLATES_CUSTOM](),
    template: AppBreadCrumbTemplate,
  },
  {
    label: t('templates.redirect.edit'),
    url: RedirectPaths[RedirectPathsEnum.REDIRECT_PAGE_TEMPLATES_UPDATE](
      redirectPageTemplate?.id,
    ),
    template: AppBreadCrumbTemplate,
  },
];

const initialValues = (redirectPage: PageTemplate) => ({
  name: redirectPage.name,
  active: redirectPage.active,
  meta: redirectPage.meta,
});

export const RedirectPageTemplatesUpdatePage = () => {
  const { t } = useTranslation();
  const { id } = useParams();

  const { pageTemplate, isLoading } = usePageTemplate({
    pageTemplateId: id,
  });

  return (
    <StateProvider
      state={pageTemplate}
      isLoading={isLoading}
      loadingMessage={t('templates.redirect.loading.data')}
      errorMessage={t('templates.redirect.loading.error')}
    >
      <RedirectPageTemplatesUpdateWithState
        redirectPage={pageTemplate as PageTemplate}
      />
    </StateProvider>
  );
};

export const RedirectPageTemplatesUpdateWithState: React.FC<{
  redirectPage: PageTemplate;
}> = ({ redirectPage }) => {
  const { t } = useTranslation();
  const currentAccount = useAppSelector(selectCurrentAccount);
  const navigate = useNavigate();
  const toast = useToast();
  const updateTemplate = useUpdatePageTemplate();
  const { setDialogData } = useContext(DialogContext);

  const [showLoader, setShowLoader] = useState(false);

  const steps = [
    {
      label: t('templates.info'),
    },
    {
      label: t('templates.structure'),
    },
  ];
  // Set the initial step
  const [activeStep, setActiveStep] = useState(0);
  const [blockNextStep, setBlockNextStep] = useState(true);
  const [isTemplateChanged, setIsTemplateChanged] = useState(false);

  // this is used to prevent the form from reinitializing on every change made
  const [initialFormValues, setInitialFormValues] =
    useState<RedirectPageTemplateFormState>(initialValues(redirectPage));
  // Set form initial values
  const [formValues, setFormValues] = useState<RedirectPageTemplateFormState>(
    initialValues(redirectPage),
  );

  // Set initial template for Bee
  const [initialTemplate, setInitialTemplate] = useState(
    redirectPage.editor ?? (plainTemplate as object),
  );

  // Unblock step 2 if the form is valid
  const toggleStepBlocked = (isValid: boolean) => {
    setBlockNextStep(!isValid);
  };

  const showExitPrompt = () => {
    const formValuesToCompare = initialValues(redirectPage);
    return !isTemplateChanged && isEqual(formValuesToCompare, formValues);
  };

  // Track changes to the template
  const handleChangeTemplate = (page: any) => {
    setInitialTemplate(page);
    setIsTemplateChanged(true);
  };

  // Check if a template with this name already exists
  const handleNextStep = async (
    name: string,
    account: Account,
    step: number,
  ) => {
    const filters = account.isSystem
      ? [equal('name', name), equal('account', null)]
      : [equal('name', name), equal('account', account.id)];

    try {
      const landingPageTemplates = await client.pageTemplates.getPageTemplates({
        filters: [...filters, equal('type', PageTemplatesEnum.REDIRECT)],
      });

      // Check if the name is available and if not check wether the edited template is the one that has the name
      if (
        landingPageTemplates.count &&
        redirectPage &&
        landingPageTemplates.result[0].id !== redirectPage.id
      ) {
        toast?.error(
          t('templates.name.unavailable'),
          t('templates.name.another'),
        );
        return;
      }

      setActiveStep(step);
    } catch (e) {
      handleAxiosError(e as Error | AxiosError, toast);
    }
  };

  const triggerNextStep = async () =>
    await handleNextStep(formValues.name, currentAccount as Account, 1);

  // Handle SAVE from Bee container
  const handleSaveRedirectPageTemplate = async (page: object, html: string) => {
    try {
      if (!currentAccount || !redirectPage) {
        return;
      }

      // TODO: Hit the save bee plugin here, and get the page and html from it

      const params = {
        name: formValues.name,
        active: formValues.active,
        editor: page,
        html: html,
        // TODO: Uncomment when we integrate our own Save button
        // editor: initialTemplate || plainTemplate,
        // html: '<!DOCTYPE html></html>', // Find a way to get the html form BEE
        account: currentAccount.isSystem ? null : currentAccount.id,
      };

      setShowLoader(true);
      await updateTemplate.update({
        pageTemplateId: redirectPage.id,
        updates: params,
      });

      setShowLoader(false);
      toast?.success(t('toast.success'), t('templates.redirect.updates'));

      if (currentAccount.isSystem) {
        navigate(
          RedirectPaths[RedirectPathsEnum.REDIRECT_PAGE_TEMPLATES_PREDEFINED](),
        );
      } else {
        navigate(
          RedirectPaths[RedirectPathsEnum.REDIRECT_PAGE_TEMPLATES_CUSTOM](),
        );
      }
    } catch (e) {
      setShowLoader(false);
      handleAxiosError(e as Error | AxiosError, toast);
    }
  };

  const handleExit = () => {
    const navigateUrl =
      RedirectPaths[
        RedirectPathsEnum[
          currentAccount?.isSystem
            ? 'REDIRECT_PAGE_TEMPLATES_PREDEFINED'
            : 'REDIRECT_PAGE_TEMPLATES_CUSTOM'
        ]
      ]();

    if (showExitPrompt()) {
      navigate(navigateUrl);

      return;
    }

    setDialogData({
      type: 'confirmation',
      show: true,
      header: t('dialog.unsavedChanges'),
      message: t('dialog.unsavedChanges.confirm'),
      onAccept: () => navigate(navigateUrl),
    });
  };

  return (
    <StyledMainContainer
      direction="column"
      align="flex-start"
      justify="flex-start"
      minHeight="100%"
    >
      {showLoader && <AbsoluteLoader message={t('templates.saving')} />}
      <div id="template-header-container">
        <AppBreadCrumb
          model={getBreadcrumbs(
            currentAccount as Account,
            redirectPage as PageTemplate,
            t,
          )}
        />
        <h1>
          {t('templates.redirect.edit')}: {redirectPage?.name}
        </h1>
        <Steps
          model={steps}
          activeIndex={activeStep}
          onSelect={async (e) => {
            // Check if the name is available only when switching from step 0 to 1
            if (e.index === 1 && currentAccount) {
              await triggerNextStep();
              return;
            }

            setInitialFormValues(formValues);
            setActiveStep(e.index);
          }}
          readOnly={blockNextStep}
        />
      </div>
      <div id="template-content-container">
        {activeStep === 0 && (
          <StyledFormWrapper>
            <RedirectPageTemplateInformationForm
              initialValues={initialFormValues}
              onSubmit={async () => {
                // Check if the name is available and continue to step 2, else show an error
                if (currentAccount) {
                  await triggerNextStep();
                }
              }}
              onInputChange={setFormValues}
              onValidateForm={toggleStepBlocked}
              isSystem={currentAccount?.isSystem}
            />
          </StyledFormWrapper>
        )}
        {activeStep === 1 && (
          <BeePluginPageContainer
            template={initialTemplate || plainTemplate}
            handleChange={handleChangeTemplate}
            handleSave={handleSaveRedirectPageTemplate}
          />
        )}
      </div>
      <FlexContainer id="template-footer-container" justify="space-between">
        <div>
          <AppButton
            type="outlined"
            severity="secondary"
            label={t('button.exit')}
            className="mr-3"
            onClick={handleExit}
          />
          {/* TODO: Uncomment when we find a way to get the html onChange */}
          {/* <AppButton
            severity="secondary"
            label={t('button.save')}
            onClick={handleSaveRedirectPageTemplate}
            isDisabled={blockNextStep}
          /> */}
        </div>

        <div>
          {activeStep > 0 && (
            <AppButton
              severity="secondary"
              label={t('button.back')}
              icon="pi pi-arrow-left"
              iconPos="left"
              onClick={() => {
                setInitialFormValues(formValues);
                setActiveStep(0);
              }}
              className="mr-3"
            />
          )}
          <AppButton
            severity="secondary"
            label={t('button.next')}
            icon="pi pi-arrow-right"
            iconPos="right"
            onClick={async () => {
              // Check if the name is available and continue to step 2, else show an error
              if (currentAccount) {
                await triggerNextStep();
              }
            }}
            isDisabled={blockNextStep || activeStep === 1}
          />
        </div>
      </FlexContainer>
    </StyledMainContainer>
  );
};
