import {
  Campaign,
  CampaignEmailingEnum,
  CampaignFormValues,
  CampaignStatusesEnum,
  CampaignStatusMessages,
  CampaignUserResultMessages,
  CampaignUserResultStatusesEnum,
  SaveCampaignRequest,
} from '@/client/campaigns';
import { User } from '@/client/users';
import { TranslationFunctionType } from '@/common/types';
import {
  checkIfDateIsPastDate,
  checkIfDatesAreEqual,
  checkIfNowOrAfter,
} from '@/utils/helpers/dates.helper';
import { targetGroupInitialValues } from '@/utils/helpers/target-group.helper';
import { isEmpty } from 'lodash';

const setSendByDate = (
  values?: CampaignFormValues,
  transformDates?: boolean,
) => {
  if (!transformDates)
    return values?.periodType === CampaignEmailingEnum.BLAST
      ? null
      : values?.sendByDate;

  if (
    values?.periodType === CampaignEmailingEnum.BLAST ||
    !checkIfNowOrAfter(values?.launchDate) ||
    checkIfDatesAreEqual(values?.launchDate, values?.sendByDate)
  )
    return null;

  return !checkIfNowOrAfter(values?.sendByDate)
    ? new Date()
    : values?.sendByDate;
};

const setLaunchDate = (
  values?: CampaignFormValues,
  transformDates?: boolean,
) => {
  if (!transformDates) return values?.launchDate;

  return values?.launchDate &&
    checkIfDateIsPastDate(new Date(), values.launchDate)
    ? new Date()
    : values?.launchDate;
};

export const prepareRequestData = (
  accountId?: string,
  values?: CampaignFormValues,
  transformDates?: boolean,
): SaveCampaignRequest => {
  const launchDate = setLaunchDate(values, transformDates);
  const sendByDate = setSendByDate(values, transformDates);

  return {
    encodeEmailTemplateImages: values?.encodeEmailTemplateImages,
    name: values?.name,
    emailTemplate: values?.emailTemplate,
    landingPage: values?.landingPage,
    launchDate,
    sendByDate,
    sendingProfile: values?.senderProfile?.id,
    url: undefined,
    account: accountId,
    users: values?.targetGroup?.usersList.map((user) => user.id),
    isTemplate: values?.isTemplate,
  };
};

export const copyCampaignData = (campaign: Campaign, users: User[]) => ({
  encodeEmailTemplateImages: campaign?.meta?.encodeEmailTemplateImages,
  emailTemplate: campaign.emailTemplate?.id,
  landingPage: campaign.landingPage?.id,
  users: users.map((user) => user.id),
  sendingProfile: campaign.sendingProfile?.id,
  launchDate: campaign.launchDate,
  sendByDate: campaign.sendByDate,
});

export const getCampaignSubmitValueByStep = (
  step: number,
  data: SaveCampaignRequest,
) => {
  const modifiedData: { [key: number]: any } = {
    0: {
      encodeEmailTemplateImages: data?.encodeEmailTemplateImages,
      emailTemplate: data?.emailTemplate,
      name: data?.name,
    },
    1: {
      landingPage: data?.landingPage,
    },
    2: {
      sendingProfile: data?.sendingProfile,
    },
    3: {
      users: data?.users,
    },
    4: {
      name: data?.name,
      launchDate: data?.launchDate,
      sendByDate: data?.sendByDate,
    },
  };

  return { ...modifiedData[step], accountId: data.account };
};

export const getCampaignTemplateSubmitValueByStep = (
  step: number,
  data: SaveCampaignRequest,
) => {
  const modifiedData: { [key: number]: any } = {
    0: {
      encodeEmailTemplateImages: data?.encodeEmailTemplateImages,
      emailTemplate: data?.emailTemplate,
      name: data?.name,
    },
    1: {
      landingPage: data?.landingPage,
    },
    2: {
      sendingProfile: data?.sendingProfile,
    },
    3: {
      name: data?.name,
      isTemplate: data?.isTemplate,
    },
  };

  return { ...modifiedData[step], accountId: data.account };
};

export const setCampaignInitialValues = (
  campaign: Campaign,
  users: User[],
) => ({
  encodeEmailTemplateImages: campaign?.meta?.encodeEmailTemplateImages,
  emailTemplate: campaign?.emailTemplate?.id,
  landingPage: campaign?.landingPage?.id,
  targetGroup: {
    ...targetGroupInitialValues,
    usersList: users,
  },
  senderProfile: campaign?.sendingProfile,
  periodType: campaign?.sendByDate
    ? CampaignEmailingEnum.DRIP
    : CampaignEmailingEnum.BLAST,
  sendByDate: campaign?.sendByDate && new Date(campaign.sendByDate),
  launchDate: campaign?.launchDate && new Date(campaign.launchDate),
  name: campaign?.name,
  isTemplate: campaign?.isTemplate,
  meta: campaign?.meta,
});

export const getCampaignStatusOptions = (t: TranslationFunctionType) => [
  {
    label: t(CampaignStatusMessages[CampaignStatusesEnum.ACTIVE]),
    value: CampaignStatusesEnum.ACTIVE,
  },
  {
    label: t(CampaignStatusMessages[CampaignStatusesEnum.DRAFT]),
    value: CampaignStatusesEnum.DRAFT,
  },
  {
    label: t(CampaignStatusMessages[CampaignStatusesEnum.SCHEDULED]),
    value: CampaignStatusesEnum.SCHEDULED,
  },
  {
    label: t(CampaignStatusMessages[CampaignStatusesEnum.COMPLETED]),
    value: CampaignStatusesEnum.COMPLETED,
  },
  {
    label: t(CampaignStatusMessages[CampaignStatusesEnum.PENDING_LAUNCH]),
    value: CampaignStatusesEnum.PENDING_LAUNCH,
  },
  {
    label: t(CampaignStatusMessages[CampaignStatusesEnum.PENDING_COMPLETION]),
    value: CampaignStatusesEnum.PENDING_COMPLETION,
  },
  {
    label: t(CampaignStatusMessages[CampaignStatusesEnum.FAILED_LAUNCH]),
    value: CampaignStatusesEnum.FAILED_LAUNCH,
  },
  {
    label: t(CampaignStatusMessages[CampaignStatusesEnum.FAILED_COMPLETION]),
    value: CampaignStatusesEnum.FAILED_COMPLETION,
  },
];

export const getCampaignUserResultStatuses = (
  t: TranslationFunctionType,
  showEmailOpened?: boolean,
) =>
  [
    {
      label: t(
        CampaignUserResultMessages[
          CampaignUserResultStatusesEnum.CAMPAIGN_CREATED
        ],
      ),
      value: [CampaignUserResultStatusesEnum.CAMPAIGN_CREATED],
    },
    {
      label: t(
        CampaignUserResultMessages[CampaignUserResultStatusesEnum.EMAIL_SENT],
      ),
      value: showEmailOpened
        ? CampaignUserResultStatusesEnum.EMAIL_SENT
        : [
            CampaignUserResultStatusesEnum.EMAIL_SENT,
            CampaignUserResultStatusesEnum.EMAIL_OPENED,
          ],
    },
    showEmailOpened
      ? {
          label: t(
            CampaignUserResultMessages[
              CampaignUserResultStatusesEnum.EMAIL_OPENED
            ],
          ),
          value: CampaignUserResultStatusesEnum.EMAIL_OPENED,
        }
      : {},
    {
      label: t(
        CampaignUserResultMessages[CampaignUserResultStatusesEnum.CLICKED_LINK],
      ),
      value: CampaignUserResultStatusesEnum.CLICKED_LINK,
    },
    {
      label: t(
        CampaignUserResultMessages[
          CampaignUserResultStatusesEnum.SUBMITTED_DATA
        ],
      ),
      value: CampaignUserResultStatusesEnum.SUBMITTED_DATA,
    },
    {
      label: t(
        CampaignUserResultMessages[
          CampaignUserResultStatusesEnum.EMAIL_REPORTED
        ],
      ),
      value: CampaignUserResultStatusesEnum.EMAIL_REPORTED,
    },
    {
      label: t(
        CampaignUserResultMessages[CampaignUserResultStatusesEnum.ERROR],
      ),
      value: CampaignUserResultStatusesEnum.ERROR,
    },
    {
      label: t(
        CampaignUserResultMessages[CampaignUserResultStatusesEnum.SCHEDULED],
      ),
      value: CampaignUserResultStatusesEnum.SCHEDULED,
    },
    {
      label: t(
        CampaignUserResultMessages[CampaignUserResultStatusesEnum.SENDING],
      ),
      value: CampaignUserResultStatusesEnum.SENDING,
    },
  ].filter((item) => !isEmpty(item));
