import { handleAxiosError } from '@/api/helpers';
import { client } from '@/client';
import { ScormStatusAttemptEnum } from '@/client/scorm';
import { useToast } from '@/hooks/useToast';
import { LearnerDashboardCoursesPage } from '@/pages/dashboards/learner/courses/LearnerDashboardCoursesPage';
import { AxiosError } from 'axios';
import React, { useEffect, useState } from 'react';

export const LearnerDashboardActivePage: React.FC = () => {
  const toast = useToast();

  const [atLeastOneCompleted, setAtLeastOneCompleted] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const getOneCompletedScormCourse = async () => {
      try {
        setIsLoading(true);

        const response = await client.scormCourses.getScormCourses({
          take: 1,
          skip: 0,
          type: ScormStatusAttemptEnum.COMPLETED,
        });

        setAtLeastOneCompleted(response.count);
      } catch (e) {
        handleAxiosError(e as Error | AxiosError, toast);
      } finally {
        setIsLoading(false);
      }
    };
    getOneCompletedScormCourse();
  }, []);

  return (
    <LearnerDashboardCoursesPage
      type={ScormStatusAttemptEnum.IN_PROGRESS}
      atLeastOneCompleted={!!atLeastOneCompleted}
      isLoading={isLoading}
    />
  );
};
