import { CreateCampaignFromTemplateFormState } from '@/client/campaigns';
import EmailPlaceholderImage from '@/images/placeholders/email-template.svg';
import PagePlaceholderImage from '@/images/placeholders/page-template.svg';
import { ImageCard } from '@/ui/image-card';
import { CardGridContainer, FlexContainer } from '@/ui/styled-ui';
import { displaySenderProfile } from '@/utils/helpers';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

const StyledCardGridContainer = styled(CardGridContainer)`
  max-height: 100%;
  grid-template-columns: 1fr 3fr;
  grid-template-rows: 1fr 1fr;
  margin: 0;
`;

const StyledImageCard = styled(ImageCard)`
  height: min-content;
`;

const StyledEmailPreview = styled(FlexContainer)`
  flex-direction: column;
  grid-area: 1 / 2 / 3/ 3;
  height: 100%;
  border: 1px solid var(--gray-dark);
  padding: var(--default-padding);
  background-color: var(--beige-main);
  border-radius: var(--medium-border-radius);
  overflow: hidden;

  h4 {
    margin: 0;
    padding-bottom: var(--small-padding);
    align-self: flex-start;
    color: var(--black-main);
    font-size: var(--medium-font-size);
    line-height: var(--medium-line-height);
    font-style: normal;
    font-weight: 700;
  }

  .preview-container {
    width: 100%;
    height: 100%;
    overflow-y: scroll;

    ::-webkit-scrollbar-thumb {
      border: 4px solid var(--beige-main);
    }
    ::-webkit-scrollbar-track {
      background-color: var(--beige-main);
    }
    img {
      width: 100%;
      height: auto;
    }
  }
`;

export type PreviewCampaignTemplatePropsType = {
  values: CreateCampaignFromTemplateFormState;
};

export const PreviewCampaignTemplate: React.FC<
  PreviewCampaignTemplatePropsType
> = ({ values }) => {
  const { t } = useTranslation();
  const campaign = values.selectedCampaign;

  return (
    <>
      {campaign && (
        <StyledCardGridContainer>
          <StyledImageCard
            key={campaign.id}
            id={campaign.id}
            title={campaign.name}
            selectName={campaign.name}
            interactionType="withSelect"
            thumbnail={
              campaign.emailTemplate?.preview?.signedUrl ||
              campaign.emailTemplate?.thumbnail?.signedUrl
            }
            author={displaySenderProfile(t, campaign.sendingProfile)}
            templateType="email"
            isSelected={!!values.selectedCampaign}
          />
          <StyledEmailPreview>
            <div className="preview-container">
              <h4>{t('templates.email')}</h4>
              <img
                src={
                  campaign.emailTemplate?.preview?.signedUrl ||
                  campaign.emailTemplate?.thumbnail?.signedUrl ||
                  EmailPlaceholderImage
                }
                alt={campaign.emailTemplate?.thumbnail?.fileName}
              />
              <h4>{t('templates.pageTemplate')}</h4>
              <img
                src={
                  campaign.landingPage?.preview?.signedUrl ||
                  campaign.landingPage?.thumbnail?.signedUrl ||
                  PagePlaceholderImage
                }
                alt={campaign.landingPage?.thumbnail?.fileName}
              />
              {campaign.landingPage?.redirectPage && (
                <>
                  <h4>{t('templates.redirectTemplate')}</h4>
                  <img
                    src={
                      campaign.landingPage?.redirectPage.preview?.signedUrl ||
                      campaign.landingPage?.redirectPage?.thumbnail
                        ?.signedUrl ||
                      PagePlaceholderImage
                    }
                    alt={
                      campaign.landingPage?.redirectPage?.thumbnail?.fileName
                    }
                  />
                </>
              )}
            </div>
          </StyledEmailPreview>
        </StyledCardGridContainer>
      )}
    </>
  );
};
