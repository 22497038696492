import { FiltersType, ListingRequest } from '@/api/types';
import { client } from '@/client';
import { Account, ListAccountsResponse } from '@/client/accounts';
import {
  AccountsSchedulesRequest,
  BranchesSchedulesRequest,
  ChangeScheduleDateRequest,
  ChangeScheduleEnrollTypeRequest,
  Course,
  DeleteEnrollmentPayload,
  DeleteScheduleRequest,
  ForceCompleteRequest,
  GroupsSchedulesRequest,
  ListAccountScheduleResponse,
  ListCourseAccountResponse,
  ListCourseBranchesResponse,
  ListCourseGroupsResponse,
  ListCourseScheduleResponse,
  ListCoursesResponse,
  ListCourseUsersResponse,
  MakeAvailableAccountsRequest,
  MakeAvailableCoursesToAccountRequest,
  SaveCourseRequest,
  UpdateCourseRequest,
  UsersSchedulesRequest,
} from '@/client/courses';
import { MessageResponseModel } from '@/client/models';
import {
  AddAvailableAccountsMutation,
  AddAvailableCoursesToAccountMutation,
  ChangeScheduleDateMutation,
  DeleteCourseMutation,
  DeleteScheduleMutation,
  ForceCompleteCourseMutation,
  GenericForbiddenApiError,
  SaveAccountScheduleMutation,
  SaveBranchesSchedulesMutation,
  SaveCourseMutation,
  SaveGroupsSchedulesMutation,
  SaveUsersSchedulesMutation,
} from '@/hooks/query';
import { partialRequests } from '@/utils/helpers';
import {
  useMutation,
  useQuery,
  UseQueryOptions,
  UseQueryResult,
} from 'react-query';

const queryKey = 'courses';
const singleQueryKey = 'course';

export const useCourses = (
  params: UseQueryOptions & {
    take?: number;
    skip?: number;
    sort?: string[];
    search?: string;
    filters?: FiltersType;
  },
): UseQueryResult<ListCoursesResponse> & {
  courses: ListCoursesResponse | undefined;
} => {
  const {
    take = 5,
    skip = 0,
    sort,
    filters,
    search,
    retry = false,
    retryDelay = 500,
    staleTime = 0,
    cacheTime = 0,
    ...restOptions
  } = params;

  const { data, ...rest } = useQuery(
    [
      queryKey,
      take,
      skip,
      JSON.stringify(sort),
      JSON.stringify(filters),
      JSON.stringify(search),
    ],
    () => client.courses.getCourses(params),
    {
      retry,
      retryDelay,
      staleTime,
      cacheTime,
      ...(restOptions as any),
    },
  );

  return {
    courses: data,
    ...(rest as UseQueryResult<ListCoursesResponse>),
  };
};

export const useUnavailableCoursesToAccount = (
  params: UseQueryOptions & {
    sort?: string[];
    filters?: FiltersType;
  } & { accountId: string },
): UseQueryResult<Course[]> & {
  courses: Course[] | undefined;
} => {
  const {
    accountId,
    sort,
    filters,
    retry = false,
    retryDelay = 500,
    staleTime = 0,
    cacheTime = 0,
    ...restOptions
  } = params;
  const unavailableCoursesRequest = async (params: ListingRequest) =>
    await client.courses.getUnavailableCoursesToAccount(
      { ...params, sort },
      accountId,
    );
  const { data, ...rest } = useQuery(
    [`${queryKey}-partial`],
    () => partialRequests(filters || [], unavailableCoursesRequest),
    {
      retry,
      retryDelay,
      staleTime,
      cacheTime,
      ...(restOptions as any),
    },
  );

  return {
    courses: data,
    ...(rest as UseQueryResult<Course[]>),
  };
};

export const useCourse = (
  params: UseQueryOptions & {
    courseId?: string;
  },
): UseQueryResult<Course> & {
  course: Course | undefined;
} => {
  const {
    courseId,
    retry = false,
    retryDelay = 500,
    staleTime = 0,
    cacheTime = 0,
    ...restOptions
  } = params;

  const { data, ...rest } = useQuery(
    [singleQueryKey, courseId],
    () => (courseId ? client.courses.getCourse(courseId) : undefined),
    {
      retry,
      retryDelay,
      staleTime,
      cacheTime,
      ...(restOptions as UseQueryResult<Course>),
    },
  );

  return {
    course: data,
    ...(rest as UseQueryResult<Course>),
  };
};

export const useSaveCourse = (): {
  create: (payload: SaveCourseRequest) => Promise<Course>;
} & SaveCourseMutation => {
  const { mutateAsync, ...rest } = useMutation<
    Course,
    GenericForbiddenApiError,
    SaveCourseRequest
  >('addCourse', (payload: SaveCourseRequest) =>
    client.courses.saveCourse(payload),
  );

  return { create: mutateAsync, ...(rest as any) };
};

export const useUpdateCourse = (): {
  update: (payload: UpdateCourseRequest) => Promise<Course>;
} & SaveCourseMutation => {
  const { mutateAsync, ...rest } = useMutation<
    Course,
    GenericForbiddenApiError,
    UpdateCourseRequest
  >('updateCourse', (payload: UpdateCourseRequest) =>
    client.courses.updateCourse(payload),
  );

  return { update: mutateAsync, ...(rest as any) };
};

export const useDeleteCourse = (): {
  delete: (userId: string) => Promise<MessageResponseModel>;
} & DeleteCourseMutation => {
  const { mutateAsync, ...rest } = useMutation<
    MessageResponseModel,
    GenericForbiddenApiError,
    string
  >('deleteCourse', (courseId: string) =>
    client.courses.deleteCourse(courseId),
  );

  return { delete: mutateAsync, ...(rest as any) };
};

export const useMakeAccountsAvailable = (): {
  add: (payload: MakeAvailableAccountsRequest) => Promise<MessageResponseModel>;
} & AddAvailableAccountsMutation => {
  const { mutateAsync, ...rest } = useMutation<
    MessageResponseModel,
    GenericForbiddenApiError,
    MakeAvailableAccountsRequest
  >('addAvailableAccounts', (payload: MakeAvailableAccountsRequest) =>
    client.courses.saveAvailableAccounts({
      courseId: payload.courseId,
      accounts: payload.accounts,
    }),
  );

  return { add: mutateAsync, ...(rest as any) };
};

export const useMakeCoursesAvailableToAccount = (): {
  add: (
    payload: MakeAvailableCoursesToAccountRequest,
  ) => Promise<MessageResponseModel>;
} & AddAvailableCoursesToAccountMutation => {
  const { mutateAsync, ...rest } = useMutation<
    MessageResponseModel,
    GenericForbiddenApiError,
    MakeAvailableCoursesToAccountRequest
  >('addAvailableAccounts', (payload: MakeAvailableCoursesToAccountRequest) =>
    client.courses.saveAvailableCoursesToAccount({
      courses: payload.courses,
      accountId: payload.accountId,
    }),
  );

  return { add: mutateAsync, ...(rest as any) };
};

export const useMakeAccountsUnavailable = (): {
  remove: (
    payload: MakeAvailableAccountsRequest,
  ) => Promise<MessageResponseModel>;
} & AddAvailableAccountsMutation => {
  const { mutateAsync, ...rest } = useMutation<
    MessageResponseModel,
    GenericForbiddenApiError,
    MakeAvailableAccountsRequest
  >('removeAvailableAccounts', (payload: MakeAvailableAccountsRequest) =>
    client.courses.saveUnavailableAccounts({
      courseId: payload.courseId,
      accounts: payload.accounts,
    }),
  );

  return { remove: mutateAsync, ...(rest as any) };
};

export const useUnavailableAccounts = (
  params: UseQueryOptions & {
    token?: string;
    take?: number;
    skip?: number;
    sort?: string[];
    filters?: FiltersType;
  } = {},
  courseId?: string,
): UseQueryResult<ListAccountsResponse> & {
  accounts?: ListAccountsResponse;
} => {
  const {
    take = 5,
    skip = 0,
    sort,
    filters,
    retry = false,
    retryDelay = 500,
    staleTime = 0,
    cacheTime = 0,
    ...restOptions
  } = params;
  const { data, ...rest } = useQuery(
    [
      `${queryKey}Accounts`,
      take,
      skip,
      JSON.stringify(sort),
      JSON.stringify(filters),
    ],
    () => client.courses.getUnavailableAccounts(params, courseId),
    {
      retry,
      retryDelay,
      staleTime,
      cacheTime,
      ...(restOptions as any),
    },
  );

  return {
    accounts: data,
    ...(rest as UseQueryResult<ListAccountsResponse>),
  };
};

export const useUnavailableAccountsPartialRequest = (
  params: UseQueryOptions & {
    sort?: string[];
    filters?: FiltersType;
  },
  courseId?: string,
): UseQueryResult<Account[]> & {
  accounts: Account[] | undefined;
} => {
  const {
    sort,
    filters,
    retry = false,
    retryDelay = 500,
    staleTime = 0,
    cacheTime = 0,
    ...restOptions
  } = params;
  const unavailableAccountsRequest = async (params: ListingRequest) =>
    await client.courses.getUnavailableAccounts({ ...params, sort }, courseId);
  const { data, ...rest } = useQuery(
    [`${queryKey}UnavailableAccountsPartial`],
    () => partialRequests(filters || [], unavailableAccountsRequest),
    {
      retry,
      retryDelay,
      staleTime,
      cacheTime,
      ...(restOptions as any),
    },
  );

  return {
    accounts: data,
    ...(rest as UseQueryResult<Account[]>),
  };
};

export const useCourseSchedules = (
  params: UseQueryOptions & {
    take?: number;
    skip?: number;
    sort?: string[];
    filters?: FiltersType;
  },
  courseId?: string,
): UseQueryResult<ListCourseScheduleResponse> & {
  schedules: ListCourseScheduleResponse | undefined;
} => {
  const {
    take = 5,
    skip = 0,
    sort,
    filters,
    retry = false,
    retryDelay = 500,
    staleTime = 0,
    cacheTime = 0,
    ...restOptions
  } = params;

  const { data, ...rest } = useQuery(
    [
      `${queryKey}Schedule`,
      take,
      skip,
      JSON.stringify(sort),
      JSON.stringify(filters),
    ],
    () => client.courses.getCourseSchedules(params, courseId),
    {
      retry,
      retryDelay,
      staleTime,
      cacheTime,
      ...(restOptions as any),
    },
  );

  return {
    schedules: data,
    ...(rest as UseQueryResult<ListCourseScheduleResponse>),
  };
};

export const useCourseAccounts = (
  params: UseQueryOptions & {
    take?: number;
    skip?: number;
    sort?: string[];
    filters?: FiltersType;
  },
  courseId?: string,
): UseQueryResult<ListCourseAccountResponse> & {
  courseAccounts: ListCourseAccountResponse | undefined;
} => {
  const {
    take = 5,
    skip = 0,
    sort,
    filters,
    retry = false,
    retryDelay = 500,
    staleTime = 0,
    cacheTime = 0,
    ...restOptions
  } = params;

  const { data, ...rest } = useQuery(
    [
      `${queryKey}CourseAccounts`,
      take,
      skip,
      JSON.stringify(sort),
      JSON.stringify(filters),
    ],
    () => client.courses.getCourseAccounts(params, courseId),
    {
      retry,
      retryDelay,
      staleTime,
      cacheTime,
      ...(restOptions as any),
    },
  );

  return {
    courseAccounts: data,
    ...(rest as UseQueryResult<ListCourseAccountResponse>),
  };
};

export const useCourseBranches = (
  params: UseQueryOptions & {
    take?: number;
    skip?: number;
    sort?: string[];
    filters?: FiltersType;
  },
  courseId?: string,
): UseQueryResult<ListCourseBranchesResponse> & {
  courseBranches: ListCourseBranchesResponse | undefined;
} => {
  const {
    take = 5,
    skip = 0,
    sort,
    filters,
    retry = false,
    retryDelay = 500,
    staleTime = 0,
    cacheTime = 0,
    ...restOptions
  } = params;

  const { data, ...rest } = useQuery(
    [
      `${queryKey}CourseBranches`,
      take,
      skip,
      JSON.stringify(sort),
      JSON.stringify(filters),
    ],
    () => client.courses.getCourseBranches(params, courseId),
    {
      retry,
      retryDelay,
      staleTime,
      cacheTime,
      ...(restOptions as any),
    },
  );

  return {
    courseBranches: data,
    ...(rest as UseQueryResult<ListCourseBranchesResponse>),
  };
};

export const useCourseGroups = (
  params: UseQueryOptions & {
    take?: number;
    skip?: number;
    sort?: string[];
    filters?: FiltersType;
  },
  courseId?: string,
): UseQueryResult<ListCourseGroupsResponse> & {
  courseGroups: ListCourseGroupsResponse | undefined;
} => {
  const {
    take = 5,
    skip = 0,
    sort,
    filters,
    retry = false,
    retryDelay = 500,
    staleTime = 0,
    cacheTime = 0,
    ...restOptions
  } = params;

  const { data, ...rest } = useQuery(
    [
      `${queryKey}CourseGroups`,
      take,
      skip,
      JSON.stringify(sort),
      JSON.stringify(filters),
    ],
    () => client.courses.getCourseGroups(params, courseId),
    {
      retry,
      retryDelay,
      staleTime,
      cacheTime,
      ...(restOptions as any),
    },
  );

  return {
    courseGroups: data,
    ...(rest as UseQueryResult<ListCourseGroupsResponse>),
  };
};

export const useCourseUsers = (
  params: UseQueryOptions & {
    take?: number;
    skip?: number;
    sort?: string[];
    filters?: FiltersType;
  },
  courseId?: string,
): UseQueryResult<ListCourseUsersResponse> & {
  courseUsers: ListCourseUsersResponse | undefined;
} => {
  const {
    take = 5,
    skip = 0,
    sort,
    filters,
    retry = false,
    retryDelay = 500,
    staleTime = 0,
    cacheTime = 0,
    ...restOptions
  } = params;

  const { data, ...rest } = useQuery(
    [
      `${queryKey}CourseUsers`,
      take,
      skip,
      JSON.stringify(sort),
      JSON.stringify(filters),
    ],
    () => client.courses.getCourseUsers(params, courseId),
    {
      retry,
      retryDelay,
      staleTime,
      cacheTime,
      ...(restOptions as any),
    },
  );

  return {
    courseUsers: data,
    ...(rest as UseQueryResult<ListCourseUsersResponse>),
  };
};

export const useAccountsSchedules = (): {
  add: (payload: AccountsSchedulesRequest) => Promise<MessageResponseModel>;
} & SaveAccountScheduleMutation => {
  const { mutateAsync, ...rest } = useMutation<
    MessageResponseModel,
    GenericForbiddenApiError,
    AccountsSchedulesRequest
  >('saveAccountSchedule', (payload: AccountsSchedulesRequest) =>
    client.courses.saveAccountSchedule({
      courses: payload.courses,
      accounts: payload.accounts,
      date: payload.date,
      autoEnroll: payload.autoEnroll,
      type: payload.type,
    }),
  );

  return { add: mutateAsync, ...(rest as any) };
};

export const useBranchesSchedules = (): {
  add: (payload: BranchesSchedulesRequest) => Promise<MessageResponseModel>;
} & SaveBranchesSchedulesMutation => {
  const { mutateAsync, ...rest } = useMutation<
    MessageResponseModel,
    GenericForbiddenApiError,
    BranchesSchedulesRequest
  >('saveBranchesSchedules', (payload: BranchesSchedulesRequest) =>
    client.courses.saveBranchesSchedules({
      courses: payload.courses,
      branches: payload.branches,
      date: payload.date,
      autoEnroll: payload.autoEnroll,
      type: payload.type,
    }),
  );

  return { add: mutateAsync, ...(rest as any) };
};

export const useGroupsSchedules = (): {
  add: (payload: GroupsSchedulesRequest) => Promise<MessageResponseModel>;
} & SaveGroupsSchedulesMutation => {
  const { mutateAsync, ...rest } = useMutation<
    MessageResponseModel,
    GenericForbiddenApiError,
    GroupsSchedulesRequest
  >('saveGroupsSchedules', (payload: GroupsSchedulesRequest) =>
    client.courses.saveGroupsSchedules({
      courses: payload.courses,
      groups: payload.groups,
      date: payload.date,
      autoEnroll: payload.autoEnroll,
      type: payload.type,
    }),
  );

  return { add: mutateAsync, ...(rest as any) };
};

export const useUsersSchedules = (): {
  add: (payload: UsersSchedulesRequest) => Promise<MessageResponseModel>;
} & SaveUsersSchedulesMutation => {
  const { mutateAsync, ...rest } = useMutation<
    MessageResponseModel,
    GenericForbiddenApiError,
    UsersSchedulesRequest
  >('saveUsersSchedules', (payload: UsersSchedulesRequest) =>
    client.courses.saveUsersSchedules({
      courses: payload.courses,
      users: payload.users,
      date: payload.date,
      type: payload.type,
    }),
  );

  return { add: mutateAsync, ...(rest as any) };
};

export const useChangeScheduleDate = (): {
  edit: (payload: ChangeScheduleDateRequest) => Promise<MessageResponseModel>;
} & ChangeScheduleDateMutation => {
  const { mutateAsync, ...rest } = useMutation<
    MessageResponseModel,
    GenericForbiddenApiError,
    ChangeScheduleDateRequest
  >('changeScheduleDate', (payload: ChangeScheduleDateRequest) =>
    client.courses.changeScheduleDate({
      scheduleId: payload.scheduleId,
      scheduleType: payload.scheduleType,
      date: payload.date,
      type: payload.type,
    }),
  );

  return { edit: mutateAsync, ...(rest as any) };
};

export const useChangeScheduleEnrollType = (): {
  edit: (
    payload: ChangeScheduleEnrollTypeRequest,
  ) => Promise<MessageResponseModel>;
} & ChangeScheduleDateMutation => {
  const { mutateAsync, ...rest } = useMutation<
    MessageResponseModel,
    GenericForbiddenApiError,
    ChangeScheduleEnrollTypeRequest
  >('changeScheduleEnrollType', (payload: ChangeScheduleEnrollTypeRequest) =>
    client.courses.changeScheduleEnrollType({
      scheduleId: payload.scheduleId,
      scheduleType: payload.scheduleType,
      autoEnroll: payload.autoEnroll,
      type: payload.type,
    }),
  );

  return { edit: mutateAsync, ...(rest as any) };
};

export const useDeleteSchedule = (): {
  delete: (payload: DeleteScheduleRequest) => Promise<MessageResponseModel>;
} & DeleteScheduleMutation => {
  const { mutateAsync, ...rest } = useMutation<
    MessageResponseModel,
    GenericForbiddenApiError,
    DeleteScheduleRequest
  >('deleteSchedule', (payload: DeleteScheduleRequest) =>
    client.courses.deleteSchedule(payload.id, payload.source),
  );

  return { delete: mutateAsync, ...(rest as any) };
};

export const useDeleteEnrollment = (): {
  post: (payload: DeleteEnrollmentPayload) => Promise<MessageResponseModel>;
} & DeleteScheduleMutation => {
  const { mutateAsync, ...rest } = useMutation<
    MessageResponseModel,
    GenericForbiddenApiError,
    DeleteEnrollmentPayload
  >('deleteEnrollment', (payload: DeleteEnrollmentPayload) =>
    client.courses.deleteEnrollment(payload),
  );

  return { post: mutateAsync, ...(rest as any) };
};

export const useAccountSchedules = (
  params: UseQueryOptions & {
    take?: number;
    skip?: number;
    sort?: string[];
    filters?: FiltersType;
  },
  accountId?: string,
): UseQueryResult<ListAccountScheduleResponse> & {
  schedules: ListAccountScheduleResponse | undefined;
} => {
  const {
    take = 5,
    skip = 0,
    sort,
    filters,
    retry = false,
    retryDelay = 500,
    staleTime = 0,
    cacheTime = 0,
    ...restOptions
  } = params;

  const { data, ...rest } = useQuery(
    [
      `${queryKey}AccountSchedule`,
      take,
      skip,
      JSON.stringify(sort),
      JSON.stringify(filters),
    ],
    () => client.courses.getAccountSchedules(params, accountId),
    {
      retry,
      retryDelay,
      staleTime,
      cacheTime,
      ...(restOptions as any),
    },
  );

  return {
    schedules: data,
    ...(rest as UseQueryResult<ListAccountScheduleResponse>),
  };
};

export const useForceComplete = (): {
  complete: (payload: ForceCompleteRequest) => Promise<MessageResponseModel>;
} & ForceCompleteCourseMutation => {
  const { mutateAsync, ...rest } = useMutation<
    MessageResponseModel,
    GenericForbiddenApiError,
    ForceCompleteRequest
  >('forceCompleteCourse', (payload: ForceCompleteRequest) =>
    client.courses.forceComplete(payload),
  );

  return { complete: mutateAsync, ...(rest as any) };
};

export const useCoursesPartialRequest = (
  params: UseQueryOptions & {
    sort?: string[];
    filters?: FiltersType;
  },
): UseQueryResult<Course[]> & {
  courses: Course[] | undefined;
} => {
  const {
    filters,
    retry = false,
    retryDelay = 500,
    staleTime = 0,
    cacheTime = 0,
    ...restOptions
  } = params;
  const coursesRequest = async (params: ListingRequest) =>
    await client.courses.getCourses(params);
  const { data, ...rest } = useQuery(
    [`${queryKey}-partial`],
    () => partialRequests(filters || [], coursesRequest),
    {
      retry,
      retryDelay,
      staleTime,
      cacheTime,
      ...(restOptions as any),
    },
  );

  return {
    courses: data,
    ...(rest as UseQueryResult<Course[]>),
  };
};
