import { equal, notEqual } from '@/api/helpers';
import { Account } from '@/client/accounts';
import {
  CourseEntityScheduleEnum,
  CourseScheduleType,
  EnrollManyToManyFormValues,
  MaterialType,
} from '@/client/courses';
import { SystemRoles, User } from '@/client/users';
import { manyToManyEnrollSchema } from '@/components/courses/validations/enroll';
import { FormikDropdown, FormikSwitch } from '@/components/form';
import {
  BranchesMultiselectInput,
  CoursesMultiselectInput,
  GroupsMultiselectInput,
  UsersMultiselectInput,
} from '@/components/form/selectors';
import { useAppSelector } from '@/hooks/store';
import { selectCurrentUser } from '@/store/features/users';
import { AppButton } from '@/ui/buttons';
import { AppCalendar } from '@/ui/calendar';
import { FlexContainer } from '@/ui/styled-ui';
import { coursesEnrollToOptions } from '@/utils/helpers';
import { Field, Form, Formik } from 'formik';
import moment from 'moment';
import { Dialog, DialogProps } from 'primereact/dialog';
import { DropdownChangeEvent } from 'primereact/dropdown';
import { Message } from 'primereact/message';
import { FormEvent } from 'primereact/ts-helpers';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

const StyledDialog = styled(Dialog)`
  width: 500px;
`;

type ManyToManyEnrollModalProps = {
  onSubmit: (data: EnrollManyToManyFormValues) => void;
  account: Account;
  isDocument?: boolean;
} & DialogProps;

export const ManyToManyEnrollModal: React.FC<ManyToManyEnrollModalProps> = ({
  onSubmit,
  onHide,
  account,
  visible,
  isDocument,
}) => {
  const { t } = useTranslation();
  const user = useAppSelector(selectCurrentUser);
  const isBranchAdmin =
    user?.role.code === SystemRoles.ADMIN && !!user.branch?.id;
  const [initialValues] = useState<EnrollManyToManyFormValues>({
    courses: [],
    ...(isBranchAdmin
      ? { enrollTo: CourseEntityScheduleEnum.BRANCH }
      : { enrollTo: CourseEntityScheduleEnum.ACCOUNT }),
    branches: [],
    groups: [],
    users: [],
    ...(isBranchAdmin ? {} : { accounts: [account] }),
    date: null,
    autoEnroll: false,
    type: CourseScheduleType.STATIC,
  });

  return (
    <StyledDialog
      blockScroll
      visible={visible}
      header={<h1>{t('generic.enroll')}</h1>}
      onHide={onHide}
      draggable={false}
      data-testid="enroll-many-to-many-modal"
    >
      <Formik
        initialValues={initialValues}
        validationSchema={manyToManyEnrollSchema(t)}
        validateOnMount
        onSubmit={onSubmit}
      >
        {({ setFieldValue, values, errors }) => {
          return (
            <Form>
              <div className="field w-full mb-4">
                <label htmlFor="courses">
                  {`${isDocument ? t('documents') : t('courses')} (${
                    values?.courses?.length ?? 0
                  })`}
                </label>
                <CoursesMultiselectInput
                  className="w-full"
                  onChange={(courses) => {
                    setFieldValue('courses', courses);
                  }}
                  additionalFilters={[
                    equal('availableToAccounts', account?.id),
                    equal('retired', false),
                    isDocument
                      ? notEqual('type', MaterialType.COURSE)
                      : equal('type', MaterialType.COURSE),
                  ]}
                  selectedOptions={values.courses}
                  placeholder={isDocument ? t('documents.select') : undefined}
                />
              </div>
              <div className="field w-full mb-4">
                <Field
                  id="enroll-to"
                  name="enrollTo"
                  label={t('courses.enrollTo')}
                  className="w-full"
                  component={FormikDropdown}
                  onChange={(e: DropdownChangeEvent) => {
                    setFieldValue('enrollTo', e.value);
                    setFieldValue('users', []);
                    setFieldValue('branches', []);
                    setFieldValue('groups', []);
                  }}
                  options={coursesEnrollToOptions(t, user as User)}
                />
              </div>
              {values.enrollTo === CourseEntityScheduleEnum.BRANCH && (
                <div className="field w-full mb-4">
                  <label htmlFor="branches">
                    {`${t('branches')} (${values?.branches?.length ?? 0})`}
                  </label>
                  <BranchesMultiselectInput
                    className="w-full"
                    onChange={(branches) => {
                      setFieldValue('branches', branches);
                    }}
                    additionalFilters={[
                      equal('account', account?.id),
                      equal('active', true),
                    ]}
                    selectedOptions={values.branches}
                  />
                </div>
              )}
              {values.enrollTo === CourseEntityScheduleEnum.GROUP && (
                <div className="field w-full mb-4">
                  <label htmlFor="groups">
                    {`${t('groups')} (${values?.groups?.length ?? 0})`}
                  </label>
                  <GroupsMultiselectInput
                    className="w-full"
                    onChange={(groups) => {
                      setFieldValue('groups', groups);
                    }}
                    additionalFilters={[
                      equal('account', account?.id),
                      equal('active', true),
                    ]}
                    selectedOptions={values.groups}
                  />
                </div>
              )}
              {values.enrollTo === CourseEntityScheduleEnum.USER && (
                <div className="field w-full">
                  <label htmlFor="users">
                    {`${t('users')} (${values?.users?.length ?? 0})`}
                  </label>
                  <UsersMultiselectInput
                    className="w-full"
                    onChange={(users) => {
                      setFieldValue('users', users);
                    }}
                    additionalFilters={[
                      equal('account', account?.id),
                      equal('active', true),
                    ]}
                    selectedOptions={values.users}
                  />
                </div>
              )}

              <div className="field w-full mb-4">
                <label>{t('generic.enrollDate')}</label>
                <AppCalendar
                  id="enroll-date"
                  name="enrollDate"
                  className="w-full"
                  placeholder={t('courses.select.enrollDate')}
                  minDate={new Date()}
                  value={values.date}
                  onChange={(event: FormEvent) => {
                    setFieldValue(
                      'date',
                      moment(event.value?.toString()).utc(true).toDate(),
                    );
                  }}
                  readOnlyInput
                />
              </div>
              {values.enrollTo !== CourseEntityScheduleEnum.USER && (
                <div className="field-checkbox mt-3">
                  <Field
                    inputId="autoEnroll"
                    name="autoEnroll"
                    label={t('courses.autoEnroll')}
                    value={values.autoEnroll}
                    component={FormikSwitch}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      setFieldValue('autoEnroll', e.target.value);
                    }}
                  />
                </div>
              )}
              <Message
                severity="warn"
                text={
                  values.enrollTo === CourseEntityScheduleEnum.BRANCH
                    ? t('courses.enroll.branchesUsers.warning')
                    : t('courses.enroll.branches.warning')
                }
              />
              <FlexContainer justify="flex-end" className="mt-5">
                <AppButton
                  label={t('button.cancel')}
                  severity="secondary"
                  type="outlined"
                  onClick={onHide}
                  className="mr-3"
                  data-testid="enroll-many-to-many-cancel-form"
                />
                <AppButton
                  label={t('generic.enroll')}
                  isSubmit
                  isDisabled={!!Object.keys(errors).length}
                  data-testid="enroll-many-to-many-submit-form"
                />
              </FlexContainer>
            </Form>
          );
        }}
      </Formik>
    </StyledDialog>
  );
};
