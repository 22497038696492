import classNames from 'classnames';
import { get } from 'lodash';
import { InputTextarea, InputTextareaProps } from 'primereact/inputtextarea';
import React, { SyntheticEvent, useEffect } from 'react';

export type FormikTextareaProps = {
  field: {
    name: string;
    value: string;
    onChange: () => void;
    onBlur: (e: SyntheticEvent<HTMLTextAreaElement>) => void;
  };
  form: {
    touched: Record<string, any>;
    errors: Record<string, any>;
    setFieldValue: (
      field: string,
      value: any,
      shouldValidate?: boolean,
    ) => void;
  };
  label?: string;
};

export const FormikTextarea: React.FC<
  FormikTextareaProps & InputTextareaProps
> = ({ field, form, label, className, required, ...rest }) => {
  // Remove and disallow empty spaces at beginning of value
  useEffect(() => {
    form.setFieldValue(field.name, field.value?.trimStart());
  }, [field.value]);

  const fieldError = get(form.errors, field.name);
  const fieldTouched = get(form.touched, field.name);

  return (
    <>
      <label
        htmlFor={field.name}
        className={classNames({
          'p-error': fieldTouched && !!fieldError,
        })}
      >
        {label}
        {required ? <span className="red"> *</span> : ''}
      </label>
      <InputTextarea
        {...field}
        value={field.value ?? ''}
        {...rest}
        className={classNames(className, {
          'p-invalid': fieldTouched && !!fieldError,
        })}
        onBlur={(e: SyntheticEvent<HTMLTextAreaElement>) => {
          field.onBlur(e);
          form.setFieldValue(field.name, field.value.trim());
        }}
        autoComplete="off"
      />
      {fieldTouched && !!fieldError ? (
        <small className="p-error">{fieldError}</small>
      ) : null}
    </>
  );
};
