import { GroupCondition } from '@/api/enums';
import { equal, group } from '@/api/helpers';
import { CampaignFormValues } from '@/client/campaigns';
import { SenderProfile } from '@/client/sender-profiles';
import { RedirectPaths, RedirectPathsEnum } from '@/common/constants';
import { DialogContext } from '@/common/context';
import { SenderProfileAutocompleteInput } from '@/components/form/autocomplete';
import { useAppSelector } from '@/hooks/store';
import { selectCurrentAccount } from '@/store/features/account';
import { AppButton } from '@/ui/buttons';
import { FlexContainer } from '@/ui/styled-ui';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

type SenderProfileStepProps = {
  values: CampaignFormValues;
  valuesSetter: any;
  exitPrompt?: boolean;
};

export const SenderProfileStep: React.FC<SenderProfileStepProps> = ({
  values,
  valuesSetter,
  exitPrompt,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { id: campaignId } = useParams();
  const { setDialogData } = useContext(DialogContext);
  const account = useAppSelector(selectCurrentAccount);

  const editSelectedSender = (
    senderProfile?: SenderProfile,
    campaignId?: string,
  ) => {
    if (senderProfile && campaignId) {
      const createdInAccount = senderProfile?.account?.id === account?.id;

      if (exitPrompt) {
        return createdInAccount || account?.isSystem
          ? navigate(
              RedirectPaths[RedirectPathsEnum.SENDER_PROFILES_EDIT](
                senderProfile.id,
              ),
              { state: { campaignId } },
            )
          : navigate(
              RedirectPaths[RedirectPathsEnum.SENDER_PROFILES_CREATE](),
              { state: { senderProfile, campaignId } },
            );
      }

      setDialogData({
        type: 'confirmation',
        show: true,
        header: t('dialog.unsavedChanges'),
        message: t('dialog.unsavedChanges.confirm'),
        onAccept: () =>
          createdInAccount || account?.isSystem
            ? navigate(
                RedirectPaths[RedirectPathsEnum.SENDER_PROFILES_EDIT](
                  senderProfile.id,
                ),
                {
                  state: { campaignId },
                },
              )
            : navigate(
                RedirectPaths[RedirectPathsEnum.SENDER_PROFILES_CREATE](),
                {
                  state: { senderProfile, campaignId },
                },
              ),
      });
    }
  };

  return (
    <>
      <h2>{t('campaign.sender')}</h2>
      <div className="field w-6 mb-4">
        <label>
          {t('campaign.senderProfiles')}
          <span className="red"> *</span>
        </label>
        <FlexContainer gap={16}>
          <SenderProfileAutocompleteInput
            className="w-full"
            defaultValue={values.senderProfile}
            onChange={(profile: SenderProfile) =>
              valuesSetter('senderProfile', profile)
            }
            additionalFilters={[
              group(GroupCondition.OR, [
                equal('account', account?.id),
                equal('account', null),
              ]),
            ]}
            data-testid="campaign-form-sender-profile-step-profiles"
          />
          <AppButton
            tooltip={t('sender.edit.selected')}
            icon="pi pi-pencil"
            rounded
            type="text"
            isDisabled={!values.senderProfile}
            onClick={() => editSelectedSender(values.senderProfile, campaignId)}
          />
        </FlexContainer>
      </div>
    </>
  );
};
