import { languageDisplayLabel } from '@/api/enums';
import { MaterialType } from '@/client/courses';
import { AttemptsReport } from '@/client/reports';
import { TableNamesEnum } from '@/common/constants';
import {
  DataTable,
  DataTableColumnsMultiselect,
  DataTableColumnType,
  DataTableToolbar,
} from '@/components/tables/crud';
import { useCourse, useCourseAttemptsReports } from '@/hooks/query';
import { useTable } from '@/hooks/table.hook';
import { FlexContainer } from '@/ui/styled-ui';
import { determineAttemptStatusColor } from '@/utils/helpers/ui.helper';
import { ProgressSpinner } from 'primereact/progressspinner';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { CoursesActionHeader } from './CoursesActionHeader';
import { CoursesTabs } from './CoursesTabs';

export const CoursesAnalytics: React.FC = () => {
  const { t } = useTranslation();
  const { id: courseId } = useParams();

  const { course, isLoading: isCourseLoading } = useCourse({
    courseId,
  });

  const { skip, take, sort, onSort, onPage } = useTable();

  const { attemptStats, isLoading, refetch } = useCourseAttemptsReports({
    courseId,
  });
  const columns: DataTableColumnType[] = [
    {
      field: 'language',
      header: t('generic.lang'),
      sortable: false,
      filterable: false,
      render: (row: AttemptsReport) =>
        row.language
          ? languageDisplayLabel[row.language]
          : t('course.analytics.noLanguageInfo'),
    },
    {
      field: 'status',
      header: t('generic.status'),
      sortable: false,
      filterable: false,
      render: (row: AttemptsReport) =>
        determineAttemptStatusColor(row.status, t),
    },
    {
      field: 'count',
      style: { textAlign: 'center' },
      header: t('course.analytics.attemptsCount'),
      sortable: false,
      filterable: false,
      render: (row: AttemptsReport) => row.count,
    },
  ];

  // Set the preselected columns
  const [visibleColumns, setVisibleColumns] = useState<string[]>([]);
  const defaultVisibleColumns = columns.map((column) => column.field);
  const alwaysVisibleColumns = ['language'];
  //

  const toolbar = (
    <DataTableToolbar justify="flex-end">
      <DataTableColumnsMultiselect
        columns={columns}
        tableName={TableNamesEnum.COURSE_ANALYTICS}
        visibleColumns={visibleColumns}
        setVisibleColumns={setVisibleColumns}
        defaultVisibleColumns={defaultVisibleColumns}
        alwaysVisibleColumns={alwaysVisibleColumns}
      />
    </DataTableToolbar>
  );

  return (
    <>
      {isCourseLoading && !course && (
        <FlexContainer direction="column" className="mt-5">
          <ProgressSpinner />
          <h3>{t('course.analytics.loading')}</h3>
        </FlexContainer>
      )}
      {!isCourseLoading && course && (
        <>
          <CoursesActionHeader course={course} onTriggerRefetch={refetch} />
          {courseId && (
            <CoursesTabs
              courseId={courseId}
              isMaterial={
                course.type === MaterialType.DOCUMENT ||
                course.type === MaterialType.TEMPLATE
              }
            />
          )}
          <DataTable
            data={attemptStats}
            count={attemptStats?.length}
            isLoading={isLoading}
            toolbar={toolbar}
            columns={columns}
            visibleColumns={visibleColumns}
            onPage={onPage}
            rows={take}
            skip={skip}
            onSort={onSort}
            sort={sort}
          />
        </>
      )}
    </>
  );
};
