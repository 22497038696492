import { FilePurpose, languageDisplayCode, LanguagesEnum } from '@/api/enums';
import { equal } from '@/api/helpers';
import { Account } from '@/client/accounts';
import { CourseFormState } from '@/client/courses';
import { File } from '@/client/files';
import { Actions, Subjects } from '@/client/users';
import {
  LoadingStateType,
  RedirectPaths,
  RedirectPathsEnum,
} from '@/common/constants';
import { availableLanguages } from '@/common/constants/languages';
import { DialogContext } from '@/common/context';
import { createCourseSchema } from '@/components/courses/forms/validations';
import {
  FormikInput,
  FormikMultiSelect,
  FormikRichTextArea,
  FormikSwitch,
} from '@/components/form';
import {
  AccountSelectInput,
  EfrontCoursesMultiselectInput,
} from '@/components/form/selectors';
import { useAppSelector } from '@/hooks/store';
import { usePermission } from '@/hooks/usePermission';
import { selectCurrentAccount } from '@/store/features/account';
import { AppButton } from '@/ui/buttons';
import { AppCalendar } from '@/ui/calendar';
import { CourseFiles } from '@/ui/course-files';
import { AppFormAccordion } from '@/ui/form-accordion';
import { AppIconTooltip } from '@/ui/icon-tooltip';
import { ImageUpload } from '@/ui/image-upload';
import { FlexContainer, FormContainer } from '@/ui/styled-ui';
import { TabSwitchButtons } from '@/ui/tab-switch-buttons';
import { courseLanguagesOptions, sortAlphabetically } from '@/utils/helpers';
import { Field, Form, Formik, FormikValues } from 'formik';
import { DropdownChangeEvent } from 'primereact/dropdown';
import { FormEvent } from 'primereact/ts-helpers';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

type CourseFormProps = {
  initialValues: CourseFormState;
  state?: LoadingStateType;
  onSubmit: (data: FormikValues) => void;
  courseThumbnailData?: File;
  customAccountData?: Account;
  isCreate?: boolean;
  selfHosted?: boolean;
};

export const CourseForm: React.FC<CourseFormProps> = ({
  initialValues,
  state,
  onSubmit,
  courseThumbnailData,
  customAccountData,
  isCreate = false,
  selfHosted,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { setDialogData } = useContext(DialogContext);
  const account = useAppSelector(selectCurrentAccount);
  const isSystem = account?.isSystem;
  const retired = !!initialValues.retired;
  const { cannot } = usePermission();
  const disabled: boolean =
    cannot(Actions.CREATE, Subjects.COURSES) || !isSystem;

  const [selectedTab, setSelectedTab] = useState(
    languageDisplayCode[LanguagesEnum.EN],
  );

  const [selectedCustomAccount, setSelectedCustomAccount] =
    useState<Account | null>(null);
  const [validOptions, setValidOptions] = useState([] as string[]);
  const [isSubmitDisabled, setIsSubmitDisabled] = useState<boolean>(false);

  useEffect(() => {
    if (customAccountData) {
      setSelectedCustomAccount(customAccountData);
    }
  }, [customAccountData]);

  // Define required translations
  const requiredTranslations = [languageDisplayCode[LanguagesEnum.EN]];

  // Generate validOptions based on the current form values
  const checkValidOptions = (values: FormikValues) => {
    return availableLanguages
      .filter((languageCode) => {
        if (values.courseTranslations[languageCode]) {
          return values.courseTranslations[languageCode].name !== '';
        }
      })
      .map((languageCode) => languageDisplayCode[languageCode]);
  };

  const showDescription = (
    values: FormikValues,
    languageCode: string,
  ): boolean => {
    if (values.courseTranslations && !isSystem && !isCreate) {
      return !!values.courseTranslations[languageCode]?.description;
    }
    return true;
  };

  const cancelHandler = (values: CourseFormState) => {
    if (initialValues !== values || retired) {
      setDialogData({
        show: true,
        type: 'confirmation',
        header: t(isCreate ? 'course.cancel.create' : 'course.cancel.update'),
        message: t('course.cancel.confirm'),
        onAccept: () => navigate(RedirectPaths[RedirectPathsEnum.COURSES]()),
      });
    } else {
      navigate(RedirectPaths[RedirectPathsEnum.COURSES]());
    }
  };

  const courseLanguagesOptionTemplate = (option: any) => {
    return <span>{option.name}</span>;
  };

  const handleChangeCourseLanguages = (
    event: DropdownChangeEvent,
    values: CourseFormState,
    setFieldValue: (field: string, value: string[]) => void,
  ) => {
    const options = event.value;
    setFieldValue('courseLanguages', options);

    if (values?.courseFiles) {
      for (const key in values.courseFiles) {
        if (!options.includes(key)) {
          delete values.courseFiles[key];
        }
      }
    }
  };

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={createCourseSchema(t)}
      onSubmit={onSubmit}
      validateOnChange
      validateOnMount
    >
      {({ errors, setFieldValue, values, validateForm }) => {
        // Use a useEffect to update validOptions whenever form values change
        useEffect(() => {
          setValidOptions(checkValidOptions(values));
        }, [values]);

        useEffect(() => {
          if (!values.customContent) {
            validateForm();
          }
        }, [values.customContent, values.selfHosted]);

        return (
          <FormContainer width={100}>
            {isCreate && <h1 className="mt-0 mb-5">{t('course.new')}</h1>}
            <Form className="w-full" autoComplete="off">
              <FlexContainer
                gap={24}
                width="100%"
                justify="space-between"
                align="flex-start"
              >
                <div className="w-full">
                  <FlexContainer className="mb-4" justify="flex-start">
                    <TabSwitchButtons
                      options={availableLanguages.map(
                        (languageCode) => languageDisplayCode[languageCode],
                      )}
                      requiredOptions={requiredTranslations}
                      validOptions={validOptions}
                      onToggle={(option) => setSelectedTab(option as string)}
                      defaultSelected={selectedTab}
                    />
                  </FlexContainer>
                  {availableLanguages.map(
                    (languageCode) =>
                      selectedTab === languageDisplayCode[languageCode] && (
                        <div key={languageCode} className="w-full">
                          <div className="field mb-4">
                            <Field
                              disabled={disabled || retired}
                              id={`name-${languageCode}`}
                              label={t('course.name')}
                              name={`courseTranslations.${languageCode}.name`}
                              className="w-full"
                              component={FormikInput}
                              placeholder={t('course.name')}
                              required={languageCode === LanguagesEnum.EN}
                            />
                          </div>
                          {showDescription(values, languageCode) && (
                            <div className="field mb-0">
                              <Field
                                readOnly={disabled || retired}
                                id={`description-${languageCode}`}
                                label={t('generic.description')}
                                name={`courseTranslations.${languageCode}.description`}
                                className="w-full"
                                placeholder={t('generic.description')}
                                component={FormikRichTextArea}
                              />
                            </div>
                          )}
                        </div>
                      ),
                  )}
                  <div className="field mt-4">
                    <Field
                      disabled={disabled || retired}
                      id="course-languages"
                      name="courseLanguages"
                      filter
                      label={
                        !values?.id || values?.selfHosted
                          ? t('course.files.languages')
                          : t('course.file.languages')
                      }
                      className="w-full"
                      display="chip"
                      placeholder={t('generic.select')}
                      onChange={(event: DropdownChangeEvent) =>
                        handleChangeCourseLanguages(
                          event,
                          values,
                          setFieldValue,
                        )
                      }
                      component={FormikMultiSelect}
                      options={courseLanguagesOptions.sort((a, b) =>
                        sortAlphabetically(a.name, b.name),
                      )}
                      itemTemplate={courseLanguagesOptionTemplate}
                    />
                  </div>

                  {(!disabled || initialValues.customAccount) && (
                    <CourseFiles
                      values={values}
                      label={
                        isCreate || selfHosted
                          ? t('generic.files')
                          : t('generic.file')
                      }
                      selfHosted={selfHosted}
                      isCreate={isCreate}
                      isDisabled={disabled || retired}
                      setFieldValue={setFieldValue}
                      setIsSubmitDisabled={setIsSubmitDisabled}
                    />
                  )}

                  <div className="mb-5">
                    {!disabled && !initialValues.retired && (
                      <>
                        <AppButton
                          severity="secondary"
                          type="outlined"
                          label={t('button.cancel')}
                          className="mt-5 mr-3"
                          state={state}
                          onClick={() => cancelHandler(values)}
                        />

                        <AppButton
                          isSubmit
                          severity="success"
                          label={
                            isCreate ? t('button.create') : t('button.save')
                          }
                          className="mt-5"
                          state={state}
                          isDisabled={
                            !!Object.keys(errors).length || isSubmitDisabled
                          }
                        />
                      </>
                    )}
                  </div>
                </div>

                <div className="w-full">
                  <div className="field w-full mb-4">
                    <ImageUpload
                      label={t('generic.image')}
                      onFileUpload={(file: File | null) =>
                        setFieldValue('courseThumbnail', file?.id)
                      }
                      purpose={FilePurpose.THUMBNAIL}
                      preselectedFile={courseThumbnailData}
                      setIsSubmitDisabled={setIsSubmitDisabled}
                      isDisabled={disabled || retired}
                    />
                  </div>

                  {!disabled && (
                    <AppFormAccordion label={t('course.accordion.header')}>
                      <div className="field mb-4">
                        <label htmlFor="course-created-at">
                          {t('generic.creation')}
                        </label>
                        <AppCalendar
                          disabled={retired}
                          id="course-created-at"
                          name="courseCreatedAt"
                          className="w-full"
                          showIcon
                          iconPos="left"
                          placeholder={t('generic.select')}
                          maxDate={values.courseLastUpdatedAt}
                          value={values.courseCreatedAt}
                          onChange={(event: FormEvent) =>
                            setFieldValue('courseCreatedAt', event.value)
                          }
                          showButtonBar
                          onClearButtonClick={() => {
                            setFieldValue('courseCreatedAt', undefined);
                          }}
                          readOnlyInput
                        />
                      </div>
                      <div className="field mb-4">
                        <label htmlFor="course-last-updated-at">
                          {t('generic.last.updated')}
                        </label>
                        <AppCalendar
                          disabled={retired}
                          id="course-last-updated-at"
                          name="courseLastUpdatedAt"
                          className="w-full"
                          showIcon
                          iconPos="left"
                          placeholder={t('generic.select')}
                          minDate={values.courseCreatedAt}
                          value={values.courseLastUpdatedAt}
                          onChange={(event: FormEvent) =>
                            setFieldValue('courseLastUpdatedAt', event.value)
                          }
                          showButtonBar
                          onClearButtonClick={() => {
                            setFieldValue('courseLastUpdatedAt', undefined);
                          }}
                          readOnlyInput
                        />
                      </div>
                      {(!initialValues.id ||
                        (initialValues.id && initialValues.customContent)) && (
                        <div className="field-checkbox mb-4">
                          <Field
                            disabled={
                              retired ||
                              (initialValues.customContent &&
                                !!initialValues.id) ||
                              values.isStandardCatalogue
                            }
                            inputId="customContent"
                            name="customContent"
                            label={t('courses.custom.content')}
                            value={!!values.customAccount}
                            onChange={(
                              e: React.ChangeEvent<HTMLInputElement>,
                            ) => {
                              setFieldValue('customContent', e.target.value);
                              if (!e.target.value) {
                                setFieldValue('customAccount', null);
                                setSelectedCustomAccount(null);
                              }
                            }}
                            component={FormikSwitch}
                          />
                        </div>
                      )}
                      {values.customContent && (
                        <div className="field">
                          <AccountSelectInput
                            isDisabled={
                              retired ||
                              (initialValues.customContent &&
                                !!initialValues.id) ||
                              values.isStandardCatalogue
                            }
                            defaultValue={selectedCustomAccount}
                            onSelect={(account) => {
                              if (account) {
                                setFieldValue('customAccount', account.id);
                                setSelectedCustomAccount(account);
                              }
                            }}
                            additionalFilters={[equal('isSystem', false)]}
                          />
                          <br />
                          {!values.customAccount && (
                            <small className="p-error">
                              {t('course.select.account')}
                            </small>
                          )}
                        </div>
                      )}

                      <label htmlFor="field-checkbox">
                        {t('generic.type')}
                      </label>

                      <FlexContainer justify="flex-start">
                        <div className="field-checkbox mt-2 mb-4">
                          <Field
                            disabled={retired || values.customContent}
                            inputId="trial"
                            name="trial"
                            label={t('course.trial')}
                            component={FormikSwitch}
                            value={values.trial}
                            onChange={(
                              e: React.ChangeEvent<HTMLInputElement>,
                            ) => {
                              setFieldValue('trial', e.target.value);
                            }}
                          />

                          <AppIconTooltip
                            target="trial-icon"
                            content={t('course.tooltip.trial')}
                          />
                        </div>
                        <div className="field-checkbox mt-2 mb-4 ml-4">
                          <Field
                            disabled={retired || values.customContent}
                            inputId="isStandardCatalogue"
                            name="isStandardCatalogue"
                            label={t('course.isStandardCatalogue')}
                            component={FormikSwitch}
                            value={values.isStandardCatalogue}
                            onChange={(
                              e: React.ChangeEvent<HTMLInputElement>,
                            ) => {
                              setFieldValue(
                                'isStandardCatalogue',
                                e.target.value,
                              );
                            }}
                          />

                          <AppIconTooltip
                            target="isStandardCatalogue-icon"
                            content={t('course.tooltip.isStandardCatalogue')}
                          />
                        </div>
                      </FlexContainer>
                      <div className="field w-full mb-4">
                        <label htmlFor="eFrontCourses">
                          {`${t('courses.efront')} (${
                            values?.eFrontCourses?.length ?? 0
                          })`}
                        </label>
                        <EfrontCoursesMultiselectInput
                          isDisabled={retired}
                          className="w-full"
                          onChange={(courses) => {
                            setFieldValue('eFrontCourses', courses);
                          }}
                          selectedOptions={values.eFrontCourses}
                        />
                      </div>
                      <div className="field mb-4">
                        <Field
                          disabled={disabled || retired}
                          id="hubspotProperty"
                          label={t('course.hubspotProperty')}
                          name="hubspotProperty"
                          className="w-full"
                          component={FormikInput}
                          placeholder={t('course.hubspotProperty')}
                        />
                      </div>
                    </AppFormAccordion>
                  )}
                </div>
              </FlexContainer>
            </Form>
          </FormContainer>
        );
      }}
    </Formik>
  );
};
