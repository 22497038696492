import { equal, handleAxiosError } from '@/api/helpers';
import { AppBreadCrumbTemplate } from '@/app/AppBreadCrumbTemplate';
import { client } from '@/client';
import {
  Account,
  AccountSettings,
  AccountSMTPSettings,
} from '@/client/accounts';
import { SendTestEmailFormValues } from '@/client/campaigns';
import { SystemEmailsSendingConfigFormValues } from '@/client/system-emails';
import { RedirectPaths, RedirectPathsEnum } from '@/common/constants';
import { DialogContext } from '@/common/context';
import { TranslationFunctionType } from '@/common/types';
import { SendTestEmailModal } from '@/components/campaigns/SendTestEmailModal';
import { StateProvider } from '@/components/state-provider';
import { useNotifications } from '@/hooks/notifications.hook';
import {
  useAccount,
  useAccounts,
  useSendTestEmailAccount,
} from '@/hooks/query';
import { useSaveSystemEmailConfig } from '@/hooks/query/system-email-sending-config.hook';
import { useAppDispatch, useAppSelector } from '@/hooks/store';
import { useToast } from '@/hooks/useToast';
import {
  selectCurrentAccount,
  setCurrentAccount,
} from '@/store/features/account';
import { selectCurrentUser } from '@/store/features/users';
import { CustomSwitch, SystemEmailsTabs } from '@/system-settings';
import { SystemEmailsSendingConfigForm } from '@/system-settings/components/forms/SystemEmailsSendingConfigForm';
import { AccountTestEmailResultEvents } from '@/system-settings/enums';
import { AppBreadCrumb } from '@/ui/breadcrumb';
import { AxiosError } from 'axios';
import { isEqual } from 'lodash';
import { InputSwitchChangeEvent } from 'primereact/inputswitch';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { v4 } from 'uuid';

const pathItems = (account: Account, t: TranslationFunctionType) => [
  {
    label: account?.name,
    url: RedirectPaths[RedirectPathsEnum.EDIT_ACCOUNT](account?.id),
    template: AppBreadCrumbTemplate,
  },
  {
    label: t('generic.system.emails'),
    className: 'active',
    url: RedirectPaths[RedirectPathsEnum.SYSTEM_EMAILS_TEMPLATES](),
    template: AppBreadCrumbTemplate,
  },
  {
    label: t('generic.sending.configs'),
    url: RedirectPaths[RedirectPathsEnum.SYSTEM_EMAILS_SENDING_CONFIGS](),
    template: AppBreadCrumbTemplate,
  },
];

const initialValues = (
  sendingConfigFormValues?:
    | SystemEmailsSendingConfigFormValues
    | AccountSMTPSettings,
) => ({
  senderName: sendingConfigFormValues?.senderName || '',
  fromAddress: sendingConfigFormValues?.fromAddress || '',
  host: sendingConfigFormValues?.host || '',
  username: sendingConfigFormValues?.username || undefined,
  password: sendingConfigFormValues?.password || undefined,
  ignoreCertErrors: sendingConfigFormValues?.ignoreCertErrors || false,
});

export const SystemEmailsSendingConfigPage = () => {
  const { t } = useTranslation();
  const { accounts, isLoading } = useAccounts({
    filters: [equal('isSystem', true)],
  });
  const systemAccount = useAccount({ accountId: accounts?.result[0].id });

  return (
    <StateProvider
      state={systemAccount?.account}
      isLoading={isLoading || systemAccount.isLoading}
      loadingMessage={t('system.email.sending.config.data.loading')}
      errorMessage={t('system.email.sending.config.data.error')}
    >
      <SystemEmailsSendingConfigWithState
        systemAccount={systemAccount?.account as Account}
      />
    </StateProvider>
  );
};

const SystemEmailsSendingConfigWithState: React.FC<{
  systemAccount: Account;
}> = ({ systemAccount }) => {
  const { t } = useTranslation();
  const currentAccount = useAppSelector(selectCurrentAccount);
  const currentUser = useAppSelector(selectCurrentUser);
  const toast = useToast();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { setDialogData } = useContext(DialogContext);

  const {
    lastMessage,
    setNotificationParam: setAccountId,
    notificationParam: notificationAccountId,
    isConnected,
  } = useNotifications(client.accounts.accountsNotifyUrl);

  const updateSystemEmailConfig = useSaveSystemEmailConfig();
  const sendTestEmailAccount = useSendTestEmailAccount();

  const [showCustom, setShowCustom] = useState<boolean>(
    !!currentAccount?.settings?.smtp?.isCustom,
  );

  const [notifyId, setNotifyId] = useState<string | undefined>(undefined);
  const [showSendTestEmail, setShowSendTestEmail] = useState(false);
  const [formValues, setFormValues] =
    useState<SystemEmailsSendingConfigFormValues>(initialValues());

  useEffect(() => {
    if (!notificationAccountId) return;
    handleNotificationTestEmail(lastMessage);
  }, [JSON.stringify(lastMessage)]);

  useEffect(() => {
    if (!currentAccount) return;

    let settings: AccountSettings | undefined = systemAccount?.settings;

    if (!currentAccount?.isSystem) {
      settings = currentAccount?.settings;
    }
    setFormValues(initialValues(settings?.smtp));
  }, [currentAccount, systemAccount, showCustom]);

  const handleNotificationTestEmail = (messages: any) => {
    if (
      !messages?.data?.event ||
      !messages?.data?.payload ||
      ![
        AccountTestEmailResultEvents.SUCCESS,
        AccountTestEmailResultEvents.ERROR,
      ].includes(messages?.data?.event) ||
      messages.data.payload.notifyId !== notifyId
    )
      return;

    switch (messages.data.event) {
      case AccountTestEmailResultEvents.SUCCESS:
        toast?.success(t('toast.success'), t('account.test.email.sent'), 5000);
        break;
      case AccountTestEmailResultEvents.ERROR:
        toast?.info(t('toast.info'), t('account.test.email.failed'), 5000);
    }

    setNotifyId(undefined);
    setAccountId(undefined);
  };

  const handleSendTestEmail = async (data: SendTestEmailFormValues) => {
    const notifyId = v4();
    setNotifyId(notifyId);
    setAccountId(currentAccount?.id);

    // Wait for a notify connection to establish
    await new Promise((resolve) => {
      const intervalId = setInterval(() => {
        if (isConnected.current) {
          clearInterval(intervalId);
          resolve(true);
        }
      }, 100);
    });

    try {
      if (isConnected.current) {
        await sendTestEmailAccount.send({
          accountId: currentAccount?.id as string,
          email: data.email,
          notifyId,
        });
      }
    } catch (e) {
      handleAxiosError(e as AxiosError, toast);
    }

    setShowSendTestEmail(false);
  };

  const handleUpdateSysEmailConfig = async (
    isCustom: boolean,
    data?: SystemEmailsSendingConfigFormValues | AccountSMTPSettings,
  ) => {
    try {
      const response = await updateSystemEmailConfig.update({
        ...data,
        id: currentAccount?.id as string,
        isCustom: isCustom,
      });

      dispatch(
        setCurrentAccount({
          ...(currentAccount as Account),
          settings: {
            ...currentAccount?.settings,
            smtp: response,
          },
        }),
      );
    } catch (e) {
      handleAxiosError(e as AxiosError, toast);
    }
  };

  const handleSubmit = async (data: SystemEmailsSendingConfigFormValues) => {
    try {
      await handleUpdateSysEmailConfig(true, data);

      navigate(
        RedirectPaths[RedirectPathsEnum.SYSTEM_EMAILS_SENDING_CONFIGS](),
      );

      toast?.success(
        t('toast.success'),
        t('system.email.sending.config.save.success'),
      );
    } catch (e) {
      handleAxiosError(e as AxiosError, toast);
    }
  };

  const handleToggle = async (
    e: InputSwitchChangeEvent,
    data?: AccountSMTPSettings,
  ) => {
    setShowCustom(!!e.value);
    if (data) {
      try {
        await handleUpdateSysEmailConfig(!!e.value, data);
        navigate(
          RedirectPaths[RedirectPathsEnum.SYSTEM_EMAILS_SENDING_CONFIGS](),
        );
      } catch (e) {
        handleAxiosError(e as AxiosError, toast);
      }
    }
  };

  return (
    <>
      <AppBreadCrumb model={pathItems(currentAccount as Account, t)} />
      <SystemEmailsTabs className="mb-4" />
      {!currentAccount?.isSystem && (
        <CustomSwitch
          checked={showCustom}
          onChange={(e: InputSwitchChangeEvent) =>
            currentAccount?.settings?.smtp.host
              ? handleToggle(e, formValues)
              : handleToggle(e)
          }
          label={t('campaign.testEmail.custom')}
        />
      )}

      <SystemEmailsSendingConfigForm
        initialValues={formValues}
        onSubmit={handleSubmit}
        onSendTestEmail={(values: SystemEmailsSendingConfigFormValues) => {
          if (
            showCustom &&
            !isEqual(values, initialValues(currentAccount?.settings?.smtp))
          ) {
            setDialogData({
              show: true,
              type: 'info',
              header: t('system.email.sending.config.test.email.save'),
              message: t('system.email.sending.config.test.email.changes'),
            });

            return;
          }

          setShowSendTestEmail(true);
        }}
        disabled={!currentAccount?.isSystem && !showCustom}
      />
      <SendTestEmailModal
        email={currentUser?.email as string}
        visible={showSendTestEmail}
        onSubmit={handleSendTestEmail}
        onHide={() => setShowSendTestEmail(false)}
        extended={false}
      />
    </>
  );
};
