import { RedirectPaths, RedirectPathsEnum } from '@/common/constants';
import classNames from 'classnames';
import { MenuItem } from 'primereact/menuitem';
import { TabMenu } from 'primereact/tabmenu';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';

const getItemTemplate = (url: string, current: string) => (item: MenuItem) =>
  (
    <Link
      to={url}
      className={classNames({
        'p-menuitem-link': true,
        'p-menuitem-link-active': current.includes(url),
      })}
      role="menuitem"
    >
      <span className="p-menuitem-text">{item.label}</span>
    </Link>
  );

export const ResellerTabs = () => {
  const { t } = useTranslation();
  const location = useLocation();

  const getMenuItems = (): MenuItem[] => {
    const items: MenuItem[] = [
      {
        label: t('resellers.list'),
        template: getItemTemplate(
          RedirectPaths[RedirectPathsEnum.RESELLERS](),
          location.pathname,
        ),
      },
      {
        label: t('resellers.platformSettings'),
        template: getItemTemplate(
          RedirectPaths[RedirectPathsEnum.RESELLERS_SYSTEM](),
          location.pathname,
        ),
      },
    ];

    return items;
  };

  const [activeIndex, setActiveIndex] = useState<number>(0);

  useEffect(() => {
    switch (location.pathname) {
      case RedirectPaths[RedirectPathsEnum.RESELLERS]():
        setActiveIndex(0);
        break;
      case RedirectPaths[RedirectPathsEnum.RESELLERS_SYSTEM]():
        setActiveIndex(1);
        break;
      default:
        setActiveIndex(0);
    }
  }, [location.pathname]);

  return (
    <TabMenu
      model={getMenuItems()}
      activeIndex={activeIndex}
      onTabChange={() => null}
    />
  );
};
