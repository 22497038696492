import { File } from '@/client/files';

export const downloadCourseFile = (file: File) => {
  const link = document.createElement('a');
  link.href = file.signedUrl;
  link.setAttribute('download', file.fileName);

  // Append to html link element page
  document.body.appendChild(link);

  // Start download
  link.click();

  // Clean up and remove the link
  link.parentNode && link.parentNode.removeChild(link);
};
