import { LanguagesEnum } from '@/api/enums';
import { Account, AccountADFormValues } from '@/client/accounts';
import { LoadingStateType } from '@/common/constants';
import { azureAdSchema } from '@/components/accounts/forms/validations/azure-ad';
import { FormikInput, FormikWrappedSwitch } from '@/components/form';
import { AppButton } from '@/ui/buttons';
import { CheckboxesContainer } from '@/ui/checkboxes-container';
import { FlexContainer, FormContainer } from '@/ui/styled-ui';
import { Field, Form, Formik } from 'formik';
import { Tooltip } from 'primereact/tooltip';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { v4 } from 'uuid';
import { AzureADGroupSelection } from '../AzureADGroupSelection';

type AzureADFormProps = {
  initialValues: AccountADFormValues;
  onSubmit: (data: AccountADFormValues) => void;
  onSync: () => void;
  account: Account;
  state?: LoadingStateType;
  syncState?: LoadingStateType;
};
export const AzureADForm: React.FC<AzureADFormProps> = ({
  initialValues,
  onSubmit,
  onSync,
  account,
  state,
  syncState,
}) => {
  const { t } = useTranslation();

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={azureAdSchema(t)}
      onSubmit={onSubmit}
    >
      {({ values, errors, setFieldValue, touched, setFieldTouched }) => {
        const [cantSync, setCantSync] = useState(false);
        useEffect(() => {
          const initialValuesWithoutActive = { ...initialValues, active: null };
          const valuesWithoutActive = { ...values, active: null };
          setCantSync(
            JSON.stringify(initialValuesWithoutActive) !==
              JSON.stringify(valuesWithoutActive),
          );
        }, [Object.values(values)]);
        const hasAdConfig =
          !!values.clientId && !!values.clientSecret && !!values.tenantId;
        return (
          <FormContainer>
            <Form className="w-full">
              <div className="field w-full">
                <Field
                  id="clientId"
                  name="clientId"
                  label={t('azure.clientId')}
                  className="w-full"
                  value={values.clientId}
                  component={FormikInput}
                  required
                />
              </div>
              <div className="field w-full">
                <Field
                  id="clientSecret"
                  name="clientSecret"
                  label={t('azure.clientSecret')}
                  className="w-full"
                  value={values.clientSecret}
                  component={FormikInput}
                  required
                />
              </div>
              <div className="field w-full">
                <Field
                  id="tenantId"
                  name="tenantId"
                  label={t('azure.tenantId')}
                  className="w-full"
                  value={values.tenantId}
                  component={FormikInput}
                  required
                />
              </div>

              {Object.keys(values.mapping).map((key) => (
                <AzureADGroupSelection
                  key={key}
                  index={key}
                  values={values.mapping}
                  adGroup={values.mapping[key]}
                  account={account}
                  setFieldValue={setFieldValue}
                  errors={errors}
                  touched={touched}
                  setFieldTouched={setFieldTouched}
                />
              ))}

              <div className="mb-4">
                <AppButton
                  icon="pi pi-plus"
                  label={t('azure.newGroup')}
                  severity="secondary"
                  type="outlined"
                  onClick={() =>
                    setFieldValue('mapping', {
                      ...values.mapping,
                      [v4()]: {
                        groupId: '',
                        account: account,
                        branches: [],
                        groups: [],
                        language: LanguagesEnum.EN,
                      },
                    })
                  }
                />
              </div>

              <FlexContainer align="flex-end" justify="space-between">
                <CheckboxesContainer
                  label={t('azure.sync')}
                  className="toggle-container"
                >
                  <span
                    id="toggle-wrapper"
                    className="flex justify-content-between w-full"
                  >
                    <Field
                      inputId="active"
                      name="active"
                      label={t('generic.enable')}
                      value={values.active}
                      component={FormikWrappedSwitch}
                      disabled={
                        !initialValues.isMigratedOutsideOfEFront || !hasAdConfig
                      }
                    />
                    {!initialValues?.isMigratedOutsideOfEFront ||
                      (!hasAdConfig && (
                        <Tooltip
                          position="mouse"
                          target="#toggle-wrapper"
                          content={
                            !initialValues.isMigratedOutsideOfEFront
                              ? t('azure.sync.disabled.notMigrated.tooltip')
                              : t('azure.sync.disabled.notConfigured.tooltip')
                          }
                        />
                      ))}
                  </span>
                </CheckboxesContainer>
                {initialValues.hasAd && (
                  <AppButton
                    icon="pi pi-sync"
                    type="outlined"
                    label={t('azure.force.sync')}
                    className="mt-4 ml-3"
                    onClick={onSync}
                    state={syncState}
                    isDisabled={cantSync}
                  />
                )}
              </FlexContainer>

              <AppButton
                isSubmit
                severity="secondary"
                label={t('button.save')}
                className="w-2 min-w-min mt-4 mb-5"
                state={state}
                isDisabled={!!Object.keys(errors).length}
              />
            </Form>
          </FormContainer>
        );
      }}
    </Formik>
  );
};
