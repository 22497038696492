import { LanguagesEnum } from '@/api/enums';
import { handleAxiosError } from '@/api/helpers';
import { client } from '@/client';
import { CourseFilesType } from '@/client/courses';
import { ScormCourseDispatchResponse } from '@/client/scorm';
import { useNotifications } from '@/hooks/notifications.hook';
import { useCourse } from '@/hooks/query';
import { useAppSelector } from '@/hooks/store';
import { useToast } from '@/hooks/useToast';
import { ScormProvider } from '@/scorm';
import { ScormLanguageChanger } from '@/scorm/components/ScormLanguageChanger';
import { ScormVersion } from '@/scorm/types';
import { selectCurrentUser } from '@/store/features/users';
import { AppButton } from '@/ui/buttons';
import { FlexContainer } from '@/ui/styled-ui';
import { isUserLanguageAvailableInScorm } from '@/utils/helpers';
import { AxiosError } from 'axios';
import { Dialog, DialogProps } from 'primereact/dialog';
import { ProgressSpinner } from 'primereact/progressspinner';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

const StyledDialog = styled(Dialog)`
  width: 60%;
  max-width: 1440px;
  min-height: 85vh;

  .p-dialog-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
`;

const StyledGrayFlexContainer = styled(FlexContainer)`
  border: 1px solid var(--gray-dark);
  border-radius: var(--xsmall-border-radius);
  background-color: var(--beige-main);
  padding: var(--default-padding);
`;

const StyledIframeContainer = styled.div`
  position: relative;
  display: flex;
  margin: auto;
  width: 100%;

  &::before {
    content: '';
    width: 0;
    padding-bottom: 56.25%; /* 16:9 aspect ratio */
    display: block;
  }
`;

const StyledIframe = styled.iframe`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  border: none;
`;

type CourseScormPreviewModalProps = {
  courseId: string;
} & DialogProps;

export const CourseScormPreviewModal: React.FC<
  CourseScormPreviewModalProps
> = ({ courseId, visible, onHide }) => {
  const [isScormStarted, setIsScormStarted] = useState<boolean>(false);
  const currentUser = useAppSelector(selectCurrentUser);
  const { t } = useTranslation();
  const toast = useToast();
  const { course, isLoading, refetch } = useCourse({
    courseId: courseId,
    staleTime: Infinity,
  });
  const [isDecompressingFiles, setIsDecompressingFiles] =
    useState<boolean>(false);

  const [selectedLanguage, setSelectedLanguage] = useState<LanguagesEnum>(
    // Set initial preselected language if not available set to English
    currentUser?.language &&
      course &&
      isUserLanguageAvailableInScorm(
        course?.courseFiles,
        currentUser?.language as LanguagesEnum,
      )
      ? (currentUser?.language as LanguagesEnum)
      : LanguagesEnum.EN,
  );

  const getLanguage = () => {
    if (course?.selfHosted && course?.courseLanguages?.length === 1) {
      return course?.courseLanguages[0];
    }

    if (course && !course?.selfHosted) {
      return LanguagesEnum.EN;
    }

    return selectedLanguage;
  };

  const [isUrlLoading, setIsUrlLoading] = useState<boolean>(false);
  const [scormDetails, setScormDetails] =
    useState<ScormCourseDispatchResponse>();

  const checkAndFetchScormUrl = async () => {
    const courseLanguage = getLanguage();

    if (
      course?.courseFiles &&
      Object.values(course?.courseFiles).some(
        ({ fileMeta }: CourseFilesType) => !fileMeta?.dispatchFile,
      )
    ) {
      setIsDecompressingFiles(true);
      setCourseId(courseId);
      // Wait for a notify connection to establish
      await new Promise((resolve) => {
        const intervalId = setInterval(() => {
          if (isConnected.current) {
            clearInterval(intervalId);
            resolve(true);
          }
        }, 100);
      });
    } else if (course && courseLanguage) {
      try {
        setIsUrlLoading(true);
        const data = await client.courses.getCourseUrl(
          course.id,
          courseLanguage,
        );
        setScormDetails(data);
      } catch (e) {
        handleAxiosError(e as Error | AxiosError, toast);
      } finally {
        setIsUrlLoading(false);
      }
    }
  };

  const {
    lastMessage: notifications,
    setNotificationParam: setCourseId,
    notificationParam: notificationCourseId,
    isConnected,
  } = useNotifications(client.courses.coursesNotifyUrl);

  useEffect(() => {
    if (!notificationCourseId) return;
    handleCourseNotification(notifications);
  }, [JSON.stringify(notifications)]);

  const handleCourseNotification = async (messages: any) => {
    if (
      !messages?.data?.event ||
      !['course.files.decompressed'].includes(messages?.data?.event)
    )
      return;

    if (messages?.data.success) {
      setIsDecompressingFiles(false);
      refetch();
      checkAndFetchScormUrl();
    }

    if (!messages?.data?.success && messages?.data?.error) {
      toast?.error(t('toast.error'), messages?.data?.error, 5000);
    }

    setCourseId(undefined);
    setIsDecompressingFiles(false);
  };

  useEffect(() => {
    if (!course) return;

    if (course?.selfHosted && course?.courseLanguages?.length === 1) {
      setIsScormStarted(true);
    }

    if (course?.courseFiles?.length) {
      checkAndFetchScormUrl();
    }
  }, [course]);

  useEffect(() => {
    if (course?.courseFiles?.length) {
      checkAndFetchScormUrl();
    }

    setIsScormStarted(true);
  }, [selectedLanguage]);

  return (
    <StyledDialog
      headerStyle={{ display: 'none' }}
      blockScroll
      visible={visible}
      onHide={onHide}
      draggable={false}
      dismissableMask
      closeOnEscape
      data-testid="scorm-preview-modal"
    >
      {isLoading && !course && (
        <FlexContainer direction="column" className="mt-5">
          <ProgressSpinner />
          <h3>{t('course.content.loading')}</h3>
        </FlexContainer>
      )}
      {!isLoading && course && !!course?.courseFiles?.length && (
        <FlexContainer
          direction="column"
          align="flex-start"
          justify="flex-start"
          height="100%"
          flex="1"
        >
          {(isScormStarted || !course.selfHosted) &&
            !isUrlLoading &&
            !!course?.courseFiles?.length &&
            !isDecompressingFiles &&
            currentUser && (
              <FlexContainer direction="column" flex="1">
                {isScormStarted &&
                course.selfHosted &&
                course?.courseLanguages?.length > 1 ? (
                  <FlexContainer justify="space-between" className="mb-3">
                    <h1>{course.name}</h1>
                    <ScormLanguageChanger
                      language={selectedLanguage}
                      course={course}
                      shouldShowWarning={true}
                      onChange={(value) => setSelectedLanguage(value)}
                    />
                  </FlexContainer>
                ) : (
                  <FlexContainer justify="flex-start" className="mb-3">
                    <h1>{course.name}</h1>
                  </FlexContainer>
                )}
                <StyledIframeContainer>
                  <ScormProvider
                    course={course}
                    version={scormDetails?.version as ScormVersion}
                    user={currentUser}
                    isPreview={true}
                  >
                    <StyledIframe src={scormDetails?.url} />
                  </ScormProvider>
                </StyledIframeContainer>
                <FlexContainer justify="flex-end" className="mt-5">
                  <AppButton
                    label={t('button.close')}
                    severity="secondary"
                    type="outlined"
                    onClick={onHide}
                    className="mr-3"
                    data-testid="scorm-preview-modal-cancel"
                  />
                </FlexContainer>
              </FlexContainer>
            )}
        </FlexContainer>
      )}
      {course && !course?.courseFiles?.length && !isDecompressingFiles && (
        <>
          <h3 className="mt-auto mb-0">{t('course.scorm.preview.noFiles')}</h3>
          <FlexContainer justify="flex-end" className="mt-auto">
            <AppButton
              label={t('button.close')}
              severity="secondary"
              type="outlined"
              onClick={onHide}
              className="mr-3"
              data-testid="scorm-preview-modal-cancel"
            />
          </FlexContainer>
        </>
      )}
      {course && isDecompressingFiles && (
        <StyledGrayFlexContainer direction="column" flex="1" height="100%">
          <ProgressSpinner />
          <h3>{t('course.scorm.preview.decompressing')}</h3>
        </StyledGrayFlexContainer>
      )}
    </StyledDialog>
  );
};
