import { equal } from '@/api/helpers';
import { MaterialType } from '@/client/courses';
import {
  CourseSchedulePeriodUnit,
  PlannerCRUDCourse,
} from '@/client/planner/types';
import { useCoursesPartialRequest } from '@/hooks/query';
import { useAppSelector } from '@/hooks/store';
import { selectCurrentAccount } from '@/store/features/account';
import { AppCalendar } from '@/ui/calendar';
import { FlexContainer } from '@/ui/styled-ui';
import { TimelineIconPath } from '@/utils/helpers';
import { updateTimeline } from '@/utils/planner';
import { t } from 'i18next';
import moment from 'moment';
import { ScrollPanel } from 'primereact/scrollpanel';
import { FormEvent } from 'primereact/ts-helpers';
import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { PlannerCourseAddButton } from './PlannerCourseAddButton';
import { PlannerImageCard } from './PlannerImageCard';
import { PlannerPeriodSelector } from './PlannerPeriodSelector';

const StyledScrollPanel = styled(ScrollPanel)`
  width: 100%;
  height: 290px;
`;

const StyledDiv = styled.div`
  margin: 0 var(--xsmall-padding);
  position: relative;
  width: 160px;

  &.date-header {
    position: relative;
    margin-left: 36px;

    svg {
      display: inline-block;
      position: absolute;
      left: -26px;
      height: 100%;
      vertical-align: middle;
      width: 8px;

      path {
        fill: var(--gray-dark);
      }

      &.red {
        path {
          fill: var(--red-main);
        }
      }
    }
  }
`;

const StyledDateSpan = styled.span`
  font-size: var(--xsmall-font-size);
  text-align: center;
  color: var(--gray-darker);
  margin: auto;
  z-index: 99;
  position: absolute;
  bottom: -32px;
  left: 0;

  &.red {
    color: var(--red-main);
  }
`;

const StyledAppCalendar = styled(AppCalendar)`
  border-radius: var(--xsmall-border-radius);
  border: 1px solid var(--gray-dark);
  > input {
    height: auto;
    border-radius: var(--xsmall-border-radius);
  }
`;

type PlannerCRUDTimelineProps = {
  items: PlannerCRUDCourse[];
  isNewUsers?: boolean;
  onItemAdd: (item: any) => void;
  onItemDateChange: (date: Date, itemId: string) => void;
  onItemPeriodChange: (
    periodValue: number,
    periodType: CourseSchedulePeriodUnit,
    itemId: string,
  ) => void;
  onItemRemove: (itemId: string) => void;
};

export const PlannerCRUDTimeline: React.FC<PlannerCRUDTimelineProps> = ({
  items,
  isNewUsers,
  onItemAdd,
  onItemDateChange,
  onItemPeriodChange,
  onItemRemove,
}) => {
  const account = useAppSelector(selectCurrentAccount);
  const scrollPanelRef = useRef<any>(null);

  const [timelineItems, setTimelineItems] = useState<PlannerCRUDCourse[]>([]);

  const { courses, isLoading: isCoursesLoading } = useCoursesPartialRequest({
    filters: [
      equal('availableToAccounts', account?.id),
      equal('retired', false),
      equal('type', MaterialType.COURSE),
    ],
  });

  useEffect(() => {
    updateTimeline(items, setTimelineItems, isNewUsers);

    const slider = scrollPanelRef?.current?.getElement();
    const draggableElement = slider?.querySelector('.p-scrollpanel-content');
    const handleWheel = (event: WheelEvent) => {
      event.preventDefault();
      draggableElement.scrollBy({ left: event.deltaY < 0 ? -30 : 30 });
    };

    if (draggableElement) {
      draggableElement.addEventListener('wheel', handleWheel);
    }

    return () => {
      if (draggableElement) {
        draggableElement.removeEventListener('wheel', handleWheel);
      }
    };
  }, [items]);

  const isAllTimelineItemsPastDate = () => {
    return timelineItems.every((item) => item.isPastDate);
  };

  return (
    <div className="w-full">
      {items?.length ? (
        <StyledScrollPanel ref={scrollPanelRef}>
          <div className="flex pb-5">
            {timelineItems.map((item, index) => (
              <div
                key={`${index}${item.enrollDate}${item.id}`}
                className="flex relative"
              >
                {item.isDateHeader && (
                  <StyledDateSpan
                    className={
                      item?.firstNonPastDate && !isNewUsers ? 'red' : ''
                    }
                  >
                    {item.dateKey}
                  </StyledDateSpan>
                )}
                <StyledDiv
                  className={
                    item.isDateHeader ? 'date-header relative' : 'relative'
                  }
                >
                  {item.isDateHeader && (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      height="204"
                      viewBox="0 0 7 204"
                      fill="none"
                      className={
                        item?.firstNonPastDate && !isNewUsers ? 'red' : ''
                      }
                    >
                      <TimelineIconPath />
                    </svg>
                  )}
                  <PlannerImageCard
                    item={item}
                    isNewUsers={isNewUsers}
                    onItemRemove={onItemRemove}
                    isCRUD
                  />
                  {!isNewUsers && (
                    <StyledAppCalendar
                      id="enroll-date"
                      name="enrollDate"
                      className="w-full white-input mt-2"
                      placeholder={t('generic.select')}
                      value={moment(item.enrollDate).toDate()}
                      minDate={new Date()}
                      onChange={(event: FormEvent) => {
                        onItemDateChange(
                          moment(event.value?.toString()).utc(true).toDate(),
                          item.id,
                        );
                      }}
                      readOnlyInput
                      icon="pi pi-calendar"
                      iconPos="left"
                      style={{ width: '160px !important' }}
                      disabled={item.isPastDate || !!item.executedAt}
                    />
                  )}
                  {isNewUsers && (
                    <PlannerPeriodSelector
                      periodValue={item.periodValue}
                      periodUnit={item.periodUnit}
                      onChange={(
                        periodValue: number,
                        periodUnit: CourseSchedulePeriodUnit,
                      ) => onItemPeriodChange(periodValue, periodUnit, item.id)}
                    />
                  )}
                </StyledDiv>
                {item.isLastOfDateGroup &&
                  (!item.isPastDate ||
                    isNewUsers ||
                    isAllTimelineItemsPastDate()) && (
                    <PlannerCourseAddButton
                      courses={courses}
                      timelineItems={timelineItems}
                      currentTimelineItem={
                        isAllTimelineItemsPastDate() ? undefined : item
                      }
                      onItemAdd={onItemAdd}
                      isCoursesLoading={isCoursesLoading}
                    />
                  )}
              </div>
            ))}
          </div>
        </StyledScrollPanel>
      ) : (
        <FlexContainer direction="column" gap={0}>
          <PlannerCourseAddButton
            courses={courses}
            timelineItems={timelineItems}
            onItemAdd={onItemAdd}
            isCoursesLoading={isCoursesLoading}
          />
        </FlexContainer>
      )}
    </div>
  );
};
