import { LanguagesEnum } from '@/api/enums';
import { TranslationFunctionType } from '@/common/types';
import { generateCourseTranslationsValidationSchema } from '@/utils/helpers';
import {
  AnyObject,
  array,
  boolean,
  date,
  number,
  object,
  string,
  TestContext,
} from 'yup';

export const createCourseSchema = (t: TranslationFunctionType) =>
  object({
    courseTranslations: generateCourseTranslationsValidationSchema(t),
    courseLanguages: array(),
    courseThumbnail: string(),
    courseCreatedAt: date(),
    courseLastUpdatedAt: date(),
    customContent: boolean(),
    type: string(),
    trial: boolean(),
    eFrontCourses: array(),
    customAccount: string().when(['customContent'], ([customContent], schema) =>
      customContent === true ? schema.required() : schema.notRequired(),
    ),
    hubspotProperty: string(),
    // Validate that each language have uploaded file
    courseFiles: object().test(
      'courseFilesRequired',
      t('error.requiredFile'),
      (schema, context: TestContext<AnyObject>) => {
        const value = context.parent;

        if (!value?.selfHosted) {
          return true;
        }

        const courseLanguages =
          !value.id || value.selfHosted
            ? value.courseLanguages
            : [LanguagesEnum.EN];

        for (const language of courseLanguages) {
          const matchingFiles = Object.keys(value?.courseFiles).filter(
            (key: string) => key === language && value?.courseFiles[key]?.file,
          );

          if (!matchingFiles?.length) {
            throw context.createError({
              path: 'courseFiles',
              message: t('error.requiredFile'),
            });
          }
        }

        return true;
      },
    ),
  });

export const forceCompleteCourseSchema = (t: TranslationFunctionType) =>
  object({
    score: number()
      .min(1, t('error.minScore'))
      .max(100, t('error.maxScore'))
      .required('error.requiredField'),
  });
