import { processListingParams } from '@/api/helpers';
import { PostUserRequest } from '@/client/accounts';
import {
  Branch,
  ListBranchesRequest,
  ListBranchesResponse,
  SaveBranchRequest,
  UpdateBranchRequest,
} from '@/client/branches';
import { getExceptionFromAxiosError } from '@/client/helpers';
import { MessageResponseModel } from '@/client/models';
import { AxiosError, AxiosInstance, AxiosResponse } from 'axios';
import { omit } from 'lodash';

export const branchesService = (client: AxiosInstance) => {
  const BRANCHES_BASE_URL = 'branches';
  const USERS_BRANCHES_BASE_URL = (branchId: string) =>
    `/branches/${branchId}/users`;

  const getBranches = async (
    params: ListBranchesRequest & { withDescendants?: boolean },
  ): Promise<ListBranchesResponse> => {
    try {
      const result = await client.get<
        ListBranchesResponse,
        AxiosResponse<ListBranchesResponse>
      >(BRANCHES_BASE_URL, {
        withCredentials: true,
        params: {
          ...processListingParams(omit(params, 'withDescendants')),
          withDescendants: params.withDescendants ? 1 : 0,
        },
      });

      return result.data;
    } catch (e) {
      throw getExceptionFromAxiosError(e as AxiosError);
    }
  };

  const getBranch = async (branchId: string): Promise<Branch> => {
    try {
      const result = await client.get(`${BRANCHES_BASE_URL}/${branchId}`, {
        withCredentials: true,
      });

      return result.data;
    } catch (e) {
      throw getExceptionFromAxiosError(e as AxiosError);
    }
  };

  const saveBranch = async (request: SaveBranchRequest): Promise<Branch> => {
    try {
      const result = await client.post(BRANCHES_BASE_URL, request, {
        withCredentials: true,
      });

      return result.data;
    } catch (e) {
      throw getExceptionFromAxiosError(e as AxiosError);
    }
  };

  const saveUsers = async (
    branchId: string,
    request: PostUserRequest,
  ): Promise<MessageResponseModel> => {
    try {
      const result = await client.post<
        MessageResponseModel,
        AxiosResponse<MessageResponseModel>
      >(USERS_BRANCHES_BASE_URL(branchId), request, {
        withCredentials: true,
      });

      return result.data;
    } catch (e) {
      throw getExceptionFromAxiosError(e as AxiosError);
    }
  };

  const updateBranch = async (
    request: UpdateBranchRequest,
  ): Promise<Branch> => {
    try {
      const result = await client.patch(
        `${BRANCHES_BASE_URL}/${request.branchId}`,
        request.updates,
        {
          withCredentials: true,
        },
      );

      return result.data;
    } catch (e) {
      throw getExceptionFromAxiosError(e as AxiosError);
    }
  };

  const deleteBranch = async (
    branchId: string,
  ): Promise<MessageResponseModel> => {
    try {
      const result = await client.delete<
        MessageResponseModel,
        AxiosResponse<MessageResponseModel>
      >(`${BRANCHES_BASE_URL}/${branchId}`, {
        withCredentials: true,
      });

      return new MessageResponseModel(result.data);
    } catch (e) {
      throw getExceptionFromAxiosError(e as AxiosError);
    }
  };

  const deleteUser = async (
    branchId: string,
    userId: string,
  ): Promise<MessageResponseModel> => {
    try {
      const result = await client.delete<
        MessageResponseModel,
        AxiosResponse<MessageResponseModel>
      >(`${USERS_BRANCHES_BASE_URL(branchId)}/${userId}`, {
        withCredentials: true,
      });

      return result.data;
    } catch (e) {
      throw e as AxiosError;
    }
  };

  const importUsers = async (
    branchId: string,
    fileId: string,
    importKey: string,
  ): Promise<MessageResponseModel> => {
    try {
      const result = await client.post<
        MessageResponseModel,
        AxiosResponse<MessageResponseModel>
      >(
        `${BRANCHES_BASE_URL}/${branchId}/import`,
        { fileId, importKey },
        {
          withCredentials: true,
        },
      );

      return result.data;
    } catch (e) {
      throw e as AxiosError;
    }
  };

  const branchesNotifyUrl = (branchId: string) =>
    `${client.defaults.baseURL}${BRANCHES_BASE_URL}/${branchId}/notify`;

  return {
    getBranches,
    getBranch,
    saveBranch,
    saveUsers,
    updateBranch,
    deleteBranch,
    deleteUser,
    importUsers,
    branchesNotifyUrl,
  };
};
