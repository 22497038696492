export enum Actions {
  READ = 'read',
  CREATE = 'create',
  UPDATE = 'update',
  DELETE = 'delete',
  MANAGE = 'manage', // System casl ability, will grant all,
  SELF = 'self', // When you have access only to your own subjects (e.g the ones you've created)
  ADD_USER = 'add_user',
  REMOVE_USER = 'remove_user',
  OWN = 'own', // When you are the owner, or you were assigned as the owner (e.g admin role)
}
