import {
  languageDisplayCode,
  languageDisplayLabel,
  LanguagesEnum,
} from '@/api/enums';
import {
  CourseAvailabilityOptionsEnum,
  CourseEntityScheduleEnum,
  CourseFilesType,
  CourseTranslationsType,
  MaterialType,
  ModifiedCourseTranslationsType,
  UserStatusEnum,
} from '@/client/courses';
import { ScormStatusAttemptEnum } from '@/client/scorm';
import { Actions, Subjects, SystemRoles, User } from '@/client/users';
import { availableLanguages } from '@/common/constants/languages';
import { TranslationFunctionType } from '@/common/types';
import { usePermission } from '@/hooks/usePermission';
import { t } from 'i18next';
import { object, string } from 'yup';

export const documentContentTypeOptions = (t: TranslationFunctionType) => [
  {
    label: t('document.type.document'),
    value: MaterialType.DOCUMENT,
  },
  {
    label: t('document.type.template'),
    value: MaterialType.TEMPLATE,
  },
];

export const courseAvailabilityOptions = (t: TranslationFunctionType) => [
  {
    label: t('courses.availability.markedAsAuto'),
    value: CourseAvailabilityOptionsEnum.AUTO,
  },
  {
    label: t('courses.availability.custom'),
    value: CourseAvailabilityOptionsEnum.CUSTOM,
  },
];

export const coursesEnrollmentOptions = (t: TranslationFunctionType) => [
  {
    label: t('courses.autoEnroll.marked'),
    value: CourseAvailabilityOptionsEnum.AUTO,
  },
  {
    label: t('courses.availability.custom'),
    value: CourseAvailabilityOptionsEnum.CUSTOM,
  },
];

export const coursesEnrollToOptions = (
  t: TranslationFunctionType,
  currentUser?: User | null,
) => {
  const { can } = usePermission();

  const isBranchAdmin =
    currentUser?.role.code === SystemRoles.ADMIN && !!currentUser?.branch?.id;
  return [
    ...(isBranchAdmin
      ? []
      : [
          {
            label: t('account'),
            value: CourseEntityScheduleEnum.ACCOUNT,
          },
        ]),
    {
      label: t('branches'),
      value: CourseEntityScheduleEnum.BRANCH,
    },
    ...(can(Actions.UPDATE, Subjects.COURSE_GROUP_SCHEDULES)
      ? [
          {
            label: t('groups'),
            value: CourseEntityScheduleEnum.GROUP,
          },
        ]
      : []),
    {
      label: t('users'),
      value: CourseEntityScheduleEnum.USER,
    },
  ];
};

export const inheritedEntityType = (t: TranslationFunctionType) => [
  {
    label: t('account'),
    value: CourseEntityScheduleEnum.ACCOUNT,
  },
  {
    label: t('branch'),
    value: CourseEntityScheduleEnum.BRANCH,
  },
];

export const getScheduleEntity = (source: CourseEntityScheduleEnum) => {
  switch (source) {
    case CourseEntityScheduleEnum.ACCOUNT:
      return t('account');
    case CourseEntityScheduleEnum.BRANCH:
      return t('branch');
    case CourseEntityScheduleEnum.GROUP:
      return t('group');
    case CourseEntityScheduleEnum.USER:
      return t('user');
  }
};

export const courseLanguagesOptions = [
  {
    name: languageDisplayLabel[LanguagesEnum.ZH],
    label: languageDisplayCode[LanguagesEnum.ZH],
    value: LanguagesEnum.ZH,
  },
  {
    name: languageDisplayLabel[LanguagesEnum.CZ],
    label: languageDisplayCode[LanguagesEnum.CZ],
    value: LanguagesEnum.CZ,
  },
  {
    name: languageDisplayLabel[LanguagesEnum.DK],
    label: languageDisplayCode[LanguagesEnum.DK],
    value: LanguagesEnum.DK,
  },
  {
    name: languageDisplayLabel[LanguagesEnum.NL],
    label: languageDisplayCode[LanguagesEnum.NL],
    value: LanguagesEnum.NL,
  },
  {
    name: languageDisplayLabel[LanguagesEnum.EN],
    label: languageDisplayCode[LanguagesEnum.EN],
    value: LanguagesEnum.EN,
  },
  {
    name: languageDisplayLabel[LanguagesEnum.FI],
    label: languageDisplayCode[LanguagesEnum.FI],
    value: LanguagesEnum.FI,
  },
  {
    name: languageDisplayLabel[LanguagesEnum.FR],
    label: languageDisplayCode[LanguagesEnum.FR],
    value: LanguagesEnum.FR,
  },
  {
    name: languageDisplayLabel[LanguagesEnum.DE],
    label: languageDisplayCode[LanguagesEnum.DE],
    value: LanguagesEnum.DE,
  },
  {
    name: languageDisplayLabel[LanguagesEnum.KL],
    label: languageDisplayCode[LanguagesEnum.KL],
    value: LanguagesEnum.KL,
  },
  {
    name: languageDisplayLabel[LanguagesEnum.IT],
    label: languageDisplayCode[LanguagesEnum.IT],
    value: LanguagesEnum.IT,
  },
  {
    name: languageDisplayLabel[LanguagesEnum.LV],
    label: languageDisplayCode[LanguagesEnum.LV],
    value: LanguagesEnum.LV,
  },
  {
    name: languageDisplayLabel[LanguagesEnum.LT],
    label: languageDisplayCode[LanguagesEnum.LT],
    value: LanguagesEnum.LT,
  },
  {
    name: languageDisplayLabel[LanguagesEnum.NO],
    label: languageDisplayCode[LanguagesEnum.NO],
    value: LanguagesEnum.NO,
  },
  {
    name: languageDisplayLabel[LanguagesEnum.PL],
    label: languageDisplayCode[LanguagesEnum.PL],
    value: LanguagesEnum.PL,
  },
  {
    name: languageDisplayLabel[LanguagesEnum.PG],
    label: languageDisplayCode[LanguagesEnum.PG],
    value: LanguagesEnum.PG,
  },
  {
    name: languageDisplayLabel[LanguagesEnum.ES],
    label: languageDisplayCode[LanguagesEnum.ES],
    value: LanguagesEnum.ES,
  },
  {
    name: languageDisplayLabel[LanguagesEnum.SE],
    label: languageDisplayCode[LanguagesEnum.SE],
    value: LanguagesEnum.SE,
  },
  {
    name: languageDisplayLabel[LanguagesEnum.TR],
    label: languageDisplayCode[LanguagesEnum.TR],
    value: LanguagesEnum.TR,
  },
  {
    name: languageDisplayLabel[LanguagesEnum.VI],
    label: languageDisplayCode[LanguagesEnum.VI],
    value: LanguagesEnum.VI,
  },
  {
    name: languageDisplayLabel[LanguagesEnum.AR],
    label: languageDisplayCode[LanguagesEnum.AR],
    value: LanguagesEnum.AR,
  },
];

export const getTranslationByLanguage = (
  translations: CourseTranslationsType[],
  language: LanguagesEnum,
) => {
  const translation = translations.find(
    (object) => object.language === language,
  );

  // Fallback to English for missing translations
  const fallbackTranslation = translations.find(
    (object) => object.language === LanguagesEnum.EN,
  );

  return {
    name: translation?.name || fallbackTranslation?.name,
    description: translation?.description || fallbackTranslation?.description,
  };
};

export const priorityLanguagesOrder = [
  languageDisplayCode[LanguagesEnum.EN],
  languageDisplayCode[LanguagesEnum.DK],
  languageDisplayCode[LanguagesEnum.SE],
  languageDisplayCode[LanguagesEnum.NO],
];

export const sortLanguagesWithPriority = (languages: string[]) => {
  return languages?.sort((a, b) => {
    const priorityA = priorityLanguagesOrder.indexOf(a);
    const priorityB = priorityLanguagesOrder.indexOf(b);

    if (priorityA !== -1 || priorityB !== -1) {
      if (priorityB === -1) return -1;
      if (priorityA === -1) return 1;

      return priorityA - priorityB;
    }

    return a.localeCompare(b);
  });
};

export const getCourseLanguages = (languages: string[]) => {
  const sortedLanguages = sortLanguagesWithPriority(
    languages?.map((languageCode: string) => languageDisplayCode[languageCode]),
  );
  return sortedLanguages?.join(', ') || '';
};

export const getTranslatedScormStatusAttempt = (
  status: ScormStatusAttemptEnum | undefined,
) => {
  switch (status) {
    case ScormStatusAttemptEnum.IN_PROGRESS: {
      return t('course.attempt.status.inProgress');
    }
    case ScormStatusAttemptEnum.COMPLETED: {
      return t('course.attempt.status.completed');
    }
    case ScormStatusAttemptEnum.FAILED: {
      return t('course.attempt.status.failed');
    }
    default: {
      return undefined;
    }
  }
};

export const getAwarenessReportStatusOptions = (t: TranslationFunctionType) => [
  {
    label: t('course.attempt.status.inProgress'),
    value: UserStatusEnum.IN_PROGRESS,
  },
  {
    label: t('course.attempt.status.completed'),
    value: UserStatusEnum.COMPLETED,
  },
  {
    label: t('course.attempt.status.enrolled'),
    value: UserStatusEnum.ENROLLED,
  },
];

export const getCourseAttemptProgress = (attempt: any) => {
  if (!attempt?.status) {
    return 0;
  }

  // progress_measure have a value between 0 and 1
  const progressMeasure = (attempt?.payload?.progress_measure || 0) * 100;

  return attempt.status !== ScormStatusAttemptEnum.COMPLETED
    ? progressMeasure
    : 100;
};

// This is how data comes from BE, but we transform it to make use of our validation schema
export const modifyCourseTranslations = (
  courseTranslations: CourseTranslationsType[],
) => {
  const modifiedTranslations = {} as any;

  courseTranslations.forEach((item) => {
    const { language, name, description } = item;
    modifiedTranslations[language] = { name, description };
  });

  return modifiedTranslations;
};

export const modifyCourseFiles = (courseFiles: CourseFilesType[]) => {
  const modifiedFiles = {} as any;

  courseFiles.forEach((item) => {
    const { language, file, fileMeta } = item;
    modifiedFiles[language] = { file, fileMeta };
  });

  return modifiedFiles;
};

export const sortAlphabetically = (a: any, b: any) => {
  if (a === '' || b === '') return;
  const firstLang = a.split('[')[1];
  const secondLang = b.split('[')[1];

  return firstLang.localeCompare(secondLang);
};

export const isUserLanguageAvailableInScorm = (
  courseFiles: CourseFilesType[],
  lang: LanguagesEnum,
) => courseFiles?.some((file) => file.language === lang);

export const isUserLanguageAvailableinMaterial = (
  availableLanguages: LanguagesEnum[],
  lang: LanguagesEnum,
) => availableLanguages.includes(lang);

export const generateCourseTranslationsInitialValues = () => {
  return modifyCourseTranslations(
    availableLanguages.map((language) => ({
      language,
      name: '',
      description: '',
    })),
  );
};

export const parseModifiedCourseTranslations = (
  modifiedTranslations: ModifiedCourseTranslationsType,
) => {
  return Object.keys(modifiedTranslations).map((language) => ({
    language,
    name: modifiedTranslations[language]?.name || '',
    description: modifiedTranslations[language]?.description || '',
  }));
};

export const generateCourseTranslationsValidationSchema = (
  t: TranslationFunctionType,
) => {
  const requiredLanguages = [LanguagesEnum.EN];
  const schema = {} as any;

  availableLanguages.forEach((language) => {
    schema[language] = object().shape({
      name: requiredLanguages.includes(language)
        ? string().required(t('error.requiredField'))
        : string(),
      description: string(),
    });
  });

  return object().shape(schema);
};
