import { TranslationFunctionType } from '@/common/types';
import moment from 'moment';
import { array, date, object, string } from 'yup';

const parseDate = (value: Date | null, originalValue: Date | null) => {
  if (originalValue) {
    return moment(originalValue, true).seconds(0).millisecond(0).toDate();
  }

  return null;
};

export const launchCampaignSchema = (t: TranslationFunctionType) =>
  object().shape(
    {
      emailTemplate: string().uuid().required(t('error.select.emailTemplate')),
      landingPage: string().uuid().required(t('error.select.landingPage')),
      targetGroup: object({
        usersList: array().min(1, t('error.select.oneUser')),
      }),
      senderProfile: object().required(t('error.select.sender')),
      periodType: string().equals(['drip', 'blast']),
      launchDate: date()
        .required(t('error.select.launch'))
        .transform(parseDate),
      sendByDate: date()
        .transform(parseDate)
        .when(
          ['periodType', 'launchDate'],
          ([periodType, launchDate], schema) =>
            periodType === 'drip' && launchDate
              ? schema
                  .required(t('error.select.sendDate'))
                  .min(moment(launchDate), t('error.select.pastLaunch'))
              : schema.notRequired(),
        ),
      name: string().required(t('error.select.name')),
    },
    [
      ['sendByDate', 'periodType'],
      ['sendByDate', 'launchDate'],
    ],
  );
