import { DashboardType, LAST_SELECTED_DASHBOARD } from '@/common/constants';
import { LanguageContext } from '@/common/context/LanguageContext';
import { useAppDispatch, useAppSelector } from '@/hooks/store';
import { setCurrentDashboard } from '@/store/features/dashboard';
import { selectCurrentUser } from '@/store/features/users';
import React, { useContext, useEffect } from 'react';
import { Outlet } from 'react-router-dom';

type AppDashboardTypeSetterProps = {
  isAdmin: boolean;
};

export const AppDashboardTypeSetter: React.FC<AppDashboardTypeSetterProps> = ({
  isAdmin,
}) => {
  const dispatch = useAppDispatch();
  const currentUser = useAppSelector(selectCurrentUser);
  const { changeLanguage } = useContext(LanguageContext);

  useEffect(() => {
    if (isAdmin) {
      localStorage.setItem(LAST_SELECTED_DASHBOARD, DashboardType.ADMIN);
      dispatch(setCurrentDashboard(DashboardType.ADMIN));
    } else {
      localStorage.setItem(LAST_SELECTED_DASHBOARD, DashboardType.LEARNER);
      dispatch(setCurrentDashboard(DashboardType.LEARNER));
    }

    // TODO: Remove this when we add full SE support
    changeLanguage(currentUser?.language);
  }, [isAdmin]);
  return <Outlet />;
};
