import { equal, handleAxiosError, isIn, nested } from '@/api/helpers';
import { AppBreadCrumbTemplate } from '@/app/AppBreadCrumbTemplate';
import { Account } from '@/client/accounts';
import { Actions, Subjects, User } from '@/client/users';
import {
  LoadingStatuses,
  RedirectPaths,
  RedirectPathsEnum,
} from '@/common/constants';
import { DialogContext } from '@/common/context';
import {
  DataTableActions,
  DataTableColumnType,
} from '@/components/tables/crud';
import { UserDatatable } from '@/components/users/datatables';
import { BulkUsersModalForm } from '@/components/users/forms';
import { useAddUser, useBranch, useRemoveUser } from '@/hooks/query';
import { useAppSelector } from '@/hooks/store';
import { usePermission } from '@/hooks/usePermission';
import { useToast } from '@/hooks/useToast';
import { BranchesTabs } from '@/pages/branches/BranchesTabs';
import { selectCurrentAccount } from '@/store/features/account';
import { AppBreadCrumb } from '@/ui/breadcrumb';
import { queryStateConverter } from '@/utils/helpers';
import { AxiosError } from 'axios/index';
import { FormikHelpers, FormikValues } from 'formik';
import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { LoadingPage } from '../LoadingPage';

export const BranchUsers: React.FC = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const { can } = usePermission();
  const navigate = useNavigate();
  const location = useLocation();

  const [showBulkModal, setShowBulkModal] = useState(false);

  const { branch, isLoading } = useBranch({ branchId: id as string });
  const account = useAppSelector(selectCurrentAccount);

  const filters = [
    equal('account', (account as Account)?.id),
    equal('branch', branch?.id),
  ];

  const [shouldRefetch, setShouldRefetch] = useState<boolean>(false);

  const onCreateOrDelete = () => {
    setShouldRefetch(true);

    setTimeout(() => {
      setShouldRefetch(false);
    }, 500);
  };

  const removeUser = useRemoveUser('branches');

  const pathItems = [
    {
      label: (account as Account)?.name,
      url: RedirectPaths[RedirectPathsEnum.EDIT_ACCOUNT](
        (account as Account)?.id,
      ),
      template: AppBreadCrumbTemplate,
    },
    {
      label: t('branches'),
      url: RedirectPaths[RedirectPathsEnum.BRANCHES](),
      template: AppBreadCrumbTemplate,
    },
    {
      label: branch?.name,
      url: RedirectPaths[RedirectPathsEnum.BRANCHES_EDIT](id as string),
      template: AppBreadCrumbTemplate,
    },
    {
      label: t('users'),
      url: RedirectPaths[RedirectPathsEnum.BRANCHES_USERS](id as string),
      template: AppBreadCrumbTemplate,
    },
  ];

  const { setDialogData } = useContext(DialogContext);

  const handleRemoveManager = async (userId: string) => {
    try {
      await removeUser.remove({ typeId: branch?.id as string, userId });

      toast?.success(t('toast.success'), t('user.removed'));
      onCreateOrDelete();
    } catch (e) {
      handleAxiosError(e as Error | AxiosError, toast);
    }
  };

  const menuItems = (user: User) => [
    {
      label: t('generic.actions'),
      items: [
        {
          label: t('generic.edit'),
          icon: 'pi pi-pencil',
          command: () =>
            navigate(RedirectPaths[RedirectPathsEnum.USERS_EDIT](user.id), {
              state: {
                pathname: location.pathname,
                search: location.search,
              },
            }),
        },
        {
          label: t('generic.remove'),
          icon: 'pi pi-times',
          command: () =>
            setDialogData({
              type: 'confirmation',
              show: true,
              header: t('dialog.remove'),
              message: t('user.remove'),
              onAccept: async () => handleRemoveManager(user.id),
            }),
        },
      ],
    },
  ];

  const columns: DataTableColumnType[] = can(
    Actions.REMOVE_USER,
    Subjects.BRANCHES,
  )
    ? [
        {
          field: 'actions',
          header: '',
          sortable: false,
          filterable: false,
          style: {
            width: '80px',
            textAlign: 'center',
          },
          render: (row: User) => (
            <DataTableActions
              disabled={menuItems(row).length < 1}
              menuItems={menuItems(row)}
            />
          ),
        },
      ]
    : [];

  const addUser = useAddUser('branches');
  const toast = useToast();

  const handleSubmit = async (
    data: FormikValues,
    helpers?: FormikHelpers<{ users?: User[] }>,
  ) => {
    try {
      await addUser.add({
        typeId: branch?.id as string,
        userIds: data.users.map((user: User) => user.id),
      });

      toast?.success(
        t('toast.success'),
        data?.users && data.users?.length > 1
          ? t('users.added')
          : t('user.added'),
      );
      setShowBulkModal(false);
      helpers?.resetForm();
      onCreateOrDelete();
    } catch (e) {
      handleAxiosError(e as Error | AxiosError, toast);
    }
  };

  if (isLoading) {
    return <LoadingPage message={t('generic.loading')} />;
  }

  return (
    <>
      <AppBreadCrumb model={pathItems} />
      {branch && (
        <>
          <h1>{branch.name}</h1>
          <BranchesTabs branch={branch} />
        </>
      )}

      {can(Actions.REMOVE_USER, Subjects.BRANCHES) && (
        <BulkUsersModalForm
          visible={showBulkModal}
          onHide={() => setShowBulkModal(false)}
          modalHeading={`${t('generic.addTo')} ${branch?.name}`}
          initialValues={{ users: [] }}
          additionalFilters={[
            equal('active', true),
            nested('role', [isIn('code', ['learner', 'admin'])]),
            equal('account', (account as Account)?.id),
            equal('branch', null),
          ]}
          onSubmit={handleSubmit}
          state={addUser ? queryStateConverter(addUser) : LoadingStatuses.IDLE}
        />
      )}

      <UserDatatable
        withToolbar
        withImport
        withBranches
        withGroups={1}
        defaultFilters={filters}
        defaultTableFilters={{ status: 'yes' }}
        additionalColumns={columns}
        shouldRefetch={shouldRefetch}
        branchId={id}
        setShowBulkModal={setShowBulkModal}
      />
    </>
  );
};
