import { FiltersType } from '@/api/types';
import { UsersReportType } from '@/client/reports';
import { SummaryReportDatatable } from '@/components/reports/datatables';
import { useAppSelector } from '@/hooks/store';
import { useMixpanel } from '@/hooks/useMixpanel';
import { pendoEvent, pendoProperty, usePendo } from '@/hooks/usePendo';
import { selectCurrentAccount } from '@/store/features/account';
import { selectCurrentUser } from '@/store/features/users';
import mixpanel from 'mixpanel-browser';
import React, { useEffect, useState } from 'react';
import { ReportsActionHeader } from './ReportsActionHeader';

export const SummaryReportsPage: React.FC = () => {
  const [exportFilters, setExportFilters] = useState<FiltersType | undefined>();
  const [forceRefetch, setForceRefetch] = useState<boolean>(false);
  const currentAccount = useAppSelector(selectCurrentAccount);
  const currentUser = useAppSelector(selectCurrentUser);
  const { pendoTrack } = usePendo();
  const { track } = useMixpanel();

  useEffect(() => {
    if (currentUser?.account.isTrackingEnabled && (mixpanel as any)?.__loaded) {
      pendoTrack(pendoEvent.summaryReportVisited, {
        [pendoProperty.forAccountId]: currentAccount?.id ?? '',
        [pendoProperty.forAccountName]: currentAccount?.name ?? '',
      });
      track('View Summary reports');
    }
  }, [(mixpanel as any)?.__loaded]);

  return (
    <>
      <ReportsActionHeader
        type={UsersReportType.SUMMARY}
        filters={exportFilters}
        setForceRefetch={setForceRefetch}
      />
      <SummaryReportDatatable
        setExportFilters={setExportFilters}
        forceRefetch={forceRefetch}
      />
    </>
  );
};
