import { TranslationFunctionType } from '@/common/types';
import { boolean, object, string } from 'yup';

export const ssoSchema = (t: TranslationFunctionType) =>
  object({
    idpEntityId: string().when(
      ['isManual', 'isSSO'],
      ([isManual, isSSO], schema) =>
        isManual && isSSO
          ? schema.required(t('error.requiredField'))
          : schema.notRequired(),
    ),
    singleSignOnServiceUrl: string().when(
      ['isManual', 'isSSO'],
      ([isManual, isSSO], schema) =>
        isManual && isSSO
          ? schema.required(t('error.requiredField'))
          : schema.notRequired(),
    ),
    signingCertificate: string().when(
      ['isManual', 'isSSO'],
      ([isManual, isSSO], schema) =>
        isManual && isSSO
          ? schema.required(t('error.requiredField'))
          : schema.notRequired(),
    ),
    importUrl: string().when(
      ['isManual', 'isSSO'],
      ([isManual, isSSO], schema) =>
        !isManual && isSSO
          ? schema.required(t('error.requiredField'))
          : schema.notRequired(),
    ),
    displayName: string(),
    isSSO: boolean(),
    isManual: boolean().default(false),
  });
