import { handleAxiosError } from '@/api/helpers';
import { AppBreadCrumbTemplate } from '@/app/AppBreadCrumbTemplate';
import { UpdateMe } from '@/client/profile-settings';
import {
  DashboardType,
  RedirectPaths,
  RedirectPathsEnum,
} from '@/common/constants';
import { ProfileSettingsForm } from '@/components/me/forms';
import { useMe, useUpdateMe } from '@/hooks/query';
import { useAppDispatch, useAppSelector } from '@/hooks/store';
import { useToast } from '@/hooks/useToast';
import { selectCurrentDashboard } from '@/store/features/dashboard';
import { setCurrentUser } from '@/store/features/users';
import { AppBreadCrumb } from '@/ui/breadcrumb';
import { BackButton } from '@/ui/styled-ui/BackButton';
import { queryStateConverter } from '@/utils/helpers';
import { AxiosError } from 'axios';
import { omit } from 'lodash';
import { MenuItem } from 'primereact/menuitem';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { LoadingPage } from '../LoadingPage';

export type ProfileSettings = {
  firstName: string;
  lastName: string;
  username: string;
  email: string;
  password?: string;
  repeatPassword?: string;
  language: string;
};

const getBreadcrumbs = (
  t: (key: string, props?: object) => string,
  selectedDashboard: string | null,
): MenuItem[] => [
  {
    label: t('generic.me'),
    url:
      selectedDashboard === DashboardType.ADMIN
        ? RedirectPaths[RedirectPathsEnum.PROFILE_SETTINGS]()
        : RedirectPaths[RedirectPathsEnum.LEARNER_PROFILE_SETTINGS](),
    template: AppBreadCrumbTemplate,
  },
  {
    label: t('generic.profile'),
    url:
      selectedDashboard === DashboardType.ADMIN
        ? RedirectPaths[RedirectPathsEnum.PROFILE_SETTINGS]()
        : RedirectPaths[RedirectPathsEnum.LEARNER_PROFILE_SETTINGS](),
    template: AppBreadCrumbTemplate,
  },
];

export const ProfileSettingsPage = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { user, isLoading } = useMe({});
  const updateMe = useUpdateMe();
  const navigate = useNavigate();
  const location = useLocation();
  const toast = useToast();
  const selectedDashboard = useAppSelector(selectCurrentDashboard);

  const [initialValues, setInitialValues] = useState<ProfileSettings>({
    firstName: '',
    lastName: '',
    email: '',
    username: '',
    password: undefined,
    repeatPassword: undefined,
    language: '',
  });

  useEffect(() => {
    if (!user) return;

    setInitialValues({
      firstName: user.firstName,
      lastName: user.lastName,
      username: user.username,
      email: user.email,
      language: user.language as string,
      password: undefined,
      repeatPassword: '',
    });
  }, [user]);

  const handleUserAction = async (data: ProfileSettings) => {
    const result = await updateMe.update(
      omit(data, ['username', 'email', 'repeatPassword']) as UpdateMe,
    );
    selectedDashboard === DashboardType.ADMIN
      ? navigate(RedirectPaths[RedirectPathsEnum.PROFILE_SETTINGS]())
      : navigate(RedirectPaths[RedirectPathsEnum.LEARNER_PROFILE_SETTINGS]());

    return result;
  };

  const handleSubmit = async (data: ProfileSettings) => {
    try {
      const newUser = await handleUserAction(data);

      dispatch(setCurrentUser({ ...user, ...newUser }));

      toast?.success(t('toast.success'), t('profile.settings.updated'));
    } catch (e) {
      handleAxiosError(e as Error | AxiosError, toast);
    }
  };

  if (!user || isLoading) {
    return <LoadingPage message={t('user.loading')} />;
  }

  return (
    <>
      <AppBreadCrumb model={getBreadcrumbs(t, selectedDashboard)} />
      {selectedDashboard === DashboardType.LEARNER && (
        <BackButton
          to={
            location?.state?.from ||
            RedirectPaths[RedirectPathsEnum.LEARNER_DASHBOARD_ACTIVE]()
          }
          text={t('button.back')}
        />
      )}
      <ProfileSettingsForm
        initialValues={initialValues}
        onSubmit={handleSubmit}
        state={queryStateConverter(updateMe)}
        isSSOEnabled={!!user?.account?.meta?.isSSO}
      />
    </>
  );
};
