import { client } from '@/client';
import { MessageResponseModel } from '@/client/models';
import {
  SecurityCultureEmailMutation,
  SecurityCultureRequest,
} from '@/client/security-culture/types';
import { useMutation } from 'react-query';
import { GenericForbiddenApiError } from './types';

export const useSendEmail = (): {
  send: (payload: SecurityCultureRequest) => Promise<MessageResponseModel>;
} & SecurityCultureEmailMutation => {
  const { mutateAsync, ...rest } = useMutation<
    MessageResponseModel,
    GenericForbiddenApiError,
    SecurityCultureRequest
  >('sendSecurityCultureEmails', (payload: SecurityCultureRequest) =>
    client.securityCultureMails.sendServeyMails(payload),
  );

  return { send: mutateAsync, ...(rest as any) };
};
