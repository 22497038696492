import { Actions, Subjects, User } from '@/client/users';
import { RedirectPaths, RedirectPathsEnum } from '@/common/constants';
import { usePermission } from '@/hooks/usePermission';
import classNames from 'classnames';
import { MenuItem } from 'primereact/menuitem';
import { TabMenu } from 'primereact/tabmenu';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';

type UserTabsProps = {
  user: User;
  hasDocuments?: boolean;
};

const getItemTemplate = (url: string, current: string) => (item: MenuItem) =>
  (
    <Link
      to={url}
      className={classNames({
        'p-menuitem-link': true,
        'p-menuitem-link-active': current.includes(url),
      })}
      role="menuitem"
    >
      <span className="p-menuitem-text">{item.label}</span>
    </Link>
  );

export const UserTabs: React.FC<UserTabsProps> = ({ user, hasDocuments }) => {
  const { t } = useTranslation();
  const location = useLocation();
  const { can } = usePermission();

  const getMenuItems = (): MenuItem[] => {
    const items: MenuItem[] = [];

    if (user && can(Actions.UPDATE, Subjects.USERS)) {
      items.push({
        label: t('generic.info'),
        template: getItemTemplate(
          RedirectPaths[RedirectPathsEnum.USERS_EDIT](user.id),
          location.pathname,
        ),
      });
    }

    if (user && can(Actions.READ, Subjects.USERS)) {
      items.push({
        label: t('generic.properties'),
        template: getItemTemplate(
          RedirectPaths[RedirectPathsEnum.USERS_PROPERTIES](user.id),
          location.pathname,
        ),
      });
    }

    if (user && can(Actions.READ, Subjects.USERS)) {
      items.push({
        label: t('courses'),
        template: getItemTemplate(
          RedirectPaths[RedirectPathsEnum.USERS_COURSES](user.id),
          location.pathname,
        ),
      });
    }

    if (user && can(Actions.READ, Subjects.USERS) && hasDocuments) {
      items.push({
        label: t('documents'),
        template: getItemTemplate(
          RedirectPaths[RedirectPathsEnum.USERS_DOCUMENTS](user.id),
          location.pathname,
        ),
      });
    }

    return items;
  };

  const [activeIndex, setActiveIndex] = useState<number>(0);

  useEffect(() => {
    switch (location.pathname) {
      case RedirectPaths[RedirectPathsEnum.USERS_EDIT](user?.id):
        setActiveIndex(0);
        break;
      case RedirectPaths[RedirectPathsEnum.USERS_PROPERTIES](user?.id):
        setActiveIndex(1);
        break;
      case RedirectPaths[RedirectPathsEnum.USERS_COURSES](user?.id):
        setActiveIndex(2);
        break;
      case RedirectPaths[RedirectPathsEnum.USERS_DOCUMENTS](user?.id):
        setActiveIndex(3);
        break;
      default:
        setActiveIndex(0);
    }
  }, [location.pathname, user]);

  return (
    <TabMenu
      model={getMenuItems()}
      activeIndex={activeIndex}
      onTabChange={() => null}
    />
  );
};
