import { processListingParams } from '@/api/helpers';
import { getExceptionFromAxiosError } from '@/client/helpers';
import { AxiosError, AxiosInstance, AxiosResponse } from 'axios';
import {
  CoursePlanner,
  ListCoursePlannerRequest,
  ListCoursePlannerResponse,
  SavePlannerRequest,
} from './types';

export const plannerService = (client: AxiosInstance) => {
  const PLANNER_BASE_URL = '/account';

  const createPlanner = async (
    accountId: string,
    request: SavePlannerRequest,
  ): Promise<CoursePlanner> => {
    try {
      const result = await client.post(
        `${PLANNER_BASE_URL}/${accountId}/course-planner`,
        request,
        {
          withCredentials: true,
        },
      );

      return result.data;
    } catch (e) {
      throw getExceptionFromAxiosError(e as AxiosError);
    }
  };

  const updatePlanner = async (
    accountId: string,
    request: SavePlannerRequest,
  ): Promise<CoursePlanner> => {
    try {
      const result = await client.patch(
        `${PLANNER_BASE_URL}/${accountId}/course-planner/${request.id}`,
        request,
        {
          withCredentials: true,
        },
      );

      return result.data;
    } catch (e) {
      throw getExceptionFromAxiosError(e as AxiosError);
    }
  };

  const getPlanners = async (
    params: ListCoursePlannerRequest,
    accountId?: string,
  ): Promise<ListCoursePlannerResponse> => {
    try {
      const result = await client.get<
        ListCoursePlannerResponse,
        AxiosResponse<ListCoursePlannerResponse>
      >(`${PLANNER_BASE_URL}/${accountId}/course-planner`, {
        withCredentials: true,
        params: processListingParams(params),
      });

      return result.data;
    } catch (e) {
      throw getExceptionFromAxiosError(e as AxiosError);
    }
  };

  return {
    getPlanners,
    createPlanner,
    updatePlanner,
  };
};
