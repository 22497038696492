import { SystemEmailsSendingConfigFormValues } from '@/client/system-emails';
import { FormikInput, FormikSwitch } from '@/components/form';
import { systemEmailSendingConfigSchema } from '@/system-settings';
import { AppButton } from '@/ui/buttons';
import { FlexContainer, FormContainer } from '@/ui/styled-ui';
import { Field, Form, Formik } from 'formik';
import { Tooltip } from 'primereact/tooltip';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

type SystemEmailsSendingConfigFormProps = {
  initialValues: SystemEmailsSendingConfigFormValues;
  onSubmit: (data: SystemEmailsSendingConfigFormValues) => Promise<void>;
  onSendTestEmail: (data: SystemEmailsSendingConfigFormValues) => void;
  disabled?: boolean;
};

export const SystemEmailsSendingConfigForm: React.FC<
  SystemEmailsSendingConfigFormProps
> = ({ initialValues, onSubmit, onSendTestEmail, disabled }) => {
  const { t } = useTranslation();

  const [passwordVisibility, setPasswordVisibility] = useState(false);

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={systemEmailSendingConfigSchema(t)}
    >
      {({ values, setFieldValue }) => (
        <FormContainer>
          <Form className="w-full" autoComplete="off">
            {!disabled && (
              <>
                <div className="field">
                  <Field
                    id="senderName"
                    name="senderName"
                    label={t('generic.senderName')}
                    className="w-full"
                    component={FormikInput}
                    disabled={disabled}
                  />
                </div>
                <Tooltip target=".from-tooltip-icon" />
                <div className="field">
                  <Field
                    id="fromAddress"
                    name="fromAddress"
                    label={
                      <>
                        {t('generic.senderEmail')}
                        <i
                          className="pi pi-question-circle from-tooltip-icon ml-1"
                          data-pr-tooltip={t('sender.smtp.info')}
                        />
                      </>
                    }
                    className="w-full"
                    type="email"
                    component={FormikInput}
                    disabled={disabled}
                  />
                </div>
                <div className="field">
                  <Field
                    id="host"
                    name="host"
                    label={t('sender.host')}
                    className="w-full"
                    component={FormikInput}
                    placeholder="smtp.example.com:25"
                    required
                    disabled={disabled}
                  />
                </div>
                <div className="field">
                  <Field
                    id="username"
                    name="username"
                    label={t('generic.username')}
                    className="w-full"
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      setFieldValue('username', e.target.value || undefined)
                    }
                    component={FormikInput}
                    disabled={disabled}
                  />
                </div>
                <FlexContainer align="flex-start">
                  <div className="field w-full mb-0">
                    <Field
                      id="password"
                      label={t('generic.password')}
                      name="password"
                      type={passwordVisibility ? 'text' : 'password'}
                      className="w-full"
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        setFieldValue(
                          'password',
                          e.target.value.replace(/ /g, '') || undefined,
                        )
                      }
                      component={FormikInput}
                      disabled={disabled}
                    />
                  </div>
                  <AppButton
                    icon={passwordVisibility ? 'pi pi-eye' : 'pi pi-eye-slash'}
                    type="text"
                    severity="secondary"
                    onClick={() => setPasswordVisibility((state) => !state)}
                    className="input-eye-button"
                  />
                </FlexContainer>
                <Tooltip target=".custom-tooltip-icon" />
                <div className="field-checkbox mt-3">
                  <Field
                    inputId="ignore-errors"
                    name="ignoreCertErrors"
                    label={
                      <FlexContainer>
                        {t('sender.ignoreErrors')}
                        <i
                          className="pi pi-question-circle custom-tooltip-icon ml-2"
                          data-pr-tooltip={t('sender.ignoreErrors.info')}
                        />
                      </FlexContainer>
                    }
                    component={FormikSwitch}
                    disabled={disabled}
                  />
                </div>
              </>
            )}
            <div className="mt-6 mb-5 flex gap-4">
              <AppButton
                label={t('camapign.testEmail.send')}
                type="outlined"
                onClick={() => onSendTestEmail(values)}
              />
              {!disabled && (
                <AppButton
                  isSubmit
                  label={t('button.save')}
                  severity="secondary"
                  isDisabled={disabled}
                />
              )}
            </div>
          </Form>
        </FormContainer>
      )}
    </Formik>
  );
};
