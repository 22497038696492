import { FiltersType, GroupedFilterConditions } from '@/api/types';
import { client } from '@/client';
import {
  ListScormCoursesResponse,
  ScormCourseAttempt,
  ScormStatusAttemptEnum,
} from '@/client/scorm';
import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query';

const queryKey = 'scorms';
const singleQueryKey = 'scorm';

export const useScormCourses = (
  params: UseQueryOptions & {
    take?: number;
    skip?: number;
    filters?: FiltersType | GroupedFilterConditions;
    type: ScormStatusAttemptEnum;
    search?: string;
  },
): UseQueryResult<ListScormCoursesResponse> & {
  scormCourses: ListScormCoursesResponse | undefined;
} => {
  const {
    type,
    take = 200,
    skip = 0,
    filters,
    retry = false,
    retryDelay = 500,
    staleTime = 0,
    cacheTime = 0,
    search,
    ...restOptions
  } = params;

  const { data, ...rest } = useQuery(
    [
      queryKey,
      take,
      skip,
      type,
      JSON.stringify(filters),
      JSON.stringify(search),
    ],
    () => client.scormCourses.getScormCourses(params),
    {
      retry,
      retryDelay,
      staleTime,
      cacheTime,
      ...(restOptions as any),
    },
  );

  return {
    scormCourses: data,
    ...(rest as UseQueryResult<ListScormCoursesResponse>),
  };
};

export const useScormCourseLastAttempt = (
  params: UseQueryOptions & {
    scormCourseId?: string;
  },
): UseQueryResult<ScormCourseAttempt> & {
  scormCourseAttempt: ScormCourseAttempt | undefined;
} => {
  const {
    scormCourseId,
    retry = false,
    retryDelay = 500,
    staleTime = 0,
    cacheTime = 0,
    ...restOptions
  } = params;

  const { data, ...rest } = useQuery(
    [singleQueryKey, scormCourseId],
    () =>
      scormCourseId
        ? client.scormCourses.getScormCourseLastAttempt(scormCourseId)
        : undefined,
    {
      retry,
      retryDelay,
      staleTime,
      cacheTime,
      ...(restOptions as UseQueryResult<ScormCourseAttempt>),
    },
  );

  return {
    scormCourseAttempt: data,
    ...(rest as UseQueryResult<ScormCourseAttempt>),
  };
};

export const useScormCourseAttempt = (
  params: UseQueryOptions & {
    scormCourseId?: string;
    attemptId?: string;
  },
): UseQueryResult<ScormCourseAttempt> & {
  scormCourseAttempt: ScormCourseAttempt | undefined;
} => {
  const {
    scormCourseId,
    attemptId,
    retry = false,
    retryDelay = 500,
    staleTime = 0,
    cacheTime = 0,
    ...restOptions
  } = params;

  const { data, ...rest } = useQuery(
    [singleQueryKey, scormCourseId],
    () =>
      scormCourseId
        ? client.scormCourses.getScormCourseAttempt(scormCourseId, attemptId)
        : undefined,
    {
      retry,
      retryDelay,
      staleTime,
      cacheTime,
      ...(restOptions as UseQueryResult<ScormCourseAttempt>),
    },
  );

  return {
    scormCourseAttempt: data,
    ...(rest as UseQueryResult<ScormCourseAttempt>),
  };
};
