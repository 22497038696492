import { FlexContainer } from '@/ui/styled-ui';
import React, { ReactNode } from 'react';
import styled from 'styled-components';

const StyledCard = styled(FlexContainer)`
  flex: 1 1 30%;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--default-padding);
  border: 1px solid var(--gray-dark);
  border-radius: var(--xsmall-border-radius);

  &.placeholder {
    background-color: var(--beige-main);
    border-color: var(--beige-main);
    min-height: 200px;
  }
`;

const StyledText = styled.div`
  line-height: var(--small-line-height);
  font-size: var(--small-font-size);
`;

type AccountCardPropsType = {
  title?: string;
  content?: string | ReactNode;
  footer?: ReactNode;
  isPlaceholder?: boolean;
} & React.HTMLAttributes<any>;

export const AccountCard: React.FC<AccountCardPropsType> = ({
  title,
  content,
  footer,
  isPlaceholder,
}) => {
  return (
    <StyledCard className={isPlaceholder ? 'placeholder' : ''}>
      {!isPlaceholder && (
        <>
          <h4 className="m-0 pb-3">{title}</h4>
          <StyledText className="m-0 pb-4 text-sm">{content}</StyledText>
          {footer}
        </>
      )}
    </StyledCard>
  );
};
