import { RedirectPaths, RedirectPathsEnum } from '@/common/constants';
import classNames from 'classnames';
import { MenuItem } from 'primereact/menuitem';
import { TabMenu } from 'primereact/tabmenu';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

export const LearnerDashboardCoursesTabs: React.FC = () => {
  const { t } = useTranslation();

  const getItemTemplate = (url: string, current: string) => (item: MenuItem) =>
    (
      <Link
        to={url}
        className={classNames({
          'p-menuitem-link': true,
          'p-menuitem-link-active': current.includes(url),
        })}
        role="menuitem"
      >
        <span className="p-menuitem-text">{item.label}</span>
      </Link>
    );

  const tabItems: MenuItem[] = [
    {
      label: t('generic.toDo'),
      template: getItemTemplate(
        RedirectPaths[RedirectPathsEnum.LEARNER_DASHBOARD_ACTIVE](),
        location.pathname,
      ),
    },
    {
      label: t('generic.completed'),
      template: getItemTemplate(
        RedirectPaths[RedirectPathsEnum.LEARNER_DASHBOARD_COMPLETED](),
        location.pathname,
      ),
    },
  ];

  const [activeIndex, setActiveIndex] = useState<number>(0);

  useEffect(() => {
    switch (location.pathname) {
      case RedirectPaths[RedirectPathsEnum.LEARNER_DASHBOARD_ACTIVE]():
        setActiveIndex(0);
        break;
      case RedirectPaths[RedirectPathsEnum.LEARNER_DASHBOARD_COMPLETED]():
        setActiveIndex(1);
        break;
      default:
        setActiveIndex(0);
    }
  }, [location.pathname]);

  return (
    <TabMenu
      model={tabItems}
      activeIndex={activeIndex}
      onTabChange={() => null}
    />
  );
};
