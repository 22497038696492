import { LanguagesEnum } from '@/api/enums';
import { ListingRequest, ListingResponse } from '@/api/types';
import { Course } from '@/client/courses';
import { User } from '@/client/users';
import { ScormVersion } from '@/scorm/types';

export enum ScormStatusAttemptEnum {
  IN_PROGRESS = 'in-progress',
  COMPLETED = 'completed',
  FAILED = 'failed',
}

export enum ScormCMICompletionStatusEnum {
  BROWSED = 'browsed', // Only for SCORM 1.2
  NOT_ATTEMPTED = 'not attempted',
  INCOMPLETE = 'incomplete',
  COMPLETED = 'completed',
  PASSED = 'passed', // Only for SCORM 1.2
  FAILED = 'failed', // Only for SCORM 1.2
  UNKNOWN = 'unknown', // Only for SCORM 2004
}

export type ScormCourseAttempt = {
  id: string;
  course: Course | null;
  user: User | null;
  status: ScormStatusAttemptEnum;
  created: Date;
  updated: Date;
  payload: Record<string, any>;
  score: number;
  language?: LanguagesEnum;
};

export type ScormCourseDispatchResponse = {
  url: string;
  version: ScormVersion;
  cookies: {
    'CloudFront-Key-Pair-Id': string;
    'CloudFront-Signature': string;
    'CloudFront-Expires'?: number;
    'CloudFront-Policy'?: string;
  };
};

export type UpdateScormCourseAttemptRequest = {
  courseId: string;
  attemptId: string;
  payload?: Record<string, any>;
  language?: LanguagesEnum;
};

export type ListScormCoursesRequest = ListingRequest;
export type ListScormCoursesResponse = ListingResponse<Course>;
