import { CampaignFormValues } from '@/client/campaigns';
import { EmailTemplateStep } from '@/components/campaigns/forms/EmailTemplateStep';
import { PageTemplateStep } from '@/components/campaigns/forms/PageTemplateStep';
import { PeriodStep } from '@/components/campaigns/forms/PeriodStep';
import { SenderProfileStep } from '@/components/campaigns/forms/SenderProfileStep';
import { TargetGroupStep } from '@/components/campaigns/forms/TargetGroupStep';
import React from 'react';
import { InfoStep } from './InfoStep';

type CampaignWizardStepsProps = {
  activeStep: number;
  values: CampaignFormValues;
  setFieldValue: (field: string, value: any) => void;
  campaignId?: string;
  exitPrompt?: boolean;
  isTemplate?: boolean;
};

export const CampaignWizardSteps: React.FC<CampaignWizardStepsProps> = ({
  activeStep,
  values,
  setFieldValue,
  campaignId,
  exitPrompt,
  isTemplate,
}) => {
  const step = () => {
    switch (activeStep) {
      case 0:
        return (
          <EmailTemplateStep
            values={values}
            valuesSetter={setFieldValue}
            exitPrompt={exitPrompt}
            preselectedEmailTempId={values.emailTemplate}
          />
        );
      case 1:
        return (
          <PageTemplateStep
            values={values}
            valuesSetter={setFieldValue}
            exitPrompt={exitPrompt}
          />
        );
      case 2:
        return (
          <SenderProfileStep
            values={values}
            valuesSetter={setFieldValue}
            exitPrompt={exitPrompt}
          />
        );
      case 3:
        return isTemplate ? (
          <InfoStep values={values} valuesSetter={setFieldValue} />
        ) : (
          <TargetGroupStep values={values} valuesSetter={setFieldValue} />
        );
      case 4:
        return (
          <PeriodStep
            values={values}
            valuesSetter={setFieldValue}
            campaignId={campaignId}
          />
        );
      default:
        return null;
    }
  };

  return step();
};
