import { FlexContainer } from '@/ui/styled-ui';
import { Chart } from 'primereact/chart';
import { Skeleton } from 'primereact/skeleton';
import React, { ReactNode, useEffect, useState } from 'react';
import styled from 'styled-components';

const StyledTitle = styled.h3`
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  font-size: var(--medium-font-size);
  line-height: var(--medium-line-height);

  &.report-title {
    font-size: var(--small-font-size);
    font-weight: 500;
    line-height: var(--small-line-height);
  }

  .label-icon {
    fill: var(--purple-dark);
    vertical-align: middle;
    font-size: var(--small-font-size);
  }
`;

const StyledCart = styled.div`
  position: relative;
  .count-label {
    font-size: var(--small-font-size);
    line-height: var(--small-line-height);
    font-weight: 700;
    color: var(--red-main);
    text-align: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    .count {
      font-size: calc(2 * var(--small-font-size));
      line-height: var(--small-line-height);
      font-weight: 700;
      color: var(--black-main);
    }

    .all-count {
      color: var(--gray-darker);
    }
  }
`;

export type DoughnutChartProps = {
  id: string;
  label: string;
  allCount: number;
  singleCount: number;
  cutout?: string;
  width?: string;
  isPercentCount?: boolean;
  isDashboard?: boolean;
  labelIcon?: ReactNode;
  isLoading?: boolean;
};

export const DoughnutChart: React.FC<DoughnutChartProps> = ({
  id,
  label,
  allCount,
  singleCount,
  cutout,
  width = '220px',
  isPercentCount,
  isDashboard,
  labelIcon,
  isLoading,
}) => {
  const [chartData, setChartData] = useState({});
  const [chartOptions, setChartOptions] = useState({});
  const documentStyle = getComputedStyle(document.documentElement);

  const calcPercentages = (singleCount: number, allCount: number) => {
    if (allCount !== 0) {
      const percentage = Math.round((singleCount / allCount) * 100);
      return percentage > 100 ? 100 : percentage;
    } else return 0;
  };

  useEffect(() => {
    const calculateRemainder = () => {
      if (allCount < 1) {
        return 1;
      } else if (allCount === singleCount || allCount - singleCount < 1) {
        return 0;
      } else {
        return allCount - singleCount;
      }
    };

    const data = {
      datasets: [
        {
          label: label,
          data: [singleCount, calculateRemainder()],
          backgroundColor: [
            documentStyle.getPropertyValue('--red-main'),
            documentStyle.getPropertyValue('--gray-dark'),
          ],
          borderColor: 'transparent',
        },
      ],
    };
    const options = {
      cutout: cutout ?? '80%',
      plugins: {
        legend: {
          display: false,
        },
        tooltip: { enabled: false },
      },
      responsive: false,
      maintainAspectRatio: false,
    };

    setChartData(data);
    setChartOptions(options);
  }, [allCount, singleCount, label]);

  return (
    <FlexContainer direction="column" width="min-content">
      {!isDashboard && (
        <StyledTitle className="m-0 mb-4 text-center report-title">
          <svg
            className="label-icon"
            width="33"
            height="32"
            viewBox="0 0 33 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            {labelIcon}
          </svg>
          {label}
        </StyledTitle>
      )}

      {isLoading ? (
        <Skeleton className="chart" shape="circle" size="160px" />
      ) : (
        <StyledCart>
          <Chart
            id={id}
            type="doughnut"
            data={chartData}
            options={chartOptions}
            className="chart"
            width={width}
          />
          <span className="count-label">
            <span className="count">
              {calcPercentages(Number(singleCount), Number(allCount))}%
            </span>
            {!isPercentCount && (
              <div>
                {singleCount} <span className="all-count"> / {allCount}</span>
              </div>
            )}
          </span>
        </StyledCart>
      )}

      {isDashboard && !isPercentCount && (
        <StyledTitle className="m-0 mt-4 text-center report-title">
          <svg
            className="label-icon"
            width="33"
            height="32"
            viewBox="0 0 33 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            {labelIcon}
          </svg>
          {label}
        </StyledTitle>
      )}
    </FlexContainer>
  );
};
