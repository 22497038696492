import { FiltersType, ListingRequest } from '@/api/types';
import { client } from '@/client';
import { BranchFormState } from '@/client/branches';
import { SystemRoles } from '@/client/users';
import { LoadingStateType } from '@/common/constants';
import { branchesSchema } from '@/components/branches/forms/validations';
import { FormikSwitch } from '@/components/form';
import { FormikInput } from '@/components/form/FormikInput';
import { FormikLazyDropdown } from '@/components/form/FormikLazyDropdown';
import { useAppSelector } from '@/hooks/store';
import { selectCurrentUser } from '@/store/features/users';
import { AppButton } from '@/ui/buttons';
import { FormContainer } from '@/ui/styled-ui';
import { Field, Form, Formik, FormikValues } from 'formik';
import { DropdownChangeEvent } from 'primereact/dropdown';
import React from 'react';
import { useTranslation } from 'react-i18next';

type BranchFormProps = {
  initialValues?: BranchFormState;
  additionalFilters?: FiltersType;
  state?: LoadingStateType;
  onSubmit: (data: FormikValues) => void;
  disabled?: boolean;
};

export const BranchForm: React.FC<BranchFormProps> = ({
  initialValues,
  additionalFilters,
  state,
  onSubmit,
  disabled,
}) => {
  const { t } = useTranslation();
  const user = useAppSelector(selectCurrentUser);
  const isBranchAdmin: boolean =
    user?.role.code === SystemRoles.ADMIN && !!user.branch?.id;

  const fetchBranches = async (params: ListingRequest) =>
    await client.branches.getBranches({ ...params, sort: ['name,asc'] });

  return (
    <Formik
      enableReinitialize
      initialValues={
        initialValues ?? { name: '', parent: undefined, active: false }
      }
      validationSchema={branchesSchema(t, isBranchAdmin)}
      onSubmit={onSubmit}
    >
      {({ errors, setFieldValue, setFieldTouched }) => (
        <FormContainer>
          <Form className="w-full" autoComplete="off">
            <div className="field w-full mb-4">
              <Field
                id="name"
                name="name"
                label={t('generic.name')}
                className="w-full"
                component={FormikInput}
                placeholder={t('branch.name')}
                required
                disabled={disabled}
              />
            </div>
            <div className="field w-full mb-4">
              <Field
                id="parent"
                showClear
                label={t('branch.parent')}
                name="parent"
                filter
                onChange={(e: DropdownChangeEvent) =>
                  setFieldValue('parent', e.value)
                }
                onBlur={() => setFieldTouched('parent', true)}
                optionLabel="name"
                className="w-full"
                component={FormikLazyDropdown}
                placeholder={t('generic.select')}
                fetchService={fetchBranches}
                additionalFilters={additionalFilters}
                disabled={disabled}
                required={isBranchAdmin}
              />
            </div>
            <div className="field-checkbox">
              <Field
                inputId="active"
                name="active"
                label={t('branch.setActive')}
                component={FormikSwitch}
                disabled={disabled}
              />
            </div>

            <AppButton
              isSubmit
              severity="secondary"
              label={
                initialValues?.name ? t('button.save') : t('button.create')
              }
              className="w-4 mt-4 mb-5"
              state={state}
              isDisabled={!!Object.keys(errors).length || disabled}
            />
          </Form>
        </FormContainer>
      )}
    </Formik>
  );
};
