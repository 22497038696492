import { processListingParams } from '@/api/helpers';
import { getExceptionFromAxiosError } from '@/client/helpers';
import {
  ListScormCoursesRequest,
  ListScormCoursesResponse,
  ScormCourseAttempt,
  ScormStatusAttemptEnum,
  UpdateScormCourseAttemptRequest,
} from '@/client/scorm';
import { AxiosError, AxiosInstance, AxiosResponse } from 'axios';

export const scormService = (client: AxiosInstance) => {
  const SCORM_BASE_URL = '/me/courses';

  const getScormCourses = async (
    params: ListScormCoursesRequest & { type: ScormStatusAttemptEnum },
  ): Promise<ListScormCoursesResponse> => {
    try {
      const result = await client.get<
        ListScormCoursesResponse,
        AxiosResponse<ListScormCoursesResponse>
      >(SCORM_BASE_URL, {
        withCredentials: true,
        params: processListingParams(params),
      });

      return result.data;
    } catch (e) {
      throw getExceptionFromAxiosError(e as AxiosError);
    }
  };

  const getScormCourseLastAttempt = async (
    scormCourseId?: string,
  ): Promise<ScormCourseAttempt> => {
    try {
      const result = await client.get<
        ScormCourseAttempt,
        AxiosResponse<ScormCourseAttempt>
      >(`${SCORM_BASE_URL}/${scormCourseId}/attempt`, {
        withCredentials: true,
      });

      return result.data;
    } catch (e) {
      throw getExceptionFromAxiosError(e as AxiosError);
    }
  };

  const getScormCourseAttempt = async (
    scormCourseId?: string,
    attemptId?: string,
  ): Promise<ScormCourseAttempt> => {
    try {
      const result = await client.get<
        ScormCourseAttempt,
        AxiosResponse<ScormCourseAttempt>
      >(`${SCORM_BASE_URL}/${scormCourseId}/attempt/${attemptId}`, {
        withCredentials: true,
      });

      return result.data;
    } catch (e) {
      throw getExceptionFromAxiosError(e as AxiosError);
    }
  };

  const getScormCourseAttempts = async (
    scormCourseId?: string,
  ): Promise<ScormCourseAttempt[]> => {
    try {
      const result = await client.get<
        ScormCourseAttempt[],
        AxiosResponse<ScormCourseAttempt[]>
      >(`${SCORM_BASE_URL}/${scormCourseId}/attempts`, {
        withCredentials: true,
      });

      return result.data;
    } catch (e) {
      throw getExceptionFromAxiosError(e as AxiosError);
    }
  };

  const createScormCourseNewAttempt = async (
    courseId: string,
  ): Promise<ScormCourseAttempt> => {
    try {
      return (
        await client.post(`${SCORM_BASE_URL}/${courseId}/attempt`, {
          withCredentials: true,
        })
      ).data;
    } catch (e) {
      throw getExceptionFromAxiosError(e as AxiosError);
    }
  };

  const updateScormCourseAttempt = async (
    params: UpdateScormCourseAttemptRequest,
  ): Promise<ScormCourseAttempt> => {
    try {
      const result = await client.patch<
        ScormCourseAttempt,
        AxiosResponse<ScormCourseAttempt>
      >(
        `${SCORM_BASE_URL}/${params.courseId}/attempt/${params.attemptId}`,
        { ...params.payload, language: params.language },
        {
          withCredentials: true,
        },
      );

      return result.data;
    } catch (e) {
      throw getExceptionFromAxiosError(e as AxiosError);
    }
  };

  return {
    getScormCourses,
    getScormCourseLastAttempt,
    getScormCourseAttempt,
    getScormCourseAttempts,
    createScormCourseNewAttempt,
    updateScormCourseAttempt,
  };
};
