import { handleAxiosError } from '@/api/helpers';
import { AppBreadCrumbTemplate } from '@/app/AppBreadCrumbTemplate';
import { client } from '@/client';
import { Account, AccountSSOConfig } from '@/client/accounts';
import { Actions, Subjects } from '@/client/users';
import {
  LoadingStateType,
  LoadingStatuses,
  RedirectPaths,
  RedirectPathsEnum,
} from '@/common/constants';
import { TranslationFunctionType } from '@/common/types';
import { SSOForm } from '@/components/accounts/forms/SSOForm';
import { useNotifications } from '@/hooks/notifications.hook';
import { useAccount, useImportSSOConfig, useSSOConfig } from '@/hooks/query';
import { useAppSelector } from '@/hooks/store';
import { usePermission } from '@/hooks/usePermission';
import { useToast } from '@/hooks/useToast';
import { AccountTabs } from '@/pages/accounts/AccountTabs';
import { selectCurrentAccount } from '@/store/features/account';
import { AppBreadCrumb } from '@/ui/breadcrumb';
import { FlexContainer } from '@/ui/styled-ui';
import { AxiosError } from 'axios';
import { MenuItem } from 'primereact/menuitem';
import { ProgressSpinner } from 'primereact/progressspinner';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

const getPathItems = (
  account: Account | undefined,
  currentAccount: Account,
  can: (action: Actions, subject: Subjects) => boolean,
  t: TranslationFunctionType,
): MenuItem[] => {
  const pathItems: MenuItem[] = [];

  pathItems.push({
    label: currentAccount?.name,
    url: !currentAccount.isSystem
      ? RedirectPaths[RedirectPathsEnum.ACCOUNT](currentAccount?.id)
      : RedirectPaths[RedirectPathsEnum.EDIT_ACCOUNT](currentAccount?.id),
    template: AppBreadCrumbTemplate,
  });

  if (
    currentAccount?.isSystem &&
    account &&
    can(Actions.READ, Subjects.ACCOUNTS)
  ) {
    pathItems.push(
      {
        label: t('accounts'),
        url: RedirectPaths[RedirectPathsEnum.ACCOUNTS](),
        template: AppBreadCrumbTemplate,
      },
      {
        label: account?.name,
        url: RedirectPaths[RedirectPathsEnum.EDIT_ACCOUNT](account?.id),
        className: 'active',
        template: AppBreadCrumbTemplate,
      },
    );
  }

  pathItems.push({
    label: t('sso'),
    url: RedirectPaths[RedirectPathsEnum.ACCOUNT_SSO](
      account ? account?.id : currentAccount?.id,
    ),
    template: AppBreadCrumbTemplate,
  });

  return pathItems;
};

export const AccountSSOPage = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const toast = useToast();
  const { account, isLoading, refetch } = useAccount({ accountId: id });
  const currentAccount = useAppSelector(selectCurrentAccount);
  const { can } = usePermission();
  const [loadingState, setLoadingState] = useState<LoadingStateType>(
    LoadingStatuses.IDLE,
  );
  const [initialValues, setInitialValues] = useState<AccountSSOConfig>({
    isSSO: false,
    isManual: true,
    idpEntityId: '',
    singleSignOnServiceUrl: '',
    signingCertificate: '',
    displayName: '',
  });

  const parseInitialValues = () => {
    const initialManualValues: any = {
      isSSO: true,
      isManual: true,
    };

    if (account) {
      initialManualValues.idpEntityId = account?.ssoSettings?.idpEntityId;
      initialManualValues.singleSignOnServiceUrl =
        account?.ssoSettings?.singleSignOnServiceUrl;
      initialManualValues.signingCertificate =
        account?.ssoSettings?.signingCertificate;
      initialManualValues.displayName = account?.ssoSettings?.displayName || '';
      initialManualValues.isSSO = account?.meta?.isSSO || false;
    } else {
      initialManualValues.idpEntityId =
        currentAccount?.ssoSettings?.idpEntityId;
      initialManualValues.singleSignOnServiceUrl =
        currentAccount?.ssoSettings?.singleSignOnServiceUrl;
      initialManualValues.signingCertificate =
        currentAccount?.ssoSettings?.signingCertificate;
      initialManualValues.displayName =
        currentAccount?.ssoSettings?.displayName || '';
      initialManualValues.isSSO = currentAccount?.meta?.isSSO || false;
    }

    return initialManualValues;
  };

  useEffect(() => {
    setInitialValues(parseInitialValues());
  }, [account, currentAccount]);

  const {
    lastMessage: notifications,
    setNotificationParam: setAccountId,
    notificationParam: notificationAccountId,
    isConnected,
  } = useNotifications(client.accounts.ssoNotifyUrl);

  useEffect(() => {
    if (!notificationAccountId) return;
    handleSSONotification(notifications);
  }, [JSON.stringify(notifications)]);

  const handleSSONotification = async (messages: any) => {
    if (
      !messages?.data?.event ||
      !['sso.save.success', 'sso.save.error'].includes(messages?.data?.event)
    )
      return;

    if (messages?.data?.event === 'sso.save.success') {
      toast?.success(t('toast.success'), t('account.sso.config.updated'), 5000);
      await refetch();
      setInitialValues(parseInitialValues());
    }

    if (messages?.data?.event === 'sso.save.error') {
      toast?.error(t('toast.error'), messages?.data?.payload?.error, 5000);
    }

    setLoadingState(LoadingStatuses.IDLE);
    setAccountId(undefined);
  };

  const importSSOConfig = useImportSSOConfig();
  const handleImportSSOConfig = async (data: AccountSSOConfig) => {
    await importSSOConfig.create({
      accountId: id as string,
      displayName: data.isSSO ? data.displayName : undefined,
      importUrl: data.isSSO ? data.importUrl : undefined,
      isSSO: data.isSSO,
    });
    setLoadingState(LoadingStatuses.LOADING);
  };

  const ssoConfig = useSSOConfig();
  const handleUpdateSSOConfig = async (data: AccountSSOConfig) => {
    await ssoConfig.update({
      accountId: id as string,
      displayName: data.isSSO ? data.displayName : undefined,
      idpEntityId: data.isSSO ? data.idpEntityId : undefined,
      singleSignOnServiceUrl: data.isSSO
        ? data.singleSignOnServiceUrl
        : undefined,
      signingCertificate: data.isSSO ? data.signingCertificate : undefined,
      isSSO: data.isSSO,
    });
    setLoadingState(LoadingStatuses.LOADING);
  };

  const handleSubmit = async (data: AccountSSOConfig) => {
    if (id) {
      setAccountId(id);

      // Wait for a notify connection to establish
      await new Promise((resolve) => {
        const intervalId = setInterval(() => {
          if (isConnected.current) {
            clearInterval(intervalId);
            resolve(true);
          }
        }, 100);
      });

      try {
        if (isConnected.current) {
          if (data?.isManual) {
            await handleUpdateSSOConfig(data);
          } else {
            await handleImportSSOConfig(data);
          }
        }
      } catch (e) {
        handleAxiosError(e as Error | AxiosError, toast);
      }
    }
  };

  return (
    <>
      {isLoading ? (
        <FlexContainer direction="column" className="mt-5">
          <ProgressSpinner />
        </FlexContainer>
      ) : (
        <>
          {currentAccount && (
            <AppBreadCrumb
              model={getPathItems(account, currentAccount, can, t)}
            />
          )}
          <h1 className="mt-4">{account?.name}</h1>

          {account && <AccountTabs account={account} />}
          {currentAccount && !account && (
            <AccountTabs account={currentAccount} />
          )}

          <h3 className="mt-4 mb-4">{t('sso.config')}</h3>

          <SSOForm
            initialValues={initialValues}
            onSubmit={handleSubmit}
            state={loadingState}
            account={account}
          />
        </>
      )}
    </>
  );
};
