import { equal, handleAxiosError } from '@/api/helpers';
import { AppBreadCrumbTemplate } from '@/app/AppBreadCrumbTemplate';
import { client } from '@/client';
import { Account } from '@/client/accounts';
import {
  EmailTemplate,
  EmailTemplateFormState,
} from '@/client/email-templates';
import {
  RedirectPaths,
  RedirectPathsEnum,
  templateBuilderLegend,
} from '@/common/constants';
import { DialogContext } from '@/common/context';
import { TranslationFunctionType } from '@/common/types';
import { BeePluginEmailContainer } from '@/components/bee-plugin';
import { EmailTemplateInformationForm } from '@/components/email-templates/forms';
import { StateProvider } from '@/components/state-provider';
import { ClipboardContainer } from '@/components/templates';
import { useEmailTemplate, useUpdateEmailTemplate } from '@/hooks/query';
import { useAppSelector } from '@/hooks/store';
import { useToast } from '@/hooks/useToast';
import { selectCurrentAccount } from '@/store/features/account';
import { AppBreadCrumb } from '@/ui/breadcrumb';
import { AppButton } from '@/ui/buttons';
import { AbsoluteLoader, FlexContainer } from '@/ui/styled-ui';
import { BackButton } from '@/ui/styled-ui/BackButton';
import { AxiosError } from 'axios';
import { isEqual } from 'lodash';
import { MenuItem } from 'primereact/menuitem';
import { Steps } from 'primereact/steps';
import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import plainTemplate from './templates/plain-template.json';

const StyledMainContainer = styled(FlexContainer)`
  position: relative;
`;

const StyledFormWrapper = styled.div`
  padding: var(--default-padding);
`;

const getBreadcrumbs = (
  account: Account,
  emailTemplate: EmailTemplate,
  t: TranslationFunctionType,
): MenuItem[] => [
  {
    label: account?.name,
    url: RedirectPaths[RedirectPathsEnum.EDIT_ACCOUNT](account?.id),
    template: AppBreadCrumbTemplate,
  },
  {
    label: account?.isSystem
      ? t('templates.predefined.email')
      : t('templates.custom.email'),
    url: account?.isSystem
      ? RedirectPaths[RedirectPathsEnum.EMAIL_TEMPLATES_PREDEFINED]()
      : RedirectPaths[RedirectPathsEnum.EMAIL_TEMPLATES_CUSTOM](),
    template: AppBreadCrumbTemplate,
  },
  {
    label: t('templates.email.edit'),
    url: RedirectPaths[RedirectPathsEnum.EMAIL_TEMPLATES_UPDATE](
      emailTemplate?.id,
    ),
    template: AppBreadCrumbTemplate,
  },
];

const initialValues = (emailTemplate: EmailTemplate) => ({
  name: emailTemplate.name,
  subject: emailTemplate.subject,
  text: emailTemplate.text,
  active: emailTemplate.active,
  meta: emailTemplate.meta,
});

export const EmailTemplatesUpdatePage = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const { emailTemplate, isLoading } = useEmailTemplate({
    emailTemplateId: id,
  });
  const location = useLocation();
  const campaignId = location?.state?.campaignId;

  return (
    <StateProvider
      state={emailTemplate}
      isLoading={isLoading}
      loadingMessage={t('system.email.template.data.loading')}
      errorMessage={t('system.email.template.data.error')}
    >
      <EmailTemplatesUpdateWithState
        emailTemplate={emailTemplate as EmailTemplate}
        campaignId={campaignId}
      />
    </StateProvider>
  );
};

export const EmailTemplatesUpdateWithState: React.FC<{
  emailTemplate: EmailTemplate;
  campaignId?: string;
}> = ({ emailTemplate, campaignId }) => {
  const { t } = useTranslation();
  const currentAccount = useAppSelector(selectCurrentAccount);
  const navigate = useNavigate();
  const toast = useToast();
  const updateTemplate = useUpdateEmailTemplate();
  const { setDialogData } = useContext(DialogContext);

  const [showLoader, setShowLoader] = useState(false);

  const steps = [
    {
      label: t('templates.info'),
    },
    {
      label: t('templates.structure'),
    },
  ];
  // Set the initial step
  const [activeStep, setActiveStep] = useState(campaignId ? 1 : 0);
  const [blockNextStep, setBlockNextStep] = useState(campaignId ? false : true);
  const [isTemplateChanged, setIsTemplateChanged] = useState(false);

  // this is used to prevent the form from reinitializing on every change made
  const [initialFormValues, setInitialFormValues] =
    useState<EmailTemplateFormState>(initialValues(emailTemplate));
  // Set form initial values
  const [formValues, setFormValues] = useState<EmailTemplateFormState>(
    initialValues(emailTemplate),
  );

  // Set initial template for Bee
  const [initialTemplate, setInitialTemplate] = useState(
    emailTemplate.editor ?? (plainTemplate as object),
  );

  // Unblock step 2 if the form is valid
  const toggleStepBlocked = (isValid: boolean) => {
    setBlockNextStep(!isValid);
  };

  const showExitPrompt = () => {
    const formValuesToCompare = initialValues(emailTemplate);
    return !isTemplateChanged && isEqual(formValuesToCompare, formValues);
  };

  // Track changes to the template
  const handleChangeTemplate = (page: any) => {
    setInitialTemplate(page);
    setIsTemplateChanged(true);
  };

  // Check if a template with this name already exists
  const handleNextStep = async (
    name: string,
    account: Account,
    step: number,
  ) => {
    const filters = account.isSystem
      ? [equal('name', name), equal('account', null)]
      : [equal('name', name), equal('account', account.id)];

    try {
      const emailTemplates = await client.emailTemplates.getEmailTemplates({
        filters: filters,
      });

      // Check if the name is available and if not check wether the edited template is the one that has the name
      if (
        emailTemplates.count &&
        emailTemplate &&
        emailTemplates.result[0].id !== emailTemplate.id
      ) {
        toast?.error(
          t('templates.name.unavailable'),
          t('templates.name.another'),
        );
        return;
      }

      setActiveStep(step);
    } catch (e) {
      handleAxiosError(e as Error | AxiosError, toast);
    }
  };

  const triggerNextStep = async () =>
    await handleNextStep(formValues.name, currentAccount as Account, 1);

  // Handle SAVE from Bee container
  const handleSaveEmailTemplate = async (page: object, html: string) => {
    try {
      if (!currentAccount || !emailTemplate) {
        return;
      }
      // TODO: Hit the save bee plugin here, and get the page and html from it

      const params = {
        name: formValues.name,
        subject: formValues.subject,
        text: formValues.text,
        active: formValues.active,
        editor: page,
        html: html,
        // TODO: Uncomment when we integrate our own Save button
        // editor: initialTemplate || plainTemplate,
        // html: '<!DOCTYPE html></html>', // Find a way to get the html form BEE
        account: currentAccount.isSystem ? null : currentAccount.id,
      };

      setShowLoader(true);
      await updateTemplate.update({
        emailTemplateId: emailTemplate.id,
        updates: params,
      });

      setShowLoader(false);
      toast?.success(t('toast.success'), t('templates.email.updated'));
      if (!campaignId) {
        if (currentAccount.isSystem) {
          navigate(
            RedirectPaths[RedirectPathsEnum.EMAIL_TEMPLATES_PREDEFINED](),
          );
        } else {
          navigate(RedirectPaths[RedirectPathsEnum.EMAIL_TEMPLATES_CUSTOM]());
        }
      } else {
        navigate(RedirectPaths[RedirectPathsEnum.CAMPAIGNS_EDIT](campaignId), {
          state: {
            activeStep: 0,
            isTemplate: !!currentAccount?.isSystem,
          },
        });
      }
    } catch (e) {
      setShowLoader(false);
      handleAxiosError(e as Error | AxiosError, toast);
    }
  };

  const handleExit = () => {
    const navigateUrl =
      RedirectPaths[
        RedirectPathsEnum[
          currentAccount?.isSystem
            ? 'EMAIL_TEMPLATES_PREDEFINED'
            : 'EMAIL_TEMPLATES_CUSTOM'
        ]
      ]();

    if (showExitPrompt()) {
      navigate(navigateUrl);

      return;
    }

    setDialogData({
      type: 'confirmation',
      show: true,
      header: t('dialog.unsavedChanges'),
      message: t('dialog.unsavedChanges.confirm'),
      onAccept: () => navigate(navigateUrl),
    });
  };
  return (
    <StyledMainContainer
      direction="column"
      align="flex-start"
      justify="flex-start"
      minHeight="100%"
    >
      {showLoader && <AbsoluteLoader message={t('templates.saving')} />}
      <div id="template-header-container">
        {/* TODO: Prompt the user if any changes to the template or form were made and not saved */}
        <AppBreadCrumb
          model={getBreadcrumbs(
            currentAccount as Account,
            emailTemplate as EmailTemplate,
            t,
          )}
        />
        <h1>
          {t('templates.email.edit')}: {emailTemplate?.name}
        </h1>
        <Steps
          model={steps}
          activeIndex={activeStep}
          onSelect={async (e) => {
            // Check if the name is available only when switching from step 0 to 1
            if (e.index === 1 && currentAccount) {
              await triggerNextStep();
              return;
            }

            setInitialFormValues(formValues);
            setActiveStep(e.index);
          }}
          readOnly={blockNextStep}
        />
        <ClipboardContainer clipboardItems={templateBuilderLegend(t)} />
      </div>
      {campaignId && (
        <BackButton
          to={RedirectPaths[RedirectPathsEnum.CAMPAIGNS_EDIT](campaignId)}
          text={t('campaign.back')}
          className="mb-3"
          options={{
            state: {
              activeStep: 0,
              isTemplate: !!currentAccount?.isSystem,
            },
          }}
        />
      )}
      <div id="template-content-container">
        {activeStep === 0 && (
          <StyledFormWrapper>
            <EmailTemplateInformationForm
              initialValues={initialFormValues}
              onSubmit={async () => {
                // Check if the name is available and continue to step 2, else show an error
                if (currentAccount) {
                  await triggerNextStep();
                }
              }}
              onInputChange={setFormValues}
              onValidateForm={toggleStepBlocked}
              isSystem={currentAccount?.isSystem}
            />
          </StyledFormWrapper>
        )}
        {activeStep === 1 && (
          <BeePluginEmailContainer
            template={initialTemplate || plainTemplate}
            handleChange={handleChangeTemplate}
            handleSave={handleSaveEmailTemplate}
          />
        )}
      </div>
      <FlexContainer id="template-footer-container" justify="space-between">
        <div>
          <AppButton
            type="outlined"
            severity="secondary"
            label={t('button.exit')}
            className="mr-3"
            onClick={handleExit}
          />
          {/* TODO: Uncomment when we find a way to get the html onChange */}
          {/* <AppButton
            severity="secondary"
            label={t('button.save')}
            onClick={handleSaveEmailTemplate}
            isDisabled={blockNextStep}
          /> */}
        </div>

        <div>
          {activeStep > 0 && (
            <AppButton
              severity="secondary"
              label={t('button.back')}
              icon="pi pi-arrow-left"
              iconPos="left"
              onClick={() => {
                setInitialFormValues(formValues);
                setActiveStep(0);
              }}
              className="mr-3"
            />
          )}
          <AppButton
            severity="secondary"
            label={t('button.next')}
            icon="pi pi-arrow-right"
            iconPos="right"
            onClick={async () => {
              // Check if the name is available and continue to step 2, else show an error
              if (currentAccount) {
                await triggerNextStep();
              }
            }}
            isDisabled={blockNextStep || activeStep === 1}
          />
        </div>
      </FlexContainer>
    </StyledMainContainer>
  );
};
